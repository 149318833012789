import { Footer } from "components";
import styles from "screens/V3/CreateStoryScreen/CreateStoryScreen.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import UploadPhotos from "components/V3/UploadPhotos/UploadPhotos";
import CreateStoryModel from "models/CreateStoryModel";
import { useFormik } from "formik";
import { Form, Input } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import TallStoryCard from "components/V3/TallStoryCard/TallStoryCard";
import { useLanguage } from "hooks/useLanguage";
import { useUtilities } from "hooks/useUtilities";
import { createStoryAction } from "redux/actions/StoriesActions/CreateStoryAction/createStoryAction";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";
import { dotPulse } from "ldrs";
import { is } from "date-fns/locale";

const CreateStoryScreen = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  dotPulse.register();
  const { t } = useTranslation();
  const { dispatch, navigate } = useUtilities();
  const { textDirectionStyle, currentLanguage } = useLanguage();
  const { isMobile, addBreakpoint } = useResponsive();
  const [isMax800, setIsMax800] = useState<boolean>(false);
  addBreakpoint({
    value: 800,
    type: "MAX",
    setState: setIsMax800,
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<any>();
  const [storyTitle, setStoryTitle] = useState<string>("");

  const handleFileUpload = (event: any) => {
    console.log("event.target.files");
    console.log(event.target.files);
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    console.log("CHANGED FILE STATE");
    console.log(file);
  }, [file]);

  console.log("RENDERING");

  const { story, createStoryLoading, errors } = useSelector(
    (state: RootState) => state.createStoryReducer
  );
  // useEffect(() => {
  //   console.log(storyTitle);
  // }, [storyTitle]);

  const handleSubmit = async () => {
    if (file && storyTitle && storyTitle?.length > 0) {
      const data = {
        image: file,
        description: storyTitle,
      };
      await dispatch(createStoryAction(data));
      if (errors) {
        console.log("errors");
        console.log(errors);
      } else {
        console.log("story");
        console.log(story);
        navigate(`/${currentLanguage}/profile/view`);
      }
    }
  };

  return (
    <div className="w-full h-[100vh] flex flex-col justify-between items-center">
      <div
        className={`w-full flex flex-col items-center justify-start mb-12`}
        // px-[${
        //   isMobile ? "5%" : isMax800 ? "15%" : "30%"
        // }]
        style={{
          paddingInline: isMobile ? "3%" : isMax800 ? "15%" : "30%",
          paddingTop: isMobile ? "3%" : undefined,
        }}
      >
        {!isMobile && (
          <p className="mt-[2.94rem] text-[var(--new-blue)] text-[1.25rem] font-[700] mb-5">
            {t("create_story")}
          </p>
        )}
        {file && (
          <div className="relative">
            <div
              className="absolute top-3 right-2 flex items-center justify-center w-[2rem] h-[2rem] rounded-full bg-white cursor-pointer"
              onClick={() => {
                setFile(null);
              }}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </div>
            <div
              className={`w-[14.26194rem] h-[22.90556rem] rounded-[0.625rem] flex flex-col justify-between px-3 py-3 ${styles.shadow} hover:cursor-pointer`}
              style={{
                backgroundImage: `url('${encodeURI(
                  URL.createObjectURL(file)
                )}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            {/* <TallStoryCard image={encodeURI(URL.createObjectURL(file))} /> */}
          </div>
        )}
        {!file && (
          <>
            <input
              id="file"
              type="file"
              onChange={(event: any) => {
                handleFileUpload(event);
              }}
              ref={fileInputRef}
              accept="image/*"
            />
            <label
              // htmlFor="file"
              onClick={() => {
                fileInputRef.current?.click();
              }}
              className={`w-full flex items-center justify-center cursor-pointer h-[11.5625rem] rounded-[0.625rem] ${styles.shadow}`}
            >
              <div className="flex flex-col items-center justify-center gap-y-1">
                <div
                  className={`${styles.plusCircle} flex items-center justify-center`}
                >
                  <PlusOutlined />
                </div>
                <p className="text-center souq-s">{t("addImage")}</p>
              </div>
            </label>
          </>
        )}

        <div className={`w-full mb-12`}>
          <p
            dir={textDirectionStyle}
            className="text-right font-bold mb-2 mt-10 required"
          >
            {t("story_title")}
          </p>
          <>
            <Input
              placeholder={t("story_desc")}
              size="large"
              className="w-full"
              name="title"
              onChange={(e: any) => {
                setStoryTitle(e.target.value);
              }}
              // status={formik.errors.title ? "error" : ""}
              dir={textDirectionStyle}
            />
          </>
        </div>

        <button
          className={`${
            storyTitle && storyTitle?.length > 0 && file
              ? styles.publishButton
              : styles.publishButtonDisabled
          }`}
          onClick={handleSubmit}
        >
          {!createStoryLoading ? (
            <p>{t("publish")}</p>
          ) : (
            <l-dot-pulse size="25" speed="1.3" color="white"></l-dot-pulse>
          )}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default CreateStoryScreen;
