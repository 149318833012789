import
{
    AMANLEEK_AUTH_LOADING,
    AMANLEEK_AUTH_SUCCESS,
    AMANLEEK_AUTH_FAILURE
}
from "redux/types";

const initialState = {
    amaanleekAuthLoading: false,
    amanleekToken: null,
    errors: null
}

export const amanleekAuthenticationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AMANLEEK_AUTH_LOADING :
            return {...state, amaanleekAuthLoading: action.payload};
        case AMANLEEK_AUTH_SUCCESS :
            return {...state, amanleekToken: action.payload.access_token};
        case AMANLEEK_AUTH_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}