import { ArrowLeftIcon } from "assets/icons/V3";
import styles from "components/V3/ExhibitorList/ExhibitorList.module.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";
import { Spin } from "antd";
import CircularExhibitorCard from "../CircularExhibitorCard/CircularExhibitorCard";

interface ExhibitorListProps {}

const ExhibitorList: FC<ExhibitorListProps> = ({}) => {
  const { t } = useTranslation();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const navigate = useNavigate();
  const { homeExhibitorsLoading, homeExhibitors } = useSelector(
    (state: RootState) => state.listHomeScreenExhibitorsReducer
  );

  return (
    <div
      dir="rtl"
      className={`py-4 w-full h-[12rem] rounded-[1.25rem] flex flex-col justify-between items-start bg-white ${styles.shadow}`}
    >
      <div
        dir="rtl"
        className="w-full flex flex-row items-center justify-between px-4"
      >
        <p className={`text-[1.25rem]`}>{t("homepage_special_dealers")}</p>
        <p
          className="hover:cursor-pointer"
          onClick={() => {
            navigate(`/${currentLanguage}/exhibitions`);
          }}
        >
          {t("more")}
        </p>
      </div>
      <Spin spinning={homeExhibitorsLoading} className="w-full">
        <div
          className={`w-full flex flex-row items-center gap-x-5 overflow-x-auto px-4`}
        >
          {homeExhibitors?.map((item: any) => {
            return (
              <CircularExhibitorCard
                id={item.id}
                image={item.image}
                name={item.name}
              />
            );
          })}
        </div>
      </Spin>
    </div>
  );
};

export default ExhibitorList;
