import styles from "components/CustomButton/CustomButton.module.css";
import { FC } from "react";

interface ButtonProps {
  className?: any;
  id?: any;
  onClick?: any;
  children: any;
  textStyling?: any;
  boxShadow?: any;
  isBold?: boolean;
  type?: any;
  color?: any;
  disabled?: boolean;
  buttonStyle?: any;
  addShadow?: boolean;
  opacity?: any;
}

const CustomButton: FC<ButtonProps> = ({
  className,
  id,
  onClick,
  children,
  textStyling,
  addShadow = false,
  color,
  boxShadow,
  isBold = false,
  opacity = 1,
  buttonStyle,
  type = "button",

  disabled = false,
}) => {
  return (
    <button
      id={id}
      disabled={disabled}
      style={{
        backgroundColor: color,
        boxShadow: boxShadow,
        opacity: opacity !== null ? opacity : disabled ? 0.1 : 1.0,
        fontFamily: isBold ? "rbFontBold" : "rbFont",
        userSelect: "none",
        ...buttonStyle,
        // borderRadius:".45rem"
      }}
      className={`${disabled && "cursor-not-allowed"} ${textStyling} ${
        addShadow ? styles.buttonShadow : ""
      } ${className}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default CustomButton;
