import { StarIcon } from "assets/icons/V3";
import { tachometer } from "assets/icons";
import { ClockCircleFilled, HeartFilled } from "@ant-design/icons";
import styles from "components/V3/NewCustomPostCard/NewCustomPostCard.module.css";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { souqCarWhite } from "assets/icons";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { favouritePostAction } from "redux/actions/FavouritePostActions/favouritePostAction";

interface PostCardProps {
  isSpecialAd: boolean;
  showActions: boolean;
  id: any;
  width?: any;
  callsCount: number;
  suspendClick?: any;
  postDescription?: any;
  reactivateClick?: any;
  viewsCount: number;
  uploadDate?: string;
  postType?: string[];
  carMaker?: string;
  postTitle: string;
  paymentOption?: string;
  category: any;
  customerName: string;
  isFavorited?: boolean;
  city: string;
  price: string;
  carCondition: string;
  productionDate: string;
  kilometrage: string;
  className?: any;
  isMobile?: boolean;
  postCardStyle?: any;
  isDeleted?: any;
  handlePostEditClick?: any;
  brandLogo: any;
  image: any;
  created_at: any;
  address: any;
  favouriteCallback?: any;
}

const NewCustomPostCard: FC<PostCardProps> = ({
  isSpecialAd = false,
  isDeleted = false,
  isFavorited = false,
  width,
  showActions,
  id,
  suspendClick,
  brandLogo,
  reactivateClick,
  handlePostEditClick,
  postDescription,
  callsCount,
  viewsCount,
  uploadDate,
  postType,
  carMaker,
  postTitle,
  paymentOption,
  customerName = "Not Found",
  city,
  category,
  price,
  carCondition,
  productionDate,
  kilometrage,
  isMobile = false,
  className = "",
  postCardStyle,
  image,
  created_at,
  address,
  favouriteCallback,
}) => {
  const dispatch: any = useDispatch();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const [favouritedState, setFavouritedState] = useState(isFavorited);

  const { t } = useTranslation();
  const isArabic = currentLanguage === "ar" ? "rtl" : "ltr";

  const navigate = useNavigate();

  const isCash = (paymentType: string | undefined) => {
    return paymentType === "Cash" || paymentType === "كاش";
  };

  const favouriteIcons = [
    "M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z",
  ];

  const handleFavourite = () => {
    setFavouritedState((prevState) => !prevState);
    dispatch(favouritePostAction(id as string, !favouritedState));
    favouriteCallback && favouriteCallback();
  };

  return (
    <div
      dir={isArabic}
      className={` w-full ${
        isMobile
          ? !isCash(paymentOption)
            ? "h-[8rem]"
            : "h-[7.5rem]"
          : !isCash(paymentOption)
          ? "h-[13rem]"
          : "h-[12.625rem]"
      } flex flex-row items-center bg-white rounded-[0.9375rem] pr-[0.5rem] ${
        isSpecialAd ? "border-[0.5px] border-[var(--souq-orange)]" : ""
      } ${styles.shadow} hover:cursor-pointer`}
      onClick={(e: any) => {
        if (favouriteIcons.includes(e.target.getAttribute("d"))) {
          handleFavourite();
        } else {
          navigate(`/${currentLanguage}/ads/show/${id}/${postTitle}`);
        }
      }}
    >
      <div
        className={`relative ${
          isMobile ? "w-[7.125rem]" : "w-[20rem]"
        } h-[90%] ${
          isMobile ? "rounded-[0.3125rem]" : "rounded-[0.625rem]"
        } min-w-[7.125rem]`}
      >
        <div
          className={`w-full h-full ${
            isMobile ? "rounded-[0.3125rem]" : "rounded-[0.625rem]"
          } absolute bg-[var(--new-blue)] flex items-center justify-center`}
        >
          <img src={souqCarWhite} className="w-[90%]" />
        </div>
        <div
          className={`absolute w-full h-full ${
            isMobile ? "rounded-[0.3125rem]" : "rounded-[0.625rem]"
          } flex flex-col items-start justify-between py-2 px-2`}
          style={{
            backgroundImage: `url('${encodeURI(image)}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {isSpecialAd && (
            <div
              className={`flex flex-row items-center justify-between rounded-[1rem] bg-[var(--souq-orange)] ${
                !isMobile ? "pl-2" : ""
              }`}
            >
              <img className="w-[1.34631rem] h-[0.99425rem]" src={StarIcon} />
              {!isMobile && <p className="text-white">{t("special_ad")}</p>}
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-full py-2 px-3 flex flex-col items-start justify-between">
        <div
          className={`w-full flex flex-col items-start justify-center ${
            isMobile ? "" : "gap-y-1"
          }`}
        >
          <div className="w-full flex items-center justify-between flex-row gap-x-2">
            <div className="flex flex-row items-center gap-x-2">
              <ClockCircleFilled
                className={`${isMobile ? "w-[0.5625rem]" : "w-[0.845rem]"} ${
                  isMobile ? "h-[0.57694rem]" : "h-[0.79263rem]"
                } ${isMobile ? "text-[0.57694rem]" : "text-[0.79263rem]"}`}
              />
              <p
                className={`${
                  isMobile ? "text-[0.625rem]" : "text-[0.9375rem]"
                }`}
              >
                {created_at}
              </p>
            </div>

            {userData?.token && (
              <HeartFilled
                className={`${
                  !favouritedState ? "text-[#bcbcbc]" : "text-[red]"
                } ${isMobile ? "" : "text-[1.25rem]"}`}
              />
            )}
          </div>
          <p
            className={`${
              isMobile ? "text-[0.8125rem]" : "text-[1.25rem]"
            } font-[700] overflow-ellipsis line-clamp-1`}
          >
            {postTitle}
          </p>
          <p
            className={`${
              isMobile ? "text-[0.625rem]" : "text-[1.125rem]"
            } overflow-ellipsis line-clamp-1`}
          >
            {city}, {address}
          </p>
          {!isCash(paymentOption) && (
            <p
              className={`${isMobile ? "" : "text-[1.125rem]"} ${
                isCash(paymentOption)
                  ? "text-[var(--new-blue)]"
                  : "text-[var(--souq-orange)]"
              }`}
            >
              {paymentOption}
            </p>
          )}
        </div>
        <div
          className={`w-full flex flex-row ${
            !isCash(paymentOption) ? "items-end" : "items-center"
          } justify-between gap-x-1`}
        >
          <div className="flex flex-row items-center justify-end gap-x-1">
            <img
              className={`${
                isMobile
                  ? "w-[0.75775rem] h-[0.69238rem]"
                  : "w-[1.2305rem] h-[1.05881rem]"
              }`}
              src={tachometer}
            />
            <p
              className={`${isMobile ? "text-[0.625rem]" : "text-[1.125rem]"}`}
            >
              {kilometrage?.toLocaleString()}
            </p>
            <div
              className={`bg-[var(--new-blue)] w-[0.5px] ${
                isMobile ? "h-[0.70513rem]" : "h-[1.158rem]"
              }`}
            ></div>
            <p
              className={`${isMobile ? "text-[0.625rem]" : "text-[1.125rem]"}`}
            >
              {carCondition}
            </p>
          </div>
          <div className={`flex flex-col items-center justify-start`}>
            {!isCash(paymentOption) && (
              <p className={`${isMobile ? "text-[0.5rem]" : ""}`}>
                {t("upfront")}
              </p>
            )}
            <div
              style={{
                backgroundColor: "rgba(0, 80, 255, 0.1)",
              }}
              className={`${
                isMobile
                  ? "w-[5.25rem] h-[1.8125rem]"
                  : "w-[9.95838rem] h-[3.10594rem]"
              } ${
                isMobile ? "rounded-[0.90625rem]" : "rounded-[1.875rem]"
              } flex items-center justify-center`}
            >
              <p className={`${isMobile ? "souq-xs" : "text-[1.25rem]"}`}>
                {price?.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCustomPostCard;
