import { useSelector } from "react-redux";
import { CSSProperties } from "react";
import { RootState } from "redux/rootReducer";

export const useLanguage = () => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const isArabic: boolean = currentLanguage === "ar";
  const isEnglish: boolean = currentLanguage === "en";

  const textDirectionStyleFlipped: CSSProperties["direction"] = isArabic
    ? "ltr"
    : "rtl";
  const textDirectionStyle: CSSProperties['direction'] = isArabic ? "rtl" : "ltr";
  const textAlign: CSSProperties['textAlign'] = isArabic ? "right" : "left";
  const flexDirectionStyle: string = isArabic ? "row-reverse" : "row";
  const flexColDirectionStyle: string = isArabic ? "col-reverse" : "col";
  const flexAlignStyle: string = isArabic ? "end" : "start";
  const flexAlignStyleReverse: string = isArabic ? "start" : "end";

  return {
    currentLanguage,
    isEnglish,
    isArabic,
    textDirectionStyle,
    textDirectionStyleFlipped,
    flexDirectionStyle,
    flexColDirectionStyle,
    flexAlignStyle,
    flexAlignStyleReverse,
    textAlign,
  };
};
