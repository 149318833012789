import styles from "screens/V3/SpecialAds/SpecialAds.module.css";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Input, Select, Empty, Spin, Tabs } from "antd";
import { gridView, columnView } from "assets/icons";
import {
  SearchOutlined,
  RightOutlined,
  ReloadOutlined,
  SortAscendingOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import CustomButton from "components/CustomButton/CustomButton";
import { listCarConditionAction } from "redux/actions/CarConditionActions/listCarConditionAction";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";
import { listYearsAction } from "redux/actions/YearsActions/listYearsAction";
import { listTransmissionTypesAction } from "redux/actions/TransmissionTypesActions/listTransmissionTypesAction";
import { listEngineCapacityAction } from "redux/actions/EngineCapacityActions/listEngineCapacityAction";
import { listCarBodiesAction } from "redux/actions/CarBodiesActions/listCarBodiesAction";
import { listFuelTypesAction } from "redux/actions/FuelTypesActions/listFuelTypesAction";
import { useNavigate } from "react-router-dom";
import { listCarModelsAction } from "redux/actions/CarModelsActions/listCarModelsAction";
import { listCitiesAction } from "redux/actions/CitiesActions/listCitiesAction";
import { listColorsAction } from "redux/actions/ColorsAction/listColorsAction";
import { changeCurrentPageAction } from "redux/actions/CurrentPageAction/changeCurrentPageAction";
import Footer from "components/Footer/Footer";
import Seo from "components/Seo/Seo";
import { listSortByFiltersAction } from "redux/actions/listSortByFiltersAction/listSortByFiltersAction";
import { getCarModelNameService } from "services/OneTimeServices/getCarModelNameService";
import { souqCarLogo } from "assets/icons";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";
import NewCustomPostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import { FilterBars, OrangeFilterBars, RetryIcon } from "assets/icons/V3";
import { useSearch } from "hooks/useSearch";
import { listAllFeaturedAdsAction } from "redux/actions/FeaturedPostsActions/listAllFeaturedAdsAction";
import AdvancedSearchSheet from "components/AdvancedSearchSheet/AdvancedSearchSheet";
import ShareButton from "components/V3/ShareButton/ShareButton";
import NewPaginator from "components/V3/NewPaginator/NewPaginator";
import { useDropdowns } from "hooks/useDropdowns";

const SpecialAds = () => {
  const { t } = useTranslation();

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { getSearchParam } = useSearch();

  const [searchTitle, setSearchTitle] = useState("");

  const [isMobile, setIsMobile] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(true);

  const [carCondition, setCarCondition] = useState<string>("3");
  const [selectedTransmissionType, setSelectedTransmissionType] = useState(-1);

  // Meta Tags States
  const [titleMetaTag, setTitleMetaTag] = useState("");

  const {
    allFeaturedAdsLoading,
    allFeaturedAds,
    allFeaturedAdsCurrentPage,
    allFeaturedAdsLastPage,
  } = useSelector((state: RootState) => state.listAllFeaturedAdsReducer);

  const { sortByFilters, sortByFiltersLoading } = useSelector(
    (state: RootState) => state.listSortByFiltersReducer
  );

  const { featuredPostsLoading, featuredPosts } = useSelector(
    (state: RootState) => state.listFeaturedPostsReducer
  );

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const { carConditionLoading, carConditions } = useSelector(
    (state: RootState) => state.listCarConditionReducer
  );
  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );
  const { yearsLoading, years } = useSelector(
    (state: RootState) => state.listYearsReducer
  );
  const { transmissionTypesLoading, transmissionTypes } = useSelector(
    (state: RootState) => state.listTransmissionTypesReducer
  );
  const { engineCapactiyLoading, engineCapacity } = useSelector(
    (state: RootState) => state.listEngineCapacityReducer
  );
  const { carBodiesLoading, carBodies } = useSelector(
    (state: RootState) => state.listCarBodiesReducer
  );
  const { fuelTypesLoading, fuelTypes } = useSelector(
    (state: RootState) => state.listFuelTypesReducer
  );
  const { carModelsLoading, carModels } = useSelector(
    (state: RootState) => state.listCarModelsReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );
  const { colorsLoading, colors } = useSelector(
    (state: RootState) => state.listColorsReducer
  );

  const isArabic = currentLanguage === "ar";

  const [carMakerFilter, setCarMakerFilter] = useState("");
  const [yearFromFilter, setYearFromFilter] = useState("");
  const [yearToFilter, setYearToFilter] = useState("");
  const [carModelFilter, setCarModelFilter] = useState("");
  const [citiesFilter, setCitiesFilter] = useState("");
  const [colorFilter, setColorFilter] = useState("");
  const [carConditionFilter, setCarConditionFilter] = useState("");
  const [engineCapacityFilter, setEngineCapacityFilter] = useState("");
  const [carBodyFilter, setCarBodyFilter] = useState("");
  const [fuelTypeFilter, setFuelTypeFilter] = useState("");
  const [transmissionTypeFilter, setTransmissionTypeFilter] = useState("");
  const [priceFromFilter, setPriceFromFilter] = useState("");
  const [priceToFilter, setPriceToFilter] = useState("");
  const [sortByFilter, setSortByFilter] = useState("");
  const [distanceFilter, setDistanceFilter] = useState("");

  const [pageTitle, setPageTitle] = useState("Buy & Sell");

  const [viewType, setViewType] = useState("list");

  const [showBottomModalSheet, setShowBottomModalSheet] = useState(false);

  const { carMakerDropdown, carModelsDropdown } = useDropdowns();

  useEffect(() => {
    const element = document.getElementById("header");
    element?.scrollIntoView();
    // element?.scrollIntoView({behavior:'smooth'});
  }, []);

  const yearsDropdown = years?.map((item: any) => {
    return {
      value: item,
      label: item,
    };
  });

  const engineCapacityDropdown = engineCapacity?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const transmissionTypesDropdown = transmissionTypes?.map((item: any) => {
    return {
      value: item.key,
      lavel: item.name,
    };
  });

  const carBodiesDropdown = carBodies?.map((item: any) => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  const fuelTypesDropdown = fuelTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const colorsDropdown = colors?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const sortByFiltersDropdown = sortByFilters?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const getFuelName = (key: string) => {
    var returnValue = undefined;
    if (key) {
      fuelTypes?.map((item: any) => {
        if (key === item.key) {
          returnValue = item.name;
        }
      });
    }

    return returnValue;
  };

  /*
        1 - buy & sell (current page = 3)
        2 - rental (current page = 2)
        3 - spare parts (current page = 1)
    */

  const scrollToTop = () => {
    const topElement = document.getElementById("search-section");
    topElement?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const getParamValue: any = (param: string) => {
    var value = null;
    Array.from(searchParams.entries()).forEach((item: any, idx: number) => {
      if (item[0] === param) {
        value = item[1];
      }
    });
    return value;
  };

  const getSearchCategory = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id") {
        switch (arr[i][1]) {
          case "1":
            return 4;
          case "2":
            return 3;
          case "3":
            return 2;
          case "4":
            return 1;
        }
      }
    }
    return 4;
  };

  const getCategoryId = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id") {
        switch (arr[i][1]) {
          case "1":
            return 1;
          case "2":
            return 2;
          case "3":
            return 3;
          case "4":
            return 4;
        }
      }
    }
    return 2;
  };

  const maxFirstPages = 3;

  const getCarModelName = async (carModelId: any) => {
    const carModel = await getCarModelNameService(carModelId);
    return carModel;
  };

  const [carModelName, setCarModelName] = useState("");

  async function scrollToElementSmoothly(elementId?: string) {
    const element = document.getElementById(elementId || "header");

    if (!element) {
      return; // Element not found
    }

    return new Promise<void>((resolve) => {
      element.scrollIntoView({ behavior: "smooth" });

      // Wait for a short time (adjust the time as needed)
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  const createFirstPages = (totalNumber: any) => {
    const array = [];
    for (var i = 1; !(i > totalNumber) && i <= maxFirstPages; i++) {
      array.push(i);
    }
    return array;
  };

  const handlePrevPaginationClick = async () => {
    await scrollToElementSmoothly("search-section");
    const nextPage = allFeaturedAdsCurrentPage - 1;
    navigate({
      pathname: `/${currentLanguage}/special-ads`,
      search: `?${getSearchURL().split("&page=")[0]}&page=${nextPage}`,
    });
  };

  const handlePaginationClick = async (targetPage: any) => {
    await scrollToElementSmoothly("search-section");
    navigate({
      pathname: `/${currentLanguage}/special-ads`,
      search: `?${getSearchURL().split("&page=")[0]}&page=${targetPage}`,
    });
  };

  const handleNextPaginationClick = async () => {
    await scrollToElementSmoothly("search-section");
    const nextPage = allFeaturedAdsCurrentPage + 1;
    navigate({
      pathname: `/${currentLanguage}/special-ads`,
      search: `?${getSearchURL().split("&page=")[0]}&page=${nextPage}`,
    });
  };

  useEffect(() => {
    const getName = async () => {
      try {
        const carModelId = getParamValue("car_model_id") ?? "";
        if (carModelId !== "") {
          const response = await getCarModelNameService(carModelId);
          setCarModelName(response);
        } else {
          setCarModelName("");
        }
      } catch (error) {}
    };

    getName();
  }, []);

  const getPageTitleH1 = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id" && arr[i][1] === "2") {
        return "ايجار و تأجير سيارات في مصر";
      }
      if (arr[i][0] === "car_condition" && arr[i][1] === "Used") {
        return "سيارات مستعملة للبيع في مصر";
      }
      if (arr[i][0] === "brand_id") {
        var condition = getParamValue("car_condition") ?? "";
        if (condition !== "") {
          condition = carConditions?.find(
            (item: any) => item.key === condition
          )?.name;
        }
        return `${getBrandName(arr[i][1])}-سيارات ${getBrandName(
          arr[i][1]
        )} ${carModelName} ${condition} للبيع`;
      }
    }
    return getPageTitle();
  };

  const getPageTitle = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id") {
        switch (arr[i][1]) {
          case "1":
            return t("homepage_buy_and_sell");
          case "2":
            return t("homepage_rental");
          case "3":
            return t("navbar_maintenance_text");
          case "4":
            return t("navbar_car_parts_text");
        }
      }
    }
    return t("homepage_buy_and_sell");
  };

  const getSearchFilterURL = (): string => {
    interface Params {
      [key: string]: any;
    }

    var allParams: Params = {
      category_id: getCategoryId(),
      brand_id: carMakerFilter,
      car_model_id: carModelFilter,
      car_condition: carConditionFilter,
      transmission_type: transmissionTypeFilter,
      car_type: carBodyFilter,
      price_from: priceFromFilter,
      price_to: priceToFilter,
      city_id: citiesFilter,
      fuel_type: fuelTypeFilter,
      color_id: colorFilter,
      engine_capacity: engineCapacityFilter,
      sort_by: sortByFilter,
      title: searchTitle,
    };

    var counter = 0;
    var finalParams: string = "";
    Object.keys(allParams).map(function (key: string) {
      if (
        allParams[key] !== "" &&
        allParams[key] !== undefined &&
        allParams[key] !== null
      ) {
        counter++;
        if (counter === 1) finalParams += "?" + key + "=" + allParams[key];
        else finalParams += "&" + key + "=" + allParams[key];
      }
    });

    return finalParams;
  };

  const getBrandName = (value: any) => {
    return carMakers?.find((item: any) => item?.id === Number(value))?.name;
  };

  /********************** Dynamic Meta Tags Getters ********************/

  const getMetaTagTitle = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id" && arr[i][1] === "2") {
        return "ايجار و تأجير سيارات سوق كار - سوق سيارات للايجار مصر";
      }
      if (arr[i][0] === "car_condition" && arr[i][1] === "Used") {
        return "سيارات مستعملة للبيع في مصر - اكبر سوق سيارات للبيع في مصر سوق كار";
      }
      if (arr[i][0] === "brand_id") {
        var condition = getParamValue("car_condition") ?? "";
        if (condition !== "") {
          condition = carConditions?.find(
            (item: any) => item.key === condition
          )?.name;
        }
        return `${getBrandName(arr[i][1])}-سيارات ${getBrandName(
          arr[i][1]
        )} ${carModelName} ${condition} للبيع`;
      }
    }
    return getPageTitle();
  };

  const getMetaTagKeywords = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id" && arr[i][1] === "2") {
        return "سيارات للايجار، ايجار سيارات، ايجار سيارات مصر، تأجير سيارات";
      }
      if (arr[i][0] === "car_condition" && arr[i][1] === "Used") {
        return "سيارات مستعملة للبيع، سيارات مستعملة للبيع في مصر، عربيات مستعملة للبيع، سيارات للبيع، عربيات للبيع، سيارات مستعملة، عربيات مستعملة، سيارات للبيع في مصر";
      }
      if (arr[i][0] === "brand_id") {
        var condition = getParamValue("car_condition") ?? "";
        if (condition !== "") {
          condition = carConditions?.find(
            (item: any) => item.key === condition
          )?.name;
        }
        return `${getBrandName(arr[i][1])}،سيارات ${getBrandName(
          arr[i][1]
        )} ${carModelName} ${condition} للبيع، سيارات ${getBrandName(
          arr[i][1]
        )} للبيع في مصر، ${getBrandName(arr[i][1])} ${condition} في مصر`;
      }
    }
    return "سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار";
  };

  const getMetaTagDescription = () => {
    const arr = Array.from(searchParams.entries());
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "category_id" && arr[i][1] === "2") {
        return "ايجار و تأجير سيارات مصر - سوق كار سيارات للايجار الاكبر في مصر";
      }
      if (arr[i][0] === "car_condition" && arr[i][1] === "Used") {
        return "سيارات مستعملة للبيع في مصر - سوق كار اكبر سوق سيارات مستعملة للبيع في مصر";
      }
      if (arr[i][0] === "brand_id") {
        var condition = getParamValue("car_condition") ?? "";
        if (condition !== "") {
          condition = carConditions?.find(
            (item: any) => item.key === condition
          )?.name;
        }
        return `${getBrandName(arr[i][1])}-سيارات ${getBrandName(
          arr[i][1]
        )} ${carModelName} ${condition} للبيع`;
      }
    }
    return `سوق كار - ${pageTitle}`;
  };

  const getSearchURL = () => {
    var searchFilters = "";
    Array.from(searchParams.entries()).forEach((item: any, idx: number) => {
      if (idx === Array.from(searchParams.entries()).length - 1) {
        searchFilters += `${item[0]}=${item[1]}`;
      } else {
        searchFilters += `${item[0]}=${item[1]}&`;
      }
    });
    return searchFilters;
  };

  const [selectedFamousFilter, setSelectedFamousFilter] = useState(-1);

  const isFilterSelected = (item: any) => {
    return (
      getSearchParam(item?.query.split("=")[0]) === item?.query.split("=")[1]
    );
  };

  const famousSearchFilters = [
    {
      id: 0,
      name: t("electric_cars"),
      query: "fuel_type=Electric",
    },
    {
      id: 1,
      name: t("new_cars"),
      query: "car_condition=New",
    },
    {
      id: 2,
      name: t("rental_only"),
      query: "rental_only=true",
    },
    {
      id: 3,
      name: t("almost_new"),
      query: "car_condition=Almost New",
    },
    {
      id: 4,
      name: t("automatic"),
      query: "transmission_type=Automatic",
    },
    {
      id: 6,
      name: t("sedan"),
      query: "car_type=Sedan",
    },
    {
      id: 7,
      name: t("used"),
      query: "car_condition=Used",
    },
    {
      id: 5,
      name: t("homepage_advanced_search"),
      query: "",
    },
  ];

  /********************** End Dynamic Meta Tags Getters ********************/

  useEffect(() => {
    dispatch(changeCurrentPageAction(-1));

    setPageTitle(getPageTitle());
    // meta tags

    dispatch(listCarConditionAction());
    dispatch(listCarMakerAction());
    dispatch(listYearsAction());
    dispatch(listTransmissionTypesAction());
    dispatch(listEngineCapacityAction());
    dispatch(listCarBodiesAction());
    dispatch(listFuelTypesAction());
    dispatch(listCitiesAction());
    dispatch(listTransmissionTypesAction());
    dispatch(listColorsAction());
    dispatch(listSortByFiltersAction());
  }, []);

  useEffect(() => {
    var searchFilters = "";
    Array.from(searchParams.entries()).forEach((item: any, idx: number) => {
      if (idx === Array.from(searchParams.entries()).length - 1) {
        searchFilters += `${item[0]}=${item[1]}`;
      } else {
        searchFilters += `${item[0]}=${item[1]}&`;
      }
    });
    dispatch(listAllFeaturedAdsAction(searchFilters));
  }, [searchParams]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 960) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
        // setFiltersOpen(false);
      }
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      setFiltersOpen(false);
    }
  }, []);

  const isDefined = (value: any) => {
    return value !== undefined && value !== null;
  };

  const tabBarItems = [
    {
      key: "1",
      label: t("for_sale"),
      children: "",
    },
    {
      key: "2",
      label: t("for_rent"),
      children: "",
    },
    {
      key: "3",
      label: t("navbar_car_parts_text"),
      children: "",
    },
  ];

  return (
    <>
      {/* Desktop Search Navbar */}

      {!isMobile && (
        <div
          dir="rtl"
          className={`w-full flex flex-row items-center justify-center h-[5rem] bg-white ${styles.border} ${styles.searchNavbar} pb-2`}
        >
          <div className="w-full flex flex-row items-center justify-center gap-x-2 px-2 md:justify-start md:w-[70%]">
            {!isMobile && (
              <p className="text-[0.875rem]">{t("keyword_search")}</p>
            )}
            <div className="navbar-keyword-search">
              <Input
                placeholder={t("keyword_search_placeholder")}
                size="large"
                className={isMobile ? "w-[17rem]" : `w-[32rem]`}
                value={searchTitle}
                onChange={(e) => {
                  setSearchTitle(e.target.value);
                }}
              />
            </div>
            <CustomButton
              className={`w-[5.1875rem] h-[3.25rem] rounded-[0.5rem]`}
              color={"var(--new-blue)"}
              onClick={() => {
                if (searchTitle.trim() !== "")
                  navigate(
                    `/${currentLanguage}/special-ads?category_id=${getCategoryId()}&title=${searchTitle}`
                  );
              }}
            >
              <SearchOutlined className={`text-[1.25rem] text-white`} />
            </CustomButton>
          </div>
        </div>
      )}

      {/* End Descktop Search Navbar */}

      {/* Mobile Search Navbar */}
      {isMobile && (
        <div
          dir="rtl"
          className={`w-full flex flex-col items-center gap-y-3 justify-center h-[9rem] bg-white ${styles.border} ${styles.searchNavbar} pb-2`}
        >
          <div
            dir="rtl"
            className={`w-full flex flex-row items-center justify-start bg-white`}
          >
            <div className="w-full flex flex-row items-center justify-start gap-x-2 px-4 md:justify-start md:w-[65%]">
              {!isMobile && (
                <p className="text-[0.875rem]">{t("keyword_search")}</p>
              )}
              <div className="navbar-keyword-search">
                <Input
                  placeholder={t("keyword_search_placeholder")}
                  size="large"
                  value={searchTitle}
                  className={isMobile ? "w-[17rem]" : `w-[32rem]`}
                  onChange={(e) => {
                    setSearchTitle(e.target.value);
                  }}
                />
              </div>
              <CustomButton
                className={`w-[5.1875rem] h-[3.25rem] rounded-[0.5rem]`}
                color={"var(--new-blue)"}
                onClick={() => {
                  if (searchTitle.trim() !== "")
                    navigate(
                      `/${currentLanguage}/ads/view?category_id=${getCategoryId()}&title=${searchTitle}`
                    );
                }}
              >
                <SearchOutlined className={`text-[1.25rem] text-white`} />
              </CustomButton>
            </div>
          </div>
          <div className="w-full flex flex-row items-center justify-start gap-x-2 overflow-x-scroll overflow-y-hidden px-3 py-3">
            {famousSearchFilters?.map((item: any) => {
              if (item.id !== 5) {
                const isSelected = isFilterSelected(item);
                return (
                  <CustomButton
                    className={`flex items-center justify-center h-[2.5625rem] px-5 border-[0.5px] border-[var(--souq-orange)] rounded-[1.5rem]`}
                    color={isSelected ? "var(--souq-orange)" : "white"}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedFamousFilter(-1);
                        setSearchParams(
                          `category_id=${getSearchParam("category_id")}&`
                        );
                      } else {
                        setSelectedFamousFilter(item.id);
                        setSearchParams(
                          `category_id=${getSearchParam("category_id")}&${
                            item.query
                          }`
                        );
                      }
                    }}
                  >
                    <p
                      className={`${
                        isSelected ? "text-white" : "text-black"
                      } text-[0.75rem] whitespace-nowrap`}
                    >
                      {item.name}
                    </p>
                  </CustomButton>
                );
              } else {
                const isSelected = isFilterSelected(item);
                return (
                  <CustomButton
                    className={`flex items-center justify-center h-[2.5625rem] px-5 border-[0.5px] border-[var(--souq-orange)] rounded-[1.5rem]`}
                    color={isSelected ? "var(--souq-orange)" : "white"}
                    onClick={() => {
                      // HENA
                      setShowBottomModalSheet(true);
                    }}
                  >
                    <img src={FilterBars} />
                    <p
                      className={`${
                        isSelected ? "text-white" : "text-black"
                      } text-[0.75rem] whitespace-nowrap`}
                    >
                      {item.name}
                    </p>
                  </CustomButton>
                );
              }
            })}
          </div>
          <AdvancedSearchSheet
            title={t("search_filters")}
            isOpen={showBottomModalSheet}
            isSpecial={true}
            setOpen={setShowBottomModalSheet}
            carCondition={carCondition}
            carConditionFilter={carConditionFilter}
            setCarConditionFilter={setCarConditionFilter}
            setCarCondition={setCarCondition}
            carMakerFilter={carMakerFilter}
            setCarMakerFilter={setCarMakerFilter}
            carModelFilter={carModelFilter}
            setCarModelFilter={setCarModelFilter}
            productionYearFilter={yearFromFilter}
            setProductionYearFilter={setYearFromFilter}
            priceFromFilter={priceFromFilter}
            setPriceFromFilter={setPriceFromFilter}
            priceToFilter={priceToFilter}
            setPriceToFilter={setPriceToFilter}
            // distanceFilter={Filter}
            // setDistanceFilter={setDistanceFilter}
            engineCapacityFilter={engineCapacityFilter}
            setEngineCapacityFilter={setEngineCapacityFilter}
            carBodyFilter={carBodyFilter}
            setCarBodyFilter={setCarBodyFilter}
            citiesFilter={citiesFilter}
            setCitiesFilter={setCitiesFilter}
            fuelTypeFilter={fuelTypeFilter}
            setFuelTypeFilter={setFuelTypeFilter}
            transmissionTypeFilter={transmissionTypeFilter}
            setTransmissionTypeFilter={setTransmissionTypeFilter}
            colorFilter={colorFilter}
            setColorFilter={setColorFilter}
            // selectedSearchCategory={selectedSearchCategory}
            // setSelectedSearchCategory={setSelectedSearchCategory}
          />
        </div>
      )}
      {/* End Mobile Search Navbar */}

      <Seo
        title={getMetaTagTitle()}
        description={getMetaTagDescription()}
        keywords={getMetaTagKeywords()}
        thumbnailImage={souqCarLogo}
        thumbnailDescription={getMetaTagDescription()}
      />
      <div
        className={`w-full flex flex-col items-center gap-x-10 gap-y-6 pt-[1rem]`}
        style={{ backgroundColor: "var(--new-souq-background)" }}
      >
        {/* ////////////// */}
        {/* SEARCH SECTION */}
        {/* ////////////// */}
        <div
          id="search-section"
          className={`${styles.searchSection} w-full flex gap-x-6 justify-center`}
          style={{
            direction: isArabic ? "ltr" : "rtl",
          }}
        >
          {isMobile ? (
            <>
              {/* MOBILE VIEW */}
              <div dir="rtl" className="w-full flex flex-col gap-y-3">
                <div className="special-ads-mobile-tabbar-container">
                  <Tabs
                    size="large"
                    items={tabBarItems}
                    onChange={(e: any) => {
                      navigate(
                        {
                          pathname: `/${currentLanguage}/special-ads`,
                          search: `?category_id=${e}`,
                        },
                        { replace: true }
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </div>

                <h1 style={{ fontSize: "1.15rem", textAlign: "start" }}>
                  {getPageTitleH1()}
                </h1>

                {/* Featured Ads Mobile */}

                {allFeaturedAds?.length === 0 ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ transform: "scale(1.5)" }}
                    description={t("no_posts")}
                  />
                ) : (
                  Array.isArray(allFeaturedAds) && (
                    <Spin spinning={allFeaturedAdsLoading}>
                      {
                        <div
                          className={`w-full flex flex-col items-center gap-y-3 ${styles.gridWidth} mt-2`}
                        >
                          {allFeaturedAds?.map((item: any, idx: number) => {
                            return (
                              <NewCustomPostCard
                                key={idx}
                                id={item.uuid}
                                isSpecialAd={true}
                                showActions={false}
                                width={"w-full"}
                                isMobile={isMobile}
                                category={item.category}
                                customerName={item.customer}
                                brandLogo={item.brand_logo}
                                isFavorited={item.favourited}
                                // className={`${styles.widePostCard}`}
                                callsCount={item.contact_count}
                                viewsCount={item.views_count}
                                uploadDate={item.created_at?.split("T")[0]}
                                postTitle={item.title}
                                city={item.city}
                                price={
                                  isDefined(item.down_payment)
                                    ? item.down_payment
                                    : item.price
                                }
                                carCondition={item.car_condition}
                                productionDate={item.production_date}
                                postDescription={item.description}
                                kilometrage={item.kilo_meterage}
                                carMaker={item.brand}
                                image={item.main_image}
                                paymentOption={item.payment_type}
                                created_at={item.created_at.split("T")[0]}
                                address={item.address}
                              />
                            );
                          })}
                        </div>
                      }
                    </Spin>
                  )
                )}
                {/* End Featured Ads Mobile */}
              </div>
            </>
          ) : // DESKTOP VIEW
          // LIST VIEW
          viewType === "list" ? (
            <div dir="rtl" className="w-[55%] flex flex-col items-end">
              <Tabs
                size="large"
                items={tabBarItems}
                onChange={(e: any) => {
                  navigate(
                    {
                      pathname: `/${currentLanguage}/special-ads`,
                      search: `?category_id=${e}`,
                    },
                    { replace: true }
                  );
                }}
                style={{ width: "100%" }}
              />
              <div className="w-full flex flex-row items-center justify-between">
                <div className="flex flex-row items-center gap-x-3">
                  <h1 style={{ fontSize: "1.25rem", textAlign: "center" }}>
                    {getPageTitleH1()}
                  </h1>
                  <ShareButton url={window.location.href}>
                    <ShareAltOutlined className="text-[1.125rem]" />
                  </ShareButton>
                </div>
                <div className="flex flex-row items-center justify-center gap-x-3">
                  <div
                    onClick={() => {
                      setViewType("grid");
                    }}
                    className={`w-[2.8125rem] h-[2.8125rem] rounded-[0.5rem] flex items-center justify-center ${styles.shadow} hover:cursor-pointer`}
                  >
                    <img src={gridView} />
                  </div>
                  <div className="sort-by-dropdown">
                    <Select
                      style={isArabic ? { direction: "rtl" } : {}}
                      dropdownStyle={isArabic ? { direction: "rtl" } : {}}
                      options={sortByFiltersDropdown}
                      onSelect={(value: any) => {
                        setSortByFilter(value);
                        navigate(
                          {
                            pathname: `/${currentLanguage}/special-ads`,
                            search: `?${getSearchURL()}&sort_by=${value}`,
                          },
                          { replace: true }
                        );
                      }}
                      placeholder={t("sort_by")}
                      size="large"
                      className={`w-[12.3125rem]`}
                    />
                  </div>
                </div>
              </div>

              {/* Famous Filters List View*/}
              <div
                dir="rtl"
                className="w-full mt-5 flex flex-row items-center justify-between"
              >
                {famousSearchFilters?.map((item: any) => {
                  const isSelected = isFilterSelected(item);
                  const gap = 0.5; // in rems
                  const filtersNum = famousSearchFilters.length - 1;
                  if (item.id !== 5)
                    return (
                      <CustomButton
                        className={`flex items-center justify-center h-[2.5625rem] px-5 border-[0.5px] border-[var(--souq-orange)] rounded-[1.5rem]`}
                        color={isSelected ? "var(--souq-orange)" : "white"}
                        buttonStyle={{
                          width: `calc(100% / ${filtersNum} - ${gap}rem + ${
                            gap / filtersNum
                          }rem)`,
                          textWrap: "nowrap",
                        }}
                        onClick={() => {
                          if (isSelected) {
                            setSelectedFamousFilter(-1);
                            setSearchParams(
                              `category_id=${getSearchParam("category_id")}&`
                            );
                          } else {
                            setSelectedFamousFilter(item.id);
                            setSearchParams(
                              `category_id=${getSearchParam("category_id")}&${
                                item.query
                              }`
                            );
                          }
                        }}
                      >
                        <p
                          className={`${
                            isSelected ? "text-white" : "text-black"
                          }`}
                        >
                          {item.name}
                        </p>
                      </CustomButton>
                    );
                })}
              </div>
              {/* End Famous Filters List View */}

              <div
                className={`view-posts-spin-container w-full flex flex-col items-center gap-y-3 ${styles.gridWidth} mt-4`}
              >
                {/* Special Ads List View */}

                {allFeaturedAds?.length === 0 ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ transform: "scale(1.5)" }}
                    description={t("no_posts")}
                  />
                ) : (
                  Array.isArray(allFeaturedAds) && (
                    <Spin spinning={allFeaturedAdsLoading}>
                      {
                        <div
                          className={`w-full flex flex-col items-center gap-y-3 ${styles.gridWidth} mt-4`}
                        >
                          {allFeaturedAds?.map((item: any, idx: number) => {
                            return (
                              <NewCustomPostCard
                                key={idx}
                                id={item.uuid}
                                isSpecialAd={true}
                                showActions={false}
                                width={"w-full"}
                                isMobile={isMobile}
                                category={item.category}
                                customerName={item.customer}
                                brandLogo={item.brand_logo}
                                isFavorited={item.favourited}
                                // className={`${styles.widePostCard}`}
                                callsCount={item.contact_count}
                                viewsCount={item.views_count}
                                uploadDate={item.created_at?.split("T")[0]}
                                postTitle={item.title}
                                city={item.city}
                                price={
                                  isDefined(item.down_payment)
                                    ? item.down_payment
                                    : item.price
                                }
                                carCondition={item.car_condition}
                                productionDate={item.production_date}
                                postDescription={item.description}
                                kilometrage={item.kilo_meterage}
                                carMaker={item.brand}
                                image={item.main_image}
                                paymentOption={item.payment_type}
                                created_at={item.created_at.split("T")[0]}
                                address={item.address}
                              />
                            );
                          })}
                        </div>
                      }
                    </Spin>
                  )
                )}
                {/* End Special Ads List View */}
              </div>
            </div>
          ) : (
            // GRID VIEW
            <div className="flex flex-col w-[60%] items-end">
              <div
                dir="rtl"
                className="w-full flex flex-row items-center justify-between"
              >
                <div className="flex flex-row items-center gap-x-3">
                  <h1 style={{ fontSize: "1.25rem", textAlign: "center" }}>
                    {getPageTitleH1()}
                  </h1>
                  <ShareButton url={window.location.href}>
                    <ShareAltOutlined className="text-[1.125rem]" />
                  </ShareButton>
                </div>
                <div className="flex flex-row items-center justify-center gap-x-3">
                  <div
                    onClick={() => {
                      setViewType("list");
                    }}
                    className={`w-[2.8125rem] h-[2.8125rem] rounded-[0.5rem] flex items-center justify-center ${styles.shadow} hover:cursor-pointer`}
                  >
                    <img src={columnView} />
                  </div>
                  <div className="sort-by-dropdown">
                    <Select
                      style={isArabic ? { direction: "rtl" } : {}}
                      dropdownStyle={isArabic ? { direction: "rtl" } : {}}
                      options={sortByFiltersDropdown}
                      onSelect={(value: any) => {
                        setSortByFilter(value);
                        navigate(
                          {
                            pathname: `/${currentLanguage}/special-ads`,
                            search: `?${getSearchURL()}&sort_by=${value}`,
                          },
                          { replace: true }
                        );
                      }}
                      placeholder={t("sort_by")}
                      size="large"
                      className={`w-[12.3125rem]`}
                    />
                  </div>
                </div>
              </div>

              {/* Famous Filters Grid View */}
              <div
                dir="rtl"
                className="mt-3 w-full flex flex-row justify-start gap-x-3"
              >
                {famousSearchFilters?.map((item: any) => {
                  const isSelected = isFilterSelected(item);
                  if (item.id !== 5)
                    return (
                      <CustomButton
                        className={`h-[2.5625rem] px-5 border-[0.5px] border-[var(--souq-orange)] rounded-[1.5rem]`}
                        color={isSelected ? "var(--souq-orange)" : "white"}
                        onClick={() => {
                          if (isSelected) {
                            setSelectedFamousFilter(-1);
                            setSearchParams(
                              `category_id=${getSearchParam("category_id")}&`
                            );
                          } else {
                            setSelectedFamousFilter(item.id);
                            setSearchParams(
                              `category_id=${getSearchParam("category_id")}&${
                                item.query
                              }`
                            );
                          }
                        }}
                      >
                        <p
                          className={`${
                            isSelected ? "text-white" : "text-black"
                          }`}
                        >
                          {item.name}
                        </p>
                      </CustomButton>
                    );
                })}
              </div>
              {/* End Famous Filters Grid View */}

              <div
                className={`w-full ${styles.gridWidth} flex justify-center items-center`}
              >
                <div
                  className={`w-full ${
                    isArabic ? styles.ar : styles.en
                  }  mt-8 flex flex-row flex-wrap gap-y-3 gap-x-3`}
                >
                  {/* Special Ads Grid View */}

                  {allFeaturedAdsLoading ? (
                    <Spin />
                  ) : allFeaturedAds?.length === 0 ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{ transform: "scale(1.5)" }}
                      description={t("no_posts")}
                    />
                  ) : (
                    allFeaturedAds?.map((item: any, idx: number) => {
                      if (idx < 6)
                        return (
                          <NewWidePostCard
                            isSpecialAd={true}
                            isFavorited={item.favourited}
                            width={"100%"}
                            // maxWidth={"auto"}
                            callsCount={item.contact_count}
                            viewsCount={item.views_count}
                            uploadDate={item.created_at}
                            postTitle={item.title}
                            city={item.city}
                            price={
                              isDefined(item.down_payment)
                                ? item.down_payment
                                : item.price
                            }
                            carCondition={item.car_condition}
                            productionDate={item.production_date}
                            kilometrage={item.kilo_meterage}
                            id={item.uuid}
                            postType={item.category}
                            customerName={item.customer}
                            paymentOption={item.payment_type}
                            isMobile={false}
                            image={item.main_image}
                            category={item.category}
                            showActions={false}
                            brandLogo={undefined}
                            created_at={item.created_at.split("T")[0]}
                            address={item.address}
                          />
                        );
                    })
                  )}
                  {/* End Special Ads Grid View */}
                </div>
              </div>
            </div>
          )}

          {!isMobile && (
            <div
              className={`${styles.sideBarSearchSection} w-[18%] px-3 py-3 rounded-[0.9375rem] bg-white ${styles.shadow}`}
            >
              <div
                dir="rtl"
                className="mt-3 w-full flex flex-row items-center justify-between"
              >
                <div className="flex flex-row gap-x-2">
                  <img src={FilterBars} />
                  <p>{t("search_filters")}</p>
                </div>
                <div className="flex flex-row gap-x-2">
                  <img src={RetryIcon} />
                  <p className="text-[var(--new-blue)]">{t("reset_filters")}</p>
                </div>
              </div>
              <div className="mt-3 w-full flex flex-row items-center justify-center gap-x-1">
                {carConditions?.map((item: any) => {
                  return (
                    <CustomButton
                      className={`w-[4.979rem] h-[2.625rem] rounded-[0.5rem] ${
                        carCondition === item.key
                          ? "border-[0.5px] border-[var(--new-blue)]"
                          : ""
                      }`}
                      color={"rgba(0, 80, 255, 0.1)"}
                      onClick={() => {
                        setCarCondition(item.key);
                        setCarConditionFilter(item.key);
                      }}
                    >
                      <p
                        className={`${
                          carCondition === item.key
                            ? "text-[var(--new-blue)]"
                            : "text-black"
                        }`}
                      >
                        {item.name}
                      </p>
                    </CustomButton>
                  );
                })}
              </div>
              <div className="mt-3 w-full flex flex-col items-center gap-y-2">
                <div className="view-posts-advanced-search w-full">
                  {carMakersLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_car_maker")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={carMakerDropdown}
                      onSelect={(value: any) => {
                        setCarMakerFilter(value);
                        dispatch(listCarModelsAction(value));
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <div className="view-posts-advanced-search w-full">
                  {carModelsLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_car_model")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={carModelsDropdown}
                      onSelect={(value: any) => {
                        setCarModelFilter(value);
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <div
                  dir="rtl"
                  className="w-full flex flex-row items-center gap-x-2 justify-center"
                >
                  <div className="view-posts-advanced-search w-[50%]">
                    {yearsLoading ? (
                      <Spin />
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t("year_from")}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={yearsDropdown}
                        onSelect={(value: any) => setYearFromFilter(value)}
                        size="large"
                        style={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        dropdownStyle={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        className={`w-full`}
                      />
                    )}
                  </div>
                  <div className="view-posts-advanced-search w-[50%]">
                    {yearsLoading ? (
                      <Spin />
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t("year_to")}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={yearsDropdown}
                        onSelect={(value: any) => setYearToFilter(value)}
                        size="large"
                        style={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        dropdownStyle={
                          currentLanguage === "ar" ? { direction: "rtl" } : {}
                        }
                        className={`w-full`}
                      />
                    )}
                  </div>
                </div>
                <div className="view-posts-advanced-search w-full">
                  {transmissionTypesLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("transmission")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={transmissionTypesDropdown}
                      onSelect={(value: any) => {
                        setTransmissionTypeFilter(value);
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <div className="view-posts-advanced-search w-full">
                  {carBodiesLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_carbody")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={carBodiesDropdown}
                      onSelect={(value: any) => {
                        setCarBodyFilter(value);
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <div className="view-posts-advanced-search w-full">
                  {engineCapactiyLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_cc")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={engineCapacityDropdown}
                      onSelect={(value: any) => {
                        setEngineCapacityFilter(value);
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <div className="view-posts-advanced-search w-full">
                  {fuelTypesLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_fuel_type")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={fuelTypesDropdown}
                      onSelect={(value: any) => {
                        setFuelTypeFilter(value);
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <div className="view-posts-advanced-search w-full">
                  <Input
                    placeholder={t("homepage_distance")}
                    onChange={(e) => setDistanceFilter(e.target.value)}
                    size="large"
                    className="w-full text-right"
                  />
                </div>
                <div className="view-posts-advanced-search w-full">
                  {citiesLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={t("homepage_governate")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={citiesDropdown}
                      onSelect={(value: any) => {
                        setCitiesFilter(value);
                      }}
                      size="large"
                      style={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      dropdownStyle={
                        currentLanguage === "ar" ? { direction: "rtl" } : {}
                      }
                      className={`w-full`}
                    />
                  )}
                </div>
                <CustomButton
                  className={"w-full h-[3.125rem] rounded-[0.9375rem]"}
                  color={"var(--new-blue)"}
                  onClick={() => {
                    //advanced search
                    navigate(
                      `/${currentLanguage}/special-ads${getSearchFilterURL()}`
                    );
                  }}
                >
                  <p className="text-white text-[1.125rem]">{t("submit")}</p>
                </CustomButton>
              </div>
            </div>
          )}
        </div>

        <div className="mt-[1.5rem] mb-[1rem] w-full flex items-center justify-center">
          <NewPaginator
            currentPage={
              isNaN(parseInt(getSearchParam("page")))
                ? 1
                : parseInt(getSearchParam("page"))
            }
            lastPage={allFeaturedAdsLastPage}
            url={`/${currentLanguage}/special-ads`}
          />
        </div>
        {/* Pagination End */}
        <Footer />
      </div>
    </>
  );
};

export default SpecialAds;
