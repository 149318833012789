import
{
    VIEW_ARTICLE_LOADING,
    VIEW_ARTICLE_SUCCESS,
    VIEW_ARTICLE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";
import { viewArticleService } from "services/viewArticleService";

export const viewArticleAction = (articleId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: VIEW_ARTICLE_LOADING, payload: true});
        const response = await viewArticleService(articleId);
        dispatch({type: VIEW_ARTICLE_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: VIEW_ARTICLE_FAILURE, payload: err});
    } finally {
        dispatch({type: VIEW_ARTICLE_LOADING, payload: false});
    }
}