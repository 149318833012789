import
{
    BRANCH_TYPES_LOADING,
    BRANCH_TYPES_SUCCESS,
    BRANCH_TYPES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listBranchTypesService } from "services/listBranchTypesService";

export const listBranchTypesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: BRANCH_TYPES_LOADING, payload: true});
        const response = await listBranchTypesService();
        dispatch({type: BRANCH_TYPES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: BRANCH_TYPES_FAILURE, payload: err});
    } finally {
        dispatch({type: BRANCH_TYPES_LOADING, payload: false});
    }
}