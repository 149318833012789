import { BlackUserIcon } from "assets/icons/V3";
import styles from "components/V3/CircularExhibitorCard/CircularExhibitorCard.module.css";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";

interface CircularExhibitorCardProps {
  id: any;
  image: string;
  name: string;
  isMobile?: boolean;
}

const CircularExhibitorCard: FC<CircularExhibitorCardProps> = ({
  id,
  image,
  name,
  isMobile = false,
}) => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col items-center gap-y-2 overflow-y-hidden overflow-x-hidden min-w-[3rem] hover:cursor-pointer`}
      onClick={() => {
        navigate(`/${currentLanguage}/vendor/${id}`);
      }}
    >
      <div
        style={{
          backgroundImage:
            image?.split("customers/")[1] === ""
              ? `url('${BlackUserIcon}')`
              : `url('${image}')`,
          backgroundSize: image?.split("customers/")[1] === "" ? "auto 50%" : "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className={`${isMobile ? "w-[3rem]" : "w-[4.6725rem]"} ${
          isMobile ? "h-[3rem]" : "h-[4.6725rem]"
        } ${
          isMobile ? "rounded-[0.625rem]" : "rounded-[0.9375rem]"
        } bg-[var(--new-blue)]`}
      ></div>
      <p
        className={`${
          isMobile
            ? "text-[0.625rem] max-w-[3rem] line-clamp-1 overflow-ellipsis"
            : "text-[0.9375rem]"
        }`}
      >
        {name}
      </p>
    </div>
  );
};

export default CircularExhibitorCard;
