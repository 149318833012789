import * as React from "react";
import type { SVGProps } from "react";
const SvgHomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 21.4 21.4"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10.7.7c2 0 3.9.6 5.5 1.7s2.9 2.6 3.7 4.5c.8 1.8.9 3.8.6 5.7-.4 1.9-1.3 3.7-2.7 5.1-1.4 1.4-3.2 2.3-5.1 2.7-1.9.4-3.9.2-5.7-.6-1.8-.8-3.4-2-4.5-3.7S.8 12.5.8 10.6c0-2.6 1.1-5.2 2.9-7S8.1.7 10.7.7zm0-.7C8.6 0 6.6.6 4.8 1.8 3.1 3 1.7 4.6.9 6.6c-.8 1.9-1 4.1-.6 6.2.4 2.1 1.4 4 2.9 5.5s3.4 2.5 5.5 2.9c2.1.4 4.2.2 6.2-.6 1.9-.8 3.6-2.2 4.8-3.9 1.2-1.8 1.8-3.8 1.8-5.9 0-2.8-1.1-5.5-3.1-7.5C16.3 1.1 13.6 0 10.7 0z" />
    <path d="M10.7.7c2 0 3.9.6 5.5 1.7s2.9 2.6 3.7 4.5c.8 1.8.9 3.8.6 5.7-.4 1.9-1.3 3.7-2.7 5.1-1.4 1.4-3.2 2.3-5.1 2.7-1.9.4-3.9.2-5.7-.6-1.8-.8-3.4-2-4.5-3.7S.8 12.5.8 10.6c0-2.6 1.1-5.2 2.9-7S8.1.7 10.7.7zm0-.7C8.6 0 6.6.6 4.8 1.8 3.1 3 1.7 4.6.9 6.6c-.8 1.9-1 4.1-.6 6.2.4 2.1 1.4 4 2.9 5.5s3.4 2.5 5.5 2.9c2.1.4 4.2.2 6.2-.6 1.9-.8 3.6-2.2 4.8-3.9 1.2-1.8 1.8-3.8 1.8-5.9 0-2.8-1.1-5.5-3.1-7.5C16.3 1.1 13.6 0 10.7 0z" />
    <path d="M15.6 7.8c-.3-.2-.6-.4-1-.7l-2.5-1.7c-.4-.2-.9-.4-1.4-.4-.5 0-1 .1-1.4.4L6 7.7c-.3.2-.6.5-.8.9-.2.3-.3.7-.3 1.1v4.7h1V9.7c0-.2.1-.4.2-.7.1-.2.3-.4.5-.5L10 6.2c.2-.1.4-.2.7-.2.3 0 .6.1.8.2l3.4 2.3c.2.1.4.3.5.5.1.2.2.4.2.7v4.7h1V9.7c0-.4-.1-.7-.3-1.1-.2-.3-.4-.6-.7-.8m-3.9 3H9.8c-.4 0-.8.2-1 .4-.3.3-.4.6-.4 1v2.1h1v-2.1c0-.1.1-.3.1-.3.1-.1.2-.1.3-.1h2.1c.1 0 .1.1.2.1s.1.1.1.2V14.4h1v-2.1c0-.4-.2-.8-.4-1-.3-.3-.7-.5-1.1-.5" />
  </svg>
);
export default SvgHomeIcon;
