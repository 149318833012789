import
{
    LIST_YEARS_LOADING,
    LIST_YEARS_SUCCESS,
    LIST_YEARS_FAILURE
}
from "redux/types";

const initialState = {
    yearsLoading: false,
    years: null,
    errors: null
};

export const listYearsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_YEARS_LOADING :
            return {...state, yearsLoading: action.payload};
        case LIST_YEARS_SUCCESS :
            return {...state, years: action.payload.data};
        case LIST_YEARS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}