/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";

// import {logoutAction} from './../redux/actions/authActions';
import { notification } from "antd";
import store from "redux/store";
import { LOG_OUT } from "redux/types";

const apiURL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: apiURL,
});

if (store.getState()?.verifyOtpReducer?.userData?.token) {
  instance.interceptors.request.use(
    async (config: any) => {
      const token = store.getState()?.verifyOtpReducer?.userData?.token;
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    async (error) => {
      // return await Promise.resolve(error);
    }
  );
}

instance.interceptors.request.use(
  (config: any) => {
    config.headers!.lang =
      store.getState().changeLanguageReducer.currentLanguage;
    return config;
  },
  (error) => {
    // return Promise.resolve(error);
  }
);

const checkAuth = (notificationParam: any) => {
  store.dispatch({ type: LOG_OUT });
  if (window.location.pathname === "/login") {
    notificationParam.message = "Authentication Fail";
    notificationParam.description = "Please check user name or password";
  } else if (window.location.pathname.includes("/posts/create")) {
    notificationParam.message = "Cannot create post without login";
    notificationParam.description = "Please login to your account";
    // window.location.href = "/ar/login";
  }
  // else {
  //   notificationParam.message = "Authentication Fail";
  //   notificationParam.description = "Please login again";
  //   window.location.pathname = `/${store.getState()?.changeLanguageReducer?.currentLanguage}`
  // }
};

// errors Handling
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const notificationParam = {
      message: "",
      description: "",
      style: {
        zIndex: 99999999999999,
      },
    };

    switch (error.response?.status) {
      case 401:
        checkAuth(notificationParam);
        break;
      case 400:
        notificationParam.message = "Bad Request";
        notificationParam.description = "Check that data is being sent";
        window.location.href = "/404";
        break;
      case 404:
        notificationParam.message = "Not Found";
        break;
      case 422:
        notificationParam.message = "Authentication Failed";
        notificationParam.description =
          "Wrong email/password, check your credentials";
        break;
      case 500:
        notificationParam.message = "Something went wrong";
        // window.location.href = "/404";
        break;
      case 508:
        notificationParam.message = "Time Out";
        notificationParam.description = "The server terminated an operation";
        break;
      default:
        break;
    }

    if (notificationParam.message) notification.error(notificationParam);
    // return await Promise.resolve(error);
  }
);
export default instance;
