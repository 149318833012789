import { FC } from "react";
import { UserOutlined } from "@ant-design/icons";
import styles from "components/AdvancedSearchSheet/AdvancedSearchSheet.module.css";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import Sheet from "react-modal-sheet";
import { useLanguage } from "hooks/useLanguage";
import { Input, Select, Spin } from "antd";
import CustomButton from "components/CustomButton/CustomButton";
import { useUtilities } from "hooks/useUtilities";
import { listCarModelsAction } from "redux/actions/index";
import { ReloadOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { BlackDownArrow } from "assets/IconComponents";
import { useDropdowns } from "hooks/useDropdowns";

interface AdvancedSearchSheetProps {
  title?: string;
  isOpen: boolean;
  setOpen: any;
  carCondition: any;
  carConditionFilter: any;
  setCarConditionFilter: any;
  setCarCondition: any;
  carMakerFilter: any;
  setCarMakerFilter: any;
  carModelFilter: any;
  setCarModelFilter: any;
  productionYearFilter: any;
  setProductionYearFilter: any;
  priceFromFilter: any;
  setPriceFromFilter: any;
  priceToFilter: any;
  setPriceToFilter: any;
  //   distanceFilter: any;
  //   setDistanceFilter: any;
  engineCapacityFilter: any;
  setEngineCapacityFilter: any;
  carBodyFilter: any;
  setCarBodyFilter: any;
  citiesFilter: any;
  setCitiesFilter: any;
  fuelTypeFilter: any;
  setFuelTypeFilter: any;
  transmissionTypeFilter: any;
  setTransmissionTypeFilter: any;
  colorFilter: any;
  setColorFilter: any;
  selectedSearchCategory?: any;
  setSelectedSearchCategory?: any;
  isSpecial: boolean;
}

const AdvancedSearchSheet: FC<AdvancedSearchSheetProps> = ({
  title,
  isOpen,
  setOpen,
  carCondition,
  carConditionFilter,
  setCarConditionFilter,
  setCarCondition,
  carMakerFilter,
  setCarMakerFilter,
  carModelFilter,
  setCarModelFilter,
  productionYearFilter,
  setProductionYearFilter,
  priceFromFilter,
  setPriceFromFilter,
  priceToFilter,
  setPriceToFilter,
  //   distanceFilter,
  //   setDistanceFilter,
  engineCapacityFilter,
  setEngineCapacityFilter,
  carBodyFilter,
  setCarBodyFilter,
  citiesFilter,
  setCitiesFilter,
  fuelTypeFilter,
  setFuelTypeFilter,
  transmissionTypeFilter,
  setTransmissionTypeFilter,
  colorFilter,
  setColorFilter,
  selectedSearchCategory,
  setSelectedSearchCategory,
  isSpecial,
}) => {
  const { t, navigate, dispatch } = useUtilities();
  const { currentLanguage, isArabic } = useLanguage();

  const [searchParams, setSearchParams] = useSearchParams();

  //   DROPDOWN DATA
  const { carBodiesLoading, carBodies } = useSelector(
    (state: RootState) => state.listCarBodiesReducer
  );
  const { engineCapactiyLoading, engineCapacity } = useSelector(
    (state: RootState) => state.listEngineCapacityReducer
  );
  const { carConditionLoading, carConditions } = useSelector(
    (state: RootState) => state.listCarConditionReducer
  );
  const { yearsLoading, years } = useSelector(
    (state: RootState) => state.listYearsReducer
  );
  const { categoriesLoading, categories } = useSelector(
    (state: RootState) => state.listCategoriesReducer
  );
  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );
  const { featuredPostsLoading, featuredPosts } = useSelector(
    (state: RootState) => state.listFeaturedPostsReducer
  );
  const { carModelsLoading, carModels } = useSelector(
    (state: RootState) => state.listCarModelsReducer
  );
  const { colorsLoading, colors } = useSelector(
    (state: RootState) => state.listColorsReducer
  );
  const { featuresLoading, features } = useSelector(
    (state: RootState) => state.listFeaturesReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );
  const { fuelTypesLoading, fuelTypes } = useSelector(
    (state: RootState) => state.listFuelTypesReducer
  );
  const { transmissionTypesLoading, transmissionTypes } = useSelector(
    (state: RootState) => state.listTransmissionTypesReducer
  );

  const engineCapacityDropdown = engineCapacity?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const carConditionsDropdown = carConditions?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const yearsDropdown = years?.map((item: any) => {
    return {
      value: item,
      label: item,
    };
  });

  const { carMakerDropdown, carModelsDropdown } = useDropdowns();

  const colorsDropdown = colors?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const featuresDropdown = features?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const carBodiesDropdown = carBodies?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const fuelTypesDropdown = fuelTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const getCategoryId = () => {
    const getCategoryId = () => {
      const arr = Array.from(searchParams.entries());
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][0] === "category_id") {
          switch (arr[i][1]) {
            case "1":
              return 1;
            case "2":
              return 2;
            case "3":
              return 3;
            case "4":
              return 4;
          }
        }
      }
      return 2;
    };
  };

  const getSearchFilterURL = (): string => {
    interface Params {
      [key: string]: any;
    }

    var allParams: Params = {
      category_id: getCategoryId(),
      brand_id: carMakerFilter?.id ?? carMakerFilter,
      car_model_id: carModelFilter?.id ?? carModelFilter,
      car_condition: carConditionFilter,
      transmission_type: transmissionTypeFilter,
      car_type: carBodyFilter,
      price_from: priceFromFilter,
      price_to: priceToFilter,
      city_id: citiesFilter,
      fuel_type: fuelTypeFilter,
      color_id: colorFilter,
      engine_capacity: engineCapacityFilter,
    };

    var counter = 0;
    var finalParams: string = "";
    Object.keys(allParams).map(function (key: string) {
      if (
        allParams[key] !== "" &&
        allParams[key] !== undefined &&
        allParams[key] !== null
      ) {
        counter++;
        if (counter === 1) finalParams += "?" + key + "=" + allParams[key];
        else finalParams += "&" + key + "=" + allParams[key];
      }
    });

    return finalParams;
  };

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      detent="content-height"
      snapPoints={[1, 0.75, 0.5]}
      style={{
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      }}
      // initialSnap={1}
    >
      <Sheet.Backdrop onTap={() => setOpen(false)} />
      <Sheet.Container
        style={{
          borderTopLeftRadius: "2rem",
          borderTopRightRadius: "2rem",
          // overflow: "auto"
        }}
      >
        <Sheet.Header />
        <Sheet.Header>
          <p className="text-lg font-bold text-center pb-4">
            {t("homepage_advanced_search")}
          </p>
        </Sheet.Header>
        <Sheet.Content style={{ overflow: "auto" }}>
          <div
            dir={isArabic ? "rtl" : "ltr"}
            className="w-full flex justify-center mb-3"
          >
            <div
              className={`${styles.mobileAdvancedSearchContainer} bg-white rounded-xl flex flex-col items-center gap-y-5`}
            >
              <div className="w-full flex flex-col gap-y-4">
                {/* CATEGORIES */}

                {selectedSearchCategory && (
                  <div className={`w-full flex flex-row items-center gap-x-3`}>
                    <CustomButton
                      className={`${
                        selectedSearchCategory === 1
                          ? styles.selectedPostTypeButton
                          : styles.postTypeButton
                      } w-full p-2 rounded-md`}
                      onClick={() => setSelectedSearchCategory(1)}
                    >
                      {t("homepage_buy_and_sell")}
                    </CustomButton>
                    <CustomButton
                      className={`${
                        selectedSearchCategory === 2
                          ? styles.selectedPostTypeButton
                          : styles.postTypeButton
                      } w-full p-2 rounded-md`}
                      onClick={() => setSelectedSearchCategory(2)}
                    >
                      {t("homepage_rental")}
                    </CustomButton>
                  </div>
                )}

                {/* CAR CONDTITION */}
                <div className="w-full flex flex-row items-center justify-center gap-x-3">
                  {!carConditionLoading && (
                    <CustomButton
                      key={3}
                      className={`${
                        carCondition === 3
                          ? styles.selectedPostTypeButton
                          : styles.postTypeButton
                      } w-full p-2 rounded-md text-xs lg:text-base`}
                      onClick={() => {
                        setCarConditionFilter(null);
                        setCarCondition(3);
                      }}
                    >
                      {t("all")}
                    </CustomButton>
                  )}
                  {carConditionLoading ? (
                    <Spin />
                  ) : (
                    carConditions?.map((item: any, idx: any) => {
                      return (
                        <CustomButton
                          key={idx}
                          className={`${
                            carCondition === item.key
                              ? styles.selectedPostTypeButton
                              : styles.postTypeButton
                          } w-full p-2 rounded-md text-xs lg:text-base`}
                          onClick={() => {
                            setCarConditionFilter(item.key);
                            setCarCondition(item.key);
                          }}
                        >
                          {item.name}
                        </CustomButton>
                      );
                    })
                  )}
                </div>
              </div>

              <div className="w-full h-[2px] bg-stone-400"></div>

              <div className="bottom-modal-sheet-select-wrapper w-full h-full flex flex-col gap-y-6">
                {/* CAR MAKER AND MODEL */}
                <div className="w-full h-full flex items-center gap-x-3">
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setCarMakerFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("homepage_car_maker")}
                    suffixIcon={<BlackDownArrow />}
                    value={carMakerFilter?.name}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carMakerDropdown}
                    onSelect={(value: any) => {
                      setCarMakerFilter(value);
                      dispatch(listCarModelsAction(value));
                    }}
                    size="large"
                    style={{
                      direction: isArabic ? "rtl" : "ltr",
                    }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />

                  <Select
                    showSearch
                    allowClear
                    onClear={() => setCarModelFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("homepage_car_model")}
                    suffixIcon={<BlackDownArrow />}
                    // value={carModelFilter?.name ?? carModelFilter}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carModelsDropdown}
                    onSelect={(value: any) => setCarModelFilter(value)}
                    size="large"
                    style={{ direction: isArabic ? "rtl" : "ltr" }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />
                </div>

                {/* CAR YEAR AND BODY */}
                <div className="w-full h-full flex items-center gap-x-3">
                  {/* YEAR */}
                  <div className="w-full">
                    {
                      <Spin spinning={yearsLoading}>
                        <Select
                          showSearch
                          allowClear
                          onClear={() => setProductionYearFilter(null)}
                          optionFilterProp="children"
                          placeholder={t("homepage_year")}
                          suffixIcon={<BlackDownArrow />}
                          // value={productionYearFilter}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={yearsDropdown}
                          onSelect={(value: any) =>
                            setProductionYearFilter(value)
                          }
                          size="large"
                          style={{
                            direction: isArabic ? "rtl" : "ltr",
                          }}
                          dropdownStyle={{
                            direction: isArabic ? "rtl" : "ltr",
                            zIndex: 9999999,
                          }}
                          className={`${styles.searchField} w-full`}
                        />
                      </Spin>
                    }
                  </div>

                  {/* BODY */}
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setCarBodyFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("homepage_carbody")}
                    suffixIcon={<BlackDownArrow />}
                    // value={carBodyFilter}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={carBodiesDropdown}
                    onSelect={(value: any) => setCarBodyFilter(value)}
                    size="large"
                    style={{ direction: isArabic ? "rtl" : "ltr" }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />
                </div>

                {/* GOVERNATE AND COLOR */}
                <div className="w-full h-full flex items-center gap-x-3">
                  {/* GOVERNATE */}
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setCitiesFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("homepage_governate")}
                    suffixIcon={<BlackDownArrow />}
                    // value={citiesFilter}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={citiesDropdown}
                    onSelect={(value: any) => setCitiesFilter(value)}
                    size="large"
                    style={{ direction: isArabic ? "rtl" : "ltr" }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />

                  {/* COLOR */}
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setColorFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("homepage_color")}
                    suffixIcon={<BlackDownArrow />}
                    // value={colorFilter}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={colorsDropdown}
                    onSelect={(value: any) => {
                      setColorFilter(value);
                    }}
                    size="large"
                    style={{ direction: isArabic ? "rtl" : "ltr" }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />
                </div>

                {/* FUEL AND TRANSMISSION */}
                <div className="w-full h-full flex items-center gap-x-3">
                  {/* FUEL */}
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setFuelTypeFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("homepage_fuel_type")}
                    suffixIcon={<BlackDownArrow />}
                    // value={fuelTypeFilter}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={fuelTypesDropdown}
                    onSelect={(value: any) => setFuelTypeFilter(value)}
                    size="large"
                    style={{ direction: isArabic ? "rtl" : "ltr" }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />

                  {/* TRANSMISSION TYPE */}
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setTransmissionTypeFilter(null)}
                    optionFilterProp="children"
                    placeholder={t("transmission")}
                    suffixIcon={<BlackDownArrow />}
                    // value={transmissionTypeFilter}
                    filterOption={(input, option) => {
                      return (
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label_other ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      );
                    }}
                    options={transmissionTypes}
                    onSelect={(value: any) => {
                      setTransmissionTypeFilter(value);
                    }}
                    size="large"
                    style={{ direction: isArabic ? "rtl" : "ltr" }}
                    dropdownStyle={{
                      direction: isArabic ? "rtl" : "ltr",
                      zIndex: 9999999,
                    }}
                    className={`${styles.searchField} w-full`}
                  />
                </div>

                {/* PRICE FROM AND TO */}
                <div className={`w-full h-full flex items-center gap-x-3`}>
                  <Input
                    placeholder={t("homepage_price_from")}
                    value={priceFromFilter ?? ""}
                    onChange={(e: any) => setPriceFromFilter(e.target.value)}
                    style={{
                      direction: isArabic ? "rtl" : "ltr",
                      fontSize: "1rem",
                    }}
                    className={``}
                    size="large"
                  />
                  <Input
                    placeholder={t("homepage_price_to")}
                    value={priceToFilter ?? ""}
                    onChange={(e: any) => setPriceToFilter(e.target.value)}
                    style={{
                      direction: isArabic ? "rtl" : "ltr",
                      fontSize: "1rem",
                    }}
                    className={``}
                    size="large"
                  />
                </div>

                {/* DISTANCE */}
                <div className="w-full">
                  <Spin spinning={engineCapactiyLoading}>
                    <Select
                      showSearch
                      allowClear
                      onClear={() => setEngineCapacityFilter(null)}
                      optionFilterProp="children"
                      placeholder={t("homepage_cc")}
                      suffixIcon={<BlackDownArrow />}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={engineCapacityDropdown}
                      onSelect={(value: any) => setEngineCapacityFilter(value)}
                      size="large"
                      style={{
                        direction: isArabic ? "rtl" : "ltr",
                      }}
                      dropdownStyle={{
                        direction: isArabic ? "rtl" : "ltr",
                        zIndex: 9999999,
                      }}
                      className={`${styles.searchField} w-full`}
                    />
                  </Spin>
                </div>

                {/* FEATURES */}

                {/* FIXED SEARCH AND CLEAR BUTTONS */}
                <div className={`w-full flex gap-x-2 mt-4 mb-6`}>
                  {/* CLEAR FILTERS BUTTON */}
                  <div
                    className={`bg-[#EFEFFF] rounded-lg flex flex-row items-center justify-center gap-x-2 px-2 py-1 ${styles.hover}`}
                    style={{ flex: 1 }}
                    onClick={() => {
                      setCarConditionFilter(null);
                      setCarCondition(-1);
                      setCarMakerFilter(null);
                      setCarModelFilter(null);
                      setProductionYearFilter(null);
                      setPriceFromFilter(null);
                      setPriceToFilter(null);
                      //   setDistanceFilter(null);
                      setEngineCapacityFilter(null);
                      setCarBodyFilter(null);
                      setCitiesFilter(null);
                      setFuelTypeFilter(null);
                      setTransmissionTypeFilter(null);
                      // setFeaturesFilter(null);
                    }}
                  >
                    <ReloadOutlined />
                    <p>{t("delete")}</p>
                  </div>

                  {/* SEARCH BUTTON */}
                  <CustomButton
                    className="w-full rounded-lg bg-[#3127ff] p-3 flex items-center justify-center"
                    buttonStyle={{ flex: 2 }}
                    textStyling="text-xxl text-white"
                    onClick={() => {
                      if (isSpecial) {
                        navigate({
                          pathname: `/${currentLanguage}/special-ads`,
                          search: `${getSearchFilterURL()}&category_id=1`,
                        });
                      } else {
                        navigate({
                          pathname: `/${currentLanguage}/ads/view`,
                          search: getSearchFilterURL(),
                        });
                      }
                      setOpen(false);
                    }}
                  >
                    {t("homepage_search")}
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default AdvancedSearchSheet;
