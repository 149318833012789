import * as React from "react";
import type { SVGProps } from "react";
const SvgXTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 17 16"
    {...props}
  >
    <g clipPath="url(#XTwitter_svg__a)">
      <path
        fill="currentColor"
        d="M13.134.624h2.536l-5.54 6.331 6.517 8.616h-5.103l-3.996-5.225-4.574 5.225H.437L6.363 8.8.11.624h5.232L8.956 5.4 13.134.624Zm-.89 13.43h1.405L4.58 2.061H3.073l9.172 11.991Z"
      />
    </g>
    <defs>
      <clipPath id="XTwitter_svg__a">
        <path fill="#fff" d="M.11.624h16.865v15.33H.11z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgXTwitter;
