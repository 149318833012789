import styles from "screens/InsuranceScreen/InsuranceScreen.module.css";
import { useTranslation } from "react-i18next";
import { AmanleekIcon, InsuranceSuccessIcon } from "assets/icons";
import { useLanguage } from "hooks/useLanguage";
import {
  HoursIcon,
  FreeInspectionIcon,
  ContactIcon,
  RentalIcon,
} from "assets/icons";
import { CustomButton, Footer } from "components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { amanleekAuthenticationAction } from "redux/actions/AmanleekActions/Authentication/amanleekAuthenticationAction";
import { Input, Modal, Select, Spin, notification } from "antd";
import { amanleekListBrandsAction } from "redux/actions/AmanleekActions/Brands/amanleekListBrandsAction";
import { amanleekListCarModelsAction } from "redux/actions/AmanleekActions/CarModels/amanleekListCarModelsAction";
import { listYearsAction } from "redux/actions/YearsActions/listYearsAction";
import { createInsuranceEntryAction } from "redux/actions/CreateInsuranceEntryAction/createInsuranceEntryAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import phoneRegExp from "utils/phoneRegularExpression";
import UserLoginModel from "models/UserLoginModel";
import { mobileLoginAction } from "redux/actions/LoginActions/mobileLoginAction";
import { verifyOtpAction } from "redux/actions/LoginActions/verifyOtpAction";
import { amanleekLeadInsertAction } from "redux/actions/AmanleekActions/LeadInsert/amanleekLeadInsertAction";
import { changeCurrentPageAction } from "redux/actions";
import scrollToTop from "utils/functions/scrollToTop";
import { BlackDownArrow } from "assets/IconComponents";

const InsuranceScreen = () => {
  const formik = useFormik({
    initialValues: {
      phone: "",
      otp: "",
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(phoneRegExp, "Number has to start with: 012, 010, 011, or 015")
        .required("Please Provide a Phone Number"),
      otp: Yup.string().required(),
      name: Yup.string(),
      email: Yup.string().email(),
    }),
    onSubmit: (values: UserLoginModel) => {
      //    navigate('/');
    },
  });

  const { t } = useTranslation();

  const { textDirectionStyle } = useLanguage();

  const [carModelsDropdown, setCarModelsDropdown] = useState<any>([]);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  /* Input Fields States */
  const [carBrand, setCarBrand] = useState({ value: "", error: "" });
  const [carModel, setCarModel] = useState({ value: "", error: "" });
  const [year, setYear] = useState({ value: "", error: "" });
  const [price, setPrice] = useState(0);
  const [age, setAge] = useState(0);
  const [priceError, setPriceError] = useState("");
  const [ageError, setAgeError] = useState("");
  /* End Input Fields States */

  /* Login Field States */
  const [showName, setShowName] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [enableResend, setEnableResend] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [customerChange, setCustomerChange] = useState(0);
  /* End Login Field States */

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { insuranceEntryLoading, insuranceEntry } = useSelector(
    (state: RootState) => state.createInsuranceEntryReducer
  );

  const { amaanleekAuthLoading, amanleekToken } = useSelector(
    (state: RootState) => state.amanleekAuthenticationReducer
  );

  const { leadInsertLoading, leadInsertSuccess } = useSelector(
    (state: RootState) => state.amanleekLeadInsertReducer
  );

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { amanleekBrandsLoading, amanleekBrands } = useSelector(
    (state: RootState) => state.amanleekListBrandsReducer
  );

  const { amanleekCarModelsLoading, amanleekCarModels } = useSelector(
    (state: RootState) => state.amanleekListCarModelsReducer
  );

  const { yearsLoading, years } = useSelector(
    (state: RootState) => state.listYearsReducer
  );

  const { loginLoading, customer } = useSelector(
    (state: RootState) => state.mobileLoginReducer
  );

  const isArabic = currentLanguage === "ar";

  const dispatch: any = useDispatch();

  useEffect(() => {
    scrollToTop();

    const authenticate = async () => {
      await dispatch(amanleekAuthenticationAction());
    };
    authenticate().then(() => {
      dispatch(amanleekListBrandsAction());
      dispatch(amanleekListCarModelsAction());
      setCarModelsDropdown(
        amanleekCarModels?.map((item: any) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        })
      );
    });

    dispatch(listYearsAction());
  }, []);

  useEffect(() => {
    if (customerChange > 0) {
      if (
        customer !== undefined &&
        customer !== null &&
        customer?.name !== ""
      ) {
        setShowOtp(true);
        setShowName(false);
        resetOtpTimer(60);
      } else {
        setShowOtp(false);
        setShowName(true);
      }
    }
  }, [customerChange]);

  const brandsDropdown = amanleekBrands?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });

  const yearsDropdown = years?.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });

  const addInsuranceEntry = async () => {
    const insuranceEntryForm: any = {};
    const leadInsertForm: any = {};

    if (carBrand.value === "") {
      setCarBrand((state: any) => {
        return {
          value: state.value,
          error: "برجاء ادخال الماركة",
        };
      });
      return;
    }

    setCarBrand((state: any) => {
      return {
        value: state.value,
        error: "",
      };
    });

    if (carModel.value === "") {
      setCarModel((state: any) => {
        return {
          value: state.value,
          error: "برجاء ادخال الموديل",
        };
      });
      return;
    }

    setCarModel((state: any) => {
      return {
        value: state.value,
        error: "",
      };
    });

    if (year.value === "") {
      setYear((state: any) => {
        return {
          value: state.value,
          error: "برجاء ادخال السنة",
        };
      });
      return;
    }
    setYear((state: any) => {
      return {
        value: state.value,
        error: "",
      };
    });

    if (price === 0) {
      setPriceError("برجاء ادخال السعر");
      return;
    }
    setPriceError("");

    if (age === 0) {
      setAgeError("برجاء ادخال السن");
      return;
    }
    setAgeError("");

    insuranceEntryForm.customer_age = age;
    insuranceEntryForm.car_brand_name = amanleekBrands.find(
      (item: any) => item.id === carBrand.value
    )?.name;
    insuranceEntryForm.car_model_name = amanleekCarModels.find(
      (item: any) => item.id === carModel.value
    )?.name;
    insuranceEntryForm.car_price = price;
    insuranceEntryForm.car_production_year = year.value;

    leadInsertForm.name = userData?.user?.name;
    leadInsertForm.phone = "+2" + userData?.user?.phone;
    leadInsertForm.age = age;
    leadInsertForm.model_id = carModel.value;
    leadInsertForm.car_year = year.value;
    leadInsertForm.car_price = price;

    if (!userData?.token) {
      setOpenLoginModal(true);
      return;
    }

    await dispatch(createInsuranceEntryAction(insuranceEntryForm));
    await dispatch(amanleekLeadInsertAction(leadInsertForm));

    setOpenSuccessModal(true);
  };

  const addInsuranceEntryNotLoggedIn = async () => {
    const insuranceEntryForm: any = {};
    const leadInsertForm: any = {};

    if (carBrand.value === "") {
      setCarBrand((state: any) => {
        return {
          value: state.value,
          error: "برجاء ادخال الماركة",
        };
      });
      return;
    }

    setCarBrand((state: any) => {
      return {
        value: state.value,
        error: "",
      };
    });

    if (carModel.value === "") {
      setCarModel((state: any) => {
        return {
          value: state.value,
          error: "برجاء ادخال الموديل",
        };
      });
      return;
    }

    setCarModel((state: any) => {
      return {
        value: state.value,
        error: "",
      };
    });

    if (year.value === "") {
      setYear((state: any) => {
        return {
          value: state.value,
          error: "برجاء ادخال السنة",
        };
      });
      return;
    }
    setYear((state: any) => {
      return {
        value: state.value,
        error: "",
      };
    });

    if (price === 0) {
      setPriceError("برجاء ادخال السعر");
      return;
    }
    setPriceError("");

    if (age === 0) {
      setAgeError("برجاء ادخال السن");
      return;
    }
    setAgeError("");

    insuranceEntryForm.customer_age = age;
    insuranceEntryForm.car_brand_name = amanleekBrands.find(
      (item: any) => item.id === carBrand.value
    )?.name;
    insuranceEntryForm.car_model_name = amanleekCarModels.find(
      (item: any) => item.id === carModel.value
    )?.name;
    insuranceEntryForm.car_price = price;
    insuranceEntryForm.car_production_year = year.value;

    leadInsertForm.name = userData?.user?.name;
    leadInsertForm.phone = "+2" + userData?.user?.phone;
    leadInsertForm.age = age;
    leadInsertForm.model_id = carModel.value;
    leadInsertForm.car_year = year.value;
    leadInsertForm.car_price = price;

    await dispatch(createInsuranceEntryAction(insuranceEntryForm));
    await dispatch(amanleekLeadInsertAction(leadInsertForm));

    setOpenSuccessModal(true);
  };

  const resetOtpTimer = (startTime: any) => {
    setEnableResend(false);
    setSecondsLeft(startTime);
    var counter = startTime;
    const timer = setInterval(() => {
      setSecondsLeft((secondsLeft) => secondsLeft - 1);
      counter--;

      if (counter === 0) {
        setEnableResend(true);
        clearInterval(timer);
      }
    }, 1000);
  };

  const handleLoginClick = async () => {
    const egyptianPhoneNumberPattern = /^(010|011|012|015)\d{8}$/;

    if (showOtp === false) {
      if (
        formik.errors.phone ||
        formik.values.phone === "" ||
        !egyptianPhoneNumberPattern.test(formik.values.phone)
      ) {
        formik.setErrors({ phone: "Cannot Proceed Without A Valid Number" });
        return;
      } else {
        await dispatch(mobileLoginAction(formik.values.phone));
        setCustomerChange(customerChange + 1);
        return;
      }
    }
    if (showName === false) {
      if (formik.errors.otp || formik.values.otp === "") {
        formik.setErrors({ otp: "Cannot Proceed Without An OTP" });
      } else {
        setShowName(true);
      }
    }
  };

  const handleVerifyOtpClick = async () => {
    if (!formik.errors.otp && formik.values.otp.trim() !== "") {
      await dispatch(
        verifyOtpAction(
          formik.values.phone,
          formik.values.otp,
          formik.values.name,
          formik.values.email
        )
      );
      if (!errors) {
        // success modal
        setOpenLoginModal(false);
        addInsuranceEntryNotLoggedIn();
      } else {
        notification.error({
          message: "OTP Error",
          description: "Please Reload The OTP",
          style: {
            zIndex: 99999999999999,
          },
        });
      }
    } else {
      formik.setErrors({ otp: "Cannot Proceed Without An OTP" });
    }
  };

  const handleNameEmailInput = () => {
    var errorExists = false;
    if (formik.errors.name || formik.values.name.trim() === "") {
      formik.setFieldError("name", "Please provide this field");
      errorExists = true;
    }
    // if (formik.errors.email || formik.values.email.trim() === "") {
    //   formik.setFieldError("email", "Please provide this field");
    //   errorExists = true;
    // }
    if (!errorExists) {
      setShowName(false);
      setShowOtp(true);
      resetOtpTimer(60);
    }
  };

  const handleResendOtpClick = async () => {
    if (enableResend) {
      setEnableResend(false);
      resetOtpTimer(60);
      await dispatch(mobileLoginAction(formik.values.phone));
    }
  };

  useEffect(() => {
    dispatch(changeCurrentPageAction(6));
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center pt-10 bg-[var(--new-souq-background)]">
      <Modal
        open={openSuccessModal}
        closable={true}
        onCancel={() => setOpenSuccessModal(false)}
        footer={null}
        maskClosable={false}
      >
        <div className="w-full flex flex-col items-center mt-10 gap-y-3">
          <img src={InsuranceSuccessIcon} />
          <p className="text-[#0050FF] font-bold text-[32px]">شكرا لك</p>
          <p>سيتم الواتصل معك في خلال 24 ساعة</p>
          <div
            className={`mt-3 w-full rounded-[10px] h-[69px] flex items-center justify-center ${styles.omColor}`}
          >
            <a
              href={`https://amanleek.com/en/insurance-products/car-insurance/?ch_brand=${carBrand?.value}&ch_model=${carModel?.value}&ch_year=${year?.value}&ch_price=${price}&user_age=${age}`}
              className="text-[#0050FF]"
            >
              لرؤية باقات العروض مع موقع امان ليك
            </a>
          </div>
        </div>
      </Modal>
      <Modal
        open={openLoginModal}
        closable={true}
        footer={null}
        onCancel={() => setOpenLoginModal(false)}
        maskClosable={false}
      >
        <div className="w-full flex items-center justify-center mb-8 mt-10">
          <p className="text-[var(--souq-blue)] text-[16px]">
            جاري تجهيز أفضل عروض التأمين المناسبة لك
          </p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {showName === false && showOtp === false ? (
            <div className="w-full flex flex-col items-center">
              <p className="mb-4 text-md">{t("fast_login")}</p>
              <div className="w-full flex flex-col items-center gap-y-2">
                <Input
                  type="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  status={formik.errors.phone ? "error" : ""}
                  placeholder={t("with_phone_number")}
                  size="large"
                  className={`${styles.phoneField} ${
                    currentLanguage === "ar"
                      ? `text-right ${styles.loginField}`
                      : "text-left"
                  }`}
                />
                {formik.errors.phone && (
                  <p
                    className="text-red-400 pt-4 text-xs"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("phonelength_errormessage")}
                  </p>
                )}
              </div>
            </div>
          ) : showName === true && showOtp === false ? (
            <div className="w-full flex flex-col items-center gap-y-3">
              <div className={`${styles.fieldLabel} w-full flex justify-end`}>
                <span>
                  <p>
                    <span
                      className={`${styles.fieldLabel}`}
                      style={{ color: "red" }}
                    >
                      *
                    </span>
                    الاسم
                  </p>
                </span>
              </div>
              <Input
                type="text"
                name="name"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                status={formik.errors.name ? "error" : ""}
                placeholder="الأسم"
                size="large"
                className={
                  currentLanguage === "ar"
                    ? `text-right ${styles.loginField}`
                    : "text-left"
                }
              />
              <div className={`${styles.fieldLabel} w-full flex justify-end`}>
                <p>البريد الاكتروني</p>
              </div>
              <Input
                type="text"
                name="email"
                onChange={formik.handleChange}
                status={formik.errors.email ? "error" : ""}
                placeholder="البريد الألكتروني"
                size="large"
                className={
                  currentLanguage === "ar"
                    ? `text-right ${styles.loginField} ${styles.emailField}`
                    : "text-left"
                }
              />
            </div>
          ) : (
            <div
              dir={isArabic ? "rtl" : "ltr"}
              className={`${styles.otpSectionContainer}`}
            >
              <div
                className={`ze:w-full sm:w-3/4 mb-4 flex flex-row items-center justify-between`}
              >
                <div
                  className={`me-5 w-full flex flex-col items-center justify-center gap-y-2 text-left`}
                >
                  <Input
                    type="text"
                    name="otp"
                    onChange={formik.handleChange}
                    status={formik.errors.otp ? "error" : ""}
                    className={`text-${isArabic ? "right" : "left"} ${
                      styles.loginField
                    } w-full`}
                    placeholder={t("otp_hint")}
                    size="large"
                  />
                </div>
                <div
                  className={`${styles.resendOtpButtonContainer} flex flex-row items-center justify-center gap-x-4`}
                >
                  {loginLoading ? (
                    <Spin />
                  ) : (
                    <CustomButton
                      className={`${
                        enableResend
                          ? styles.resendOtpButtonEnabled
                          : styles.resendOtpButtonDisabled
                      } ${
                        styles.resendOtpButton
                      } rounded-md flex justify-center items-center px-3`}
                      textStyling={`font-bold whitespace-nowrap`}
                      onClick={handleResendOtpClick}
                    >
                      {secondsLeft > 0 ? (
                        <span className={`${styles.secondsLeftText}`}>
                          {secondsLeft}
                        </span>
                      ) : (
                        <></>
                      )}{" "}
                      {t("resend")}
                    </CustomButton>
                  )}
                  {/* {userData && <CheckOutlined className="text-xl tex-[green]" />} */}
                </div>
              </div>
              {formik.errors.otp && (
                <p className="text-red-400 text-center">{formik.errors.otp}</p>
              )}
            </div>
          )}
          <div
            className="w-full flex flex-col items-center justify-center gap-y-4"
            style={{ marginTop: "3rem" }}
          >
            {showName === false && showOtp === false ? (
              loginLoading ? (
                <Spin />
              ) : (
                <CustomButton
                  className={`ze:w-[50%] lg:w-1/2 p-3 text-white mb-3`}
                  color={`blue`}
                  onClick={handleLoginClick}
                  buttonStyle={{ borderRadius: ".7rem" }}
                  addShadow={true}
                >
                  {t("next")}
                </CustomButton>
              )
            ) : showName === true && showOtp === false ? (
              <CustomButton
                className={`ze:w-full lg:w-3/4 rounded-xl p-3 text-white mb-3`}
                color={`blue`}
                onClick={handleNameEmailInput}
                buttonStyle={{ borderRadius: ".5rem" }}
                addShadow={true}
              >
                {t("next")}
              </CustomButton>
            ) : verifyOtpLoading ? (
              <Spin />
            ) : (
              <CustomButton
                className={`ze:w-1/2 lg:w-1/2 p-3 text-white mb-3`}
                color={`blue`}
                onClick={handleVerifyOtpClick}
                buttonStyle={{ borderRadius: ".5rem" }}
                addShadow={true}
              >
                {t("login")}
              </CustomButton>
            )}
          </div>
        </form>
      </Modal>
      <div
        dir={textDirectionStyle}
        className="w-full flex flex-row items-center justify-center"
      >
        <h1>{t("insurance_page_title_one")}</h1>
        <h1 className="text-[var(--souq-blue)]">
          {t("insurance_page_title_two")}
        </h1>
      </div>

      {amaanleekAuthLoading ? (
        <Spin />
      ) : (
        <div
          dir={textDirectionStyle}
          className={`w-[95%] py-8 mt-10 rounded-[20px] flex flex-col items-center justify-center gap-y-5 ${styles.blueOpacityHalf} ${styles.shadow} md:w-[60%]`}
        >
          <p className="text-black mt-[2rem]">{t("insurance_customer_car_info")}</p>
          <div className="w-full flex flex-row items-start justify-center flex-wrap gap-x-2 gap-y-3">
            {amanleekBrandsLoading ? (
              <Spin />
            ) : (
              <div className="insurance-screen-dropdown w-[45%] md:w-[14%]">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={t("homepage_car_maker")}
                  suffixIcon={<BlackDownArrow />}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={brandsDropdown}
                  onSelect={(value: any) => {
                    setCarModelsDropdown(
                      amanleekCarModels
                        ?.filter((item: any) => item.brand_id === value)
                        ?.map((item: any) => {
                          return {
                            label: item?.name,
                            value: item?.id,
                          };
                        })
                    );
                    setCarBrand((state: any) => {
                      return {
                        value: value,
                        error: state.error,
                      };
                    });
                  }}
                  size="large"
                  className="w-full"
                  style={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                  }}
                  dropdownStyle={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                  }}
                />
              </div>
            )}
            {amanleekCarModelsLoading ? (
              <Spin />
            ) : (
              <div className="insurance-screen-dropdown w-[45%] md:w-[14%]">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={t("homepage_car_model")}
                  suffixIcon={<BlackDownArrow />}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={carModelsDropdown}
                  onSelect={(value: any) => {
                    setCarModel((state: any) => {
                      return {
                        value: value,
                        error: state.error,
                      };
                    });
                  }}
                  size="large"
                  className="w-full"
                  style={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                  }}
                  dropdownStyle={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                  }}
                />
              </div>
            )}
            {yearsLoading ? (
              <Spin />
            ) : (
              <div className="insurance-screen-dropdown w-[45%] md:w-[14%]">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={t("homepage_year")}
                  suffixIcon={<BlackDownArrow />}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={yearsDropdown}
                  onSelect={(value: any) => {
                    setYear((state: any) => {
                      return {
                        value: value,
                        error: state.error,
                      };
                    });
                  }}
                  size="large"
                  className="w-full"
                  style={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                  }}
                  dropdownStyle={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                  }}
                />
              </div>
            )}
            <div className="insurance-screen-dropdown w-[45%] md:w-[14%]">
              <Input
                placeholder={t("total_price")}
                className="text-right w-full"
                type="number"
                size="large"
                onChange={(e: any) => {
                  setPrice(e.target.value);
                }}
                style={{ direction: currentLanguage === "ar" ? "rtl" : "ltr" }}
              />
            </div>
            <div className="insurance-screen-dropdown w-[92%] md:w-[14%]">
              <Input
                placeholder={t("owner_age")}
                className="text-right w-full"
                size="large"
                type="number"
                onChange={(e: any) => {
                  setAge(e.target.value);
                }}
                status={ageError === null ? "error" : ""}
                style={{ direction: currentLanguage === "ar" ? "rtl" : "ltr" }}
              />
            </div>
            {insuranceEntryLoading || leadInsertLoading ? (
              <div className="w-full flex items-center justify-center">
                <Spin />
              </div>
            ) : (
              <CustomButton
                className={`w-[92%] h-[3.5625rem] rounded-[5px] ${styles.shadow} md:w-[14%]`}
                color={"var(--new-blue)"}
                onClick={() => {
                  addInsuranceEntry();
                }}
              >
                <p className="text-white">{t("get_offer")}</p>
              </CustomButton>
            )}
          </div>
          {carBrand.error !== "" && (
            <p className="text-[red]">{carBrand.error}</p>
          )}
          {carModel.error !== "" && (
            <p className="text-[red]">{carModel.error}</p>
          )}
          {year.error !== "" && <p className="text-[red]">{year.error}</p>}
          {ageError !== "" && <p className="text-[red]">{ageError}</p>}
          {priceError !== "" && <p className="text-[red]">{priceError}</p>}
        </div>
      )}

      <div
        dir={textDirectionStyle}
        className="w-full flex flex-row justify-center items-center gap-x-3 mt-5"
      >
        <p>{t("service_from")}</p>
        <img src={AmanleekIcon} />
      </div>

      <div
        dir={textDirectionStyle}
        className="w-full flex flex-row items-center justify-center gap-x-3 mt-20 bg-[var(--new-souq-background)] md:gap-x-24"
      >
        <div className="flex flex-col items-center justify-center gap-y-3">
          <div className="w-[70px] h-[70px] bg-white rounded-full flex items-center justify-center">
            <img className="w-[35px] h-[35px]" src={FreeInspectionIcon} />
          </div>
          <p className="text-center">{t("free_inspection")}</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-3">
          <div className="w-[70px] h-[70px] bg-white rounded-full flex items-center justify-center">
            <img className="w-[35px] h-[35px]" src={RentalIcon} />
          </div>
          <p className="text-center">{t("navbar_rental_text")}</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-3">
          <div className="w-[70px] h-[70px] bg-white rounded-full flex items-center justify-center">
            <img className="w-[35px] h-[35px]" src={HoursIcon} />
          </div>
          <p className="text-center">{t("delivery_service")}</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-3">
          <div className="w-[70px] h-[70px] bg-white rounded-full flex items-center justify-center">
            <img className="w-[35px] h-[35px]" src={ContactIcon} />
          </div>
          <p className="text-center">{t("after_sale_service")}</p>
        </div>
      </div>

      <div className="mt-10 w-[95%] bg-white p-3 rounded-[20px] flex flex-col gap-y-10 md:w-[60%]">
        <div className="w-full flex flex-row items-center justify-center mt-8">
          <p className="text-[var(--souq-blue)] text-[20px]">
            {t("car_insurance_info")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_one_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_one_body")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_two_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_two_body")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_three_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_three_body_one")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_three_body_two")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_three_body_three")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_three_body_four")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_three_body_five")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_four_title")}
          </p>
          <p className="leading-[30px] font-bold">
            {t("insurance_info_section_four_body_one")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_four_point_one")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_four_point_two")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_four_point_three")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_four_point_four")}
          </p>
          <p className="leading-[30px] font-bold">
            {t("insurance_info_section_four_body_two")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_five_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_five_body")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_six_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_six_body_one")}
          </p>
          <p className="leading-[30px] font-bold mt-2">
            {t("insurance_info_section_six_body_two")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_six_body_three")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_seven_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_seven_body_one")}
          </p>
          <p className="leading-[30px] mt-5">
            {t("insurance_info_section_seven_body_two")}
          </p>
          <p className="leading-[30px] ps-5">
            {t("insurance_info_section_seven_body_three")}
          </p>
          <p className="leading-[30px] ps-5">
            {t("insurance_info_section_seven_body_four")}
          </p>
          <p className="leading-[30px] ps-5">
            {t("insurance_info_section_seven_body_five")}
          </p>
          <p className="leading-[30px] ps-5">
            {t("insurance_info_section_seven_body_six")}
          </p>
          <p className="leading-[30px] mt-5">
            {t("insurance_info_section_seven_body_seven")}
          </p>
          <p className="leading-[30px] ps-5">
            {t("insurance_info_section_seven_body_eight")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_eight_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_eight_body")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_nine_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_nine_body_one")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_nine_body_two")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_ten_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_ten_body")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_ten_body_one")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_ten_body_two")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_ten_body_three")}
          </p>
          <p className="leading-[30px] mt-5">
            {t("insurance_info_section_ten_body_four")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_eleven_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_eleven_body")}
          </p>
        </div>
        <div dir={textDirectionStyle} className="px-6">
          <p className="text-[var(--souq-blue)] font-bold mb-2">
            {t("insurance_info_section_twelve_title")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_one")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_two")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_three")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_four")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_five")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_six")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_seven")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_eight")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_nine")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_ten")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_eleven")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_twelve")}
          </p>
          <p className="leading-[30px]">
            {t("insurance_info_section_twelve_body_thirteen")}
          </p>
          <p className="leading-[30px] mt-5">
            {t("insurance_info_section_twelve_body_fourteen")}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InsuranceScreen;
