import * as React from "react";
import type { SVGProps } from "react";
const SvgYoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 800 562"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M563.5 7666.4V7442c79.7 37.5 141.4 73.7 214.4 112.8-60.2 33.3-134.7 70.8-214.4 111.6m444.1-336.1c-13.7-18.1-37.2-32.2-62.1-36.9-73.3-13.9-530.7-13.9-604 0-20 3.8-37.8 12.8-53.1 26.9-64.5 59.8-44.3 380.7-28.7 432.7 6.5 22.5 15 38.7 25.6 49.4 13.7 14.1 32.5 23.8 54 28.1 60.4 12.5 371.3 19.5 604.9 1.9 21.5-3.8 40.6-13.8 55.6-28.4 59.6-59.6 55.6-398.5 7.8-473.7"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      transform="translate(-244 -7282)"
    />
  </svg>
);
export default SvgYoutubeIcon;
