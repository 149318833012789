import
{
    MAKE_SPECIAL_LOADING,
    MAKE_SPECIAL_SUCCESS,
    MAKE_SPECIAL_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { makePostSpecialService } from "services/makePostSpecialService";

export const makePostSpecialAction = (postUUID: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: MAKE_SPECIAL_LOADING, payload: true});
        const repsonse = await makePostSpecialService(postUUID);
        dispatch({type: MAKE_SPECIAL_SUCCESS, payload: repsonse.data});
    } catch (err) {
        dispatch({type: MAKE_SPECIAL_FAILURE, payload: err});
    } finally {
        dispatch({type: MAKE_SPECIAL_LOADING, payload: false});
    }
}