import styles from "components/Footer/Footer.module.css";
import CustomButton from "components/CustomButton/CustomButton";
import {
  UpOutlined,
  FacebookFilled,
  WhatsAppOutlined,
  YoutubeFilled,
  TwitterOutlined,
  LinkedinFilled,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";
import {
  facebookIcon,
  linkedinIcon,
  instagramIcon,
  xTwitter,
  youtubeIcon,
} from "assets/icons";
import {
  InstagramIcon,
  XTwitter,
  FacebookIcon,
  YoutubeIcon,
} from "assets/IconComponents";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";
import { Spin } from "antd";

interface FooterProps {
  style?: any;
}

const Footer: FC<FooterProps> = ({ style }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    const topElement = document.getElementById("header");
    topElement?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const navigateToCategories = () => {
    const element = document.getElementById("categories-section");
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const goToCategoriesSection = async () => {
    if (window.location.pathname === `/${currentLanguage}`) {
      const topElement = document.getElementById("categories-section");
      topElement?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      navigate(`/`);
      const timeout = setTimeout(() => {
        const topElement = document.getElementById("categories-section");
        topElement?.scrollIntoView({
          behavior: "smooth",
        });
        clearTimeout(timeout);
      }, 1500);
    }
  };

  const dispatch: any = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const [isColumn, setIsColumn] = useState(false);
  const [textAlignment, setTextAlignment] = useState<any>();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";

  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
    window.addEventListener("resize", () => {
      window.innerWidth > 1023 ? setIsColumn(false) : setIsColumn(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    if (window.innerWidth <= 1023) {
      setIsColumn(true);
    }

    if (currentLanguage === "ar") setTextAlignment("text-right");
    else setTextAlignment("text-left");
  }, []);

  useEffect(() => {
    dispatch(listCarMakerAction());
  }, []);

  return !isColumn ? (
    <footer
      dir={isArabic ? "rtl" : "ltr"}
      className={`w-full ${styles.footerContainer} flex flex-row justify-between items-start`}
      style={{ height: "21rem", ...style }}
    >
      <div
        className={`flex flex-col ${styles.aboutSection} items-start justify-between`}
      >
        <div className={`w-full flex flex-col justify-between items-start`}>
          <p className={`${textAlignment} ${styles.footerHeading}`}>
            {t("about_Souq_Car")}
          </p>
          <div className={`${styles.souqDescription} ${styles.footerContent}`}>
            <p className={`${textAlignment}`}>{t("about_souqCarDesc")}</p>
          </div>
        </div>

        <div
          className={`${styles.newSocialIcons} flex items-end justify-end gap-x-3`}
        >
          <a href="https://twitter.com/home">
            {/* <img className={`${styles.socialIcons}`} src={xTwitter} alt="" /> */}
            <XTwitter />
          </a>
          <a href="https://www.instagram.com/">
            {/* <img
                className={`${styles.socialIcons}`}
                src={instagramIcon}
                alt=""
              /> */}
            <InstagramIcon />
          </a>
          <a href="https://www.youtube.com/channel/UC2uTHE7G5UbbYvhtn5PJkWA">
            {/* <img className={`${styles.socialIcons}`} src={youtubeIcon} alt="" /> */}
            <YoutubeIcon />
          </a>
          <a href="https://www.facebook.com/souq.car.egy">
            {/* <img
                className={`${styles.socialIcons}`}
                src={facebookIcon}
                alt=""
              /> */}
            <FacebookIcon />
          </a>
        </div>

        <p className={`${textAlignment}`}>{t("copyRights")}</p>
      </div>

      <div className={`flex flex-col`}>
        <p className={`${textAlignment} ${styles.footerHeading}`}>
          {t("Quick_links")}
        </p>

        <a
          href={`/${currentLanguage}`}
          className={`${textAlignment} ${styles.footerContent} mb-5`}
        >
          {t("navbar_home_text")}
        </a>
        <a
          onClick={goToCategoriesSection}
          className={`${textAlignment} ${styles.footerContent} mb-5`}
        >
          {t("categories")}
        </a>
        <a
          href={`/${currentLanguage}/about-us`}
          className={`${textAlignment} ${styles.footerContent} mb-5`}
        >
          {t("about_us")}
        </a>
        <a
          href={`/${currentLanguage}/News/view`}
          className={`${textAlignment} ${styles.footerContent} mb-5`}
        >
          {t("navbar_news_text")}
        </a>
        <a
          href={`/${currentLanguage}/contact-us`}
          className={`${textAlignment} ${styles.footerContent} mb-5`}
        >
          {t("contact_us")}
        </a>
        <a
          href={`/${currentLanguage}/ads/view?category_id=1&car_condition=Used`}
          className={`${textAlignment} ${styles.footerContent} mb-5`}
        >
          {t("used_cars")}
        </a>
      </div>

      <div className={`flex flex-col`}>
        <p className={`${textAlignment} ${styles.footerHeading}`}>
          {t("help")}
        </p>
        <a
          className={`${textAlignment} ${styles.footerContent} mb-5`}
          href={`/${currentLanguage}/faq`}
        >
          {t("faqs")}
        </a>
        <a
          className={`${textAlignment} ${styles.footerContent} mb-5`}
          href={`/${currentLanguage}/terms-of-use`}
        >
          {t("terms_of_use")}
        </a>
        <a
          className={`${textAlignment} ${styles.footerContent} mb-5`}
          href={`/${currentLanguage}/privacy-policy`}
        >
          {t("privacy_policy")}
        </a>
      </div>

      {carMakers && (
        <div className={`flex flex-col`}>
          <p className={`${textAlignment} ${styles.footerHeading}`}>
            {t("The_most_famous_cars")}
          </p>
          <div className={`flex justify-between`}>
            <div className={`flex flex-col ${styles.famousCars}`}>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[2]?.id}`}
              >
                {carMakers[2]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[5]?.id}`}
              >
                {carMakers[5]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[8]?.id}`}
              >
                {carMakers[8]?.name}
              </a>
            </div>

            <div className={`flex flex-col ${styles.famousCars}`}>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[1]?.id}`}
              >
                {carMakers[1]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[4]?.id}`}
              >
                {carMakers[4]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[7]?.id}`}
              >
                {carMakers[7]?.name}
              </a>
            </div>

            <div className={`flex flex-col ${styles.famousCars}`}>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[0]?.id}`}
              >
                {carMakers[0]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[3]?.id}`}
              >
                {carMakers[3]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-5`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[6]?.id}`}
              >
                {carMakers[6]?.name}
              </a>
            </div>
          </div>
        </div>
      )}

      <div className={``}></div>
    </footer>
  ) : (
    <footer
      className={`w-full ${styles.footerContainerMobile} flex flex-col justify-between items-start`}
    >
      <div className={`w-full flex flex-col ${styles.aboutSection} items-end`}>
        <div className={`w-full flex flex-col justify-between items-end`}>
          <p className={`w-full ${textAlignment} ${styles.footerHeading}`}>
            {t("about_Souq_Car")}
          </p>
          <div className={`${styles.souqDescription} ${styles.footerContent}`}>
            <p className={`${textAlignment}`}>{t("about_souqCarDesc")}</p>
          </div>
        </div>
      </div>

      <div className={`w-full flex flex-col mt-12`}>
        <p className={`${textAlignment} ${styles.footerHeading}`}>
          {t("Quick_links")}
        </p>

        <div
          className={`flex ${isArabic ? "justify-end" : "justify-start"} mb-2`}
        >
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            href={`/${currentLanguage}/News/view`}
            className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
          >
            {t("navbar_news_text")}
          </a>
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            href={`/${currentLanguage}`}
            className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
          >
            {t("navbar_home_text")}
          </a>
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            onClick={goToCategoriesSection}
            className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
          >
            {t("categories")}
          </a>
        </div>
        <div
          className={`flex ${isArabic ? "justify-end" : "justify-start"} mb-2`}
        >
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "1rem" }
                : { marginInlineStart: "1rem" }
            }
            href={`/${currentLanguage}/ads/view?category_id=1&car_condition=Used`}
            className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
          >
            {t("used_cars")}
          </a>
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            href={`/${currentLanguage}/contact-us`}
            className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
          >
            {t("contact_us")}
          </a>
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            href={`/${currentLanguage}/about-us`}
            className={`${textAlignment} ${styles.footerContent}  whitespace-nowrap`}
          >
            {t("about_us")}
          </a>
        </div>
      </div>

      <div className={`w-full flex flex-col mt-12`}>
        <p className={`${textAlignment} ${styles.footerHeading}`}>
          {t("help")}
        </p>
        <div
          className={`flex ${
            isArabic ? "justify-end" : "justify-start"
          } flex-wrap gap-y-3`}
        >
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            className={`${textAlignment} ${styles.footerContent} whitespace-nowrap`}
            href={`/${currentLanguage}/faq`}
          >
            {t("faqs")}
          </a>
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            className={`${textAlignment} ${styles.footerContent} whitespace-nowrap`}
            href={`/${currentLanguage}/terms-of-use`}
          >
            {t("terms_of_use")}
          </a>
          <a
            style={
              !isArabic
                ? { marginInlineEnd: "3rem" }
                : { marginInlineStart: "3rem" }
            }
            className={`${textAlignment} ${styles.footerContent} whitespace-nowrap`}
            href={`/${currentLanguage}/privacy-policy`}
          >
            {t("privacy_policy")}
          </a>
        </div>
      </div>

      {carMakers && (
        <div className={`w-full flex flex-col mt-12`}>
          <p className={`${textAlignment} ${styles.footerHeading}`}>
            {t("The_most_famous_cars")}
          </p>
          <div className={`w-full flex justify-between`}>
            <div className={`w-full flex flex-col ${styles.famousCarsMobile}`}>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[2]?.id}`}
              >
                {carMakers[2]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[5]?.id}`}
              >
                {carMakers[5]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[8]?.id}`}
              >
                {carMakers[8]?.name}
              </a>
            </div>

            <div className={`w-full flex flex-col ${styles.famousCarsMobile}`}>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[1]?.id}`}
              >
                {carMakers[1]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[4]?.id}`}
              >
                {carMakers[4]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[7]?.id}`}
              >
                {carMakers[7]?.name}
              </a>
            </div>

            <div className={`w-full flex flex-col ${styles.famousCarsMobile}`}>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[0]?.id}`}
              >
                {carMakers[0]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[3]?.id}`}
              >
                {carMakers[3]?.name}
              </a>
              <a
                className={`${textAlignment} ${styles.footerContent} mb-3`}
                href={`/${currentLanguage}/ads/view?category_id=1&brand_id=${carMakers[6]?.id}`}
              >
                {carMakers[6]?.name}
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center w-full">
        <div className={`flex justify-around items-center w-[60%]`}>
          <a href="https://www.facebook.com/souq.car.egy">
            <img
              className={`${styles.socialIcons}`}
              src={facebookIcon}
              alt=""
            />
          </a>

          <a href="https://www.youtube.com/channel/UC2uTHE7G5UbbYvhtn5PJkWA">
            <img className={`${styles.socialIcons}`} src={youtubeIcon} alt="" />
          </a>

          <a href="https://twitter.com/home">
            <img className={`${styles.socialIcons}`} src={xTwitter} alt="" />
          </a>

          <a href="https://www.instagram.com/">
            {/* <img
              className={`${styles.socialIcons}`}
              src={instagramIcon}
              alt=""
            /> */}
            <InstagramIcon />
          </a>
        </div>

        <p className={`${textAlignment}`}>{t("copyRights")}</p>
      </div>
    </footer>
  );
};

export default Footer;
