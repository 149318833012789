import { Modal, Spin, Tooltip } from "antd";
import { CustomButton, Footer } from "components";
import styles from "screens/V3/SingleArticleScreen/SingleArticleScreen.module.css";
import { useState, useEffect } from "react";
import Stories from "react-insta-stories";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listCustomerStoriesAction } from "redux/actions/StoriesActions/CustomerStoriesAction/listCustomerStoriesAction";
import {
  HeartFilled,
  MessageOutlined,
  PhoneFilled,
  CloseOutlined,
} from "@ant-design/icons";
import { CloseIcon, ShareButtonIcon, WhiteLogo } from "assets/icons/V3";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useResponsive } from "hooks/useResponsive";
import ShareButton from "components/V3/ShareButton/ShareButton";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { increaseStoryViewsService } from "services/OneTimeServices/increaseStoryViewsService";
import { favouriteStoryService } from "services/OneTimeServices/favouriteStoryService";
import { sendChatMessageAction } from "redux/actions";
import { useLanguage } from "hooks/useLanguage";
import { useUtilities } from "hooks/useUtilities";

const SingleStoryScreen = () => {
  const { uuid } = useParams<any>();

  const { userData, t, dispatch, navigate } = useUtilities();
  const { isMobile } = useResponsive();

  const { currentLanguage } = useLanguage();

  const { customerStoriesLoading, customerStories } = useSelector(
    (state: RootState) => state.listCustomerStoriesReducer
  );

  const [favouritedState, setFavouritedState] = useState(false);
  const [currentPlayingStoryIndex, setCurrentPlayingStoryIndex] = useState(0);
  const [currentPlayingStoryId, setCurrentPlayingStoryId] = useState("");

  const stories: any = [
    {
      url: "https://souqcarlive.s3.amazonaws.com/posts/1699357452photo1697103038.jpeg",
      seeMore: ({ close }: { close: any }) => (
        <div style={{ width: "100%", height: "100%" }}>Hello</div>
      ),
      header: {
        heading: "Mohit Karekar",
        subheading: "Posted 5h ago",
        profileImage: "https://picsum.photos/1000/1000",
      },
    },
    {
      url: "https://souqcarlive.s3.amazonaws.com/posts/1699357453photo1697103038%20(1).jpeg",
      header: {
        heading: "Mohit Karekar",
        subheading: "Posted 32m ago",
        profileImage: "https://picsum.photos/1080/1920",
      },
    },
    {
      url: "https://media.idownloadblog.com/wp-content/uploads/2016/04/iPhone-wallpaper-abstract-portrait-stars-macinmac.jpg",
      header: {
        heading: "mohitk05/react-insta-stories",
        subheading: "Posted 32m ago",
        profileImage:
          "https://avatars0.githubusercontent.com/u/24852829?s=400&v=4",
      },
    },
    {
      url: "https://storage.googleapis.com/coverr-main/mp4/Footboys.mp4",
      type: "video",
    },
    {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
      type: "video",
      seeMore: ({ close }: { close: any }) => (
        <div style={{ width: "100%", height: "100%" }}>Hello</div>
      ),
    },
    {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
      type: "video",
    },
    "https://images.unsplash.com/photo-1534856966153-c86d43d53fe0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80",
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    console.log("userData", userData);

    dispatch(listCustomerStoriesAction(uuid));
    if (customerStories?.length > 0) {
      setCurrentPlayingStoryId(customerStories[0]?.id);
    }
  }, []);

  const handleSendMessage = async (id: any) => {
    await dispatch(sendChatMessageAction(id, "*$$*"));
    navigate(`/${currentLanguage}/messages?vendorId=${id}`);
  };

  return (
    <div
      className={`w-full h-[100vh] flex flex-row items-center justify-center relative ${
        isMobile ? "" : "px-[4.87rem]"
      } bg-black overflow-y-hidden`}
    >
      {!isMobile ? (
        <div className="stories-container h-full flex flex-row items-end justify-center gap-x-6">
          <Spin
            spinning={customerStoriesLoading}
            className="w-[60%] h-full flex flex-row items-center justify-center"
          >
            {customerStoriesLoading ? (
              <div className="w-full mb-[2.64rem] h-[40rem] bg-gray-500 flex items-center justify-center"></div>
            ) : (
              <div className="h-full flex flex-col items-center gap-y-3">
                <img src={WhiteLogo} />
                <div className="w-full h-full mb-[2.64rem] flex flex-row items-center justify-center">
                  <Stories
                    onStoryStart={() => {
                      increaseStoryViewsService(currentPlayingStoryId);
                    }}
                    onStoryEnd={() => {
                      setCurrentPlayingStoryIndex((prevIndex) => prevIndex + 1);
                      if (
                        currentPlayingStoryIndex + 1 <
                        customerStories?.length
                      ) {
                        setCurrentPlayingStoryId(
                          customerStories[currentPlayingStoryIndex + 1].id
                        );
                      }
                      setFavouritedState(false);
                    }}
                    stories={
                      customerStories?.map((item: any) => {
                        return {
                          url: item.image,
                          header: {
                            heading: item.customer?.name,
                            subheading: item.created_at,
                            profileImage: item.customer?.personal_image,
                          },
                        };
                      }) || stories
                    }
                    width={"30rem"}
                    height={"90dvh"}
                    storyContainerStyles={{
                      borderRadius: "1rem",
                    }}
                    defaultInterval={5000}
                    loop={false}
                    storyStyles={{
                      backgroundColor: "red",
                    }}
                  />
                </div>
              </div>
            )}
          </Spin>
          <div className="flex flex-col items-center justify-center mb-[2.8rem] gap-y-9">
            <div className="flex flex-col gap-y-2">
              <ShareButton url={window.location.href}>
                <img
                  alt="share"
                  src={ShareButtonIcon}
                  className="w-[3.18669rem] h-[3.18669rem]"
                />
              </ShareButton>
              {/* <p className="text-white text-[0.9375rem]">
                {t("viewpost_share")}
              </p> */}
            </div>
            <div className="flex flex-col gap-y-2">
              <div
                className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-white flex items-center justify-center hover:cursor-pointer"
                onClick={() => {
                  setFavouritedState(true);
                  favouriteStoryService(currentPlayingStoryId);
                }}
              >
                <HeartFilled
                  className={`text-[1.5rem] ${
                    favouritedState ? "text-[red]" : "text-gray-500"
                  }`}
                />
              </div>
              {/* <p className="text-white text-[0.9375rem]">{t("like")}</p> */}
            </div>
            {customerStories?.[0]?.customer?.id !== userData?.user?.id && (
              <div className="flex flex-col gap-y-2 items-center">
                <div
                  className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-white flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    handleSendMessage(customerStories?.[0]?.customer?.id);
                  }}
                >
                  <MessageOutlined className="text-[1.5rem] text-[var(--new-blue)]" />
                </div>
              </div>
            )}
            {customerStories?.[0]?.customer?.id !== userData?.user?.id && (
              <div className="flex flex-col gap-y-2 items-center">
                <a
                  className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-white flex items-center justify-center cursor-pointer"
                  href={`tel:+20${customerStories?.[0]?.customer?.phone}`}
                >
                  <PhoneFilled className="text-[1.5rem] text-[var(--new-blue)]" />
                </a>
              </div>
            )}
            {customerStories !== null && (
              <div
                className="w-[3.18669rem] h-[3.18669rem] rounded-full bg-[var(--new-blue)] flex items-center justify-center hover:cursor-pointer"
                onClick={() => {
                  // navigate to vendor profile
                }}
                style={{
                  backgroundImage: `url('${customerStories[0]?.customer?.personal_image}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <Spin
            spinning={customerStoriesLoading}
            className="w-full flex flex-row items-center justify-center"
            style={{
              width: "100%",
            }}
          >
            {customerStoriesLoading ? (
              <div className="w-full mb-[2.64rem] h-[40rem] bg-gray-500 flex items-center justify-center"></div>
            ) : (
              <div className="w-full flex flex-col items-center gap-y-3">
                <div className="w-full flex flex-row items-center justify-around">
                  <img src={WhiteLogo} />
                  <CloseOutlined
                    className="text-white text-[1.5rem]"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div className="w-full mb-[2.64rem] flex flex-col items-center justify-center">
                  <Stories
                    onStoryStart={() => {
                      increaseStoryViewsService(currentPlayingStoryId);
                    }}
                    onStoryEnd={() => {
                      setCurrentPlayingStoryIndex((prevIndex) => prevIndex + 1);
                      if (
                        currentPlayingStoryIndex + 1 <
                        customerStories?.length
                      ) {
                        setCurrentPlayingStoryId(
                          customerStories[currentPlayingStoryIndex + 1].id
                        );
                      }
                      setFavouritedState(false);
                    }}
                    stories={
                      customerStories?.map((item: any) => {
                        return {
                          url: item.image,
                          header: {
                            heading: item.customer?.name,
                            subheading: item.created_at,
                            profileImage: item.customer?.personal_image,
                          },
                        };
                      }) || stories
                    }
                    width={"100%"}
                    height={"34rem"}
                    storyContainerStyles={{
                      borderRadius: "1rem",
                    }}
                    defaultInterval={5000}
                    preventDefault
                    loop={false}
                    storyStyles={{
                      backgroundColor: "red",
                    }}
                  />
                  <div className="px-4 w-full flex flex-row items-center justify-start gap-x-3 absolute z-30 bottom-[3.2rem]">
                    <ShareButton url={window.location.href}>
                      <img
                        alt="share"
                        src={ShareButtonIcon}
                        className="w-[1.8rem] h-[1.8rem]"
                      />
                    </ShareButton>
                    <div
                      className="bg-white w-[1.8rem] h-[1.8rem] rounded-full flex items-center justify-center"
                      onClick={() => {
                        setFavouritedState(true);
                        favouriteStoryService(currentPlayingStoryId);
                      }}
                    >
                      <HeartFilled
                        className={` ${
                          favouritedState ? "text-[red]" : "text-gray-500"
                        }`}
                      />
                    </div>
                    <div className="bg-white w-[1.8rem] h-[1.8rem] rounded-full flex items-center justify-center">
                      <MessageOutlined className="text-[var(--new-blue)]" />
                    </div>
                    <div className="bg-white w-[1.8rem] h-[1.8rem] rounded-full flex items-center justify-center">
                      <PhoneFilled className="text-[var(--new-blue)] rotate-[100deg]" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </div>
      )}
      {!isMobile && (
        <div className="absolute top-[2.68rem] right-[4.87rem] flex flex-row items-center justify-center">
          <img
            src={CloseIcon}
            className="hover:cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
            alt="close"
          />
        </div>
      )}
    </div>
  );
};

export default SingleStoryScreen;
