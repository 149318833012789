import
{
    LIST_FAQS_LOADING,
    LIST_FAQS_SUCCESS,
    LIST_FAQS_FAILURE
}
from "redux/types";

import { listTableDataService } from "services/listTableDataService";

import { Dispatch } from "redux";

export const listFaqsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_FAQS_LOADING, payload: true});
        const response = await listTableDataService('getFAQs');
        dispatch({type: LIST_FAQS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_FAQS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_FAQS_LOADING, payload: false});
    }
}