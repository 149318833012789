import {
  UPDATE_CUSTOMER_DATA_LOADING,
  UPDATE_CUSTOMER_DATA_SUCCESS,
  UPDATE_CUSTOMER_DATA_FAILURE,
  UPDATE_CUSTOMER_DATA,
} from "redux/types";

import { Dispatch } from "redux";

import { updateCustomerDataService } from "services/updateCustomerDataService";
import store from "redux/store";

export const updateCustomerAction =
  (name: string, email: string, personal_image: any) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPDATE_CUSTOMER_DATA_LOADING, payload: true });
      const response = await updateCustomerDataService(
        name,
        email,
        personal_image
      );
      dispatch({ type: UPDATE_CUSTOMER_DATA_SUCCESS, payload: response.data });
      dispatch({
        type: UPDATE_CUSTOMER_DATA,
        payload: {
          token: store.getState()?.verifyOtpReducer?.userData?.token,
          user: response.data?.customer,
        },
      });
    } catch (err) {
      dispatch({ type: UPDATE_CUSTOMER_DATA_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPDATE_CUSTOMER_DATA_LOADING, payload: false });
    }
  };
