import
{
    UPDATE_SOCIAL_LINKS_LOADING,
    UPDATE_SOCIAL_LINKS_SUCCESS,
    UPDATE_SOCIAL_LINKS_FAILURE
}
from "redux/types";

const initialState = {
    updateSocialLinksLoading: false,
    updateSocialLinksSuccess: null,
    errors: null
}

export const updateSocialLinksReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_SOCIAL_LINKS_LOADING :
            return {...state, updateSocialLinksLoading: action.payload};
        case UPDATE_SOCIAL_LINKS_SUCCESS :
            return {...state, updateSocialLinksSuccess: action.payload.success};
        case UPDATE_SOCIAL_LINKS_FAILURE :
            return {...state, errors: action.paylad};
        default:
            return state;
    }
}