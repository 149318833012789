import { Footer } from "components";
import styles from "screens/V3/ExhibitionsScreen/ExhibitionsScreen.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect } from "react";
import { listExhibitionsAction } from "redux/actions/ExhibitionsActions/listExhibitionsAction";
import { Spin } from "antd";
import SquareExhibitorCard from "components/V3/SquareExhibitorCard/SquareExhibitorCard";
import { useSearch } from "hooks/useSearch";
import { useSearchParams } from "react-router-dom";
import NewPaginator from "components/V3/NewPaginator/NewPaginator";
import { useResponsive } from "hooks/useResponsive";
import { changeCurrentPageAction } from "redux/actions";

const ExhibitionsScreen = () => {
  const { t } = useTranslation();

  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { loading, exhibitions, currentPage, lastPage } = useSelector(
    (state: RootState) => state.listExhibitionsReducer
  );

  const { isMobile } = useResponsive();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { getSearchParam } = useSearch();

  useEffect(() => {
    dispatch(listExhibitionsAction(getSearchParam("page") ?? 1));

    dispatch(changeCurrentPageAction(2));
  }, [searchParams]);

  return (
    <div className="mt-[2.94rem] w-full flex flex-col items-center">
      <h1 className="text-[var(--new-blue)] text-[1.25rem] font-[700]">
        {t("exhibitions_and_dealers")}
      </h1>
      <div
        className={`mt-[2.25rem] square-exhibition-card-spinner ${
          isMobile ? "w-[95%]" : "w-[75%]"
        } flex flex-row items-center justify-start gap-y-3 gap-x-3 flex-wrap`}
      >
        <Spin spinning={loading} className="w-full">
          <div
            dir="rtl"
            className="w-full flex flex-row items-center justify-start gap-y-3 gap-x-3 flex-wrap"
          >
            {exhibitions?.map((item: any) => {
              return (
                <SquareExhibitorCard
                  id={item.id}
                  image={item.image}
                  name={item.name}
                  description={item.description}
                  isSpecial={true}
                  width={"w-[32%]"}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
        </Spin>
      </div>
      <div className="w-full flex flex-row items-center justify-center mt-[4.56rem] mb-[6.31rem]">
        <NewPaginator
          currentPage={
            isNaN(parseInt(getSearchParam("page")))
              ? 1
              : parseInt(getSearchParam("page"))
          }
          lastPage={lastPage}
          url={`/${currentLanguage}/exhibitions`}
        />
      </div>
      <Footer />
    </div>
  );
};

export default ExhibitionsScreen;
