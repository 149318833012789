import 
{
    CHANGE_CURRENT_PAGE
}
from "redux/types";

const initialState = {
    currentPage: 0
};

export const changeCurrentPageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CHANGE_CURRENT_PAGE :
            return {...state, currentPage: action.payload};
        default:
            return state;
    }
}