import { PROFILE_POST_CATEGORY } from "redux/types";

const initialState = {
  profilePostCategory: "Active",
};

export const profilePostCategoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PROFILE_POST_CATEGORY:
      return { ...state, profilePostCategory: action.payload };
    default:
      return state;
  }
};
