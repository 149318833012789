import "components/Carousel/BannersCarousel/BannersCarousel.css";
import { useEffect, useState } from "react";
import BannerEn1 from "assets/images/Banners/en/d1.png";
import BannerEn2 from "assets/images/Banners/en/d2.png";
import BannerEn3 from "assets/images/Banners/en/d3.png";
import BannerEn3_1 from "assets/images/Banners/en/d3.1.png";
import BannerEn3_2 from "assets/images/Banners/en/d3.2.png";
import BannerEnMobile1 from "assets/images/Banners/en/p1.png";
import BannerEnMobile2 from "assets/images/Banners/en/p2.png";
import BannerEnMobile3 from "assets/images/Banners/en/p3.png";
import BannerEnMobile3_1 from "assets/images/Banners/en/p3.1.png";
import BannerEnMobile3_2 from "assets/images/Banners/en/p3.2.png";
import BannerAr1 from "assets/images/Banners/ar/d1.png";
import BannerAr2 from "assets/images/Banners/ar/d2.png";
import BannerAr3 from "assets/images/Banners/ar/d3.png";
import BannerAr3_1 from "assets/images/Banners/ar/d3.1.png";
import BannerAr3_2 from "assets/images/Banners/ar/d3.2.png";
import BannerArMobile1 from "assets/images/Banners/ar/p1.png";
import BannerArMobile2 from "assets/images/Banners/ar/p2.png";
import BannerArMobile3 from "assets/images/Banners/ar/p3.png";
import BannerArMobile3_1 from "assets/images/Banners/ar/p3.1.png";
import BannerArMobile3_2 from "assets/images/Banners/ar/p3.2.png";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

const BannersCarousel = () => {
  const arabicBanners: any = {
    desktop: [BannerAr1, BannerAr2, BannerAr3, BannerAr3_1, BannerAr3_2],
    phone: [
      BannerArMobile1,
      BannerArMobile2,
      BannerArMobile3,
      BannerArMobile3_1,
      BannerArMobile3_2,
    ],
  };

  const [isMobile, setIsMobile] = useState(false);

  const englishBanners: any = {
    desktop: [BannerEn1, BannerEn2, BannerEn3, BannerEn3_1, BannerEn3_2],
    phone: [
      BannerEnMobile1,
      BannerEnMobile2,
      BannerEnMobile3,
      BannerEnMobile3_1,
      BannerEnMobile3_2,
    ],
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    const wrapper: any = document.querySelector(".banner-wrapper");
    const carousel: any = document.getElementById("banner-carousel");
    const firstCardWidth: any =
      carousel?.querySelector(".banner-card")?.offsetWidth;
    const arrowBtns = document.querySelectorAll(".banner-wrapper i");
    const carouselChildrens = [...carousel?.children];

    let isDragging = false,
      isAutoPlay = true,
      startX: any,
      startScrollLeft: any,
      timeoutId: any;

    // Get the number of cards that can fit in the carousel at once
    let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

    // Insert copies of the last few cards to beginning of carousel for infinite scrolling
    carouselChildrens
      .slice(-cardPerView)
      .reverse()
      .forEach((card: any) => {
        carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
      });

    // Insert copies of the first few cards to end of carousel for infinite scrolling
    carouselChildrens.slice(0, cardPerView).forEach((card: any) => {
      carousel.insertAdjacentHTML("beforeend", card.outerHTML);
    });

    // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
    carousel.classList.add("no-transition");
    carousel.scrollLeft = carousel.offsetWidth;
    carousel.classList.remove("no-transition");

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft +=
          btn.id == "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e: any) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e: any) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const infiniteScroll = () => {
      // If the carousel is at the beginning, scroll to the end
      if (carousel.scrollLeft === 0) {
        carousel.classList.add("no-transition");
        // carousel.scrollLeft = carousel.scrollWidth - carousel.offsetWidth;
        carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }
      // If the carousel is at the end, scroll to the beginning
      else if (
        Math.ceil(carousel.scrollLeft) >=
        carousel.scrollWidth - carousel.offsetWidth
      ) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }

      // Clear existing timeout & start autoplay if mouse is not hovering over carousel
      clearTimeout(timeoutId);
      // if (!wrapper.matches(":hover")) autoPlay();
      autoPlay();
    };

    const autoPlay = () => {
      // if(window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
      // Autoplay the carousel after every 2500 ms
      timeoutId = setTimeout(
        () => (carousel.scrollLeft -= firstCardWidth),
        5000
      );
    };
    autoPlay();

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
    wrapper.addEventListener("mouseleave", autoPlay);
  }, []);

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  return (
    <div className="banner-wrapper">
      {/* <i id="left" className="fa-solid fa-angle-left"></i> */}
      <ul id="banner-carousel" className="banner-carousel">
        {isMobile ? (
          <>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.phone[0]
                      : englishBanners.phone[0]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.phone[1]
                      : englishBanners.phone[1]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.phone[3]
                      : englishBanners.phone[3]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.phone[4]
                      : englishBanners.phone[4]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
          </>
        ) : (
          <>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.desktop[0]
                      : englishBanners.desktop[0]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.desktop[1]
                      : englishBanners.desktop[1]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.desktop[3]
                      : englishBanners.desktop[3]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
            <li id="banner-card" className="banner-card">
              <div className="banner-img">
                <img
                  src={
                    currentLanguage === "ar"
                      ? arabicBanners.desktop[4]
                      : englishBanners.desktop[4]
                  }
                  className="banner-imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
            </li>
          </>
        )}
        {/* <li  id="banner-card" className="banner-card">
                    <div className="banner-img"><img src={isMobile ? currentLanguage === "ar" ? arabicBanners.phone[0] : englishBanners.phone[0] : currentLanguage === "ar" ? arabicBanners.desktop[0] : englishBanners.desktop[0]} className="banner-imageStyling" alt="img" draggable="false" /></div>
                </li>
                <li  id="banner-card" className="banner-card">
                    <div className="banner-img"><img src={isMobile ? currentLanguage === "ar" ? arabicBanners.phone[1] : englishBanners.phone[1] : currentLanguage === "ar" ? arabicBanners.desktop[1] : englishBanners.desktop[1]} className="banner-imageStyling" alt="img" draggable="false" /></div>
                </li>
                <li  id="banner-card" className="banner-card">
                    <div className="banner-img"><img src={isMobile ? currentLanguage === "ar" ? arabicBanners.phone[2] : englishBanners.phone[2] : currentLanguage === "ar" ? arabicBanners.desktop[2] : englishBanners.desktop[2]} className="banner-imageStyling" alt="img" draggable="false" /></div>
                </li> */}
      </ul>
      {/* <i id="right" className="fa-solid fa-angle-right"></i> */}
    </div>
  );
};

export default BannersCarousel;
