import
{
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET_OTP,
    RESET_CUSTOMER,
}
from "redux/types";

const initialState = {
    loginLoading: false,
    otp: null,
    customer: null,
    mobileLoginErrors: null,
}

export const mobileLoginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_LOADING :
            return {...state, loginLoading: action.payload};
        case LOGIN_SUCCESS :
            return {...state, otp: action.payload.otp.otp, customer: action.payload.customer};
        case LOGIN_FAILURE :
            return {...state, mobileLoginErrors: action.payload};
        case RESET_OTP :
            return {...state, otp: null}
        case RESET_CUSTOMER :
            return {...state, customer: null}
        default:
            return state;
    }
}