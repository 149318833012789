import {
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
} from "redux/types";

import { verifyOtpService } from "services/verifyOtpService";

import { Dispatch } from "redux";

export const verifyOtpAction =
  (mobile: string, otp: string, name?: string, email?: string, city?: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: VERIFY_OTP_LOADING, payload: true });
      const response = await verifyOtpService(mobile, otp, name, email, city);
      dispatch({ type: VERIFY_OTP_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: VERIFY_OTP_FAILURE, payload: err });
    } finally {
      dispatch({ type: VERIFY_OTP_LOADING, payload: false });
    }
  };
