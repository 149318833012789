import styles from "screens/V3/LoginScreen/LoginScreen.module.css";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import phoneRegExp from "utils/phoneRegularExpression";
import UserLoginModel from "models/UserLoginModel";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  Spin,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import Seo from "components/Seo/Seo";
import CustomButton from "components/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { mobileLoginAction } from "redux/actions/LoginActions/mobileLoginAction";
import { verifyOtpAction } from "redux/actions/LoginActions/verifyOtpAction";
import { CheckOutlined } from "@ant-design/icons";
import { googleIcon, facebookIcon, buttonLeftArrow } from "assets/icons";
import { RESET_CUSTOMER, UPDATE_CUSTOMER_DATA } from "redux/types";
import LoginIllustration from "assets/images/LoginIllustration.svg";
import { useResponsive } from "hooks/useResponsive";
import { useLanguage } from "hooks/useLanguage";
import { useFunctions } from "hooks/useFunctions";
import { motion, AnimatePresence } from "framer-motion";
import NumberForm from "./NumberForm";
import { useIsDefined } from "hooks/useIsDefined";
import OTPForm from "./OTPForm";
import { use } from "i18next";
import { changeCurrentPageAction } from "redux/actions";
import NumberFormMobile from "./NumberFormMobile";
import DetailsForm from "./DetailsForm";

const LoginScreen = () => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const { isMobile } = useResponsive();
  const { isDefined } = useIsDefined();
  const { isArabic, textDirectionStyle } = useLanguage();
  const { submitForm } = useFunctions();
  const { t } = useTranslation();
  const otpMessage = isArabic
    ? "برجاء ادخال رمز التحقيق"
    : "Please provide your OTP";

  enum LoginStep {
    PHONE = "PHONE",
    NAME = "NAME",
    OTP = "OTP",
  }
  enum Trigger {
    CHECK_CUSTOMER = "CHECK_CUSTOMER",
    CHECK_OTP = "CHECK_OTP",
  }

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { loginLoading, customer, mobileLoginErrors } = useSelector(
    (state: RootState) => state.mobileLoginReducer
  );

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const [loginStep, setLoginStep] = useState(LoginStep.PHONE);
  const [trigger, setTrigger] = useState<Trigger>();
  const [mobileNumber, setMobileNumber] = useState<string>();

  const [customerName, setCustomerName] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [customerCity, setCustomerCity] = useState<string>();

  useEffect(() => {
    if (userData?.token) {
      navigate("/");
    } else {
      dispatch({ type: RESET_CUSTOMER });
      dispatch({ type: UPDATE_CUSTOMER_DATA });
    }
  }, []);

  useEffect(() => {
    dispatch(changeCurrentPageAction(-1));
  }, []);

  useEffect(() => {
    switch (trigger) {
      case Trigger.CHECK_CUSTOMER:
        if (isDefined(customer) && customer?.name !== "") {
          setLoginStep(LoginStep.OTP);
          setCustomerName(customer?.name);
          setCustomerEmail(customer?.email);
          setCustomerCity(customer?.city);
        } else {
          setLoginStep(LoginStep.NAME);
        }
        break;
    }
  }, [customer]);

  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.key === "Enter") {
  //       // handleOtpForm();
  //     }
  //   };

  //   // Attach the event listener when the component mounts
  //   document.addEventListener("keydown", handleKeyDown);

  //   // Detach the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    if (userData === "invalid Otp") {
      dispatch({ type: UPDATE_CUSTOMER_DATA });
      notification.error({
        message: "رمز التحقيق غير صحيح",
        description: "برجاء ادخاله مرة اخرى",
        placement: "bottomRight",
        style: {
          zIndex: 999,
          direction: textDirectionStyle,
        },
      });
    } else if (userData?.token) {
      navigate("/");
    }
  }, [userData]);

  const handleNumberForm = async (values: any) => {
    setTrigger(Trigger.CHECK_CUSTOMER);
    await dispatch(mobileLoginAction(values.phone));
    setMobileNumber(values.phone);
  };

  const handleOtpForm = async (values: any) => {
    console.log("OTP FORM VALUES");
    console.log(values);
    const otpString = values.otp.join("");
    if (otpString.length === 6) {
      // const otpNumber = parseInt(otpString, 10);
      setTrigger(Trigger.CHECK_OTP);
      await dispatch(
        verifyOtpAction(
          mobileNumber ?? "",
          otpString,
          customerName,
          customerEmail,
          customerCity
        )
      );
    }
  };

  const handleNameForm = async (values: any) => {
    console.log("NAME FORM VALUES");
    console.log(values);
    setCustomerName(values.name);
    setCustomerEmail(values.email);
    setCustomerCity(values.city);
    setLoginStep(LoginStep.OTP);
  };

  const renderForms = () => {
    return loginStep === LoginStep.PHONE ? (
      !isMobile ? (
        <NumberForm onFinish={handleNumberForm} isLoading={loginLoading} />
      ) : (
        <NumberFormMobile
          onFinish={handleNumberForm}
          isLoading={loginLoading}
        />
      )
    ) : loginStep === LoginStep.OTP ? (
      <OTPForm
        onFinish={handleOtpForm}
        resendOTP={() =>
          handleNumberForm({
            phone: mobileNumber,
          })
        }
        goBack={() => setLoginStep(LoginStep.PHONE)}
        mobileNumber={mobileNumber ?? ""}
        isLoading={verifyOtpLoading}
      />
    ) : (
      loginStep === LoginStep.NAME && <DetailsForm onFinish={handleNameForm} />
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorTextPlaceholder: "rgba(19, 19, 19, 0.40)",
          },
        },
      }}
    >
      <Seo
        title="سوق كار - الدخول"
        description="سوق كار - تسجيل الدخول"
        keywords="سوق.كار"
      />
      <div
        className={`${styles.mainContainer} w-full flex flex-col items-center`}
        style={{ backgroundColor: "var(--new-souq-background)" }}
      >
        {!isMobile ? (
          <div
            className={`${styles.loginContainer} flex flex-col items-center justify-between`}
          >
            <AnimatePresence>{renderForms()}</AnimatePresence>
          </div>
        ) : (
          <div className="w-full h-full px-3">
            <AnimatePresence>{renderForms()}</AnimatePresence>
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};

export default LoginScreen;
