import { Outlet } from "react-router-dom";
import MainViewContainer from "../components/MainViewContainer/MainViewContainer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { changeLanguageAction } from "redux/actions/LanguageActions/changeLanguageAction";

const ProtectedRoutes = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);

  const { lang } = useParams();

  useEffect(() => {
    if (lang !== 'en' && lang !== 'ar') {
      navigate('error/languageNotSupported');
      return;
    }
    if (lang !== currentLanguage) {
      dispatch(changeLanguageAction(lang as 'en' | 'ar'));
    }
  }, []);

  return (
    <MainViewContainer>
      <Outlet />
    </MainViewContainer>
  );
};

export default ProtectedRoutes;
