import
{
    MAKE_SPECIAL_LOADING,
    MAKE_SPECIAL_SUCCESS,
    MAKE_SPECIAL_FAILURE
}
from "redux/types";

const initialState = {
    makeSpecialLoading: false,
    specialAd: null,
    errors: null
}

export const makePostSpecialReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MAKE_SPECIAL_LOADING :
            return {...state, makeSpecialLoading: action.payload};
        case  MAKE_SPECIAL_SUCCESS :
            return {...state, specialAd: action.payload.data};
        case MAKE_SPECIAL_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}