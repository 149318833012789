import
{
    HOME_EXHIBITORS_LOADING,
    HOME_EXHIBITORS_SUCCESS,
    HOME_EXHIBITORS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listHomeScreenExhibitorsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: HOME_EXHIBITORS_LOADING, payload: true});
        const response = await listTableDataService("getHomeScreenExhibitors");
        dispatch({type: HOME_EXHIBITORS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: HOME_EXHIBITORS_SUCCESS, payload: err});
    } finally {
        dispatch({type: HOME_EXHIBITORS_LOADING, payload: false});
    }
}