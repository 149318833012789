import
{
    SERVICE_TYPES_LOADING,
    SERVICE_TYPES_SUCCESS,
    SERVICE_TYPES_FAILURE
}
from "redux/types";

const initialState = {
    serviceTypesLoading: false,
    serviceTypes: null,
    errors: null
}

export const listServiceTypesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SERVICE_TYPES_LOADING :
            return {...state, serviceTypesLoading: action.payload}
        case SERVICE_TYPES_SUCCESS :
            return {...state, serviceTypes: action.payload.serviceTypes}
        case SERVICE_TYPES_FAILURE :
            return {...state, errors: action.payload}
        default:
            return state;
    }
}

