import { useStore } from "./useStore";
import { useResponsive } from "hooks/useResponsive";
import MobileStoreScreen from "screens/V3/StoreScreen/MobileStoreScreen/MobileStoreScreen";
import DesktopStoreScreen from "screens/V3/StoreScreen/DesktopStoreScreen/DesktopStoreScreen";

const StoreScreen = () => {
  const useStoreData = useStore();
  const { isMobile } = useResponsive();

  if (isMobile) {
    return <MobileStoreScreen useStore={useStoreData} />;
  }
  return <DesktopStoreScreen useStore={useStoreData} />;
};

export default StoreScreen;
