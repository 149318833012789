import
{
    SORT_BY_FILTERS_LOADING,
    SORT_BY_FILTERS_SUCCESS,
    SORT_BY_FILTERS_FAILURE
}
from "redux/types";

const initialState = {
    sortByFiltersLoading: false,
    sortByFilters: null,
    errors: null
}

export const listSortByFiltersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SORT_BY_FILTERS_LOADING :
            return {...state, sortByFiltersLoading: action.payload};
        case SORT_BY_FILTERS_SUCCESS :
            return {...state, sortByFilters: action.payload.data};
        case SORT_BY_FILTERS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}