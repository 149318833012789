import navbarFilled from "@material-tailwind/react/theme/components/navbar/navbarFilled";
import {
  BASIC_SEARCH_LOADING,
  BASIC_SEARCH_SUCCESS,
  BASIC_SEARCH_FAILURE,
} from "redux/types";

const initialState = {
  loading: false,
  posts: null,
  meta: null,
  errors: null,
};

export const advancedSearchFilterReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case BASIC_SEARCH_LOADING:
      return { ...state, loading: action.payload };
    case BASIC_SEARCH_SUCCESS:
      return { ...state, posts: action.payload.data, meta:action.payload.meta };
    case BASIC_SEARCH_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
