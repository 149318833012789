import 
{
    LIST_CAR_BODIES_LOADING,
    LIST_CAR_BODIES_SUCCESS,
    LIST_CAR_BODIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listCarBodiesAction = 
    () => async (dispatch: Dispatch) => {
        try {
            dispatch({type: LIST_CAR_BODIES_LOADING, payload: true});
            const response = await listTableDataService('carTypesList');
            dispatch({type: LIST_CAR_BODIES_SUCCESS, payload: response.data});
        } catch (err) {
            dispatch({type: LIST_CAR_BODIES_FAILURE, payload: err});
        } finally {
            dispatch({type: LIST_CAR_BODIES_LOADING, payload: false});
        }
    }