import { FC } from "react";
import { Helmet } from "react-helmet-async";

interface SeoProps {
    title: string;
    description: string;
    keywords: string;
    thumbnailDescription?: string;
    thumbnailImage?: any;
}

const Seo: FC<SeoProps> = ({ title, description, keywords, thumbnailDescription, thumbnailImage }) => {
    return (
        <Helmet>
            {/* Main Head Tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            {/* Main Head Tags */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content="سوق كار" />
            <meta property="og:description" content={thumbnailDescription} />
            <meta property="og:URL" content={thumbnailImage} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={thumbnailImage} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="souq.car" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={thumbnailImage} />
        </Helmet>
    )
}

export default Seo;