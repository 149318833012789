import { constants } from "zlib";

//Auth Types
export const AUTH_LOADING = "AUTH_LOADING";
export const LOG_IN = "LOG_IN";
export const SET_USER = "SET_USER";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const TRY_AGAIN = "TRY_AGAIN";
export const LOG_OUT = "LOG_OUT";
export const RESET_AUTH = "RESET_AUTH";

// Current Page Types
export const CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE";

// List Car Body Types
export const LIST_CAR_BODIES_LOADING = "LIST_CAR_BODIES_LOADING";
export const LIST_CAR_BODIES_SUCCESS = "LIST_CAR_BODIES_SUCCESS";
export const LIST_CAR_BODIES_FAILURE = "LIST_CAR_BODIES_FAILURE";

// List Engine Capacity Types
export const LIST_ENGINE_CAPACITY_LOADING = "LIST_ENGINE_CAPACITY_LOADING";
export const LIST_ENGINE_CAPACITY_SUCCESS = "LIST_ENGINE_CAPACITY_SUCCESS";
export const LIST_ENGINE_CAPACITY_FAILURE = "LIST_ENGINE_CAPACITY_FAILURE";

// List Car Condition Types
export const LIST_CAR_CONDITION_LOADING = "LIST_CAR_CONDITION_LOADING";
export const LIST_CAR_CONDITION_SUCCESS = "LIST_CAR_CONDITION_SUCCESS";
export const LIST_CAR_CONDITION_FAILURE = "LIST_CAR_CONDITION_FAILURE";

// List PaymentType Types
export const LIST_PAYMENT_TYPES_LOADING = "LIST_PAYMENT_TYPES_LOADING";
export const LIST_PAYMENT_TYPES_SUCCESS = "LIST_PAYMENT_TYPES_SUCCESS";
export const LIST_PAYMENT_TYPES_FAILURE = "LIST_PAYMENT_TYPES_FAILURE";

// List Years Types
export const LIST_YEARS_LOADING = "LIST_YEARS_LOADING";
export const LIST_YEARS_SUCCESS = "LIST_YEARS_SUCCESS";
export const LIST_YEARS_FAILURE = "LIST_YEARS_FAILURE";

// List Categories Types
export const LIST_CATEGORIES_LOADING = "LIST_CATEGORIES_LOADING";
export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";
export const LIST_CATEGORIES_FAILURE = "LIST_CATEGORIES_FAILURE";

// List Branches Types
export const LIST_BRANCHES_LOADING = "LIST_BRANCHES_LOADING";
export const LIST_BRANCHES_SUCCESS = "LIST_BRANCHES_SUCCESS";
export const LIST_BRANCHES_FAILURE = "LIST_BRANCHES_FAILURE";

// List Car Maker Types
export const LIST_CAR_MAKER_LOADING = "LIST_CAR_MAKER_LOADING";
export const LIST_CAR_MAKER_SUCCESS = "LIST_CAR_MAKER_SUCCESS";
export const LIST_CAR_MAKER_FAILURE = "LIST_CAR_MAKER_FAILURE";

// List Features Posts Types
export const LIST_FEATURED_POSTS_LOADING = "LIST_FEATURED_POSTS_LOADING";
export const LIST_FEATURED_POSTS_SUCCESS = "LIST_FEATURED_POSTS_SUCCESS";
export const LIST_FEATURED_POSTS_FAILURE = "LIST_FEATURED_POSTS_FAILURE";

// List Single Post Types
export const LIST_SINGLE_POST_LOADING = "LIST_SINGLE_POST_LOADING";
export const LIST_SINGLE_POST_SUCCESS = "LIST_SINGLE_POST_SUCCESS";
export const LIST_SINGLE_POST_FAILURE = "LIST_SINGLE_POST_FAILURE";

// Language State
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

// List FAQs Types
export const LIST_FAQS_LOADING = "LIST_FAQS_LOADING";
export const LIST_FAQS_SUCCESS = "LIST_FAQS_SUCCESS";
export const LIST_FAQS_FAILURE = "LIST_FAQS_FAILURE";

// Send Message Types
export const SEND_MESSAGE_LOADING = "SEND_MESSAGE_LOADING";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

// Basic Search Filter Types
export const BASIC_SEARCH_LOADING = "BASIC_SEARCH_LOADING";
export const BASIC_SEARCH_SUCCESS = "BASIC_SEARCH_SUCCESS";
export const BASIC_SEARCH_FAILURE = "BASIC_SEARCH_FAILURE";

// TransmissionType Types
export const TRANSMISSION_TYPES_LOADING = "TRANSMISSION_TYPES_LOADING";
export const TRANSMISSION_TYPES_SUCCESS = "TRANSMISSION_TYPES_SUCCESS";
export const TRANSMISSION_TYPES_FAILURE = "TRANSMISSION_TYPES_FAILURE";

// Fuel Types
export const FUEL_TYPES_LOADING = "FUEL_TYPES_LOADING";
export const FUEL_TYPES_SUCCESS = "FUEL_TYPES_SUCCESS";
export const FUEL_TYPES_FAILURE = "FUEL_TYPES_FAILURE";

// Articles
export const Articles_TYPES_LOADING = "Articles_TYPES_LOADING";
export const Articles_TYPES_SUCCESS = "Articles_TYPES_SUCCESS";
export const Articles_TYPES_FAILURE = "Articles_TYPES_FAILURE";

// Car Models Types
export const CAR_MODELS_LOADING = "CAR_MODELS_LOADING";
export const CAR_MODELS_SUCCESS = "CAR_MODELS_SUCCESS";
export const CAR_MODELS_FAILURE = "CAR_MODELS_FAILURE";

// Features Types
export const FEATURES_LOADING = "FEATURES_LOADING";
export const FEATURES_SUCCESS = "FEATURES_SUCCESS";
export const FEATURES_FAILURE = "FEATURES_FAILURE";

// Colors Types
export const COLORS_LOADING = "COLORS_LOADING";
export const COLORS_SUCCESS = "COLROS_SUCCESS";
export const COLORS_FAILURE = "COLORS_FAILURE";

// Cities Types
export const CITIES_LOADING = "CITIES_LOADING";
export const CITIES_SUCCESS = "CITIES_SUCCESS";
export const CITIES_FAILURE = "CITIES_FAILURE";

// Login Types
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESET_OTP = "RESET_OTP";

// Verify Otp Types
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const RESET_USER_DATA = "RESET_USER_DATA";

// Create Post Types
export const CREATE_POST_LOADING = "CREATE_POST_LOADING";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";
export const RESET_CREATE_POST = "RESET_CREATE_POST";

// Post Status Types
export const POST_STATUS_LOADING = "POST_STATUS_LOADING";
export const POST_STATUS_SUCCESS = "POST_STATUS_SUCCESS";
export const POST_STATUS_FAILURE = "POST_STATUS_FAILURE";

// View Profile Posts
export const VIEW_PROFILE_POSTS_LOADING = "VIEW_PROFILE_POSTS_LOADING";
export const VIEW_PROFILE_POSTS_SUCCESS = "VIEW_PROFILE_POSTS_SUCCESS";
export const VIEW_PORFILE_POSTS_FAILURE = "VIEW_PROFILE_POSTS_FAILURE";

// About Us Types
export const ABOUT_US_LOADING = "ABOUT_US_LOADING";
export const ABOUT_US_SUCCESS = "ABOUT_US_SUCCESS";
export const ABOUT_US_FAILURE = "ABOUT_US_FAILURE";

// Privacy Policy Types
export const PRIVACY_POLICY_LOADING = "PRIVACY_POLICY_LOADING";
export const PRIVACY_POLICY_SUCCESS = "PRIVACY_POLICY_SUCCESS";
export const PRIVACY_POLICY_FAILURE = "PRIVACY_POLICY_FAILURE";

// Terms Of Use Types
export const TERMS_OF_USE_LOADING = "TERMS_OF_USE_LOADING";
export const TERMS_OF_USE_SUCCESS = "TERMS_OF_USE_SUCCESS";
export const TERMS_OF_USE_FAILURE = "TERMS_OF_USE_FAILURE";

// Display Mobile Types
export const DISPLAY_MOBILE_LOADING = "DISPLAY_MOBILE_LOADING";
export const DISPLAY_MOBILE_SUCCESS = "DISPLAY_MOBILE_SUCCESS";
export const DISPLAY_MOBILE_FAILURE = "DISPLAY_MOBILE_FAILURE";

// Vendor Data Types
export const VENDOR_DATA_LOADING = "TERMS_OF_USE_LOADING";
export const VENDOR_DATA_SUCCESS = "TERMS_OF_USE_SUCCESS";
export const VENDOR_DATA_FAILURE = "TERMS_OF_USE_FAILURE";

// Update Customer Data Types
export const UPDATE_CUSTOMER_DATA_LOADING = "UPDATE_CUSTOMER_DATA_LOADING";
export const UPDATE_CUSTOMER_DATA_SUCCESS = "UPDATE_CUSTOMER_DATA_SUCCESS";
export const UPDATE_CUSTOMER_DATA_FAILURE = "UPDATE_CUSTOMER_DATA_FAILURE";
export const UPDATE_CUSTOMER_DATA = "UPDATE_CUSTOMER_DATA";

// Vendor Posts Types
export const VENDOR_POSTS_LOADING = "VENDOR_POSTS_LOADING";
export const VENDOR_POSTS_SUCCESS = "VENDOR_POSTS_SUCCESS";
export const VENDOR_POSTS_FAILURE = "VENDOR_POSTS_FAILURE";

// Get All Chats Types
export const ALL_CHATS_LOADING = "ALL_CHATS_LOADING";
export const ALL_CHATS_SUCCESS = "ALL_CHATS_SUCCESS";
export const ALL_CHATS_FAILURE = "ALL_CHATS_FAILURE";

// Get Messages Types
export const MESSAGES_LOADING = "MESSAGES_LOADING";
export const MESSAGES_SUCCESS = "MESSAGES_SUCCESS";
export const MESSAGES_FAILURE = "MESSAGES_FAILURE";

// Send Chat Message Types
export const SEND_CHAT_MESSAGE_LOADING = "SEND_CHAT_MESSAGE_LOADING";
export const SEND_CHAT_MESSAGE_SUCCESS = "SEND_CHAT_MESSAGE_SUCCESS";
export const SEND_CHAT_MESSAGE_FAILURE = "SEND_CHAT_MESSAGE_FAILURE";

// Suspend Post Types
export const SUSPEND_POST_LOADING = "SUSPEND_POST_LOADING";
export const SUSPEND_POST_SUCCESS = "SUSPEND_POST_SUCCESS";
export const SUSPEND_POST_FAILURE = "SUSPEND_POST_FAILURE";

// Reactivate Post Types
export const REACTIVATE_POST_LOADING = "REACTIVATE_POST_LOADING";
export const REACTIVATE_POST_SUCCESS = "REACTIVATE_POST_SUCCESS";
export const REACTIVATE_POST_FAILURE = "REACTIVATE_POST_FAILURE";

// View Article Types
export const VIEW_ARTICLE_LOADING = "VIEW_ARTICLE_LOADING";
export const VIEW_ARTICLE_SUCCESS = "VIEW_ARTICLE_SUCCESS";
export const VIEW_ARTICLE_FAILURE = "VIEW_ARTICLE_FAILURE";

// Create Post Progress
export const SET_PROGRESS_STATE = "SET_PROGRESS_STATE";

// Create Post Category
export const POST_CATEGORY_STATE = "POST_CATEGORY_STATE";

// View Post Main Image
export const POST_MAIN_IMAGE_STATE = "POST_MAIN_IMAGE_STATE";

// List RentalTypes Types
export const RENTAL_TYPES_LOADING = "RENTAL_TYPES_LOADING";
export const RENTAL_TYPES_SUCCESS = "RENTAL_TYPES_SUCCESS";
export const RENTAL_TYPES_FAILURE = "RENTAL_TYPES_FAILURE";

// List RentalDriver
export const RENTAL_DRIVER_LOADING = "RENTAL_DRIVER_LOADING";
export const RENTAL_DRIVER_SUCCESS = "RENTAL_DRIVER_SUCCESS";
export const RENTAL_DRIVER_FAILURE = "RENTAL_DRIVER_FAILURE";

// List ServiceTypes
export const SERVICE_TYPES_LOADING = "SERVICE_TYPES_LOADING";
export const SERVICE_TYPES_SUCCESS = "SERVICE_TYPES_SUCCESS";
export const SERVICE_TYPES_FAILURE = "SERVICE_TYPES_FAILURE";

// List SparePartType
export const SPARE_PART_TYPE_LOADING = "SPARE_PART_TYPE_LOADING";
export const SPARE_PART_TYPE_SUCCESS = "SPARE_PART_TYPE_SUCCESS";
export const SPARE_PART_TYPE_FAILURE = "SPARE_PART_TYPE_FAILURE";

// List SparePartCondition
export const SPARE_PART_CONDITION_LOADING = "SPARE_PART_CONDITION_LOADING";
export const SPARE_PART_CONDITION_SUCCESS = "SPARE_PART_CONDITION_SUCCESS";
export const SPARE_PART_CONDITION_FAILURE = "SPARE_PART_CONDITION_FAILURE";

// MAP COORDINATES
export const SET_MAP_STATE = "SET_MAP_STATE";

export const SET_MAP_POST_STATE = "SET_MAP_POST_STATE";

export const POST_PAYMENT_TYPE = "POST_PAYMENT_TYPE";

export const DRAWER_STATUS_TYPE = "DRAWER_STATUS_TYPE";

export const PROFILE_POST_STATUS = "PROFILE_POST_STATUS";
export const PROFILE_POST_CATEGORY = "PROFILE_POST_CATEGORY";

// Update Post Types
export const UPDATE_POST_LOADING = "UPDATE_POST_LOADING";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";
export const SET_UPDATE_POST_DATA = "SET_UPDATE_POST_DATA";

// Get User Data Types
export const GET_USER_DATA_LOADING = "GET_USER_DATA_LOADING";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

// All Featured Ads Types
export const ALL_FEATURED_ADS_LOADING = "ALL_FEATURED_ADS_LOADING";
export const ALL_FEATURED_ADS_SUCCESS = "ALL_FEATURED_ADS_SUCCESS";
export const ALL_FEATURED_ADS_FAILURE = "ALL_FEATURED_ADS_FAILURE";

export const RESET_CUSTOMER = "RESET_CUSTOMER";

// Sort By Filters
export const SORT_BY_FILTERS_LOADING = "SORT_BY_FILTERS_LOADING";
export const SORT_BY_FILTERS_SUCCESS = "SORT_BY_FILTERS_SUCCESS";
export const SORT_BY_FILTERS_FAILURE = "SORT_BY_FILTERS_FAILURE";

// Favourite Post Types
export const FAVOURITE_POST_LOADING = "FAVOURITE_POST_LOADING";
export const FAVOURITE_POST_SUCCESS = "FAVOURITE_POST_SUCCESS";
export const FAVOURITE_POST_FAILURE = "FAVOURITE_POST_FAILURE";

// List Favourite Posts Types
export const LIST_FAVOURITES_LOADING = "LIST_FAVOURITES_LOADING";
export const LIST_FAVOURITES_SUCCESS = "LIST_FAVOURITES_SUCCESS";
export const LIST_FAVOURITES_FAILURE = "LIST_FAVOURITES_FAILURE";

// List Notifications Types
export const LIST_NOTIFICATIONS_LOADING = "LIST_NOTIFICATIONS_LOADING";
export const LIST_NOTIFICATIONS_SUCCESS = "LIST_NOTIFICATIONS_SUCCESS";
export const LIST_NOTIFICATIONS_FAILURE = "LIST_NOTIFICATIONS_FAILURE";

// Amanleek Auth Types
export const AMANLEEK_AUTH_LOADING = "AMANLEEK_AUTH_LOADING";
export const AMANLEEK_AUTH_SUCCESS = "AMANLEEK_AUTH_SUCCESS";
export const AMANLEEK_AUTH_FAILURE = "AMANLEEK_AUTH_FAILURE";

// Amanleek List Brands Types
export const AMANLEEK_BRANDS_LOADING = "AMANLEEK_BRANDS_LOADING";
export const AMANLEEK_BRANDS_SUCCESS = "AMANLEEK_BRANDS_SUCCESS";
export const AMANLEEK_BRANDS_FAILURE = "AMANLEEK_BRANDS_FAILURE";

// Amanleek List Car Models Types
export const AMANLEEK_CAR_MODELS_LOADING = "AMANLEEK_CAR_MODELS_LOADING";
export const AMANLEEK_CAR_MODELS_SUCCESS = "AMANLEEK_CAR_MODELS_SUCCESS";
export const AMANLEEK_CAR_MODELS_FAILURE = "AMANLEEK_CAR_MODELS_FAILURE";

// Create Insurance Entry Types
export const INSURANCE_ENTRY_LOADING = "INSURANCE_ENTRY_LOADING";
export const INSURANCE_ENTRY_SUCCESS = "INSURANCE_ENTRY_SUCCESS";
export const INSURANCE_ENTRY_FAILURE = "INSURANCE_ENTRY_FAILURE";

// Amanleek Lead Insert Types
export const AMANLEEK_LEAD_INSERT_LOADING = "AMANLEEK_LEAD_INSERT_LOADING";
export const AMANLEEK_LEAD_INSERT_SUCCESS = "AMANLEEK_LEAD_INSERT_SUCCESS";
export const AMANLEEK_LEAD_INSERT_FAILURE = "AMANLEEK_LEAD_INSERT_FAILURE";
// See Notification Types
export const SEE_NOTIFICATION_LOADING = "SEE_NOTIFICATION_LOADING";
export const SEE_NOTIFICATION_SUCCESS = "SEE_NOTIFICATION_SUCCESS";
export const SEE_NOTIFICATION_FAILURE = "SEE_NOTIFICATION_FAILURE";

// Week Days Types
export const WEEK_DAYS_LOADING = "WEEK_DAYS_LOADING";
export const WEEK_DAYS_SUCCESS = "WEEK_DAYS_SUCCESS";
export const WEEK_DAYS_FAILURE = "WEEK_DAYS_FAILURE";

// Exhibitor Services Types
export const EXHIBITOR_SERVICES_LOADING = "EXHIBITOR_SERVICES_LOADING";
export const EXHIBITOR_SERVICES_SUCCESS = "EXHIBITOR_SERVICES_SUCCESS";
export const EXHIBITOR_SERVICES_FAILURE = "EXHIBITOR_SERVICES_FAILURE";

// Branch Types
export const BRANCH_TYPES_LOADING = "BRANCH_TYPES_LOADING";
export const BRANCH_TYPES_SUCCESS = "BRANCH_TYPES_SUCCESS";
export const BRANCH_TYPES_FAILURE = "BRANCH_TYPES_FAILURE";

// Exhibitor Data Types
export const EXHIBITOR_DATA_LOADING = "EXHIBITOR_DATA_LOADING";
export const EXHIBITOR_DATA_SUCCESS = "EXHIBITOR_DATA_SUCCESS";
export const EXHIBITOR_DATA_FAILURE = "EXHIBITOR_DATA_FAILURE";

// Update Exhibitor Types
export const UPDATE_EXHIBITOR_LOADING = "UPDATE_EXHIBITOR_LOADING";
export const UPDATE_EXHIBITOR_SUCCESS = "UPDATE_EXHIBITOR_SUCCESS";
export const UPDATE_EXHIBITOR_FAILURE = "UPDATE_EXHIBITOR_FAILURE";

// Home Screen Exhibitors
export const HOME_EXHIBITORS_LOADING = "HOME_EXHIBITORS_LOADING";
export const HOME_EXHIBITORS_SUCCESS = "HOME_EXHIBITORS_SUCCESS";
export const HOME_EXHIBITORS_FAILURE = "HOME_EXHIBITORS_FAILURE";

// Exhibitions Types
export const EXHIBITIONS_LOADING = "EXHIBITIONS_LOADING";
export const EXHIBITIONS_SUCCESS = "EXHIBITIONS_SUCCESS";
export const EXHIBITIONS_FAILURE = "EXHIBITIONS_FAILURE";

// List Stories Types
export const LIST_STORIES_LOADING = "LIST_STORIES_LOADING";
export const LIST_STORIES_SUCCESS = "LIST_STORIES_SUCCESS";
export const LIST_STORIES_FAILURE = "LIST_STORIES_FAILURE";

// Customer Stories Types
export const CUSTOMER_STORIES_LOADING = "CUSTOMER_STORIES_LOADING";
export const CUSTOMER_STORIES_SUCCESS = "CUSTOMER_STORIES_SUCCESS";
export const CUSTOMER_STORIES_FAILURE = "CUSTOMER_STORIES_FAILURE";

// Create Story Types
export const CREATE_STORY_LOADING = "CREATE_STORY_LOADING";
export const CREATE_STORY_SUCCESS = "CREATE_STORY_SUCCESS";
export const CREATE_STORY_FAILURE = "CREATE_STORY_FAILURE";

// Get Exhibitor Statistics Types
export const EXHIBITOR_STATISTICS_LOADING = "EXHIBITOR_STATISTICS_LOADING";
export const EXHIBITOR_STATISTICS_SUCCESS = "EXHIBITOR_STATISTICS_SUCCESS";
export const EXHIBITOR_STATISTICS_FAILURE = "EXHIBITOR_STATISTICS_FAILURE";

// Update Exhibitor Social Links Types
export const UPDATE_SOCIAL_LINKS_LOADING = "UPDATE_SOCIAL_LINKS_LOADING";
export const UPDATE_SOCIAL_LINKS_SUCCESS = "UPDATE_SOCIAL_LINKS_SUCCESS";
export const UPDATE_SOCIAL_LINKS_FAILURE = "UPDATE_SOCIAL_LINKS_FAILURE";

// Update General Data Types
export const UPDATE_GENERAL_DATA_LOADING = "UPDATE_GENERAL_DATA_LOADING";
export const UPDATE_GENERAL_DATA_SUCCESS = "UPDATE_GENERAL_DATA_SUCCESS";
export const UPDATE_GENERAL_DATA_FAILURE = "UPDATE_GENERAL_DATA_FAILURE";

// Get My Stories Types
export const MY_STORIES_LOADING = "MY_STORIES_LOADING";
export const MY_STORIES_SUCCESS = "MY_STORIES_SUCCESS";
export const MY_STORIES_FAILURE = "MY_STORIES_FAILURE";

// Create Branch Types
export const CREATE_BRANCH_LOADING = "CREATE_BRANCH_LOADING";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILURE = "CREATE_BRANCH_FAILURE";

// Update Branch Types
export const UPDATE_BRANCH_LOADING = "UPDATE_BRANCH_LOADING";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "UPDATE_BRANCH_FAILURE";

// Favourite Exhibitors Types
export const FAVOURITE_EXHIBITORS_LOADING = "FAVOURITE_EXHIBITORS_LOADING";
export const FAVOURITE_EXHIBITORS_SUCCESS = "FAVOURITE_EXHIBITORS_SUCCESS";
export const FAVOURITE_EXHIBITORS_FAILURE = "FAVOURITE_EXHIBITORS_FAILURE";

// Make Special Types
export const MAKE_SPECIAL_LOADING = "MAKE_SPECIAL_LOADING";
export const MAKE_SPECIAL_SUCCESS = "MAKE_SPECIAL_SUCCESS";
export const MAKE_SPECIAL_FAILURE = "MAKE_SPECIAL_FAILURE";

// Delete Story Types
export const DELETE_STORY_LOADING = "DELETE_STORY_LOADING";
export const DELETE_STORY_SUCCESS = "DELETE_STORY_SUCCESS";
export const DELETE_STORY_FAILURE = "DELETE_STORY_FAILURE";

// Repost Story Types
export const REPOST_STORY_LOADING = "REPOST_STORY_LOADING";
export const REPOST_STORY_SUCCESS = "REPOST_STORY_SUCCESS";
export const REPOST_STORY_FAILURE = "REPOST_STORY_FAILURE";

export const STORE_CART = "STORE_CART";

export const GET_STORE_ITEMS_LOADING = "GET_STORE_ITEMS_LOADING";
export const GET_STORE_ITEMS_SUCCESS = "GET_STORE_ITEMS_SUCCESS";
export const GET_STORE_ITEMS_FAILURE = "GET_STORE_ITEMS_FAILURE";

// Activate Outstanding Post Reducer
export const ACTIVATE_OUTSTANDING_POST_LOADING =
  "ACTIVATE_OUTSTANDING_POST_LOADING";
export const ACTIVATE_OUTSTANDING_POST_SUCCESS =
  "ACTIVATE_OUTSTANDING_POST_SUCCESS";
export const ACTIVATE_OUTSTANDING_POST_FAILURE =
  "ACTIVATE_OUTSTANDING_POST_FAILURE";

export const CURRENT_CHAT = "CURRENT_CHAT";
