import
{
    PRIVACY_POLICY_LOADING,
    PRIVACY_POLICY_SUCCESS,
    PRIVACY_POLICY_FAILURE
}
from "redux/types";

const initialState = {
    privacyPolicyLoading: false,
    privacyPolicy: null,
    errors: null
}

export const privacyPolicyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PRIVACY_POLICY_LOADING :
            return {...state, privacyPolicyLoading: action.payload};
        case PRIVACY_POLICY_SUCCESS :
            return {...state, privacyPolicy: action.payload.data};
        case PRIVACY_POLICY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}