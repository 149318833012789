import styles from "screens/ChatScreen/ChatScreen.module.css";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";
import { listAllChatsAction } from "redux/actions/ChatsActions/listAllChatsAction";
import { Spin } from "antd";
import ChatContactCard from "components/ChatContactCard/ChatContactCard";
import { listMessagesAction } from "redux/actions/ChatsActions/listMessagesAction";
import { Input } from "antd";
import { sendChatMessageAction } from "redux/actions/ChatsActions/sendChatMessageAction";
import { SendOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { doubleTick, doubleTickSeen } from "assets/icons";
import { SendIcon } from "assets/IconComponents";
import { useTimeFormat } from "hooks/useTimeFormat";
import { useSearch } from "hooks/useSearch";
import { get } from "lodash";

const ChatScreen = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { formatDate } = useTimeFormat();

  const { getSearchParam } = useSearch();
  const vendorId = getSearchParam("vendorId");

  const { userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { allChatsLoading, allChats } = useSelector(
    (state: RootState) => state.listAllChatsReducer
  );
  const { messagesLoading, messages } = useSelector(
    (state: RootState) => state.listMessagesReducer
  );

  const [selectedContact, setSelectedContact] = useState<any>();
  const [uniqueDates, setUniqueDates] = useState([]);

  useEffect(() => {
    setUniqueDates(
      Array.from(
        new Set(
          messages
            ?.filter((item: any) => item?.message !== "*$$*")
            .map((item: any) => {
              return formatDate(item?.created_at);
            })
        )
      )
    );
  }, [messages, selectedContact]);

  useEffect(() => {
    console.log("uniqueDates", uniqueDates);
  }, [uniqueDates]);

  const { chatMessageLoading, chatMessageSuccess } = useSelector(
    (state: RootState) => state.sendChatMessageReducer
  );

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const isArabic = currentLanguage === "ar";

  const [isMobile, setIsMobile] = useState(false);

  const [message, setMessage] = useState("");

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleSendMessage = async () => {
    const chat = document.getElementById("chat");
    setScrollPosition(chat?.scrollTop || 0);

    await dispatch(sendChatMessageAction(selectedContact?.id, message));
    setMessage("");
  };

  const updateSelectedContact = () => {
    const item = allChats?.find((item: any) => item.id === Number(vendorId));
    if (item) {
      setSelectedContact(item);
    } else {
      setSelectedContact(null);
    }
  };

  var headerH = 0;
  // Get a reference to the header element by its ID
  const header = document.getElementById("header");

  // Get the height of the header
  headerH = header?.offsetHeight || 0;

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
  }, []);

  async function getChats() {
    await dispatch(listAllChatsAction());
    // updateSelectedContact();
  }

  useEffect(() => {
    getChats();
    // get curretn date and time
    var currentdate = new Date();
    // convert to 12 hour time format
    var datetime = currentdate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }, []);

  useEffect(() => {
    dispatch(listMessagesAction(vendorId));
  }, [vendorId]);

  useEffect(() => {
    if (chatMessageSuccess) {
      dispatch(listMessagesAction(vendorId));
    }
  }, [chatMessageSuccess]);

  useEffect(() => {
    if (!messagesLoading) {
      const chat = document.getElementById("chat");
      if (chat) {
        chat.scrollTop = scrollPosition;
      }
      // wait 300ms for messages to load
      setTimeout(() => {
        const chat = document.getElementById("chat");
        if (chat) {
          chat.scrollTop = scrollPosition;
          chat.scrollTo({
            top: chat.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 300);
    }
  }, [messagesLoading]);

  useEffect(() => {
    if (!userData?.token) {
      navigate("/");
    }
  }, [userData]);

  const hasMounted = useRef(false);
  useEffect(() => {
    updateSelectedContact();
  }, [allChats]);

  var userMessage = true;
  const { t } = useTranslation();

  const getTimeFromDate = (dateStr: string) => {
    // covert string to date
    var date = new Date(dateStr);
    // convert to 12 hour time format
    var datetime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return datetime;
  };

  useEffect(() => {
    // Get all message elements
    const messages = document.querySelectorAll(".message");

    // Check each message element and update the layout accordingly
    messages.forEach((message) => {
      const content = message.querySelector(".msg");

      // Check if the message element exists
      if (!content) return;

      // Check if the content overflows
      if (content.scrollWidth > content.clientWidth) {
        // edit the style
        content.setAttribute(
          "style",
          "white-space: normal; text-overflow: clip;"
        );

        message.setAttribute("style", "flex-direction: column;"); // Change to column layout
      }
    });
  }, [messages]);

  return (
    <div className="flex flex-col h-full items-center">
      <div
        dir={isArabic ? "rtl" : "ltr"}
        className="desk:w-[75%] w-full desk:py-10 flex flex-col desk:flex-row desk:justify-center desk:items-stretch items-center"
        style={{
          height: `calc(100vh - ${headerH}px)`,
          columnGap: "1rem",
          backgroundColor: "var(--new-souq-background)",
          flexGrow: 1,
        }}
      >
        {/* CONTACTS SECTION */}
        {allChatsLoading ? (
          <Spin />
        ) : (
          (!isMobile || (isMobile && selectedContact === null)) && (
            <div
              className={`w-full h-full desk:w-[25%] bg-white rounded-xl ${styles.shadow} px-3 flex flex-col items-center`}
            >
              <p
                className="text-lg my-4"
                style={{
                  color: "var(--new-blue)",
                }}
              >
                {t("all_messages")}
              </p>
              {allChats?.length === 0 ? (
                <p>No Messages</p>
              ) : (
                allChats?.map((item: any, idx: any) => {
                  return (
                    <ChatContactCard
                      contactName={item.name}
                      isSelected={item.id === Number(vendorId)}
                      onClick={() => {
                        setSelectedContact(item);
                        navigate(
                          `/${currentLanguage}/messages?vendorId=${item.id}`
                        );
                      }}
                      personalImage={item.personal_image}
                      id={item.id}
                    />
                  );
                })
              )}
            </div>
          )
        )}
        {/* CHAT SECTION */}
        {(!isMobile || (isMobile && selectedContact !== null)) && (
          <div
            className={`w-full h-full desk:w-[75%] flex flex-col items-end gap-y-3`}
          >
            {/* <p className="font-bold text-lg">Messages</p> */}
            <div
              className={`w-full h-full bg-white rounded-xl ${styles.shadow} flex flex-col items-center p-5`}
            >
              {/* {
                          selectedContact !== null && (
                              <div className="w-[85%] h-[50px] bg-[#EFEFFF] flex items-center justify-center rounded-xl">
                                  <p className="text-lg font-bold">{selectedContact?.name}</p>
                              </div>
                          )
                      } */}
              {
                <div
                  className="w-full h-full flex flex-col justify-start gap-y-4"
                  // style={{ position: "relative" }}
                >
                  {/* Back button which sets selectedContact to null */}
                  {isMobile && selectedContact !== null && (
                    <div
                      className={`w-[90%] flex flex-row items-center justify-start gap-x-3`}
                    >
                      <div
                        className={`h-10 w-10 rounded-full bg-[#3127FF] flex items-center justify-center hover:cursor-pointer`}
                        onClick={() => {
                          setSelectedContact(null);
                          navigate(`/${currentLanguage}/messages`);
                        }}
                      >
                        <i
                          className={`fa-solid fa-arrow-${
                            isArabic ? "right" : "left"
                          } text-white text-xl`}
                        ></i>
                      </div>
                      <p className="font-bold text-lg">
                        {selectedContact?.name}
                      </p>
                    </div>
                  )}

                  {selectedContact && (
                    <div
                      id="chat"
                      className="w-full desk:px-4 py-3"
                      style={{ overflowY: "auto", flexGrow: 1 }}
                    >
                      {uniqueDates?.map((itemDate: any) => (
                        <div key={itemDate}>
                          <div className="w-full flex items-center justify-center">
                            <p className={`${styles.notificationDate}`}>
                              {itemDate}
                            </p>
                          </div>
                          {messages
                            .filter(
                              (item: any) =>
                                formatDate(item?.created_at) === itemDate &&
                                item?.message !== "*$$*"
                            )
                            .map((filteredItem: any) => (
                              <div
                                className={`message ${
                                  filteredItem.from_id === selectedContact?.id
                                    ? `${isArabic ? styles.himAR : styles.him}`
                                    : `${isArabic ? styles.meAR : styles.me}`
                                }`}
                              >
                                {/* message */}
                                <p className={`${styles.msg} msg`}>
                                  {filteredItem.message}
                                </p>
                                {/* time */}
                                <div
                                  className="flex items-center justify-center gap-x-1 ms-2"
                                  style={{ alignSelf: "flex-end" }}
                                >
                                  <p dir="ltr" className={`${styles.time}`}>
                                    {getTimeFromDate(filteredItem.created_at)}
                                  </p>
                                  {filteredItem.from_id ===
                                    userData?.user?.id && (
                                    <img
                                      src={
                                        filteredItem.seen === 0
                                          ? doubleTick
                                          : doubleTickSeen
                                      }
                                      alt=""
                                      className={`${styles.doubleTick}`}
                                      style={{
                                        opacity:
                                          filteredItem.seen === 0 ? 0.55 : 1,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedContact !== null ? (
                    <div
                      className={`w-full flex flex-row gap-x-3 justify-between`}
                    >
                      <Input
                        value={message}
                        placeholder={t("type_here")}
                        onChange={(e: any) => setMessage(e.target.value)}
                        className={`w-full chatMessageInput ${styles.messageInput}`}
                        // size="large"
                      />
                      {/* {chatMessageLoading ? (
                        <Spin />
                      ) : ( */}
                      <div
                        className="h-[3rem] w-[3rem] aspect-square rounded-full flex items-center justify-center hover:cursor-pointer"
                        style={{
                          backgroundColor: "var(--new-blue)",
                        }}
                        onClick={message !== "" ? handleSendMessage : () => {}}
                      >
                        {/* { Spin if sending message */}
                        {chatMessageLoading ? (
                          <Spin style={{ color: "white" }} />
                        ) : (
                          <SendIcon color="white" fontSize={20} />
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <p className="text-lg font-bold">{t("select_chat")}</p>
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatScreen;
