import
{
    LIST_CAR_CONDITION_LOADING,
    LIST_CAR_CONDITION_SUCCESS,
    LIST_CAR_CONDITION_FAILURE
}
from "redux/types";

const initialState = {
    carConditionLoading: false,
    carConditions: null,
    errors: null
};

export const listCarConditionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CAR_CONDITION_LOADING :
            return {...state, carConditionLoading: action.payload};
        case LIST_CAR_CONDITION_SUCCESS :
            return {...state, carConditions: action.payload.data};
        case LIST_CAR_CONDITION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}