import
{
    CREATE_STORY_LOADING,
    CREATE_STORY_SUCCESS,
    CREATE_STORY_FAILURE
}
from "redux/types";

const initialState = {
    createStoryLoading: false,
    story: null,
    errors: null
}

export const createStoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_STORY_LOADING :
            return {...state, createStoryLoading: action.payload};
        case CREATE_STORY_SUCCESS :
            return {...state, story: action.payload.data};
        case CREATE_STORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}