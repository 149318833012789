import * as React from "react";
import type { SVGProps } from "react";
const SvgNewsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 21.4 21.3"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10.7 21.3c-2.1 0-4.2-.6-5.9-1.8S1.7 16.7.9 14.7c-.8-1.9-1-4.1-.6-6.2.4-2.1 1.4-4 2.9-5.5S6.6.5 8.7.1c2.1-.4 4.2-.2 6.2.6 1.9.8 3.6 2.2 4.8 3.9 1.2 1.8 1.8 3.8 1.8 5.9 0 2.8-1.1 5.5-3.1 7.5s-4.8 3.3-7.7 3.3zm0-20.7c-2 0-3.9.6-5.6 1.7C3.5 3.4 2.2 5 1.4 6.8s-1 3.9-.6 5.8 1.4 3.8 2.8 5.2c1.4 1.4 3.2 2.4 5.2 2.8 2 .4 4 .2 5.8-.6 1.8-.8 3.4-2.1 4.5-3.7 1.1-1.7 1.7-3.6 1.7-5.6 0-2.7-1.1-5.2-3-7.1-1.8-1.9-4.4-3-7.1-3z" />
    <path d="M10.7 21.3c-2.1 0-4.2-.6-5.9-1.8S1.7 16.7.9 14.7c-.8-1.9-1-4.1-.6-6.2.4-2.1 1.4-4 2.9-5.5S6.6.5 8.7.1c2.1-.4 4.2-.2 6.2.6 1.9.8 3.6 2.2 4.8 3.9 1.2 1.8 1.8 3.8 1.8 5.9 0 2.8-1.1 5.5-3.1 7.5s-4.8 3.3-7.7 3.3zm0-20.7c-2 0-3.9.6-5.6 1.7C3.5 3.4 2.2 5 1.4 6.8s-1 3.9-.6 5.8 1.4 3.8 2.8 5.2c1.4 1.4 3.2 2.4 5.2 2.8 2 .4 4 .2 5.8-.6 1.8-.8 3.4-2.1 4.5-3.7 1.1-1.7 1.7-3.6 1.7-5.6 0-2.7-1.1-5.2-3-7.1-1.8-1.9-4.4-3-7.1-3z" />
    <path d="M18.6 17.6h-.1c-2.5-1-5.1-1.6-7.8-1.6s-5.3.5-7.8 1.6h-.2s-.1 0-.1-.1c0 0 0-.1-.1-.1v-.2s0-.1.1-.1c0 0 .1 0 .1-.1 2.5-1.1 5.2-1.6 8-1.6 2.7 0 5.5.6 8 1.6.1 0 .1.1.1.1v.2c0 .1 0 .1-.1.2 0 .1 0 .1-.1.1M10.7 6c-2.7 0-5.4-.6-8-1.7 0 0-.1 0-.1-.1l-.1-.1v-.2s0-.1.1-.1c0 0 .1 0 .1-.1h.2C5.4 4.8 8 5.3 10.7 5.3s5.3-.5 7.8-1.6h.2c.1 0 .1.1.1.1V4c0 .1-.1.1-.1.1-2.5 1.3-5.2 1.9-8 1.9z" />
    <path d="M10.7 6c-2.7 0-5.4-.6-8-1.7 0 0-.1 0-.1-.1l-.1-.1v-.2s0-.1.1-.1c0 0 .1 0 .1-.1h.2C5.4 4.8 8 5.3 10.7 5.3s5.3-.5 7.8-1.6h.2c.1 0 .1.1.1.1V4c0 .1-.1.1-.1.1-2.5 1.3-5.2 1.9-8 1.9zM6.5 17.2h-.2l-.1-.1c-.4-1-.6-1.9-.8-2.9V14s0-.1.1-.1H5.8s.1 0 .1.1v.1c.2 1 .4 1.9.8 2.8v.2s-.1.1-.2.1z" />
    <path d="M6.5 17.2h-.2l-.1-.1c-.4-1-.6-1.9-.8-2.9V14s0-.1.1-.1H5.8s.1 0 .1.1v.1c.2 1 .4 1.9.8 2.8v.2s-.1.1-.2.1zM10.7 21.3c-1.5 0-2.9-1.1-3.9-3V18l.1-.1.1.1h.2l.1.1c.9 1.7 2.2 2.7 3.4 2.7 2.2 0 4.1-2.7 4.8-6.8v-.1s0-.1.1-.1H15.9s.1 0 .1.1v.2c-.7 4.3-2.8 7.2-5.3 7.2M15.8 7.6c-.1 0-.1 0-.2-.1 0 0-.1-.1-.1-.2-.2-1-.4-1.9-.8-2.9v-.2c0-.1.1-.1.1-.1h.2c.1 0 .1.1.1.1.4 1 .6 1.9.8 3v.2s0 .1-.1.1c.1.1.1.1 0 .1M5.7 7.6h-.1s-.1 0-.1-.1c0 0 0-.1-.1-.1v-.1C6.2 3 8.3.1 10.7.1c1.5 0 2.9 1.1 3.9 3v.2c0 .1-.1.1-.1.2h-.2c-.1 0-.1-.1-.2-.1-.9-1.7-2.2-2.7-3.4-2.7-2.2 0-4.1 2.7-4.8 6.8 0 .1 0 .1-.1.2 0-.1-.1-.1-.1-.1z" />
    <path d="M5.7 7.6h-.1s-.1 0-.1-.1c0 0 0-.1-.1-.1v-.1C6.2 3 8.3.1 10.7.1c1.5 0 2.9 1.1 3.9 3v.2c0 .1-.1.1-.1.2h-.2c-.1 0-.1-.1-.2-.1-.9-1.7-2.2-2.7-3.4-2.7-2.2 0-4.1 2.7-4.8 6.8 0 .1 0 .1-.1.2 0-.1-.1-.1-.1-.1z" />
    <path d="M17.7 14.3h-14c-.3 0-.7-.1-.9-.4-.2-.2-.4-.6-.4-.9V8.4c0-.3.1-.7.4-.9s.6-.4.9-.4h14c.3 0 .7.1.9.4s.4.5.4.9V13c0 .3-.1.7-.4.9-.2.3-.5.4-.9.4zm-14-6.7c-.2 0-.4.1-.5.2-.1.2-.2.4-.2.6V13c0 .2.1.4.2.5.1.1.3.2.5.2h14c.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5V8.4c0-.2-.1-.4-.2-.5-.1-.1-.3-.2-.5-.2h-14z" />
    <path d="M17.7 14.3h-14c-.3 0-.7-.1-.9-.4-.2-.2-.4-.6-.4-.9V8.4c0-.3.1-.7.4-.9s.6-.4.9-.4h14c.3 0 .7.1.9.4s.4.5.4.9V13c0 .3-.1.7-.4.9-.2.3-.5.4-.9.4zm-14-6.7c-.2 0-.4.1-.5.2-.1.2-.2.4-.2.6V13c0 .2.1.4.2.5.1.1.3.2.5.2h14c.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5V8.4c0-.2-.1-.4-.2-.5-.1-.1-.3-.2-.5-.2h-14z" />
    <path d="M9.9 12.3h-2c-.1 0-.1 0-.2-.1s-.1-.1-.1-.2V9.4c0-.1 0-.1.1-.2s.1-.1.2-.1h2c.1 0 .1 0 .2.1s.1.1.1.2 0 .1-.1.2H8.2v2.1h1.7c.1 0 .1 0 .2.1s.1.1.1.2 0 .1-.1.2c0 .1-.1.1-.2.1z" />
    <path d="M9.9 12.3h-2c-.1 0-.1 0-.2-.1s-.1-.1-.1-.2V9.4c0-.1 0-.1.1-.2s.1-.1.2-.1h2c.1 0 .1 0 .2.1s.1.1.1.2 0 .1-.1.2H8.2v2.1h1.7c.1 0 .1 0 .2.1s.1.1.1.2 0 .1-.1.2c0 .1-.1.1-.2.1z" />
    <path d="M9.7 11H8c-.1 0-.1 0-.2-.1s-.1-.1-.1-.2 0-.1.1-.2.1-.1.2-.1h1.7c.1 0 .1 0 .2.1s.1.1.1.2 0 .1-.1.2c-.1 0-.2.1-.2.1z" />
    <path d="M9.7 11H8c-.1 0-.1 0-.2-.1s-.1-.1-.1-.2 0-.1.1-.2.1-.1.2-.1h1.7c.1 0 .1 0 .2.1s.1.1.1.2 0 .1-.1.2c-.1 0-.2.1-.2.1zM6.7 12.3h-.1s-.1 0-.1-.1l-1.5-2V12c0 .1 0 .1-.1.2s-.1.1-.2.1-.1 0-.2-.1-.1-.1-.1-.2V9.4c0-.1 0-.1.1-.2l.1-.1h.2c.1 0 .1.1.1.1l1.5 2V9.4c0-.1 0-.1.1-.2s.1-.1.2-.1.1 0 .2.1.1.1.1.2V12c0 .1 0 .1-.1.2-.1 0-.1.1-.2.1M13.3 12.3c-.1 0-.1 0-.2-.1l-.1-.1-.5-1.7-.5 1.7c0 .1-.1.1-.1.1s-.1.1-.2.1-.1 0-.2-.1l-.1-.1-.8-2.7v-.2c0-.1.1-.1.2-.1h.2c.1 0 .1.1.1.2l.5 1.7.5-1.7c0-.1.1-.1.1-.1s.1-.1.2-.1.1 0 .2.1l.1.1.5 1.7.5-1.7c0-.1.1-.1.1-.2h.2c.1 0 .1.1.2.1v.2l-.8 2.7c0 .1-.1.1-.1.1.1.1 0 .1 0 .1M16 12.3c-.7 0-1.3-.4-1.3-.9 0-.1 0-.1.1-.2s.1-.1.2-.1.1 0 .2.1.1.1.1.2.3.3.7.3c.5 0 .7-.2.7-.3 0-.2-.1-.3-.8-.5-.5-.1-1.2-.3-1.2-1 0-.5.6-.9 1.3-.9s1.3.4 1.3.9c0 .1 0 .1-.1.2s-.1.1-.2.1-.1 0-.2-.1-.1-.1-.1-.2-.3-.3-.7-.3c-.5 0-.7.2-.7.3 0 .2.1.3.8.5.5.1 1.2.3 1.2 1 0 .5-.6.9-1.3.9z" />
    <path d="M16 12.3c-.7 0-1.3-.4-1.3-.9 0-.1 0-.1.1-.2s.1-.1.2-.1.1 0 .2.1.1.1.1.2.3.3.7.3c.5 0 .7-.2.7-.3 0-.2-.1-.3-.8-.5-.5-.1-1.2-.3-1.2-1 0-.5.6-.9 1.3-.9s1.3.4 1.3.9c0 .1 0 .1-.1.2s-.1.1-.2.1-.1 0-.2-.1-.1-.1-.1-.2-.3-.3-.7-.3c-.5 0-.7.2-.7.3 0 .2.1.3.8.5.5.1 1.2.3 1.2 1 0 .5-.6.9-1.3.9z" />
  </svg>
);
export default SvgNewsIcon;
