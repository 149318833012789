import
{
    SPARE_PART_TYPE_LOADING,
    SPARE_PART_TYPE_SUCCESS,
    SPARE_PART_TYPE_FAILURE
}
from "redux/types";

const initialState = {
    sparePartTypeLoading: false,
    sparePartType: null,
    errors: null
}

export const listSparePartTypeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SPARE_PART_TYPE_LOADING :
            return {...state, sparePartTypeLoading: action.payload}
        case SPARE_PART_TYPE_SUCCESS :
            return {...state, sparePartType: action.payload.sparePartType}
        case SPARE_PART_TYPE_FAILURE :
            return {...state, errors: action.payload}
        default:
            return state;
    }
}

