import
{
    AMANLEEK_BRANDS_LOADING,
    AMANLEEK_BRANDS_SUCCESS,
    AMANLEEK_BRANDS_FAILURE
}
from "redux/types";

const initialState = {
    amanleekBrandsLoading: false,
    amanleekBrands: null,
    errors: null
}

export const amanleekListBrandsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AMANLEEK_BRANDS_LOADING :
            return {...state, amanleekBrandsLoading: action.payload};
        case AMANLEEK_BRANDS_SUCCESS :
            return {...state, amanleekBrands: action.payload};
        case AMANLEEK_BRANDS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}