import
{
    AMANLEEK_LEAD_INSERT_LOADING,
    AMANLEEK_LEAD_INSERT_SUCCESS,
    AMANLEEK_LEAD_INSERT_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { amanleekLeadInsertService } from "ThirdPartyServices/Amanleek/amanleekLeadInsertService";

export const amanleekLeadInsertAction = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: AMANLEEK_LEAD_INSERT_LOADING, payload: true});
        const response = await amanleekLeadInsertService(data);
        dispatch({type: AMANLEEK_LEAD_INSERT_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: AMANLEEK_LEAD_INSERT_FAILURE, payload: err});
    } finally  {
        dispatch({type: AMANLEEK_LEAD_INSERT_LOADING, payload: false});
    }
}