import
{
    CAR_MODELS_LOADING,
    CAR_MODELS_SUCCESS,
    CAR_MODELS_FAILURE
}
from "redux/types";

const initialState = {
    carModelsLoading: false,
    carModels: null,
    errors: null
}

export const listCarModelsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CAR_MODELS_LOADING :
            return {...state, carModelsLoading: action.payload};
        case CAR_MODELS_SUCCESS :
            return {...state, carModels: action.payload.data};
        case CAR_MODELS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}