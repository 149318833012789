import styles from "components/Pagination/Pagination.module.css";
import { useTranslation } from "react-i18next";
import { RootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface PaginationProps {
  loading: boolean;
  current_page: number;
  last_page: number;
  path: any;
  style?: any;
  top?: string;
}
const Pagination: FC<PaginationProps> = ({
  loading,
  current_page,
  last_page,
  path,
  style,
  top,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";

  const maxFirstPages = 2;

  const problemStart = maxFirstPages + 2;

  const isProblem = last_page > problemStart;

  const createFirstPages = (totalNumber: any) => {
    const array = [];
    for (var i = 1; !(i > totalNumber) && i <= maxFirstPages; i++) {
      array.push(i);
    }
    return array;
  };

  const scrollToTop = (callback: any) => {
    const topElement = document.getElementById(top || "header");
    // topElement?.addEventListener("scroll", callback);
    topElement?.addEventListener("scroll", () => {});

    topElement?.scrollIntoView({
      behavior: "smooth",
    });

    // topElement?.removeEventListener("scroll", callback);
  };

  async function scrollToElementSmoothly(elementId?: string) {
    const element = document.getElementById(elementId || "header");

    if (!element) {
      return; // Element not found
    }

    return new Promise<void>((resolve) => {
      element.scrollIntoView({ behavior: "smooth" });

      // Wait for a short time (adjust the time as needed)
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  const getSearchURL = () => {
    var searchFilters = "";
    Array.from(searchParams.entries()).forEach((item: any, idx: number) => {
      if (item[0] !== "page") {
        if (idx === Array.from(searchParams.entries()).length - 1) {
          searchFilters += `${item[0]}=${item[1]}`;
        } else {
          searchFilters += `${item[0]}=${item[1]}&`;
        }
      }
    });
    return searchFilters;
  };

  async function handleNextPaginationClick() {
    await scrollToElementSmoothly(top);

    // scrollToTop(() => {
    const nextPage = current_page + 1;

    navigate(
      {
        pathname: `/${currentLanguage}/${path}`,
        search: `?${getSearchURL().split("page=")[0]}&page=${nextPage}`,
      },
      { replace: true }
    );
    // });
  }

  async function handlePrevPaginationClick() {
    await scrollToElementSmoothly(top);

    // scrollToTop(() => {
    const nextPage = current_page - 1;

    navigate(
      {
        pathname: `/${currentLanguage}/${path}`,
        search: `?${getSearchURL().split("page=")[0]}&page=${nextPage}`,
      },
      { replace: true }
    );
    // });
  }

  async function handlePaginationClick(targetPage: any) {
    await scrollToElementSmoothly(top);

    // scrollToTop(() => {

    navigate(
      {
        pathname: `/${currentLanguage}/${path}`,
        search: `?${getSearchURL().split("page=")[0]}&page=${targetPage}`,
      },
      { replace: true }
    );
    // });
  }

  return (
    <>
      {!loading ? (
        <div
          className={`flex flex-row${
            isArabic ? "-reverse" : ""
          } items-center justify-center gap-x-2 w-[100%]`}
          style={{ ...style }}
        >
          {current_page !== 1 ? (
            <div
              className={`${styles.pageButtonNotSelected} ${styles.pageButton}`}
              onClick={handlePrevPaginationClick}
              style={{ paddingInline: "1.5rem" }}
            >
              <p>{t("previous")}</p>
            </div>
          ) : (
            <></>
          )}

          {createFirstPages(last_page).map((number: any) => {
            return (
              <div
                className={`${
                  current_page === number
                    ? styles.pageButtonSelected
                    : styles.pageButtonNotSelected
                } ${styles.pageButton}`}
                onClick={() => handlePaginationClick(number)}
              >
                <p>{number}</p>
              </div>
            );
          })}

          {/* EXTRAS BEFORE */}
          {!(current_page === maxFirstPages + 3) ? (
            current_page > maxFirstPages + 1 &&
            !(current_page - 1 === maxFirstPages + 1) ? (
              <div
                className={`${styles.pageButtonDisabled} ${styles.pageButton}`}
              >
                <p>...</p>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div
              className={`${styles.pageButtonNotSelected} ${styles.pageButton}`}
              onClick={() => handlePaginationClick(maxFirstPages + 1)}
            >
              <p>{maxFirstPages + 1}</p>
            </div>
          )}

          {/* 1 PAGE BEFORE */}
          {isProblem &&
          current_page >= problemStart &&
          current_page < last_page ? (
            <div
              className={`${styles.pageButtonNotSelected} ${styles.pageButton}`}
              onClick={() => handlePaginationClick(current_page - 1)}
            >
              <p>{current_page - 1}</p>
            </div>
          ) : (
            <></>
          )}

          {current_page > maxFirstPages && current_page < last_page - 1 ? (
            <div
              className={`${styles.pageButtonSelected} ${styles.pageButton}`}
            >
              <p>{current_page}</p>
            </div>
          ) : (
            <></>
          )}

          {/* 1 PAGE AFTER */}
          {isProblem &&
          current_page >= maxFirstPages &&
          current_page < last_page - 2 ? (
            <div
              className={`${styles.pageButtonNotSelected} ${styles.pageButton}`}
              onClick={() => handlePaginationClick(current_page + 1)}
            >
              <p>{current_page + 1}</p>
            </div>
          ) : (
            <></>
          )}

          {/* EXTRAS AFTER */}
          {!(last_page - current_page === 4) ? (
            last_page - 2 > maxFirstPages &&
            current_page < last_page - 3 && (
              <div
                className={`${styles.pageButtonDisabled} ${styles.pageButton}`}
              >
                <p>...</p>
              </div>
            )
          ) : (
            <div
              className={`${styles.pageButtonNotSelected} ${styles.pageButton}`}
              onClick={() => handlePaginationClick(last_page - 2)}
            >
              <p>{last_page - 2}</p>
            </div>
          )}

          {last_page - 1 > maxFirstPages ? (
            <div
              className={`${
                current_page === last_page - 1
                  ? styles.pageButtonSelected
                  : styles.pageButtonNotSelected
              } ${styles.pageButton}`}
              onClick={() => handlePaginationClick(last_page - 1)}
            >
              <p>{last_page - 1}</p>
            </div>
          ) : (
            <></>
          )}

          {last_page > maxFirstPages ? (
            <div
              className={`${
                current_page === last_page
                  ? styles.pageButtonSelected
                  : styles.pageButtonNotSelected
              } ${styles.pageButton}`}
              onClick={() => handlePaginationClick(last_page)}
            >
              <p>{last_page}</p>
            </div>
          ) : (
            <></>
          )}

          {current_page !== last_page ? (
            <div
              className={`${styles.pageButtonNotSelected} ${styles.pageButton}`}
              onClick={handleNextPaginationClick}
              style={{ paddingInline: "1.5rem" }}
            >
              <p>{t("next")}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
