import "components/Carousel/PostCardCarousel/post-card-carousel.css";
import { FC, useEffect } from "react";
import { useIsDefined } from "hooks/useIsDefined";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";

interface PostCardCarouselProps {
  data: any;
  isSpecial?: boolean;
  style?: any;
}

const PostCardCarousel: FC<PostCardCarouselProps> = ({
  data,
  style,
  isSpecial = false,
}) => {
  const { isDefined } = useIsDefined();

  useEffect(() => {
    const wrapper: any = document.querySelector(".posts-wrapper");
    const carousel: any = document.getElementById("post-card-carousel");
    const firstCardWidth: any =
      carousel?.querySelector(".post-card")?.offsetWidth;
    const arrowBtns = document.querySelectorAll(".posts-wrapper i");
    const carouselChildrens = [...carousel?.children];

    let isDragging = false,
      isAutoPlay = true,
      startX: any,
      startScrollLeft: any,
      timeoutId: any;

    // Get the number of cards that can fit in the carousel at once
    let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

    // Insert copies of the last few cards to beginning of carousel for infinite scrolling
    // carouselChildrens
    //   .slice(-cardPerView)
    //   .reverse()
    //   .forEach((card: any) => {
    //     carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
    //   });

    // // Insert copies of the first few cards to end of carousel for infinite scrolling
    // carouselChildrens.slice(0, cardPerView).forEach((card: any) => {
    //   carousel.insertAdjacentHTML("beforeend", card.outerHTML);
    // });

    // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
    carousel.classList.add("no-transition");
    carousel.scrollLeft = carousel.offsetWidth;
    carousel.classList.remove("no-transition");

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft +=
          btn.id == "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e: any) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e: any) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    // const infiniteScroll = () => {
    //   // If the carousel is at the beginning, scroll to the end
    //   if (carousel.scrollLeft === 0) {
    //     carousel.classList.add("no-transition");
    //     carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
    //     carousel.classList.remove("no-transition");
    //   }
    //   // If the carousel is at the end, scroll to the beginning
    //   else if (
    //     Math.ceil(carousel.scrollLeft) ===
    //     carousel.scrollWidth - carousel.offsetWidth
    //   ) {
    //     carousel.classList.add("no-transition");
    //     carousel.scrollLeft = carousel.offsetWidth;
    //     carousel.classList.remove("no-transition");
    //   }

    //   // Clear existing timeout & start autoplay if mouse is not hovering over carousel
    //   clearTimeout(timeoutId);
    //   if (!wrapper.matches(":hover")) autoPlay();
    // };


    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    // carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
  }, []);

  return (
    <div className="posts-wrapper" style={{ ...style }}>
      <i
        id="left"
        className="fa-solid fa-angle-left"
        style={{ zIndex: 99 }}
      ></i>
      <ul id="post-card-carousel" className="post-card-carousel">
        {data?.map((item: any) => {
          return (
            <li
              onClick={() => {
                window.location.reload();
              }}
              key={item?.name}
              id="post-card"
              className="post-card"
            >
              <NewWidePostCard
                key={item?.uuid}
                id={item?.uuid}
                isSpecialAd={isSpecial}
                isFavorited={item?.favourited}
                viewsCount={item?.views_count}
                callsCount={item?.contact_count}
                uploadDate={item?.created_at}
                category={item?.category}
                postType={item?.category}
                customerName={item?.customer}
                postTitle={item?.title}
                paymentOption={item?.payment_type}
                city={item?.city}
                price={
                  isDefined(item?.down_payment)
                    ? item?.down_payment
                    : item?.price
                }
                carCondition={item?.car_condition}
                productionDate={item?.production_date}
                kilometrage={item?.kilo_meterage}
                width={"w-[350px]"}
                postCardStyle={""}
                isMobile={true}
                image={item?.main_image}
                showActions={false}
                brandLogo={undefined}
                created_at={item.created_at}
                address={item.address}
              />
            </li>
          );
        })}
      </ul>
      <i
        id="right"
        className="fa-solid fa-angle-right"
        style={{ zIndex: 99 }}
      ></i>
    </div>
  );
};

export default PostCardCarousel;
