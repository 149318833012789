import
{
    ALL_CHATS_LOADING,
    ALL_CHATS_SUCCESS,
    ALL_CHATS_FAILURE
} 
from "redux/types";

const initalState = {
    allChatsLoading: false,
    allChats: null,
    errors: null
}

export const listAllChatsReducer = (state = initalState, action: any) => {
    switch (action.type) {
        case ALL_CHATS_LOADING :
            return {...state, allChatsLoading: action.payload};
        case ALL_CHATS_SUCCESS :
            return {...state, allChats: action.payload.chats};
        case ALL_CHATS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}