import * as React from "react";
import type { SVGProps } from "react";
const SvgMaintenanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 20.8 20.9"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M17.8 3.1c-1.4-1.4-3.1-2.3-5-2.8-1.9-.4-3.9-.3-5.7.3-1.8.6-3.5 1.8-4.7 3.3C1.2 5.4.4 7.2.1 9.1c-.2 1.9.1 3.9.9 5.6.8 1.8 2.1 3.3 3.7 4.3 1.6 1.1 3.5 1.7 5.4 1.7 1.9.1 3.9-.4 5.5-1.4h.1l.5-.3h.1c1.2-.8 2.2-1.8 2.9-3 1.3-2 1.8-4.3 1.5-6.7-.2-2.4-1.3-4.6-2.9-6.2zm.5 12.9c-.3.4-.7.9-1 1.2-.4.4-.8.7-1.2 1l-.2.1-3.9-3.8c-.1 0-.1-.1-.2-.1h-.2c-.7.2-1.4.3-2.1.2-.7-.1-1.3-.4-1.9-.7-.6-.4-1.1-.9-1.4-1.5-.3-.6-.5-1.3-.6-2v-.7L7.8 12c.1 0 .2 0 .2.1h.2l2.6-1c.1 0 .1 0 .1-.1l.1-.1 1-2.6v-.2c.1-.1 0-.2 0-.3L9.7 5.6h.7c.7 0 1.4.2 2 .6.6.3 1.1.8 1.5 1.4.4.6.7 1.2.7 1.9.1.7 0 1.4-.2 2.1v.2c0 .1.1.1.1.2l3.9 3.9-.1.1zm.7-1.2-.2.3-3.6-3.6v-.1c.2-.9.2-1.8 0-2.7-.2-.9-.7-1.7-1.4-2.3S12.5 5.3 11.6 5c-.9-.2-1.8-.3-2.7 0-.1 0-.1.1-.2.1-.1.1-.1.2-.1.3 0 .1 0 .2.1.3l2.6 2.6-.8 2.2-2.2.8-2.6-2.6s-.1-.1-.2-.1h-.2c-.2.1-.3.2-.3.3-.2.9-.2 1.8 0 2.7s.7 1.7 1.4 2.3 1.4 1.1 2.3 1.4c.9.2 1.8.3 2.7 0h.1l3.6 3.6-.3.1c-2 1-4.4 1.3-6.6.8-2.2-.5-4.2-1.9-5.5-3.7C1.4 14.2.7 12 .9 9.7 1 7.4 2 5.2 3.7 3.6 5.3 2 7.4 1 9.7.8c2.3-.2 4.6.5 6.4 1.8C18 4 19.3 6 19.8 8.2c.5 2.3.2 4.6-.8 6.6" />
  </svg>
);
export default SvgMaintenanceIcon;
