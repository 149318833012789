import 
{
    LIST_FAVOURITES_LOADING,
    LIST_FAVOURITES_SUCCESS,
    LIST_FAVOURITES_FAILURE

}
from "redux/types";

import { Dispatch } from "redux";

import { listsFavouritePostsService } from "services/listFavouritePostsService";

export const listFavouritesAction = (page: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_FAVOURITES_LOADING, payload: true});
        const response = await listsFavouritePostsService(page);
        dispatch({type: LIST_FAVOURITES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_FAVOURITES_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_FAVOURITES_LOADING, payload: false});
    }
}