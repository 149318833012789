import api from "api";
import { updateMainPhotoEndPoint } from "api/apiUrls";
import store from "redux/store";

export const updateMainPhotoService = async (data: any) => {
  const response = await api.post(updateMainPhotoEndPoint(), data, {
    headers: {
      Authorization: `Bearer ${
        store.getState()?.verifyOtpReducer?.userData?.token
      }`,
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};
