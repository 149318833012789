import { POST_MAIN_IMAGE_STATE } from "redux/types";

const initialState = {
  mainImage: 0,
};

export const viewPostMainImageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_MAIN_IMAGE_STATE:
      return { ...state, mainImage: action.payload };
    default:
      return state;
  }
};
