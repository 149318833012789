// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /**  COMMON */
  get_started: "Get Started",
  lang: "العربية",
  ok: "Ok",
  cancel: "Cancel",
  currency: "SAR",
  skip: "Skip",
  close: "Close",
  confirm: "Confirm",
  accept: "Accept",
  reject: "Reject",
  save: "Save",
  help: "Help",
  next: "Next",
  title: "Title",
  story_title: "Title",
  story_desc: "Write a promotional text for you",
  favorited_ads: "Favorites",
  description: "Description",
  call_us: "Call Us",
  publish: "Publish",

  main_error_message: "Please fill in all required fields",
  images_error_message: "Please choose at least one image",
  main_image_error_message: "Please choose a main image",

  info: "Info",
  order: "Order",
  invoice: "Invoice",
  edit: "Edit",
  make_special: "Make Special",
  continue: "Continue",
  amount: "Amount",
  are_you_sure: "Are you Sure ?",

  store: "Store",

  sell: "Sell",

  reset_cart: "Reset",

  new_cars: "New Cars",

  update_post: "Update Post",

  sedan: "Sedan",

  choose_product: "Choose a Product",

  automatic: "Automatic",

  not_logged_in: "You are not logged in",

  // PROFILE SCREEN
  user_greeting: "Hello, ",
  profile_msg:
    "You can manage your profile, and follow your posts, messages and packages",
  num_ads: "Available Posts",
  active_ads: "Active Posts",
  unread_messages: "Unread Messages",
  membership_type: "Membership Type",
  all_msgs: "All Messages",
  brief: "You can write a brief about yourself here",
  activate: "Activate",
  no_posts: "No Posts",
  ads: "Advertisements",
  num_of_stars: "Number of Special Stars",
  more_stars: "More Special Stars",

  // MEMBERSHIP TYPES
  membership_free: "Free",
  membership_special: "Special",

  main_cover_message: "Please choose a cover photo",

  /** CHANGE LANGUAGE */
  changelang_title: "Change Language",
  changelang_msg:
    "Are you sure to reload the application to change the language?",
  /** DRAWER  */

  drawer_home: "Home",
  drawer_financial: "Financial",
  drawer_orders: "Orders History",
  drawer_bank: "Bank Account",
  drawer_account: "Account",
  drawer_support: "Support",
  drawer_settings: "Settings",
  drawer_logout: "Logout",

  wait: "Wait",
  notReceiveCode: "Didn’t receive the verification code?",

  /** LOGIN SCREEN STRINGS */
  login_bodytext: "Login or register",
  login_languagetext: "العربية",
  login_continuetitle: "Continue",
  login_browsetitle: "Browse Services",
  logout: "Logout",
  /** VERIFICATION SCREEN STRINGS */
  verification_header: "Verification Code",
  verification_bodytext:
    "Enter the verification code for the SMS we sent to your mobile",
  verification_buttontitle: "Verify",
  verification_resendtext: "Didn’t receive the verification code?",

  /** FINANCIAL SCREENS */
  balance: "Balance",
  transactions: "Transactions",
  cashout_requests: "Cashout requests",
  load_wallet: "Load Wallet",
  request_cashout: "Request Cashout",
  filter_transactions: "Filters Transactions",
  filter_cashout: "Filters Cashout requests",
  date_from: "Date From",
  date_till: "Date Till",
  pick_date: "Pick a date",
  filter_transaction_text: "Filter Transactions based on Date",
  show_transaction: "Show Transactions",
  filter_cashout_text: "Filter Cashout based on Date",
  show_cashout: "Show Cashout requests",
  from: "From",
  till: "Till",
  cashout_details: "Cashout Details",
  confirm_cashout: "Confirm cashout",

  request_cashout_message:
    "You should be the owner of the bank account, and your informations here should match your bank account details, in case any wrong information your washout request will be rejected.",
  confirm_cashout_message: "Are you sure you want to cashout {{amount}} SAR ?",
  /** COMPLETE DATA SCREEN */
  completeTitle: "Complete a few details",
  invEmail_errorMesg: "Invalid Email Format",
  requireEmail_errorMesg: "Email is a required field",
  requireName_errorMesg: "Group Name is a required field",

  /** ERROR MESSAGES */
  phone_errormessage:
    "Phone number must start with number (5) \nand doesn't contain any characters or special characters.",
  phonelength_errormessage: "Phone number must be at least 8-digits.",
  otplength_errormessage: "OTP number must be 4 digits.",
  otp_errormessage:
    "OTP number must be 4 digits and doesn't contain any characters or special characters.",
  required_field: "{{field_name}} is required",
  invalid_field: "{{field_name}} is invalid",

  /**  HOME Status */
  user_status: "Your status",
  not_ready_to_recive: "Not ready to receive orders",
  ready_to_recive: "ready to receive orders",
  order_comming: "Orders are coming",
  order_not_comming: "You are not ready",
  user_ready: "Be ready",
  user_not_ready: "Please change your status",
  conf_message: "Confirmation Message",
  to_receive_orders:
    "To receive orders, you should keep the app in the front all the times, you will have 20 seconds to accept orders",
  note_to_user:
    " You will not receive any orders, in case the app in the mobile background",
  new_order: "New order",
  deliver_to: "Deliver order to",
  note_to_driver:
    "We sent the order to the store, contact \nthe employee to receive the order",
  will_pay: "You will pay in the store",
  will_get_part1: "You will get ",
  will_get_part2: " when you\ncomplete the order.",
  will_get_p2: " when you complete the order.",
  confirm_financial: "Confirm Financial",
  wallet_note:
    " This order should be paid to choice first, We will deduct the amount from your wallet,\nand you will receive the amount in cash from the client when you deliver the order",
  pay_to_store: "Pay to Store",
  receive_from_client: "Receive from client",

  /** BANK ACCOUNT STACK*/
  bank_account_details: "Bank account details",
  bank_account_info:
    "You should be the owner of the bank account, and your informations here should match your bank account details, in case any wrong information your washout request will be rejected.",
  name: "Name",
  national_id: "National ID",
  iban: "IBAN",
  bank_name: "Bank Name",
  group_name: "Group Name",
  email: "Email",
  mobile_no: "Mobile No.",
  call_support: "Call support in case you want change any data",

  language: "Language",
  push_notification: "Push notifications",

  /** ORDER HISTORY */

  order_details: "Order Details",

  /* Navbar Text */
  navbar_login_text: "Login",
  navbar_create_post_text: "Create Post",

  navbar_home_text: "Home",
  navbar_buy_text: "Buy",
  navbar_rental_text: "Rent",
  navbar_car_parts_text: "Car Parts",
  navbar_news_text: "News",
  navbar_dark_mode: "Dark Mode",
  navbar_store_text: "Store",
  navbar_maintenance_text: "Maintenance",
  navbar_insurance_text: "Insurance",
  navbar_exhibitions_text: "Exhibitions",
  navbar_stories_text: "Stories",

  car_details: "Car Details",

  /* Homepage Text */
  homepage_advanced_search: "Advanced Search",
  homepage_fast_search: "Fast Search",
  homepage_search_bar: "Search Bar",

  homepage_search: "Search",
  homepage_search_bar_placeholder: "Search for a car ...",

  homepage_rental: "Rental",
  homepage_buy_and_sell: "Buy & Sell",
  homepage_color: "Color",
  homepage_distance: "Distance Covered",
  homepage_km: "KM",
  homepage_cc: "CC",
  homepage_features: "Features",
  homepage_carbody: "Car Body",
  homepage_price_from: "Price From",
  homepage_price_to: "Price To",
  homepage_governate: "Governate",
  homepage_car_maker: "Car Maker",
  homepage_car_model: "Car Model",
  homepage_year: "Year",
  homepage_car_condition: "Car Condition",
  homepage_fuel_type: "Fuel Type",

  homepage_browse_categories: "Browse Our Categories",
  homepage_special_dealers: "Special Dealers",
  homepage_search_by: "Search by",
  homepage_special_ads: "Special Ads",
  homepage_latest_articles: "Latest Articles",
  homepage_all_articles: "All Articles",

  search_by_brands: "Search By Brands",
  search_by_bodies: "Search By Bodies",

  /* ViewPost Page Text */
  viewpost_send_a_message: "Send a message to the car owner:",
  viewpost_send_msg: "Send Message",
  viewpost_send_a_message_placeholder: "Is the car available ?",
  viewpost_send: "Send",
  viewpost_safety_percautions: "Safety Percautions",
  viewpost_report: "Report a Problem",
  viewpost_description: "Description: ",
  viewpost_features: "Features: ",
  viewpost_location: "Location: ",
  viewpost_share: "Share",
  viewpost_user_ad_history_1: "Ad",
  viewpost_user_ad_history_2: "Show all ads",
  viewpost_whatsapp: "Whatsapp",
  viewpost_phone: "Show phone number",
  viewpost_call: "Call",
  viewpost_checkout: "Checkout",
  viewpost_spare_parts: "Spare Parts",
  viewpost_maintenance: "Maintenance",
  viewpost_similar_posts: "Similar Posts You May Like",

  viewpost_insure_car_title: "Insure your car",
  viewpost_insure_car_body: "Discover the best insurance plan",

  more: "more",

  post_owner_info: "Post Owner Information",

  unable_to_create_post: "Unable to create post",
  please_login_before_creating_a_post: "Please login before creating a post",

  percaution_one:
    "Meet the seller in a public place such as the metro, malls, or petrol stations",
  percaution_two: "Go with your friend when you meet the seller",
  percaution_three:
    "Examine the car well before buying and make sure that the price is right",
  percaution_four:
    "Do not pay or transfer the price until after a good inspection",

  /* Literal Translation */
  egp: "EGP",
  cash: "Cash",
  installment: "Installment",
  contact_customer_for_more_details: "Contact customer for more details",
  post_number: "Post Number :",
  month: "Month",
  upfront: "Upfront",
  greetings: "Welcome to Souq.Car",
  v3_welcome_to_souq_car: "Welcome to Souq.Car",
  login: "Login",
  with_phone_number: "Mobile number",
  terms_and_conditions_confirm:
    "By continuing, you agree to our Terms of Use and Privacy Policy",
  or: "OR",
  faqs: "Frequently Asked Questions",
  faqs_short: "FAQs",
  we_answer_all_your_questions: "We answer all of the FAQs about Souq.Car",
  contact_us: "Contact Us",
  didnt_find_your_question: "Didn't find your question?",
  do_you_have_a_question: "Do you have a question?",
  contact_info: "Contact Info",
  you_can_contact_us: "You can contact us directly through",
  leave_us_a_message: "Leave us a message",
  dont_feel_hesitant:
    "Dont feel hesitatnt to send us, we will answer as soon as possible",
  post_title: "Post Title",
  write_a_message: "Write a message",
  success_notification_message: "Sent Successfully",
  success_notification_description: "Your message was sent successfully",
  buy_and_sell_cars: "Buy & Sell Cars",
  special_ads: "Special Advertisements",
  view: "View : ",
  sort_by: "Sort by",
  search_using_keywords: "Search using keywords",
  search: "Search",
  hide_filters: "Click to hide filters",
  show_filters: "Click to show filters",
  hide_filters_mob: "Hide filters",
  show_filters_mob: "Show filters",
  submit: "Submit",
  delete: "Delete",
  search_filters: "Search filters",
  year_from: "Year from ",
  year_to: "Year to",
  transmission_type: "",
  price_from: "Price from",
  price_to: "Price to",
  engine_capacity: "Engine Capacity",
  car_body: "Car Body",
  fuel_types: "Fuel Types",

  about_us: "About Us",
  get_to_know_souq_car: "Get to know Souq.Car",
  about_souq_car: "About Souq.Car",
  about_seller: "About the seller",
  about_buyer: "About the buyer",

  //footer

  commonQuestions: "common Questions",
  terms_of_use: "Terms Of Use",
  privacy_policy: "Privacy Policy",
  copyRights: "All rights reserved to Souq.Car 2023    ",
  Quick_links: "Quick links",
  Home: "Home",
  sections: "Sections",
  who_are_we: "who are we",
  news: "news",
  contact_Us: "Contact Us",
  The_most_famous_cars: "The Most Famous Cars",
  Mercedes: "Mercedes",
  Chevrolet: "Chevrolet",
  Citron: "Citron",
  Skoda: "Skoda",
  Peugeot: "Peugeot    ",
  Alfa_Romeo: "Alfa Romeo    ",
  BMW: "BMW    ",
  about_Souq_Car: "About Souq.Car",
  CAR_News: "Car News",
  Latest_articles: "Latest articles",
  How_to_save_your_annual_fuel_consumption:
    "How to save your annual fuel consumption",
  artic_desc:
    "Unfortunately, this bad habit is widespread in the region, especially during the summer season. Keeping the engine running outside the driving period for more than a minute leads to wasting fuel and releasing huge amounts of pollutants into the air. So, if you're waiting to pick up someone or just make a short stop at the grocery store, make sure you turn off the engine.",
  read_more: "Read More",

  about_souqCarDesc:
    "We provide an integrated sales service, from searching for a car among the many options available, buying and leasing in addition to providing spare parts, delivery, and payment, and we seek to improve our services by providing a safe and easy experience",

  addImage: "Choose a photo",
  mainCover: "Main Cover",

  car_news: "Car News",
  latest_articles: "Latest Articles",
  more_articles: "Read more articles",

  please_provide_this_field: "Please provide this field",
  please_check_all_fields_provided: "Please provide all the required fields",

  //  PROFILE SCREEN USER INFO CARD
  user_my_account: "My Account",
  user_my_posts: "My Posts",
  user_phone_number: "Phone",
  user_email: "Email",
  user_member_since: "Joined",
  user_membership_type: "Membership",
  user_settings: "Settings",
  my_info: "My Info",
  personal_profile: "Personal Profile",
  show_phone_number: "Display your phone number",
  show_email: "Display your email",
  disable_account: "Disable Account",
  disable: "Disable",

  //  SETTINGS
  basic_info: "Basic Information",
  basic_info_msg:
    "Only your name, phone, and WhatsApp will be visible to visitors",
  add_personal_photo: "Add a personal photo",
  remove_photo: "Remove",

  // PAGINATION
  previous: "Prev",
  // CREATE POST SCREEN
  pictures: "Pictures",
  car_info: "Car Info",
  choose_category: "Choose a Category",
  car_info_msg:
    "Enter your car details correctly to make your post easier to find",
  increase: "May Increase",
  transmission: "Transmission Type",
  turbo: "Turbo",
  distance_covered_hint: "How much your car has been driven",
  extra_info: "Extra Info (Optional)",
  title_hint: "Write a title that describes your car",
  post_description: "Description",
  post_description_hint: "Write a description (optional)",
  price_hint: "Write a competitive price for your car",
  payment_options: "Payment Options",
  payment_options_hint: "e.g. Cash",
  price: "Price",
  price_condition: "Price Condition",
  price_condition_hint: "e.g. Negotiable",
  inspection_location: "Location for car inspection",
  click_next: "Click next to add photos",

  // CAR PHOTOS INSTRUCTIONS
  obscured: "- It is preferable that the license plates be obscured",
  front_photo: "- It is preferable that the car have a front photo",
  clear_photos: "- The photos must be clear and of good quality",
  no_competing: "- The photos should not contain logos of any competing brands",

  car_photos: "Car Photos",
  upload_10: "( You can upload up to 10 photos )",

  back: "Back",
  publish_post: "Publish Post",
  save_draft: "Save Draft",

  last_step_msg1: "Great! Just one last step",
  last_step_msg2: "Way to go! Only your contact info left",

  mobile_hint: "Mobile number",
  otp_hint: "Enter the OTP",
  resend: "Resend",
  finished: "Congrats! You finished easily",

  choose_category_message: "Please choose a category",

  form_rental_types: "Rental Duration",
  form_rental_driver: "Driver",
  form_type_of_service: "Type of Service",

  type_of_product: "Type of Product",
  state_of_product: "State of Product",

  unspecified: "Unspecified",

  view_in_google_maps: "Google Maps",
  get_directions: "Directions",

  total: "Total",
  total_ads: "Total Ads",

  post_details: "Post Details",

  create_post_title: "Create Post",

  address_hint: "Enter meeting address",

  all_messages: "Messages",

  all_ads: "All Ads",
  for_sale: "Sale",
  used: "Used",
  new: "New",
  almost_new: "Almost New",
  the_branches: "Branches",

  just_now: "Just Now",

  rejected_title: "Your Post Has Been Rejected",
  rejected_desc: "has been rejected",

  write_your_phone_number: "Write your phone number",
  accepted_title: "Your Post Has Been Accepted",
  accepted_desc: "has been accepted",

  more_special_ads: "More special ads",
  categories: "Categories",
  category: "Category",
  all: "All",

  delete_title: "Delete Ad",
  delete_desc: "To reactivate ad, it would have to go to pending again",

  send_message_error: "Please Login to send messages",

  report_message_placeholder: "Write your report here",

  whatsapp: "Whatsapp",

  working_hours: "Working Hours",
  days_off: "Days Off",

  comma: ", ",
  // CHAT PAGE
  select_page: "Select a chat to view conversation",
  used_cars: "Used Cars For Sale",
  type_here: "Type here...",

  featured_exhibitions: "Featured Exhibitions",
  notifications: "Notifications",
  the_exhibitions: "Exhibitions",

  today: "Today",
  yesterday: "Yesterday",
  no_notifications: "No Notifications",

  exhibition_name: "Exhibition Name",
  whatsapp_number: "Whatsapp",
  main_service: "Main Service",

  social_media: "Social Media",

  the_type: "Type",
  the_appointments: "Hours",
  the_address: "Address",
  other_number: "Other #",
  add_branch: "Add Branch",
  the_holiday: "Off Day",

  no_branches: "No Branches",

  edit_thumbnail: "Edit Thumbnail",
  see_profile: "View Profile",
  please_provide_valid_link: "Please provide a valid link",
  more_ads: "More Ads",
  visit_exhibition_page: "Visit Exhibition Page",

  page_not_found: "Page Not Found",
  back_to_previous_page: "Back to previous page",

  update_branches_title: "Failed to update branches",
  update_branches_desc: "Please fill in all required fields",

  prefilled_greeting: "Hello",
  prefilled_title:
    "I would like to get more information about this car you posted on",
  prefilled_maker: "Car Manufacturer",
  prefilled_model: "Car Model",
  prefilled_year: "Year",
  prefilled_location: "Location",
  prefilled_link: "Link",
  prefilled_footer:
    "Any changes made to this message will result in the enquiry not being sent to the seller",

  more_exhibitions: "More Exhibitors",
  send_whatsapp: "Send Whatsapp",
  call: "Call",
};
