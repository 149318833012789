import
{
    EXHIBITOR_DATA_LOADING,
    EXHIBITOR_DATA_SUCCESS,
    EXHIBITOR_DATA_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listExhibitorDataService } from "services/listExhibitorDataService";

export const listExhibitorDataAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: EXHIBITOR_DATA_LOADING, payload: true});
        const response = await listExhibitorDataService();
        dispatch({type: EXHIBITOR_DATA_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: EXHIBITOR_DATA_FAILURE, payload: err});
    } finally {
        dispatch({type: EXHIBITOR_DATA_LOADING, payload: false});
    }
}