import
{
    ALL_CHATS_LOADING,
    ALL_CHATS_SUCCESS,
    ALL_CHATS_FAILURE
} 
from "redux/types";

import { Dispatch } from "redux";

import { listAllChatsService } from "services/listAllChatsService";

export const listAllChatsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: ALL_CHATS_LOADING, payload: true});
        const response = await listAllChatsService();
        dispatch({type: ALL_CHATS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: ALL_CHATS_FAILURE, payload: err});
    } finally {
        dispatch({type: ALL_CHATS_LOADING, payload: false});
    }
}