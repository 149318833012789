import api from "api";
import { listMyStoriesEndPoint } from "api/apiUrls";
import store from "redux/store";

export const listMyStoriesService = async () => {
    const response = await api.get(
        listMyStoriesEndPoint(),
        {
            headers: {
                "Authorization" :  `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );
    
    return response;
}