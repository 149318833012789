import api from "api";
import { createPostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const creatPostService = async (data: any) => {
    const response = await api.post (
        createPostEndPoint(),
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}