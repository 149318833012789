import styles from "screens/V3/TermsOfUse/TermsOfUse.module.css";
import { CustomButton, Footer } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";

const TermsOfUse = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { isMobile } = useResponsive();

  return (
    <div className="w-full flex flex-col items-center">
      <div
        className={`mt-[2.96rem] ${
          isMobile ? "w-[95%]" : "w-[65%]"
        } flex flex-col items-center`}
      >
        <p className="text-[var(--new-blue)] text-[1.25rem] font-[700]">
          {t("privacy_policy")}
        </p>
        <div dir="rtl" className="w-full flex flex-col items-start gap-y-6">
          <p className="mt-[1rem] font-[700]">{t("about_souq_car")}</p>
          <p className="text-[var(--new-blue)] text-[0.9375rem]">
            {t("terms_of_use_q1")}
          </p>
          <p className="leading-8 text-[0.9375rem]">{t("terms_of_use_a1")}</p>
          <p className="text-[0.9375rem] text-[var(--new-blue)]">
            {t("terms_of_use_q2")}
          </p>
          <p className="leading-8 text-[0.9375rem]">{t("terms_of_use_a2")}</p>
          <p className="text-[0.9375rem] text-[var(--new-blue)]">
            {t("terms_of_use_q3")}
          </p>
          <p className="leading-8 text-[0.9375rem]">{t("terms_of_use_a3")}</p>
        </div>
        <div dir="rtl" className="w-full flex flex-col items-start gap-y-6">
          <p className="mt-[3.75rem] font-[700]">{t("about_buyer")}</p>
          <p className="text-[0.9375rem] text-[var(--new-blue)]">
            {t("terms_of_use_q4")}
          </p>
          <p className="leading-8 text-[0.9375rem]">{t("terms_of_use_a4")}</p>
        </div>
        <div
          dir="rtl"
          className={`mt-[2.87rem] mb-10 w-[20.75rem] h-[3.875rem] p-[0.6rem] rounded-[0.625rem] flex flex-row items-center justify-between gap-x-3 ${styles.shadow}`}
        >
          <div className="w-1/2 h-full flex items-center justify-center">
            <p>{t("didnt_find_your_question")}</p>
          </div>
          <CustomButton
            className={`w-1/2 h-full rounded-[0.3125rem] flex items-center justify-center`}
            onClick={() => {
              navigate(`/${currentLanguage}/contact-us`);
            }}
            color={"var(--new-blue)"}
          >
            <p className="text-white">{t("contact_us")}</p>
          </CustomButton>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
