import styles from "components/CustomCarDetailsCardRent/CustomCarDetailsCardRent.module.css";
import { FC } from "react";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";

interface CustomCarDetailsCardRentProps {
  data: any;
  isMobile: any;
}

const CustomCarDetailsCardRent: FC<CustomCarDetailsCardRentProps> = ({ data, isMobile }) => {

  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === "ar";

  const { t } = useTranslation();

  const isTwoRows = data.at(3).text && data.at(4).text && data.at(7).text;

  return (
    <div
      className={`w-full p-3 bg-white flex flex-col gap-y-3 rounded-xl ${styles.shadow}`}
      style={{
        direction: isArabic ? "ltr" : "rtl",
      }}
    >
      {!isMobile ? (
        <>
          <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-between rounded-xl`}
          >
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              <CustomIcon href={data.at(0).icon} width={28} height={28} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(1).text ? {color:"red"} : {}}>{data.at(1).text ? data.at(1).text : t("unspecified")}</p>
              <CustomIcon href={data.at(1).icon} width={45} height={45} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(2).text ? {color:"red"} : {}}>{data.at(2).text ? data.at(2).text : t("unspecified")}</p>
              <CustomIcon href={data.at(2).icon} width={45} height={45} width45/>
            </div>
          </div>
          <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-between rounded-xl`}
          >
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(6).text ? {color:"red"} : {}}>{data.at(6).text ? data.at(6).text : t("unspecified")}</p>
              <CustomIcon href={data.at(6).icon} width={34} height={33} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(8).text ? {color:"red"} : {}}>{data.at(8).text ? data.at(8).text : t("unspecified")}</p>
              <CustomIcon href={data.at(8).icon} width={40} height={17} width45/>
            </div>
            
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(5).text ? {color:"red"} : {}}>{data.at(5).text ? data.at(5).text : t("unspecified")}</p>
              <CustomIcon href={data.at(5).icon} width={33} height={30} width45/>
            </div>
          </div>
          <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-end rounded-xl`}
          >
            <div className={`${styles.featureContainer}`}>
              <p style={!data.at(11).text ? {color:"red"} : {}} className="truncate">{data.at(11).text ? data.at(11).text : t("unspecified")}</p>
              <CustomIcon href={data.at(11).icon} width={34} height={34} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p style={!data.at(9).text ? {color:"red"} : {}} className="truncate">{data.at(9).text ? data.at(9).text : t("unspecified")}</p>
              <CustomIcon href={data.at(9).icon} width={34} height={34} width45/>
            </div>
            <div className={`${styles.featureContainer}`}>
              <p style={!data.at(10).text ? {color:"red"} : {}} className="truncate">{data.at(10).text ? data.at(10).text : t("unspecified")}</p>
              <CustomIcon href={data.at(10).icon} width={34} height={34} width45/>
            </div>
            {/* <div className={`${styles.featureContainer}`}>
              <p className="truncate">{data.at(11).text}</p>
              <CustomIcon href={data.at(11).icon} width={40} height={38} width45/>
            </div> */}
          </div>
          {
            isTwoRows ? <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-between rounded-xl`}
          >
            {data.at(3).text ? <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(3).text ? {color:"red"} : {}}>{data.at(3).text ? data.at(3).text : t("unspecified")}</p>
              <CustomIcon href={data.at(3).icon} width={31} height={28} width45/>
            </div> : <></>}
            {data.at(4).text ? <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(4).text ? {color:"red"} : {}}>{data.at(4).text ? data.at(4).text : t("unspecified")}</p>
              <CustomIcon href={data.at(4).icon} width={40} height={41} width45/>
            </div> : <></>}
            {data.at(7).text ? <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(7).text ? {color:"red"} : {}}>{data.at(7).text ? data.at(7).text : t("unspecified")}</p>
              <CustomIcon href={data.at(7).icon} width={40} height={28} width45/>
            </div> : <></>}
            
          </div> 
          
          : 
          
          <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-end rounded-xl`}
          >
            {data.at(3).text ? <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(3).text ? {color:"red"} : {}}>{data.at(3).text ? data.at(3).text : t("unspecified")}</p>
              <CustomIcon href={data.at(3).icon} width={31} height={28} width45/>
            </div> : <></>}
            {data.at(4).text ? <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(4).text ? {color:"red"} : {}}>{data.at(4).text ? data.at(4).text : t("unspecified")}</p>
              <CustomIcon href={data.at(4).icon} width={40} height={41} width45/>
            </div> : <></>}
            {data.at(7).text ? <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(7).text ? {color:"red"} : {}}>{data.at(7).text ? data.at(7).text : t("unspecified")}</p>
              <CustomIcon href={data.at(7).icon} width={40} height={28} width45/>
            </div> : <></>}
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(12).text ? {color:"red"} : {}}>{data.at(12).text ? data.at(12).text : t("unspecified")}</p>
              <CustomIcon href={data.at(12).icon} width={24.26} height={28} width45/>
            </div>
          </div>
          }

          {
            isTwoRows ? 
            <div
            className={`w-full ${styles.blueBg} flex flex-row items-center justify-end rounded-xl`}
          >
            <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(12).text ? {color:"red"} : {}}>{data.at(12).text ? data.at(12).text : t("unspecified")}</p>
              <CustomIcon href={data.at(12).icon} width={24.26} height={28} width45/>
            </div>
          </div>
          : <></>
          }
          
        </>
      ) :
        // MOBILE VIEW
        (
          <>
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(0).icon} width={28} height={28} width45/>
                <p style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              </div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(1).icon} width={45} height={45} width45/>
                <p style={!data.at(1).text ? {color:"red"} : {}}>{data.at(1).text ? data.at(1).text : t("unspecified")}</p>
              </div>
            </div>
            
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(2).icon} width={45} height={45} width45/>
                <p style={!data.at(2).text ? {color:"red"} : {}}>{data.at(2).text ? data.at(2).text : t("unspecified")}</p>
              </div>
              
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(5).icon} width={33} height={30} width45/>
                <p style={!data.at(5).text ? {color:"red"} : {}}>{data.at(5).text ? data.at(5).text : t("unspecified")}</p>
              </div>
            </div>
            
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(6).icon} width={34} height={33} width45/>
                <p style={!data.at(6).text ? {color:"red"} : {}}>{data.at(6).text ? data.at(6).text : t("unspecified")}</p>
              </div>
              
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(9).icon} width={34} height={34} width45/>
                <p style={!data.at(9).text ? {color:"red"} : {}}>{data.at(9).text ? data.at(9).text : t("unspecified")}</p>
              </div>
            </div>
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-start rounded-xl p-2`}
            >
              
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(10).icon} width={34} height={34} width45/>
                <p style={!data.at(10).text ? {color:"red"} : {}}>{data.at(10).text ? data.at(10).text : t("unspecified")}</p>
              </div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(8).icon} width={40} height={17} width45/>
                <p style={!data.at(8).text ? {color:"red"} : {}}>{data.at(8).text ? data.at(8).text : t("unspecified")}</p>
              </div>
              {/* <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(11).icon} width={40} height={38} width45/>
                <p >{data.at(11).text}</p>
              </div> */}
            </div>
            
            <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-start rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>

              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(12).icon} width={24.26} height={28} width45/>
                <p className="truncate" style={!data.at(12).text ? {color:"red"} : {}}>{data.at(12).text ? data.at(12).text : t("unspecified")}</p>
              </div>
              
              {data.at(7).text ? <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(7).icon} width={40} height={28} width45/>
                <p style={!data.at(7).text ? {color:"red"} : {}}>{data.at(7).text ? data.at(7).text : t("unspecified")}</p>
              </div> : <></>}
              
            </div>

            {data.at(4).text || data.at(3).text ? <div
              className={`${styles.blueBg} flex flex-row${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(4).icon} width={40} height={41} width45/>
                <p style={!data.at(4).text ? {color:"red"} : {}}>{data.at(4).text ? data.at(4).text : t("unspecified")}</p>
              </div>
              <div className={`w-1/2 flex flex-row${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(3).icon} width={31} height={28} width45/>
                <p style={!data.at(3).text ? {color:"red"} : {}}>{data.at(3).text ? data.at(3).text : t("unspecified")}</p>
              </div>
            </div> : <></>}

            
          </>
        )}
    </div>
  );
};

export default CustomCarDetailsCardRent;
