export const BASE_PATH = "/";

export const HOME_PATH = "/:lang";

export const ERROR_PATH = "*";

export const LOGIN_PATH = "/:lang/login";

export const CREATE_POST_PATH = "/:lang/posts/create";

export const VIEW_POST_PATH = "/:lang/ads/show/:postUUID/:postTitle";

export const VIEW_PROFILE_PATH = "/:lang/profile/view";

export const FAQS_PATH = "/:lang/faq";

export const CONTACT_US_PATH = "/:lang/contact-us";

export const VIEW_POSTS_PATH = "/:lang/ads/view";

export const VIEW_CAR_NEWS = "/:lang/News/view";

export const VIEW_NEWS_ARTICLE = "/:lang/News/view/:id";

export const ABOUT_US_PATH = "/:lang/about-us";

export const PRIVACY_POLICY = "/:lang/privacy-policy";

export const TERMS_OF_USE = "/:lang/terms-of-use";

export const VENDOR_PATH = "/:lang/vendor/:id";

export const CHAT_SCREEN_PATH = "/:lang/messages";

export const UPDATE_POST_PATH = "/:lang/post/update";

export const SPECIAL_ADS_PATH = "/:lang/special-ads";

export const FAVORITE_POSTS_PATHS = "/:lang/favorite-posts";

export const INSURANCE_SCREEN_PATH = "/:lang/car-insurance";
export const NOTIFICATIONS_PATHS = "/:lang/notifications";

export const EXHIBITIONS_PATH = "/:lang/exhibitions";

export const SINGLE_STORY_PATH = "/:lang/stories/:uuid";
export const CREATE_STORY_PATH = "/:lang/create-story";
export const STORIES_PATH = "/:lang/stories";

export const STORE_PATH = "/:lang/store";
