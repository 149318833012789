import { useLanguage } from "hooks/useLanguage";
import { useUtilities } from "hooks/useUtilities";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getStoreItemsAction } from "redux/actions/GetStoreItemsAction/getStoreItemsAction";
import { RootState } from "redux/rootReducer";
import { STORE_CART } from "redux/types";

export const useStore = () => {
  const { dispatch } = useUtilities();
  const { isArabic } = useLanguage();
  const [details, setDetails] = useState<string[]>([]);
  const [currentProductID, setCurrentProductID] = useState<any>(null);

  const { storeCart, allProducts } = useSelector(
    (state: RootState) => state.storeReducer
  );

  const infoText =
    "خصومات تصل الي 60% علي الباقات عشان تقدر تنشر أعلاناتك بكل سهولة";

  /* These will be for when the products with no price require sending us a whatsapp message */
  /* The keys of this constant represent the ids of those products (store cards) */
  const contactMessages = {
    beExhibitor: "عايز ابقى معرض لو سمحت",
    adSpace: "عايز اعلان لو سمحت",
  };

  // الباقات
  const products = [
    {
      data_id: "adX1",
      name: "1 اعلان",
      price: 100,
      discount: 0.5,
      icon: "/icons/1-ad.svg",
      details: [
        "ميز اعلانك لمدة 10 أيام",
        "كن في صدارة نتائج البحث",
        "أحصل علي سمات التميز",
        "سعر النجمة 150ج فقط داخل الباقة",
      ],
    },
    {
      data_id: "specialX5",
      name: "باقة 5 نجوم",
      price: 1500,
      discount: 0,
      icon: "/icons/1-ad.svg",
      details: [
        "أستفد باعادة النشر 7 ايام اتوكاتيكيا",
        "أحصل علي سمات التميز",
        "سعر النجمة 150ج فقط داخل الباقة",
      ],
    },
    {
      data_id: "storiesX1",
      name: "1 استوري",
      price: 500,
      discount: 0.33,
      icon: "/icons/1-ad.svg",
      details: [
        "ميز اعلانك لمدة 10 أيام",
        "كن في صدارة نتائج البحث",
        "أستفد باعادة النشر 7 ايام اتوكاتيكيا",
        "أحصل علي سمات التميز",
        "سعر النجمة 150ج فقط داخل الباقة",
      ],
    },
    {
      data_id: "notificationsX1",
      name: "1 اشعار",
      price: 1000,
      discount: 0.17,
      icon: "/icons/1-ad.svg",
      details: [
        "كن في صدارة نتائج البحث",
        "أستفد باعادة النشر 7 ايام اتوكاتيكيا",
        "أحصل علي سمات التميز",
        "سعر النجمة 150ج فقط داخل الباقة",
      ],
    },
    {
      data_id: "joinExpo",
      name: "انشئ معرضك اونلاين",
      price: null, // No price means will show "تواصل معنا" instead of the price
      discount: 0,
      icon: "",
      details: [],
    },
  ];

  const [cartTotal, setCartTotal] = useState<any>();

  const formatPrice = (price: number) => {
    let formattedPrice;

    if (Number.isInteger(price)) {
      formattedPrice = price.toFixed(0);
    } else {
      formattedPrice = price.toFixed(2);
      if (formattedPrice.endsWith(".00")) {
        formattedPrice = price.toFixed(0);
      }
    }

    return formattedPrice;
  };

  function createWhatsAppLink(text: string, phoneNumber?: string) {
    const souqCarPhone = "2" + "01022229990";
    let encodedText = encodeURIComponent(text);
    let link =
      "https://wa.me/" + (phoneNumber ?? souqCarPhone) + "?text=" + encodedText;
    return link;
  }

  const handleCheckout = (storeCart: any) => {
    if (isArabic) {
      handleCheckoutArabic(storeCart);
    } else {
      handleCheckoutEnglish(storeCart);
    }
  };

  const handleCheckoutArabic = (storeCart: any) => {
    var message = "مرحباً، سوق.كار. \n\n";
    message += "*أود شراء المنتجات التالية:* \n";
    storeCart.forEach((item: any) => {
      const { product } = item;
      const formattedPrice = formatPrice(
        product.price * (1 - product.discount / 100) * item.quantity
      );
      message += `• `;
      message += `عدد x${item.quantity} من '*${product.name}*' `;
      if (product.discount > 0) {
        message += `بسعر ${product.price} جنيه `;
        message += `بخصم ${product.discount}% `;
      }
      message += `بإجمالي ${formattedPrice} جنيه \n\n`;
    });
    message += "================\n";
    message += "الإجمالي: " + cartTotal + " جنيه \n\n";

    console.log(message);
    const link = createWhatsAppLink(message);
    window.open(link, "_blank");
  };

  const handleCheckoutEnglish = (storeCart: any) => {
    var message = "Hello, Souq.Car \n\n";
    message += "*I would like to buy the following products:* \n";
    storeCart.forEach((item: any) => {
      const { product } = item;
      const formattedPrice = formatPrice(
        product.price * (1 - product.discount / 100) * item.quantity
      );
      message += `• `;
      message += `x${item.quantity} of '*${product.name}*' `;
      if (product.discount > 0) {
        message += `for ${product.price} EGP `;
        message += `with ${product.discount}% discount `;
      }
      message += `for a total of ${formattedPrice} EGP \n\n`;
    });
    message += "================\n";
    message += "Total: " + cartTotal + " EGP \n\n";

    console.log(message);
    const link = createWhatsAppLink(message);
    window.open(link, "_blank");
  };

  /* -updateCartTotal- is to recalculate the total when storeCart is updated */
  const updateCartTotal = () => {
    let total = 0;
    storeCart.forEach((item: any) => {
      total +=
        item.product.price * (1 - item.product.discount / 100) * item.quantity;
    });

    setCartTotal(formatPrice(total));
  };

  /*
  A selected product (store card) will be in its active-looking state and
  its details will be shown in the details container.

  -deselectProduct- will simply put the store card back to its default state and
  set the details to an empty array.
  */
  const deselectProduct = () => {
    setCurrentProductID(null);
    setDetails([]);
  };

  const resetCart = () => {
    dispatch({
      type: STORE_CART,
      payload: [],
    });
  };

  /* Called when the user clicks on the plus/minus buttons on a product's card */
  const updateInCart = (product: any, quantity: number) => {
    const cartItem = storeCart.find(
      (item: any) => item.product.data_id === product.data_id
    );
    if (cartItem) {
      if (quantity === 0) {
        dispatch({
          type: STORE_CART,
          payload: storeCart.filter(
            (item: any) => item.product.data_id !== product.data_id
          ),
        });
        return;
      }
      dispatch({
        type: STORE_CART,
        payload: storeCart.map((item: any) => {
          if (item.product.data_id === product.data_id) {
            return {
              ...item,
              quantity,
            };
          }
          return item;
        }),
      });
    } else if (quantity > 0) {
      dispatch({
        type: STORE_CART,
        payload: [
          ...storeCart,
          {
            quantity,
            product,
          },
        ],
      });
    }
  };

  /* Check whether this product (store card) has been selected to show its details */
  /* Also defines the onClick event of the StoreCard */
  const getActiveState = (product: any) => {
    const activeState: [isActive: boolean, setActiveProduct: any] = [
      currentProductID === product.data_id,
      (isCartAction?: boolean) => {
        /*
        Deselect the current selected product if the now-clicked-on product
        is actually the selected one -and- if the click is not coming form 
        the plus/minus buttons on this product's card (isCartAction = false)

        If the click is coming from the plus/minus buttons, then
        the product should not be deselected, since the user is just
        trying to update the quantity of this product in the cart.
        */
        if (!isCartAction && currentProductID === product.data_id) {
          deselectProduct();
          return;
        }

        if (isCartAction) updateCartTotal();

        /* Change the selected product to the clicked-on product */
        setCurrentProductID(product.data_id);
        setDetails(product.details);
      },
    ];

    return activeState;
  };

  /*
  The below function adds a dataset attribute to -all- elements within a specified parent container.

  This function is used to control click events, and check if it's coming from the containers
  having the class of the input 'parentClass'.

  In order to do this, a dataset attribute is added to this parent container and
  all the elements within it, such that any clicked element within the parent container
  will have the dataset attribute, set to the needed value, and so can be detected in the 
  click event.
  */
  const addDatasetAttributeToElements = (input: {
    parentClass: any;
    datasetName: any;
    datasetValue: any;
  }) => {
    const { parentClass, datasetName, datasetValue } = input;

    // Select all elements within the specified parent container
    const elements = document.querySelectorAll(
      `.${parentClass}, .${parentClass} *`
    );

    // Add the specified dataset attribute to each element if it doesn't already exist
    elements.forEach((element) => {
      const attributeName = `data-${datasetName}`;

      // Check if the dataset attribute already exists
      if (!element.hasAttribute(attributeName)) {
        element.setAttribute(attributeName, datasetValue);
      }
    });
  };

  useEffect(() => {
    dispatch(getStoreItemsAction());
  }, []);

  /*
  The purpose of this useEffect is to select the containers needed such that, when clicked on,
  the current selected product is -not- deselected.
  
  An example of such container is the details container. When clicked, it should not deselect
  the selected product showing those details.

  This is handled by checking the target of the click event of the wrapper container, seeing if
  it has the dataset attribute "store-selected-product" with the value "dontDeselectProduct".

  If it does, then the click event won't trigger the deselectProduct function.
  */
  useEffect(() => {
    addDatasetAttributeToElements({
      parentClass: "storeDontDeselect",
      datasetName: "store-selected-product",
      datasetValue: "dontDeselectProduct",
    });
  }, [currentProductID, details]);

  /*
  When an overflow happens in the cart container, I'm adding a padding here
  so that the scroll-bar does not stick to the cart items
  */
  const cartContainerRef = useRef(null);
  useEffect(() => {
    console.log("STORE CART AFTER CHANGE");
    console.log(storeCart);

    const cartContainer = cartContainerRef.current as HTMLElement | null;

    if (cartContainer) {
      if (cartContainer.scrollHeight > cartContainer.clientHeight) {
        if (isArabic) {
          cartContainer.classList.add("pl-2");
        } else {
          cartContainer.classList.add("pr-2");
        }
      } else {
        if (isArabic) {
          cartContainer.classList.remove("pl-2");
        } else {
          cartContainer.classList.remove("pr-2");
        }
      }
    }

    updateCartTotal();
  }, [storeCart]);

  return {
    // CONSTANTS
    cartContainerRef,
    allProducts,
    infoText,
    // STATES
    cartTotal,
    storeCart,

    currentProductID,
    setCurrentProductID,

    details,
    setDetails,
    // FUNCTIONS
    deselectProduct,
    updateInCart,
    resetCart,
    getActiveState,
    handleCheckout,
  };
};
