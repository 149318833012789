import {
  STORE_CART,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_FAILURE,
  GET_STORE_ITEMS_LOADING,
} from "redux/types";

const initialState = {
  allProducts: [],
  allProductsLoading: false,
  allProductsError: "",
  storeCart: [],
};

export const storeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STORE_ITEMS_SUCCESS:
      return { ...state, allProducts: action.payload.data };
    case GET_STORE_ITEMS_LOADING:
      return { ...state, allProductsLoading: action.payload };
    case GET_STORE_ITEMS_FAILURE:
      return { ...state, allProductsError: action.payload.message };
    case STORE_CART:
      return { ...state, storeCart: action.payload };
    default:
      return state;
  }
};
