import 
{
    CREATE_POST_LOADING,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAILURE,
    RESET_CREATE_POST
}
from "redux/types";

const initialState = {
    createPostLoading: false,
    newPost: null,
    error: null
}

export const createPostReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_POST_LOADING :
            return {...state, createPostLoading: action.payload};
        case CREATE_POST_SUCCESS :
            return {...state, newPost: action.payload.data};
        case CREATE_POST_FAILURE :
            return {...state, errors: action.payload};
        case RESET_CREATE_POST :
            return {...state, newPost: null};
        default:
            return state;
    }
}