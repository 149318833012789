import
{
    ALL_FEATURED_ADS_LOADING,
    ALL_FEATURED_ADS_SUCCESS,
    ALL_FEATURED_ADS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listAllFeaturedAdsService } from "services/listAllFeaturedAdsService";

export const listAllFeaturedAdsAction = (filters: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: ALL_FEATURED_ADS_LOADING, payload: true});
        const response = await listAllFeaturedAdsService(filters);
        dispatch({type: ALL_FEATURED_ADS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: ALL_FEATURED_ADS_FAILURE, payload: err});
    } finally {
        dispatch({type: ALL_FEATURED_ADS_LOADING, payload: false});
    }
}