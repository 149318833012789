import api from "api";
import { favouritePostEndPoint, unfavouritePostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const favouritePostService = async (postUUID: any, favourite: boolean) => {
    if (favourite === true) {
        const response = await api.post(
            favouritePostEndPoint(postUUID),
            {},
            {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
                }
            }
        );

        return response;
    } else {
        const response = await api.post(
            unfavouritePostEndPoint(postUUID),
            {},
            {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
                }
            }
        );

        return response;
    }
}