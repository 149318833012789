import api from "api";
import { listAllChatsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const listAllChatsService = async () => {
    const response = await api.get(
        listAllChatsEndPoint(),
        {
            headers: {
                'Authorization' : `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}