import styles from "components/V3/CustomUserInfoCard/UserInfoCard.module.css";
import {
  PhoneFilled,
  EyeFilled,
  HeartFilled,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { FC } from "react";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { whatsAppIcon } from "assets/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Modal, Tooltip, notification } from "antd";
import { WhatsAppIcon as WhatsappSVG } from "assets/icons/svg-components";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import ShareButton from "components/ShareButton/ShareButton";
import { favouritePostAction } from "redux/actions/FavouritePostActions/favouritePostAction";
import { useLanguage } from "hooks/useLanguage";
import { increaseWhatsappContactCount } from "services/OneTimeServices/increaseWhatsappContactCount";
import { useUtilities } from "hooks/useUtilities";

interface UserInfoCard {
  id: any;
  post?: any;
  callsCount: number;
  viewsCount: number;
  whatsappCount: number;
  totalPosts: number;
  customer: string;
  phoneNumber: string;
  whatsappNumber: string;
  handleShowPhoneClick: any;
  sendMessageOnClick: any;
  isMobile: boolean;
  isFavorited: boolean;
  customerId: any;
  customerImage: string;
}

const CustomUserInfoCard: FC<UserInfoCard> = ({
  callsCount,
  id,
  post,
  viewsCount,
  whatsappCount,
  totalPosts,
  customer,
  phoneNumber,
  isFavorited,
  customerId,
  whatsappNumber,
  handleShowPhoneClick,
  isMobile,
  customerImage,
  sendMessageOnClick
}) => {
  const { t } = useTranslation();
  const { textDirectionStyle } = useLanguage();
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { getPreFilledMessage } = useUtilities();

  const isArabic = currentLanguage === "ar";
  const dispatch: any = useDispatch();
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showWhatsAppNumber, setShowWhatsAppNumber] = useState(false);
  const [favoriteState, setFavoriteState] = useState(isFavorited);

  // check if whatsapp number starts with +20. if not add it
  if (whatsappNumber) {
    if (whatsappNumber.length === 10) {
      whatsappNumber = "+20" + whatsappNumber;
    } else if (whatsappNumber.length === 11 && whatsappNumber.startsWith("0")) {
      whatsappNumber = "+2" + whatsappNumber;
    }
  }

  const handleWhatsappClick = async () => {
    console.log(post?.uuid);
    await increaseWhatsappContactCount(post?.uuid);
  };

  const handleFavoriteClick = () => {
    setFavoriteState((prev) => !prev);

    dispatch(favouritePostAction(id, !favoriteState));
    // navigate(`/${currentLanguage}/ads/show/${id}`);
  };

  return (
    <div
      className={`${isMobile ? "w-full" : "w-[26.25rem]"} rounded-xl ${
        styles.contactCard
      } bg-white ${styles.shadow} flex flex-col`}
    >
      <div className={`w-full flex flex-row justify-start items-center`}>
        <div
          dir={isMobile ? "rtl" : "ltr"}
          className={`w-full flex flex-row ${
            isMobile ? "justify-start" : "justify-between"
          } items-center gap-x-4 mt-1`}
        >
          {!isMobile && (
            <div className="flex flex-row gap-x-1">
              <div
                className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full`}
              >
                <p className="text-xs">{whatsappCount}</p>
                <WhatsappSVG />
                <div className="w-[0.2rem] h-[0.2rem] rounded-[20rem] bg-black"></div>
                <p className="text-xs">{callsCount}</p>
                <PhoneFilled className="text-xs" />
              </div>
              <div
                className={`${styles.grayBackground} flex flex-row items-center gap-x-2 rounded-full`}
              >
                <p className="text-xs">{viewsCount}</p>
                <EyeFilled className="text-xs" />
              </div>
            </div>
          )}
          <p className={`${isMobile ? "text-[0.75rem]" : "text-[0.9375rem]"}`}>
            {t("post_owner_info")}
          </p>
        </div>
      </div>

      <div
        className={`mt-2 w-full flex flex-col ${
          currentLanguage === "ar" ? "items-end" : "items-start"
        } gap-y-3`}
      >
        <div
          className={`w-full flex ${
            currentLanguage === "ar" ? "flex-row" : "flex-row-reverse"
          } items-center gap-x-3`}
        >
          <div
            className={`w-full flex flex-col gap-y-1 ${
              currentLanguage === "ar" ? "items-end" : "items-start"
            }`}
          >
            <a
              href={`/${currentLanguage}/vendor/${customerId}?page=1`}
              className="text-lg"
            >
              {customer ?? t("unspecified")}
            </a>
            <div
              className={`flex ${
                currentLanguage === "ar" ? "flex-row" : "flex-row-reverse"
              } gap-x-2`}
              style={{
                direction: textDirectionStyle === "rtl" ? "rtl" : "ltr",
              }}
            >
              <p className="text-right">
                {totalPosts} {t("viewpost_user_ad_history_1")} |{" "}
                <a href={`/${currentLanguage}/vendor/${customerId}?page=1`}>
                  {t("viewpost_user_ad_history_2")}
                </a>
              </p>
            </div>
          </div>
          <div
            className={`${styles.blueBg}`}
            style={{
              backgroundImage: `url('${encodeURI(customerImage)}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>

      {!isMobile && (
        <div
          className={`w-full flex flex-row${
            isArabic ? "-reverse" : ""
          } flex-wrap gap-y-2 items-center mb-2 justify-center mt-6 md:mb-1`}
        >
          <div
            className={`flex flex-row${
              isArabic ? "-reverse" : ""
            } gap-y-2 items-center gap-x-2`}
          >
            <div
              className={`${styles.showPhoneButton} rounded-[0.3125rem] flex flex-row items-center justify-center gap-x-2`}
              onClick={() => {
                if (!showPhoneModal) {
                  handleShowPhoneClick();
                }
                setShowPhoneModal(true);
              }}
            >
              {/* phone link */}
              <p
                className={isMobile ? "text-[11px]" : ""}
                style={{ whiteSpace: "nowrap" }}
              >
                {t("call")}
              </p>
            </div>
            <div
              className={`${styles.showPhoneButton} rounded-[0.3125rem] flex flex-row items-center justify-center gap-x-2`}
              onClick={sendMessageOnClick}
            >
              <p>{t("أرسال رسالة")}</p>
            </div>
            <a
              href={`https://wa.me/${
                post?.customer_whats_app_no
                  ? post?.customer_whats_app_no
                  : post?.customer_phone
              }?text=${getPreFilledMessage(
                post?.brand,
                post?.car_model,
                post?.production_date,
                post?.city,
                encodeURIComponent(window.location.href)
              )}`}
            >
              <div
                className={`${styles.whatsappButton} rounded-[0.3125rem] flex flex-row items-center justify-center gap-x-2`}
                onClick={() => {}}
              >
                <p
                  className={`text-xs md:text-sm`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {showWhatsAppNumber ? whatsappNumber : t("viewpost_whatsapp")}
                </p>
              </div>
            </a>
          </div>
        </div>
      )}

      {/* Show Phone Modal */}
      <Modal
        open={showPhoneModal}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={() => {
          setShowPhoneModal(false);
        }}
      >
        <div className="w-full flex flex-col items-center gap-y-6">
          <p className="mt-[1.8rem] text-[var(--new-blue)] text-[1.25rem] font-[700]">
            {t("with_phone_number")}
          </p>
          <div
            className={`${styles.blueOpacityHalf} w-full h-[3.4375rem] flex items-center justify-center rounded-[0.625rem]`}
          >
            <p>{phoneNumber}</p>
          </div>
          <p>{t('bedan_word')}</p>
        </div>
      </Modal>
      {/* End Show Phone Modal */}
    </div>
  );
};

export { CustomUserInfoCard };
