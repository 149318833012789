import api from "api";
import { listVendorPostsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const listVendorPostsService = async (userId: any, page: any, filters?: any) => {
  const response = await api.get(listVendorPostsEndPoint(userId, page, filters), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        store.getState()?.verifyOtpReducer?.userData?.token
      }`,
    },
  });
  return response;
};
