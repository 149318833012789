import { ArrowLeftIcon } from "assets/icons/V3";
import styles from "components/V3/StoriesList/StoriesList.module.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { useResponsive } from "hooks/useResponsive";
import { Spin } from "antd";
import CircularExhibitorCard from "../CircularExhibitorCard/CircularExhibitorCard";
import CircularStoryCard from "../CircularStoryCard/CircularStoryCard";
import { useUtilities } from "hooks/useUtilities";

interface StoriesListProps {
  whiteBackground?: boolean;
  scaleDown?: boolean;
}

const StoriesList: FC<StoriesListProps> = ({ whiteBackground, scaleDown }) => {
  const { t, userData } = useUtilities();
  const { isMobile } = useResponsive();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const navigate = useNavigate();

  const { storiesLoading, stories } = useSelector(
    (state: RootState) => state.listStoriesReducer
  );

  return (
    <div
      className={`home-screen-stories mt-6 w-full flex flex-col items-center justify-around gap-x-3 rounded-[1.25rem] ${
        whiteBackground ? `bg-white p-4 ${styles.shadow}` : "h-[12.5625rem]"
      }`}
    >
      <div
        dir="rtl"
        className={`w-full flex flex-row items-center justify-between ${
          whiteBackground && "mb-4"
        }`}
      >
        <div className={`flex flex-row items-center gap-x-1`}>
          <p className="text-[1.125rem]">{t("story")}</p>
          <p
            className="bg-[var(--new-blue)] text-white w-[2.4375rem] h-[1.4375rem] rounded-[3.5rem] flex items-center justify-center"
            style={{ fontSize: "0.7rem" }}
          >
            beta
          </p>
        </div>
        <p
          className="hover:cursor-pointer"
          onClick={() => {
            navigate(`/${currentLanguage}/stories`);
          }}
        >
          {t("more")}
        </p>
      </div>
      <Spin spinning={storiesLoading} className="w-full">
        <div
          className={`w-full flex flex-row items-center justify-end ${
            scaleDown ? "gap-x-5" : "gap-x-[2.08rem]"
          }`}
        >
          {stories?.map((item: any) => {
            return (
              <CircularStoryCard
                id={item?.uuid}
                image={item?.personal_image}
                customerName={item?.name}
                isMobile={isMobile}
                scaleDown={scaleDown}
              />
            );
          })}
          <div
            className="flex flex-col items-center gap-y-3 hover:cursor-pointer"
            onClick={() => {
              if (userData?.token) navigate(`/${currentLanguage}/create-story`);
              else navigate(`/${currentLanguage}/login`);
            }}
          >
            <div
              style={{
                border: "dashed 0.5px black",
                padding: "0.2rem",
              }}
              className="rounded-full relative"
            >
              {userData?.token ? (
                <div
                  className={`${
                    scaleDown
                      ? "w-[4.875rem] h-[4.875rem]"
                      : "w-[5.875rem] h-[5.875rem]"
                  } rounded-full bg-[var(--new-blue)]`}
                  style={{
                    backgroundImage: `url('${userData?.user?.personal_image}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              ) : (
                <div
                  className={`${
                    scaleDown
                      ? "w-[4.875rem] h-[4.875rem]"
                      : "w-[5.875rem] h-[5.875rem]"
                  } rounded-full bg-[var(--new-blue)] flex items-center justify-center`}
                >
                  <UserOutlined className="text-[2.5rem] text-white" />
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  right: "2.5px",
                  bottom: "0",
                  fontSize: "1.5rem",
                }}
                className="bg-[#424242] rounded-full w-[2rem] h-[2rem] aspect-square flex items-center justify-center"
              >
                <p className="text-white">+</p>
              </div>
            </div>
            <p>{t("add")}</p>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default StoriesList;
