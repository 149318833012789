import
{
    MESSAGES_LOADING,
    MESSAGES_SUCCESS,
    MESSAGES_FAILURE
}
from "redux/types";

const initalState = {
    messagesLoading: false,
    messages: null,
    errors: null
}

export const listMessagesReducer = (state = initalState, action: any) => {
    switch (action.type) {
        case MESSAGES_LOADING :
            return {...state, messagesLoading: action.payload};
        case MESSAGES_SUCCESS :
            return {...state, messages: action.payload.messages};
        case MESSAGES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}