import
{
    FUEL_TYPES_LOADING,
    FUEL_TYPES_SUCCESS,
    FUEL_TYPES_FAILURE
}
from "redux/types";

const initialState = {
    fuelTypesLoading: false,
    fuelTypes: null,
    errors: null
}

export const listFuelTypesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FUEL_TYPES_LOADING :
            return {...state, loading: action.payload};
        case FUEL_TYPES_SUCCESS :
            return {...state, fuelTypes: action.payload.data};
        case FUEL_TYPES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}