import
{
    SORT_BY_FILTERS_LOADING,
    SORT_BY_FILTERS_SUCCESS,
    SORT_BY_FILTERS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSortByFiltersService } from "services/listSortByFiltersService";

export const listSortByFiltersAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: SORT_BY_FILTERS_LOADING, payload: true});
        const response = await listSortByFiltersService();
        dispatch({type: SORT_BY_FILTERS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: SORT_BY_FILTERS_FAILURE, payload: err});
    } finally {
        dispatch({type: SORT_BY_FILTERS_LOADING, payload: false});
    }
}