import api from "api";
import { updatePostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const updatePostService = async (postId: any, data: any) => {
    const response = await api.post(
        updatePostEndPoint(postId),
        data,
        {
            headers: {
                'Content-Type' : 'multipart/form-data',
                'Authorization' : `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    );
    return response;
}