import styles from "components/V3/CustomArticleCard/CustomArticleCard.module.css";
import { useTimeFormat } from "hooks/useTimeFormat";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";

interface CustomArticleCardProps {
  id: any;
  image: string;
  createdAt: string;
  title: string;
  description: string;
  isHomePageCard?: boolean;
  isMobile?: boolean;
}

const CustomArticleCard: FC<CustomArticleCardProps> = ({
  id,
  image,
  createdAt,
  title,
  description,
  isHomePageCard = true,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useTimeFormat();
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const navigate = useNavigate();

  return (
    <div
      dir="rtl"
      className={`${
        styles.shadow
      } rounded-[0.9375rem] pt-[0.4rem] pb-[1.23rem] md:pt-3 ${
        isMobile
          ? "w-full"
          : isHomePageCard
          ? "min-w-[14.875rem] min-h-[13.1875rem] md:min-h-[21.65144rem]"
          : "w-[14.875rem] h-[13.1875rem] md:w-[24.3rem] md:h-[22rem]"
      } bg-white flex flex-col items-center justify-between`}
      style={{ ...(isHomePageCard && { width: "100%" }) }}
      onClick={() => {
        navigate(`/${currentLanguage}/News/view/${id}`);
      }}
    >
      <div className="w-full flex flex-col items-center">
        <div
          className="w-[95%] h-[6.9375rem] md:h-[11.39rem] rounded-[0.625rem] flex flex-col items-start justify-end"
          style={{
            backgroundImage: `url(${encodeURI(image)})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="bg-white w-[4.25rem] h-[1.125rem] md:w-[7rem] md:h-[2rem] flex items-center justify-center rounded-full mx-2 my-2">
            <p dir="ltr" className="text-[0.625rem]">
              {formatDate(createdAt, currentLanguage, "d - M - yyyy")}
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col items-start px-3 py-3 gap-y-3">
          <p className="text-ellipsis text-[0.9375rem] line-clamp-1">{title}</p>
          <p
            className="home-screen-article-text text-ellipsis line-clamp-2 font-[rbFont]"
            style={{
              color: "rgba(0, 0, 0, 0.40)",
              fontFamily: "rbFont",
            }}
            dangerouslySetInnerHTML={{ __html: `${description}` }}
          ></p>
        </div>
      </div>
      <p
        className="text-[var(--new-blue)] hover:cursor-pointer"
        onClick={() => {}}
      >
        {t("read_more")}
      </p>
    </div>
  );
};

export default CustomArticleCard;
