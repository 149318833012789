import 
{
    WEEK_DAYS_LOADING,
    WEEK_DAYS_SUCCESS,
    WEEK_DAYS_FAILURE
}
from "redux/types";

const initialState = {
    weekDaysLoading: false,
    weekDays: null,
    errors: null
};

export const listWeekDaysReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case WEEK_DAYS_LOADING :
            return {...state, weekDaysLoading: action.payload};
        case WEEK_DAYS_SUCCESS :
            return {...state, weekDays: action.payload.data};
        case WEEK_DAYS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}