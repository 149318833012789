import
{
    UPDATE_SOCIAL_LINKS_LOADING,
    UPDATE_SOCIAL_LINKS_SUCCESS,
    UPDATE_SOCIAL_LINKS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateSocialLinksService } from "services/ExhibitorServices/updateSocialLinksService";

export const updateSocialLinksAction = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_SOCIAL_LINKS_LOADING, paylad: true});
        const response = await updateSocialLinksService(data);
        dispatch({type: UPDATE_SOCIAL_LINKS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: UPDATE_SOCIAL_LINKS_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_SOCIAL_LINKS_LOADING, payload: false});
    }
}