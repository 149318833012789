import { FC } from "react";

interface IconProps {
  className?: string;
  width?: number;
  height?: number;
  onClick?: (() => void) | undefined | any;
  onMouseEnter?: (() => void) | undefined | any;
  href: string;
  margin?: string;
  width45?: boolean;
  resize?: boolean;
}

export const CustomIcon: FC<IconProps> = ({
  className,
  height = 39,
  width = 39,
  onClick,
  href,
  onMouseEnter,
  margin,
  width45,
  resize
}) => {
  return (
    !width45 ? (
      <svg
        style={{ margin }}
        onClick={onClick}
        width={width}
        height={height}
        color="#f79e1b"
        className={`${className} flex flex-row items-center justify-end`}
        onMouseEnter={onMouseEnter}
      >
        <image style={!resize ? {} : {height: height}} href={href} />
      </svg>
    ) : (
      <div className="flex items-center justify-center" style={{ width: "45px" }}>
        <svg
          style={{ margin }}
          onClick={onClick}
          width={width}
          height={height}
          color="#f79e1b"
          className={`${className} flex flex-row items-center justify-end`}
          onMouseEnter={onMouseEnter}
        >
          <image href={href} style={{height:"100%"}}/>
        </svg>
      </div>
    )
  );
};
