import { Modal } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "components/V3/CartItem/CartItem.module.css";
import Stories from "react-insta-stories";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface CartItemProps {
  item: any;
}

const CartItem: FC<CartItemProps> = ({ item }) => {
  const product = item.product;
  const totalPrice =
    product.price * (1 - product.discount / 100) * item.quantity;
  let formattedPrice;

  if (Number.isInteger(totalPrice)) {
    formattedPrice = totalPrice.toFixed(0);
  } else {
    formattedPrice = totalPrice.toFixed(2);
    if (formattedPrice.endsWith(".00")) {
      formattedPrice = totalPrice.toFixed(0);
    }
  }
  return (
    <div key={product.id} className={`${styles.cartItem}`}>
      <div className="flex gap-3">
        <p className="text-[var(--new-blue)]">X{item.quantity}</p>
        <p>{product.name}</p>
      </div>
      <div
        className={`${
          product.discount && "w-[58.5%]"
        } flex items-center justify-between`}
      >
        {product.discount > 0 && (
          <div className="w-[65%] flex items-center justify-between">
            <p className="opacity-30 line-through">
              {product.price * item.quantity}ج
            </p>
            <p className="text-[var(--new-blue)]">خصم {product.discount}%</p>
          </div>
        )}
        <p>{formattedPrice}ج</p>
      </div>
    </div>
  );
};

export default CartItem;
