import
{
    INSURANCE_ENTRY_LOADING,
    INSURANCE_ENTRY_SUCCESS,
    INSURANCE_ENTRY_FAILURE
}
from "redux/types";

const initialState = {
    insuranceEntryLoading: false,
    insuranceEntry: null,
    errors: null
}

export const createInsuranceEntryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case INSURANCE_ENTRY_LOADING :
            return {...state, insuranceEntryLoading: action.payload};
        case INSURANCE_ENTRY_SUCCESS :
            return {...state, insuranceEntry: action.payload.data};
        case INSURANCE_ENTRY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}