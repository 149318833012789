export const listTablesDataEndPoint = (tableName: string) => {
  return `portal/${tableName}`;
};

export const listSinglePostEndPoint = (postId: string) => {
  return `portal/postShow/${postId}`;
};

export const increasePostCallsCountEndPoint = (postId: string) => {
  return `portal/postContactCountIncrease/${postId}`;
};

export const sendAMessageEndPoint = () => {
  return "portal/sendMessage";
};

export const searchFilterEndPoint = (searchFilters: string) => {
  return `portal/advanced-filter?${searchFilters}`;
};

export const carModelsEndPoint = (carMakerId: string) => {
  return `portal/getModels/${carMakerId}`;
};

export const featuredPostsEndPoint = (categoryId: string) => {
  return `portal/featuredAds?${categoryId}`;
};

export const loginWithMobileEndPoint = () => {
  return "portal/customer/login";
};

export const verifyOtpEndPoint = () => {
  return "portal/verify/otp";
};

export const createPostEndPoint = () => {
  return "portal/createPost";
};

export const getVendorDataEndPoint = (id: any) => {
  return `portal/getUserProfileData/${id}`;
};

export const listProfilePostsEndPoint = (
  postStatus: string,
  postCategoryId: any
) => {
  return `portal/profilePosts/${postStatus}/${postCategoryId}`;
};

export const updateCustomerDataEndPoint = () => {
  return `portal/updateUserData`;
};

export const listVendorPostsEndPoint = (
  userId: any,
  page: any,
  filters?: any
) => {
  return `portal/userProfilePosts/${userId}?${filters}&page=${page}`;
};

export const displayMobileEndPoint = (show: boolean) => {
  return show ? "portal/enableViewPhone" : "portal/disableViewPhone";
};

export const listAllChatsEndPoint = () => {
  return "portal/getAllChats";
};

export const listMessagesEndPoint = (userId: any) => {
  return `portal/getChatsWith/${userId}`;
};

export const sendChatMessageEndPoint = (userId: any) => {
  return `portal/sendMessageToCustomer/${userId}`;
};

export const suspendPostEndPoint = (postId: any) => {
  return `portal/suspendPost/${postId}`;
};

export const reactivatePostEndPoint = (postId: any) => {
  return `portal/reactivatePost/${postId}`;
};

export const viewArticleEndPoint = (articleId: any) => {
  return `portal/articlesShow/${articleId}`;
};

export const listRentalTypeEndPoint = () => {
  return "portal/rentalTypes";
};

export const listRentalDriverEndPoint = () => {
  return "portal/rentalDriver";
};

// MAINTENANCE - نوع الخدمة
export const listServiceTypesEndPoint = () => {
  return "portal/serviceTypes";
};

// SPARE PART - نوع المنتج
export const listSparePartTypeEndPoint = () => {
  return "portal/sparePartType";
};

export const listSparePartConditionEndPoint = () => {
  return "portal/sparePartCondition";
};

export const updatePostEndPoint = (postId: any) => {
  return `portal/updatePost/${postId}`;
};

export const getUserDataEndPoint = () => {
  return `portal/profile`;
};

export const listAllFeaturedAdsEndPoint = (filters: any) => {
  return `portal/allFeaturedAds?${filters}`;
};

export const listSortByFiltersEndPoint = () => {
  return "portal/listSortByFilters";
};

export const favouritePostEndPoint = (postUUID: any) => {
  return `portal/addToFavourites/${postUUID}`;
};

export const unfavouritePostEndPoint = (postUUID: any) => {
  return `portal/removeFromFavourites/${postUUID}`;
};

export const listFavouritePostsEndPoint = (page: any) => {
  return `portal/getFavourites?page=${page}`;
};

export const listNotificationsEndPoint = () => {
  return `portal/getNotifications`;
};

export const increaseWhatsappContactCountEndPoint = (uuid: any) => {
  return `portal/whatsappContactCountIncrease/${uuid}`;
};

export const createInsuranceEntry = () => {
  return "portal/addInsuranceEntry";
};

export const amanleekBrandsEndPoint = () => {
  return "/wp-json/v3/motor/brands";
};

export const amanleekCarModelsEndPoint = () => {
  return "/wp-json/v3/motor/models";
};

export const amanleekLeadInsertEndPoint = () => {
  return "/wp-json/v3/motor/lead/insert";
};
export const seeNotificationEndPoint = (notificationID: any) => {
  return `portal/changeNotificationSeen/${notificationID}`;
};

export const listWeekDaysEndPoint = () => {
  return "portal/weekDaysList";
};

export const listExhibitorServicesEndPoint = () => {
  return "portal/exhibitorServicesList";
};

export const listBranchTypesEndPoint = () => {
  return "portal/branchTypesList";
};

export const listExhibitorDataEndPoint = () => {
  return "portal/getExhibitorData";
};

export const updateExhibitorDataEndPoint = () => {
  return "portal/updateExhibitorData";
};

export const createStoryEndPoint = () => {
  return "portal/addStory";
};

export const getExhibitorStatisticsEndPoint = () => {
  return "portal/getExhibitorStatistics";
};

export const updateExhibitorSocialLinksEndPoint = () => {
  return "portal/updateExhibitorSocialLinks";
};

export const updateGeneralDataEndPoint = () => {
  return "portal/updateExhibitorGeneralData";
};

export const listMyStoriesEndPoint = () => {
  return "portal/getMyStories";
};

export const createBranchEndPoint = () => {
  return "portal/createBranch";
};

export const updateBranchEndPoint = (id: any) => {
  return `portal/updateBranchData/${id}`;
};

export const deleteCoverPhotoEndPoint = () => {
  return "portal/deleteCoverPhoto";
};

export const updateCoverPhotoEndPoint = () => {
  return "portal/updateCoverPhoto";
};

export const deleteMainPhotoEndPoint = () => {
  return "portal/deleteMainPhoto";
};

export const updateMainPhotoEndPoint = () => {
  return "portal/updateMainPhoto";
};

export const increaseStoryViewsEndPoint = (id: any) => {
  return `portal/increaseStoryViewCount/${id}`;
};

export const favouriteStoryEndPoint = (id: any) => {
  return `portal/favouriteStory/${id}`;
};

export const listFavouriteExhibitorsEndPoint = () => {
  return "portal/getFavouriteExhibitors";
};

export const makePostSpecialEndPoint = (postUUID: any) => {
  return `portal/makeSpecial/${postUUID}`;
};

export const deleteStoryEndPoint = (storyId: any) => {
  return `portal/deleteStory/${storyId}`;
};

export const repostStoryEndPoint = (storyId: any) => {
  return `portal/repostStory/${storyId}`;
};

export const getStoreItemsEndPoint = () => {
  return `portal/getStoreDetails`;
};

export const activateOutstandingPostEndPoint = (postUUID: any) => {
  return `portal/activateOutstandingPost/${postUUID}`;
}