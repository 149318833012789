import "components/Carousel/CarBodyCarousel/car-body-carousel.css";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";

interface CarBodyCarouselProps {
  data: any;
  isMobile?: boolean;
}

const CarBodyCarousel: FC<CarBodyCarouselProps> = ({
  data,
  isMobile = false,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const wrapper: any = document.querySelector(".body-wrapper");
    const carousel: any = document.getElementById("body-carousel");
    const firstCardWidth: any =
      carousel?.querySelector(".body-card")?.offsetWidth;
    const arrowBtns = document.querySelectorAll(".body-wrapper i");
    const carouselChildrens = [...carousel?.children];

    let isDragging = false,
      isAutoPlay = true,
      startX: any,
      startScrollLeft: any,
      timeoutId: any;

    // Get the number of cards that can fit in the carousel at once
    let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

    // // Insert copies of the last few cards to beginning of carousel for infinite scrolling
    // carouselChildrens.slice(-cardPerView).reverse().forEach((card: any) => {
    //     carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
    // });

    // // Insert copies of the first few cards to end of carousel for infinite scrolling
    // carouselChildrens.slice(0, cardPerView).forEach((card: any) => {
    //     carousel.insertAdjacentHTML("beforeend", card.outerHTML);
    // });

    // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
    carousel.classList.add("no-transition");
    carousel.scrollLeft = carousel.offsetWidth;
    carousel.classList.remove("no-transition");

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft +=
          btn.id == "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e: any) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e: any) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const infiniteScroll = () => {
      // If the carousel is at the beginning, scroll to the end
      if (carousel.scrollLeft === 0) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }
      // If the carousel is at the end, scroll to the beginning
      else if (
        Math.ceil(carousel.scrollLeft) ===
        carousel.scrollWidth - carousel.offsetWidth
      ) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }

      // Clear existing timeout & start autoplay if mouse is not hovering over carousel
      // clearTimeout(timeoutId);
      // if (!wrapper.matches(":hover")) autoPlay();
    };

    const autoPlay = () => {
      // if(window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
      // Autoplay the carousel after every 2500 ms
      timeoutId = setTimeout(
        () => (carousel.scrollLeft += firstCardWidth),
        2500
      );
    };
    // autoPlay();

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    // carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
    // wrapper.addEventListener("mouseleave", autoPlay);
  }, []);

  return (
    <div
      className={`body-wrapper ${isMobile}`}
      style={{ marginBottom: `${isMobile ? "0rem" : "4rem"}` }}
    >
      <i id="left" className="fa-solid fa-angle-left"></i>
      <ul id="body-carousel" className="body-carousel py-3">
        {data?.map((item: any, idx: any) => {
          return (
            <li
              key={item?.name}
              id="body-card"
              className="body-card"
              onClick={() => {
                navigate({
                  pathname: "ads/view",
                  search: `?category_id=1&car_type=${item?.key}`,
                });
              }}
            >
              <div className={`img`} style={{ height: "75%" }}>
                <img
                  src={item?.image}
                  className="imageStyling"
                  alt="img"
                  draggable="false"
                />
              </div>
              <p className="text-center whitespace-nowrap">{item?.name}</p>
            </li>
          );
        })}
      </ul>
      <i id="right" className="fa-solid fa-angle-right"></i>
    </div>
  );
};

export default CarBodyCarousel;
