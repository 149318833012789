import styles from "components/CustomCarDetailsCardMaintenance/CustomCarDetailsCardMaintenance.module.css";
import { FC } from "react";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useTranslation } from "react-i18next";

interface CustomCarDetailsCardMaintenanceProps {
  data: any;
  isMobile: any;
}

const CustomCarDetailsCardMaintenance: FC<CustomCarDetailsCardMaintenanceProps> = ({ data, isMobile }) => {

  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === "ar";

  const { t } = useTranslation();

  return (
    <div
      className={`w-full p-3 bg-white flex flex-col gap-y-3 rounded-xl ${styles.shadow}`}
      style={{
        direction: isArabic ? "ltr" : "rtl",
      }}
    >
      {!isMobile ? (
        <>
          <div
            className={`w-full ${styles.blueBg} flex flex-col items-center justify-between rounded-xl`}
          >
            <div className={`w-full ${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              <CustomIcon href={data.at(0).icon} width={45} height={45} width45/>
            </div>
            <div className="w-full flex items-start justify-end">
              <div className="flex flex-wrap justify-end" style={{marginInlineEnd:".8rem"}}>
                {
                  data.at(1).text.map((item: any, index: number) => {
                    return (
                      <div key={index} className={`flex items-center truncate ${styles.carBrand}`} style={!item ? {color:"red"} : {}}>
                        <p style={{userSelect:"none"}}>
                          {item.name ? item.name : t("unspecified")}
                        </p>
                        <div className={`${styles.carBrandLogo}`} style={{backgroundImage: `url('${encodeURI(item.image)}')`, backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat"}}></div>
                      </div>
                    )
                  })
                }
              </div>
                <CustomIcon href={data.at(1).icon} width={45} height={45} width45/>
            </div>
            {/* <div className={`${styles.featureContainer}`}>
              <p className="truncate" style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              <CustomIcon href={data.at(0).icon} width={28} height={28} width45/>
            </div> */}
          </div>
        </>
      ) :
        // MOBILE VIEW
        (
          <>
            <div
              className={`${styles.blueBg} flex flex-col ${isArabic ? "-reverse" : ""} items-center justify-around rounded-xl p-2`}
            >
              <div style={{ width: ".7rem" }}></div>

              <div className={`w-full flex flex-row-reverse ${isArabic ? "-reverse" : ""} items-center gap-x-2`}>
                <CustomIcon href={data.at(0).icon} width={28} height={28} width45/>
                <p style={!data.at(0).text ? {color:"red"} : {}}>{data.at(0).text ? data.at(0).text : t("unspecified")}</p>
              </div>

              <div className="w-full flex items-start justify-end">
                <div className="flex flex-wrap justify-end" style={{marginInlineEnd:".8rem"}}>
                  {
                    data.at(1).text.map((item: any, index: number) => {
                      return (
                        <div key={index} className={`flex items-center truncate ${styles.carBrand}`} style={!item ? {color:"red"} : {}}>
                          <p style={{userSelect:"none"}}>
                            {item.name ? item.name : t("unspecified")}
                          </p>
                          <div className={`${styles.carBrandLogo}`} style={{backgroundImage: `url('${encodeURI(item.image)}')`, backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat"}}></div>
                        </div>
                      )
                    })
                  }
                </div>
                <CustomIcon href={data.at(1).icon} width={45} height={45} width45/>
            </div>
            </div>
            
          </>
        )}
    </div>
  );
};

export default CustomCarDetailsCardMaintenance;
