import api from "api";
import { sendAMessageEndPoint } from "api/apiUrls";

export const sendAMessageService = async (post_title: string, email: string, message: string) => {
    const response = await api.post(
        sendAMessageEndPoint(), 
        {
            post_title: post_title,
            email: email,
            message: message,
        }, 
        {
            headers: {'Content-Type': 'application/json'}
        }
    );
    return response;
}