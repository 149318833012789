import { useState, useEffect } from "react";
import styles from "screens/V3/FavouritePostsScreen/FavouritePostsScreen.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Empty, Spin } from "antd";
import { useParams } from "react-router-dom";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "components/Pagination/Pagination";
import { listFavouritesAction } from "redux/actions/FavouritePostActions/listFavouritesAction";
import { useIsDefined } from "hooks/useIsDefined";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";
import { useSearch } from "hooks/useSearch";
import NewCustomPostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import { listFavouriteExhibitorsAction } from "redux/actions/listFavouriteExhibitorsAction/listFavouriteExhibitorsAction";
import SquareExhibitorCard from "components/V3/SquareExhibitorCard/SquareExhibitorCard";

const FavouritePostsScreen = () => {
  const { t } = useTranslation();

  const { isDefined } = useIsDefined();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { getSearchParam } = useSearch();

  const { favouritePostsLoading, favouritePosts, currentPage, lastPage } =
    useSelector((state: RootState) => state.listFavouritesReducer);

  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";

  const [isMobile, setIsMobile] = useState(false);
  const [isMax1280, setIsMax1280] = useState(false);

  const dispatch: any = useDispatch();
  const { id } = useParams();

  const [selectedCategory, setSelectedCategory] = useState(0);

  const navigate = useNavigate();

  const { verifyOtpLoading, userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );
  const { favouriteExhibitors, favouriteExhibitorsLoading } = useSelector(
    (state: RootState) => state.listFavouriteExhibitorsReducer
  );

  useEffect(() => {
    if (!userData?.token) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    dispatch(listFavouritesAction(1));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
      window.innerWidth < 1280 ? setIsMax1280(true) : setIsMax1280(false);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    if (window.innerWidth < 1280) {
      setIsMax1280(true);
    }
  }, []);

  return (
    <div
      className="w-full flex flex-col items-center"
      style={{
        textAlign: isArabic ? "right" : "left",
        backgroundColor: "var(--new-souq-background)",
      }}
    >
      <div className="w-full flex flex-col items-center gap-y-6">
        {!isMobile && (
          <h1
            className={`mt-[2.94rem] text-[var(--new-blue)] text-[1.25rem] font-[700]`}
          >
            {t("favorited_ads")}
          </h1>
        )}

        <div
          dir="rtl"
          className={`${
            isMobile
              ? "mt-[2.94rem] w-[95%] h-[3.125rem]"
              : "w-[75rem] h-[3.375rem]"
          } rounded-[0.9375rem] bg-white flex flex-row items-center justify-between ${
            styles.shadow
          }`}
        >
          <div
            className={`${
              selectedCategory === 0 ? styles.blueBg : ""
            } flex items-center justify-center w-[50%] h-full rounded-[0.9375rem] hover:cursor-pointer`}
            onClick={() => {
              if (selectedCategory === 0) return;
              setSelectedCategory(0);
            }}
          >
            <p>{t("favorited_ads")}</p>
          </div>
          <div
            className={`${
              selectedCategory === 1 ? styles.blueBg : ""
            } flex items-center justify-center w-[50%] h-full rounded-[0.9375rem] hover:cursor-pointer`}
            onClick={() => {
              if (selectedCategory === 1) return;
              setSelectedCategory(1);
              dispatch(listFavouriteExhibitorsAction());
            }}
          >
            <p>{t("favourited_exhibitor")}</p>
          </div>
        </div>

        {isMobile ? (
          <div className="favourite-posts-spin-container w-full flex flex-col items-center">
            {selectedCategory == 0 ? (
              <Spin
                spinning={favouritePostsLoading}
                className="w-full"
                style={{ width: "100%" }}
              >
                <div className="w-[95%] flex flex-col items-center justify-center gap-y-2">
                  {favouritePosts?.map((item: any, idx: any) => {
                    return (
                      <NewCustomPostCard
                        key={idx}
                        id={item.uuid}
                        isSpecialAd={false}
                        showActions={false}
                        width={"w-full"}
                        isMobile={isMobile}
                        category={item.category}
                        customerName={item.customer}
                        brandLogo={item.brand_logo}
                        isFavorited={item.favourited}
                        // className={`${styles.widePostCard}`}
                        callsCount={item.contact_count}
                        viewsCount={item.views_count}
                        uploadDate={item.created_at?.split("T")[0]}
                        postTitle={item.title}
                        city={item.city}
                        price={
                          isDefined(item.down_payment)
                            ? item.down_payment
                            : item.price
                        }
                        carCondition={item.car_condition}
                        productionDate={item.production_date}
                        postDescription={item.description}
                        kilometrage={item.kilo_meterage}
                        carMaker={item.brand}
                        image={item.main_image}
                        paymentOption={item.payment_type}
                        created_at={item.created_at?.split("T")[0]}
                        address={item.address}
                        favouriteCallback={() => {
                          dispatch(
                            listFavouritesAction(getSearchParam("page") ?? 1)
                          );
                        }}
                      />
                    );
                  })}
                </div>
              </Spin>
            ) : (
              <p>Ma3aared</p>
            )}
          </div>
        ) : selectedCategory == 0 ? (
          <Spin
            spinning={favouritePostsLoading}
            className="w-full"
            style={{ width: "100%" }}
          >
            <div
              dir="rtl"
              className="w-[75rem] flex flex-row items-center justify-start flex-wrap gap-y-3 gap-x-3"
            >
              {favouritePosts?.map((item: any, idx: any) => {
                return (
                  <NewWidePostCard
                    key={idx}
                    isMobile={isMobile}
                    id={item.uuid}
                    isSpecialAd={true}
                    showActions={false}
                    width={"w-[24.2675rem]"}
                    imageWidth={"w-[23rem]"}
                    category={item.category}
                    customerName={item.customer}
                    brandLogo={item.brand_logo}
                    isFavorited={item.favourited}
                    // className={`${styles.widePostCard}`}
                    callsCount={item.contact_count}
                    viewsCount={item.views_count}
                    uploadDate={item.created_at?.split("T")[0]}
                    postTitle={item.title}
                    city={item.city}
                    price={
                      isDefined(item.down_payment)
                        ? item.down_payment
                        : item.price
                    }
                    carCondition={item.car_condition}
                    productionDate={item.production_date}
                    postDescription={item.description}
                    kilometrage={item.kilo_meterage}
                    carMaker={item.brand}
                    image={item.main_image}
                    paymentOption={item.payment_type}
                    created_at={item.created_at.split("T")[0]}
                    address={item.address}
                    favouriteCallback={() => {
                      dispatch(
                        listFavouritesAction(getSearchParam("page") ?? 1)
                      );
                    }}
                  />
                );
              })}
            </div>
          </Spin>
        ) : (
          <Spin
            spinning={favouriteExhibitorsLoading}
            className="w-full"
            style={{ width: "100%" }}
          >
            <div
              dir="rtl"
              className="w-[75rem] flex flex-row items-center justify-start flex-wrap gap-y-3 gap-x-3"
            >
              {favouriteExhibitors?.map((item: any, idx: any) => {
                return (
                  <SquareExhibitorCard
                    id={item.id}
                    image={item.main_image}
                    name={item.customer_name}
                    description={item.description}
                    isSpecial={true}
                    width={"w-[32%]"}
                    isMobile={isMobile}
                  />
                );
              })}
            </div>
          </Spin>
        )}

        {/* PAGINATION */}
        <Pagination
          current_page={Number(currentPage)}
          last_page={lastPage}
          loading={favouritePostsLoading}
          path={`favorite-posts`}
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default FavouritePostsScreen;
