import
{
    BASIC_SEARCH_LOADING,
    BASIC_SEARCH_SUCCESS,
    BASIC_SEARCH_FAILURE
}
from "redux/types";

import { searchFilterService } from "services/searchFilterService";

import { Dispatch } from "redux";

export const basicSearchFilterAction = (searchFilters: string) => async (dispatch: Dispatch) => {
    
    try {

        dispatch({type: BASIC_SEARCH_LOADING, payload: true});
        
        const response = await searchFilterService(searchFilters);
        
        dispatch({type: BASIC_SEARCH_SUCCESS, payload: response.data});
    } catch (err) {
        
        dispatch({type: BASIC_SEARCH_FAILURE, payload: err});
        
    } finally {
        
        dispatch({type: BASIC_SEARCH_LOADING, payload: false});
        
    }
}