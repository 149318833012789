import
{
    CREATE_BRANCH_LOADING,
    CREATE_BRANCH_SUCCESS,
    CREATE_BRANCH_FAILURE
}
from "redux/types";

const initialState = {
    createBranchLoading: false,
    newBranch: null,
    errors: null
}

export const createBranchReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_BRANCH_LOADING :
            return {...state, createBranchLoading: action.payload};
        case CREATE_BRANCH_SUCCESS :
            return {...state, newBranch: action.payload.data};
        case CREATE_BRANCH_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}