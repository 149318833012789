import styles from "components/V3/CustomCarInfoCard/CustomCarInfoCard.module.css";
import { FC, useEffect } from "react";
import { ClockCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface CarInfoCardProps {
  cash: string;
  title: string;
  installmentPrice: string;
  installments: string;
  created_at: string;
  postId: string;
  condition: string;
  paymentType: string;
  isMobile: boolean;
  brandName: string;
  downPayment: any;
}

const CustomCarInfoCard: FC<CarInfoCardProps> = ({
  cash,
  title,
  installmentPrice,
  installments,
  created_at,
  downPayment,
  postId,
  condition,
  paymentType,
  isMobile,
  brandName,
}) => {
  const { t } = useTranslation();

  const isCash = (paymentType: string) => {
    return (
      paymentType !== null && (paymentType === "Cash" || paymentType === "كاش")
    );
  };

  return (
    <div
      dir="rtl"
      className={`${isMobile ? "w-full" : `w-[47.375rem]`} ${
        isMobile ? "h-[8.125rem]" : "h-[10.6875rem]"
      } ${
        styles.shadow
      } rounded-[0.625rem] flex flex-col items-start justify-between px-3 py-3`}
    >
      <p
        className={`${
          isMobile ? "text-[0.8125rem] font-bold" : "text-[1.25rem]"
        } line-clamp-1`}
      >
        {title}
      </p>
      <div className="flex flex-row items-center gap-x-3">
        <ClockCircleFilled />
        <p className={`${isMobile ? "text-[0.75rem]" : "text-[0.9375rem]"}`}>
          {created_at}
        </p>
        <div
          className={`flex items-center justify-center h-[1.6875rem] px-4 border-[0.5px] border-[var(--new-blue)] rounded-[1.125rem]`}
        >
          <p className={`${isMobile ? "text-[0.75rem]" : "text-[0.8125rem]"}`}>
            {condition}
          </p>
        </div>
        {!isCash(paymentType) && (
          <div
            className={`flex items-center justify-center h-[1.6875rem] px-4 border-[0.5px] border-[var(--souq-orange)] rounded-[1.125rem]`}
          >
            <p
              className={`${isMobile ? "text-[0.75rem]" : "text-[0.8125rem]"}`}
            >
              {paymentType}
            </p>
          </div>
        )}
      </div>
      <div className="w-full flex flex-row items-center justify-between gap-x-2">
        <div
          dir="rtl"
          className={`${
            isMobile
              ? !isCash(paymentType)
                ? "w-[50%]"
                : "w-full"
              : isCash(paymentType) || !downPayment
              ? "w-full"
              : "w-[60%]"
          } ${isMobile ? "h-[2.5rem]" : "h-[3.0625rem]"} ${
            styles.blueBackground
          } rounded-[0.3125rem] flex flex-row items-center justify-center gap-x-3`}
        >
          <p className={`${isMobile ? "text-[0.625rem]" : "text-[0.9375rem]"}`}>
            {t("total")}
          </p>
          <p
            className={`${
              isMobile ? "text-[0.9375rem]" : "text-[1.25rem]"
            } text-[var(--new-blue)]`}
          >
            {cash?.toLocaleString()}
          </p>
          <p className={isMobile ? "text-[0.625rem]" : "text-[0.9375rem]"}>
            {t("egp")}
          </p>
        </div>
        {!isCash(paymentType) && downPayment && (
          <div
            dir="rtl"
            className={`${isMobile ? "w-[50%]" : "w-[40%]"} ${
              isMobile ? "h-[2.5rem]" : "h-[3.0625rem]"
            } ${
              styles.orangeBackground
            } rounded-[0.3125rem] flex flex-row items-center justify-center gap-x-3`}
          >
            <p
              className={`${isMobile ? "text-[0.625rem]" : "text-[0.9375rem]"}`}
            >
              {t("upfront")}
            </p>
            <p
              className={`${
                isMobile ? "text-[0.9375rem]" : "text-[1.25rem]"
              } text-[var(--souq-orange)]`}
            >
              {downPayment?.toLocaleString()}
            </p>
            <p className={isMobile ? "text-[0.625rem]" : "text-[0.9375rem]"}>
              {t("egp")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomCarInfoCard;
