import
{
    VENDOR_DATA_LOADING,
    VENDOR_DATA_SUCCESS,
    VENDOR_DATA_FAILURE
}
from "redux/types";

const initialState = {
    vendorDataLoading: false,
    vendorData: null,
    isExhibitor: null,
    totalViews: null,
    totalCalls: null,
    errors: null
};

export const getVendorDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case VENDOR_DATA_LOADING :
            return {...state, vendorDataLoading: action.payload};
        case VENDOR_DATA_SUCCESS :
            return {...state, vendorData: action.payload.data, isExhibitor: action.payload.is_exhibitor, totalViews: action.payload.data.total_views, totalCalls: action.payload.data.total_calls};
        case VENDOR_DATA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}