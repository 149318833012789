import { StarIcon } from "assets/icons/V3";
import { tachometer } from "assets/icons";
import { ClockCircleFilled } from "@ant-design/icons";
import styles from "components/V3/NewCustomPostCard/NewCustomPostCard.module.css";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { souqCarWhite } from "assets/icons";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import NewCustomPostCard from "./NewCustomPostCard";
import { useResponsive } from "hooks/useResponsive";
import { useUtilities } from "hooks/useUtilities";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DeletePostModal from "components/ModalComponents/DeletePostModal/DeletePostModal";
import { Star } from "assets/icons/V3";
import { Spin } from "antd";
import { makePostSpecialAction } from "redux/actions/MakePostSpecialAction/makePostSpecialAction";
import { activateOutstandingPostAction } from "redux/actions/ActivateOutstandingPostAction/activateOutstandingPostAction";

interface PostCardProps {
  item: any;
  idx: any;
  handlePostEditClick?: any;
  handleSuspendPostClick?: any;
  handleReactivatePostClick?: any;
  isDeleted?: any;
  showActions?: any;
  showPayButton: boolean;
}

const NewCustomPostCardWithEdit: FC<PostCardProps> = ({
  item,
  idx,
  handlePostEditClick,
  handleSuspendPostClick,
  handleReactivatePostClick,
  isDeleted = false,
  showActions = true,
  showPayButton,
}) => {
  const { isMobile } = useResponsive();

  const dispatch: any = useDispatch();

  const { isDefined } = useUtilities();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { makeSpecialLoading } = useSelector(
    (state: RootState) => state.makePostSpecialReducer
  );

  const { activatePostLoading } = useSelector(
    (state: RootState) => state.activateOutstandingPostReducer
  );

  const isCash = (paymentType: string | undefined) => {
    return paymentType === "cash" || paymentType === "كاش";
  };

  const [open, setOpen] = useState(false);
  const [activateHover, setActivateHover] = useState(false);

  return (
    <div
      className={`flex ${isMobile && "flex-col"} items-center gap-x-4 gap-y-2`}
      style={{
        overflow: "visible",
        width: !isMobile ? "calc(108% + 1rem)" : undefined,
      }}
    >
      {/* POST CARD */}
      <div className={`w-[100%]`}>
        <NewCustomPostCard
          key={idx}
          isMobile={isMobile}
          id={item.uuid}
          isSpecialAd={item.isSpecial}
          showActions={false}
          width={"w-full"}
          category={item.category}
          customerName={item.customer}
          brandLogo={item.brand_logo}
          isFavorited={item.favourited}
          // className={`${styles.widePostCard}`}
          callsCount={item.contact_count}
          viewsCount={item.views_count}
          uploadDate={item.created_at?.split("T")[0]}
          postTitle={item.title}
          city={item.city}
          price={isDefined(item.down_payment) ? item.down_payment : item.price}
          carCondition={item.car_condition}
          productionDate={item.production_date}
          postDescription={item.description}
          kilometrage={item.kilo_meterage}
          carMaker={item.brand}
          image={item.main_image}
          paymentOption={item.payment_type}
          created_at={item.created_at}
          address={item.address}
          suspendClick={() => handleSuspendPostClick(item?.uuid)}
          reactivateClick={() => handleReactivatePostClick(item?.id)}
          handlePostEditClick={() =>
            handlePostEditClick(item.uuid, item.lat, item.long)
          }
        />
      </div>

      {/* EDIT OPTIONS */}
      <div
        className={`archive-story-spin-container ${isMobile ? "w-full" : ""}`}
      >
        <Spin spinning={makeSpecialLoading || activatePostLoading}>
          <div
            className={`${isMobile ? "w-full" : "w-[5rem]"} flex flex-${
              isMobile ? "col" : "row"
            } items-center gap-x-3 gap-y-3`}
          >
            <div
              className={`${isMobile ? "w-full mt-1" : "h-[200px]"} flex ${
                !isMobile && "flex-col"
              } items-start justify-center gap-y-3 gap-x-2`}
            >
              <button
                disabled={item.isSpecial}
                className={`${styles.shadow} ${
                  styles.optionsButton
                }  bg-white flex ${
                  !isMobile ? "flex-col p-3 rounded-lg" : "p-2 w-1/3"
                } items-center justify-cente gap-x-1`}
                onClick={async () => {
                  await dispatch(makePostSpecialAction(item.uuid));
                  window.location.reload();
                }}
              >
                <div className={`${styles.optionsIcon}`}>
                  <img
                    alt="star"
                    src={Star}
                    className={`h-[1rem] ${item.isSpecial && styles.grayOut}`}
                  />
                </div>
                <p
                  className={`${styles.optionsText} ${
                    item.isSpecial && styles.grayOut
                  }`}
                >
                  {t("make_special")}
                </p>
              </button>
              <button
                className={`${styles.shadow} ${
                  styles.optionsButton
                }  bg-white rounded-lg flex ${
                  !isMobile ? "flex-col p-3 rounded-lg" : "p-2 w-1/3"
                } items-center justify-cente gap-x-1`}
                onClick={() => {
                  handlePostEditClick();
                }}
              >
                <div className={`${styles.optionsIcon}`}>
                  <EditOutlined className="text-lg text-blue-400" />
                </div>
                <p className={`${styles.optionsText}`}>{t("edit")}</p>
              </button>
              {!isDeleted ? (
                <button
                  className={`${styles.shadow} ${
                    styles.optionsButton
                  } bg-whiterounded-lg flex ${
                    !isMobile ? "flex-col p-3 rounded-lg" : "p-2 w-1/3"
                  } items-center justify-center gap-x-1`}
                  onClick={() => setOpen(true)}
                >
                  <div className={`${styles.optionsIcon}`}>
                    <DeleteOutlined className="text-lg text-red-400" />
                  </div>
                  <DeletePostModal
                    open={open}
                    setOpen={setOpen}
                    suspendClick={() => handleSuspendPostClick()}
                  />
                  <p className={`${styles.optionsText}`}>{t("delete")}</p>
                </button>
              ) : (
                <div
                  className={`${styles.shadow} ${styles.optionsButton} ${
                    styles.optionsButtonReactivate
                  } rounded-lg flex ${
                    !isMobile ? "flex-col p-3 rounded-lg" : "p-2 w-1/3"
                  } items-center justify-center gap-x-1`}
                  onMouseEnter={() => setActivateHover(true)}
                  onClick={() => handleReactivatePostClick()}
                >
                  <span
                    onMouseLeave={() => setActivateHover(false)}
                    className={`material-symbols-outlined ${styles.neon} ${styles.icon}`}
                  >
                    redo
                  </span>

                  <p className={`${styles.optionsText}`}>{t("activate")}</p>
                </div>
              )}
            </div>
            {showPayButton && (
              <button
                className={`${styles.shadow} ${
                  isMobile
                    ? "rounded-l-md rounded-r-md h-[2rem] px-5 w-[33%]"
                    : "h-[3rem] p-[0.75rem] rounded-[0.5rem]"
                } bg-[var(--new-blue)]`}
                onClick={async () => {
                  await dispatch(activateOutstandingPostAction(item.uuid));
                  window.location.reload();
                }}
              >
                <p className={`${styles.optionsText} text-white`}>
                  {t("post")}
                </p>
              </button>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default NewCustomPostCardWithEdit;
