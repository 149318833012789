import styles from "screens/V3/LoginScreen/NumberFormMobile.module.css";
import { Button, Form, Input } from "antd";
import LoginIllustration from "assets/images/LoginIllustration.svg";
import { motion } from "framer-motion";
import { FC } from "react";
import { useLanguage } from "hooks/useLanguage";
import { dotPulse } from "ldrs";
import { useUtilities } from "hooks/useUtilities";

interface NumberFormMobileProps {
  onFinish: any;
  isLoading?: boolean;
}

const NumberFormMobile: FC<NumberFormMobileProps> = ({
  onFinish,
  isLoading = false,
}) => {
  const { textDirectionStyle } = useLanguage();
  const { t, navigate } = useUtilities();
  const { currentLanguage } = useLanguage();
  const egyptianPhoneNumberPattern = /^(010|011|012|015)\d{8}$/;
  dotPulse.register();

  return (
    <motion.div
      className="w-full h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Form
        id="ant-login-form"
        className="w-full h-full flex flex-col items-center justify-between"
        onFinish={onFinish}
      >
        <div className={`w-full flex flex-col items-center justify-start`}>
          <img
            className="w-full"
            src={LoginIllustration}
            alt="login-illustration"
          />
          <h1
            className="mb-10 mt-10"
            style={{ fontSize: "1.875rem", fontFamily: "rbFont" }}
          >
            {t("v3_welcome_to_souq_car")}
          </h1>
          <div
            dir={textDirectionStyle.split("").reverse().join("")}
            className="flex flex-col w-full"
          >
            <p className="w-full flex justify-end souq-s">
              {t("with_phone_number")}
            </p>

            <Form.Item
              name="phone"
              className="w-full"
              style={{ direction: textDirectionStyle }}
              rules={[
                {
                  required: true,
                  message: "برجاء إدخال رقم الموبايل",
                },
                {
                  pattern: egyptianPhoneNumberPattern,
                  message: "برجاء إدخال رقم موبايل مصري صحيح",
                },
              ]}
              // style={{ direction: textDirectionStyle }}
            >
              <Input
                placeholder={t("write_your_phone_number")}
                className="w-full mt-2 souq-s"
                size="large"
                name="price"
                // status={formik.errors.price ? "error" : ""}
                dir={textDirectionStyle}
              />
            </Form.Item>
          </div>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className="w-full h-[3rem]"></div>
          <p className="souq-xs mb-3 text-center">
            بتمابعتك فأنت موافق على{" "}
            <span
              className="cursor-pointer"
              style={{ color: "var(--new-blue)" }}
              onClick={() => navigate(`/${currentLanguage}/privacy-policy`)}
            >
              سياسة الخصوصية
            </span>{" "}
            و{" "}
            <span
              className="cursor-pointer"
              style={{ color: "var(--new-blue)" }}
              onClick={() => navigate(`/${currentLanguage}/terms-of-use`)}
            >
              شروط الاستخدام
            </span>
          </p>
          <Button
            className={`flex items-center justify-center ${styles.customButton}`}
            htmlType="submit"
          >
            {!isLoading ? (
              <p className="souq-large">{t("login")}</p>
            ) : (
              <l-dot-pulse size="25" speed="1.3" color="white"></l-dot-pulse>
            )}
          </Button>
          <div className="w-full h-[0.75rem]"></div>
        </div>
      </Form>
    </motion.div>
  );
};

export default NumberFormMobile;
