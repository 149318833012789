import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { setProgressStateAction } from "redux/actions/SetProgressStateAction/setProgressStateAction";
import scrollToTop from "utils/functions/scrollToTop";

export const useForm = () => {
  const dispatch: any = useDispatch();
  const { progressState } = useSelector(
    (state: RootState) => state.setProgressStateReducer
  );

  const checkErrors = (formik: any, includedFields: string[] = []) => {
    let errorExists = false;
    const errorFields: string[] = [];

    Object.keys(formik.values).forEach((field) => {
      if (
        (includedFields.length === 0 || includedFields.includes(field)) &&
        (formik?.values[field]?.toString()?.trim() === "" ||
          !formik?.values[field])
      ) {
        if (
          field === "down_payment" &&
          formik.values.payment_type !== "Installment"
        ) {
          return; // Skip "down_payment" if payment_type is not "Installment"
        }
        formik.setFieldError(field, `Please provide this field`);
        errorExists = true;
        errorFields.push(field);
      }
    });

    return { errorExists, errorFields };
  };

  const updateProgressState = (newState: number) => {
    console.log("NEW STATE: ", newState);
    dispatch(setProgressStateAction(newState));
  };

  return { checkErrors, updateProgressState, progressState, scrollToTop };
};
