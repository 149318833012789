import { ReactNode, useState, FC, useEffect } from 'react';
import { Button, Select as AntSelect } from 'antd';
import { DownOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import Select, { StylesConfig, components } from 'react-select';
import { defaultTheme } from 'react-select';
import { placeholderCSS } from 'react-select/dist/declarations/src/components/Placeholder';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';

interface CustomDropdownFieldProps {
  width: any;
  placeholder: any;
  onSelect: any;
  onChange?: any;
  isMobile?: boolean
  data: any;
  language?: string;
  isMaker?: boolean;
  style?: any;
}

const { colors } = defaultTheme;


const CustomDropdownField: FC<CustomDropdownFieldProps> =
  ({
    width,
    placeholder,
    onSelect,
    onChange,
    isMaker = false,
    data,
    style,
    isMobile = false,
    language = 'en', // Default language is English
  }) => {

    const { currentLanguage } = useSelector(
      (state: RootState) => state.changeLanguageReducer
    );
    const isArabic = currentLanguage === 'ar';

    const {Option} = components;

    const selectStyles: StylesConfig = {
      control: (provided) => ({
        ...provided,
        width:"100%",
        margin: 8,
        overflow: 'hidden',
        direction: isArabic ? 'rtl' : 'ltr',
      }),
      menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', minHeight: 60, direction: isArabic ? 'rtl' : 'ltr', textAlign: isArabic ? 'right' : 'left'}),
    };

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<any>();

    const IconOption = (props: any) => {
      return (
      <Option {...props} style={{display:"flex"}}>
        <img src={props?.data?.icon} alt={props?.data?.label} style={{width:24}} />
        <p>{props.label}</p>
      </Option>
      )
  }


    return (
      <Dropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        target={
          <Button
            icon={isOpen ? <DownOutlined /> : isArabic ? <LeftOutlined /> : <RightOutlined />}
            onClick={() => setIsOpen((prev) => !prev)}
            style={{
              height: 50,
              direction: isArabic ? 'rtl' : 'ltr',
              backgroundColor: '#EFEFEE',
              border:"none",
            }}
            className={`${width} flex flex-row items-center`}
          >
            {value ? `${value?.label}` : placeholder}
          </Button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          options={isMaker ? data?.map((item: any) => {
            return {
              value: item.value,
              label: item.label,
              icon: item.icon
            }
          }) : data}
          components={isMaker ? { DropdownIndicator, IndicatorSeparator: null, Option: IconOption } : { DropdownIndicator, IndicatorSeparator: null}}
          controlShouldRenderValue={true}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen={isOpen}
          onChange={(newValue) => {
            setValue(newValue);
            setIsOpen(false);
            onSelect(newValue);
            onChange && onChange(newValue);
          }}
          placeholder={placeholder}
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
        />
      </Dropdown>
    );
  };

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        minWidth: 100,
        textOverflow: 'hidden',
        overflow: 'hidden',
        width:"100%",
      }}
      {...props}
    >
  </div>
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
      minWidth: 100,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: 'relative'}}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default CustomDropdownField;