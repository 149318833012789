import 
{
    CUSTOMER_STORIES_LOADING,
    CUSTOMER_STORIES_SUCCESS,
    CUSTOMER_STORIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listCustomerStoriesAction = (customerUUID: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CUSTOMER_STORIES_LOADING, payload: true});
        const response = await listTableDataService(`getCustomerStories/${customerUUID}`);
        dispatch({type: CUSTOMER_STORIES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: CUSTOMER_STORIES_FAILURE, payload: err});
    } finally {
        dispatch({type: CUSTOMER_STORIES_LOADING, payload: false});
    }
}