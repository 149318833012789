import * as React from "react";
import type { SVGProps } from "react";
const SvgBlackDownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 10.5 7.1"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M5.3 7.1.1 1.5 1.5.1l3.8 4L9 .1l1.5 1.4z" />
  </svg>
);
export default SvgBlackDownArrow;
