import
{
    FEATURES_LOADING,
    FEATURES_SUCCESS,
    FEATURES_FAILURE
}
from "redux/types";

const initialState = {
    featuresLoading: false,
    features: null,
    errors: null
}

export const listFeaturesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FEATURES_LOADING :
            return {...state, featuresLoading: action.payload};
        case FEATURES_SUCCESS :
            return {...state, features: action.payload.records};
        case FEATURES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}