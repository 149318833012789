import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 18.5 18.6"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.5.1c.5 0 1.1.2 1.4.6.4.4.6.9.6 1.4v14.3c0 .5-.2 1.1-.6 1.4-.4.4-.9.6-1.4.6H2.2c-.5 0-1.1-.2-1.4-.6-.4-.4-.6-.9-.6-1.4V2.1C.2 1.6.4 1 .8.7c.4-.4.9-.6 1.4-.6h14.3zM16 15.9v-5.4c0-.9-.4-1.7-1-2.4-.6-.6-1.5-1-2.4-1-.9 0-1.9.5-2.4 1.3V7.3H7.4v8.6h2.9v-5c0-.8.6-1.4 1.4-1.4.4 0 .7.2 1 .4.3.3.4.6.4 1v5H16zM4.1 5.7c.5 0 .9-.2 1.2-.5.3-.3.5-.7.5-1.2 0-1-.8-1.7-1.7-1.7-.5 0-.9.2-1.2.5s-.5.8-.5 1.2c0 1 .7 1.7 1.7 1.7zm1.4 10.2V7.3H2.7v8.6h2.8z"
    />
  </svg>
);
export default SvgLinkedinIcon;
