import ProfileScreenUserInfoCard from "components/CustomUserInfoCard/ProfileScreenUserInfoCard/ProfileScreenUserInfoCard";
import { useState, useEffect } from "react";
import styles from "screens/VendorScreen/VendorScreen.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { getVendorDataAction } from "redux/actions/VendorDataActions/vendorDataActions";
import { Empty, Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "components/Footer/Footer";
import WidePostCard from "components/CustomPostCard/WidePostCard/WidePostCard";
import { listVendorPostsAction } from "redux/actions/VendorPostsActions/listVendorPostsAction";
import PostCard from "components/CustomPostCard/PostCard/PostCard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gridView, columnView } from "assets/icons";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import Pagination from "components/Pagination/Pagination";
import ExhibitorScreen from "screens/V3/ExhibitorScreen/ExhibitorScreen";
import { useIsDefined } from "hooks/useIsDefined";
import RegularVendorScreen from "../RegularVendorScreen/RegularVendorScreen";
import { useLanguage } from "hooks/useLanguage";
import { useResponsive } from "hooks/useResponsive";
import NewCustomPostCard from "components/V3/NewCustomPostCard/NewCustomPostCard";
import NewWidePostCard from "components/V3/NewCustomPostCard/NewWidePostCard/NewWidePostCard";

const VendorScreen = () => {
  const { t } = useTranslation();

  const { isDefined } = useIsDefined();

  const { vendorData, isExhibitor, vendorDataLoading } = useSelector(
    (state: RootState) => state.getVendorDataReducer
  );

  const {
    vendorPostsLoading,
    vendorPosts,
    // isExhibitor,
    currentPage,
    lastPage,
  } = useSelector((state: RootState) => state.listVendorPostsReducer);

  const { currentLanguage, isArabic, textDirectionStyle } = useLanguage();
  const { isMobile, isMax1280 } = useResponsive();

  const [searchParams, setSearchParams] = useSearchParams();
  const [viewType, setViewType] = useState("grid");

  const dispatch: any = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();

  const getSearchURL = () => {
    var searchFilters = "";
    Array.from(searchParams.entries()).forEach((item: any, idx: number) => {
      if (idx === Array.from(searchParams.entries()).length - 1) {
        searchFilters += `${item[0]}=${item[1]}`;
      } else {
        searchFilters += `${item[0]}=${item[1]}&`;
      }
    });
    return searchFilters;
  };

  useEffect(() => {
    const element = document.getElementById("header");
    element?.scrollIntoView();
    element?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!isExhibitor) {
      dispatch(getVendorDataAction(id));
    }
  }, []);

  useEffect(() => {
    if (!isExhibitor) {
      dispatch(
        listVendorPostsAction(id, getSearchURL().split("&")[0].split("=")[1])
      );
    }
  }, [searchParams]);

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
  //     window.innerWidth < 1280 ? setIsMax1280(true) : setIsMax1280(false);
  //   });
  // }, []);

  // useEffect(() => {
  //
  //
  //   if (window.innerWidth <= 960) {
  //     setIsMobile(true);
  //   }
  //   if (window.innerWidth < 1280) {
  //     setIsMax1280(true);
  //   }
  // }, []);

  const scrollToTop = () => {
    const element = document.getElementById("header");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return !isExhibitor ? (
    <div
      className="flex flex-col items-center"
      style={{
        backgroundColor: "var(--new-souq-background)",
      }}
    >
      <div className={`${isMobile ? "w-full p-4" : "w-[55rem]  py-4"}`}>
        {/* // DESKTOP VIEW */}
        <div className={`${styles.vendorSectionContainer} gap-y-4`}>
          {/* posts?.map((item: any, idx: number) => { */}
          {/* return ( */}
          <div>
            {vendorDataLoading ? (
              <Spin />
            ) : (
              <ProfileScreenUserInfoCard
                callsCount={vendorData?.total_calls}
                viewsCount={vendorData?.total_views}
                username={vendorData?.name || "Undefined"}
                // mobile={vendorData?.phone}
                // email={vendorData?.email}
                userImage={vendorData?.customer_image}
                height="52rem"
                joinedSince={
                  vendorData?.created_at?.split("T")[0] || "Undefined"
                }
                // membershipType="مجاني"
                isVendor={true}
              />
            )}
          </div>

          <div className={`${styles.vendorAdsContainer} flex flex-col`}>
            <div className={`flex flex-col justify-end`}>
              {
                // LIST VIEW
                <div
                  className={`${styles.vendorPostCards} flex flex-col items-end justify-start gap-y-4 w-full`}
                >
                  {/* map vendor posts here */}
                  {vendorPostsLoading ? (
                    <Spin />
                  ) : vendorPosts?.length === 0 ? (
                    <div className="w-full flex flex-col items-center mt-10">
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={{ transform: "scale(1.5)" }}
                      />
                    </div>
                  ) : (
                    Array.isArray(vendorPosts) &&
                    vendorPosts?.map((item: any, idx: any) => {
                      return (
                        <NewCustomPostCard
                          key={idx}
                          isMobile={isMobile}
                          id={item.uuid}
                          isSpecialAd={true}
                          showActions={false}
                          width={"w-full"}
                          category={item.category}
                          customerName={item.customer}
                          brandLogo={item.brand_logo}
                          isFavorited={item.favourited}
                          // className={`${styles.widePostCard}`}
                          callsCount={item.contact_count}
                          viewsCount={item.views_count}
                          uploadDate={item.created_at?.split("T")[0]}
                          postTitle={item.title}
                          city={item.city}
                          price={
                            isDefined(item.down_payment)
                              ? item.down_payment
                              : item.price
                          }
                          carCondition={item.car_condition}
                          productionDate={item.production_date}
                          postDescription={item.description}
                          kilometrage={item.kilo_meterage}
                          carMaker={item.brand}
                          image={item.main_image}
                          paymentOption={item.payment_type}
                          created_at={item.created_at}
                          address={item.address}
                        />
                      );
                    })
                  )}
                </div>
              }
            </div>
          </div>
        </div>
        {/* PAGINATION */}
        <Pagination
          current_page={Number(currentPage)}
          last_page={lastPage}
          loading={vendorPostsLoading}
          path={`vendor/${id}`}
          style={{ margin: "1rem" }}
          top={"header"}
        />
      </div>
      {!vendorPostsLoading && <Footer />}
    </div>
  ) : (
    <ExhibitorScreen />
  );
  // return !isExhibitor ? <RegularVendorScreen /> : ;
};

export default VendorScreen;
