import React, { FC, useEffect, useState } from "react";
import styles from "./FormButtons.module.css";
import { useForm } from "hooks/useForm";
import { useUtilities } from "hooks/useUtilities";
import { dotPulse } from "ldrs";
import {
  CheckOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { postCategoryStateAction } from "redux/actions/CreatePostCategoryAction/postCategoryStateAction";
import { useResponsive } from "hooks/useResponsive";
import { Input, Modal, Spin, notification } from "antd";
import CustomButton from "components/CustomButton/CustomButton";
import { mobileLoginAction } from "redux/actions/LoginActions/mobileLoginAction";
import { verifyOtpAction } from "redux/actions/LoginActions/verifyOtpAction";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useLanguage } from "hooks/useLanguage";
import { useTimer } from "react-timer-hook";
import { set } from "lodash";

interface FormButtonsProps {
  formik: any;
  willSubmit?: boolean;
  isLoading?: boolean;
  includedFields?: string[];
}

const FormButtons: FC<FormButtonsProps> = ({
  formik,
  willSubmit = false,
  isLoading = false,
  includedFields = [],
}) => {
  dotPulse.register();
  const { checkErrors, updateProgressState, progressState, scrollToTop } =
    useForm();
  const { t, dispatch, getUserData } = useUtilities();
  const { currentLanguage, isArabic, textDirectionStyle } = useLanguage();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { isMobile } = useResponsive();
  const [showErrorMessage, setShowErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    // autoStart: true,
    expiryTimestamp: new Date(new Date().getTime() + 60000),
    onExpire: () => {
      console.log("TIMER IS DONE");
      setEnableResend(true);
    },
  });

  const resetOtpTimer = () => {
    // Restarts to 5 minutes timer
    const valueInMinutes = 1;
    const valueInSeconds = 59;
    const time = new Date();
    time.setSeconds(
      time.getSeconds() + (valueInSeconds ?? valueInMinutes * 60)
    );
    restart(time);
  };

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { loginLoading, customer } = useSelector(
    (state: RootState) => state.mobileLoginReducer
  );

  /* Login Field States */
  const [showName, setShowName] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [enableResend, setEnableResend] = useState(false);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [disableOTPField, setDisableOTPField] = useState(true);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [customerChange, setCustomerChange] = useState(0);
  /* End Login Field States */

  useEffect(() => {
    if (customerChange > 0) {
      if (
        customer !== undefined &&
        customer !== null &&
        customer?.name !== ""
      ) {
        setShowOtp(true);
        setShowName(false);
        resetOtpTimer();
      } else {
        setShowOtp(false);
        setShowName(true);
      }
    }
  }, [customerChange]);

  const handleLoginClick = async () => {
    console.log("LOGIN CLICK");
    // if (enableResend) return;

    const egyptianPhoneNumberPattern = /^(010|011|012|015)\d{8}$/;
    console.log("SHOW OTP IS:", showOtp);
    if (showOtp === false) {
      if (
        formik.errors.phone ||
        formik.values.phone === "" ||
        !egyptianPhoneNumberPattern.test(formik.values.phone)
      ) {
        console.log("ERROR!! ");
        formik.setErrors({ phone: "Cannot Proceed Without A Valid Number" });
        return;
      } else {
        console.log("SENDING REQUEST MOBILE ACTION");
        await dispatch(mobileLoginAction(formik.values.phone));
        setDisableOTPField(false);
        resetOtpTimer();
        setEnableResend(true);
        setCustomerChange(customerChange + 1);
        return;
      }
    }

    if (showName === false) {
      if (formik.errors.otp || formik.values.otp === "") {
        formik.setErrors({ otp: "Cannot Proceed Without An OTP" });
      } else {
        setShowName(true);
      }
    }
  };

  const handleVerifyOtpClick = async () => {
    if (!formik.errors.otp && formik.values.otp.trim() !== "") {
      await dispatch(
        verifyOtpAction(
          formik.values.phone,
          formik.values.otp,
          formik.values.name,
          formik.values.email
        )
      );

      if (!errors) {
        setShowLoginModal(false);
        formik.handleSubmit();
      } else {
        notification.error({
          message: "OTP Error",
          description: "Please Reload The OTP",
          style: {
            zIndex: 99999999999999,
          },
        });
      }
    } else {
      formik.setErrors({ otp: "Cannot Proceed Without An OTP" });
    }
  };

  const handleNameEmailInput = () => {
    var errorExists = false;
    if (formik.errors.name || formik.values.name.trim() === "") {
      formik.setFieldError("name", "Please provide this field");
      errorExists = true;
    }
    // if (formik.errors.email || formik.values.email.trim() === "") {
    //   formik.setFieldError("email", "Please provide this field");
    //   errorExists = true;
    // }
    if (!errorExists) {
      setShowName(false);
      setShowOtp(true);
      resetOtpTimer();
    }
  };

  const handleResendOtpClick = async () => {
    if (enableResend) {
      setEnableResend(false);
      resetOtpTimer();
      await dispatch(mobileLoginAction(formik.values.phone));
    }
  };

  const renderModal = () => {
    return (
      <Modal
        open={showLoginModal}
        closable={true}
        footer={null}
        onCancel={() => setShowLoginModal(false)}
        maskClosable={false}
      >
        <div className="w-full flex items-center justify-center mb-8 mt-10">
          <p className="text-[var(--souq-blue)] text-[16px]">
            {t("not_logged_in")}
          </p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {showName === false && showOtp === false ? (
            <div className="w-full flex flex-col items-center">
              <p className="mb-4 text-md">{t("fast_login")}</p>
              <div className="w-full flex flex-col items-center gap-y-2">
                <Input
                  type="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  status={formik.errors.phone ? "error" : ""}
                  placeholder={t("with_phone_number")}
                  size="large"
                  className={`${styles.phoneField} ${
                    currentLanguage === "ar"
                      ? `text-right ${styles.loginField}`
                      : "text-left"
                  }`}
                />
                {formik.errors.phone && (
                  <p
                    className="text-red-400 pt-4 text-xs"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("phonelength_errormessage")}
                  </p>
                )}
              </div>
            </div>
          ) : showName === true && showOtp === false ? (
            <div className="w-full flex flex-col items-center gap-y-3">
              <div className={`${styles.fieldLabel} w-full flex justify-end`}>
                <span>
                  <p>
                    <span
                      className={`${styles.fieldLabel}`}
                      style={{ color: "red" }}
                    >
                      *
                    </span>
                    الاسم
                  </p>
                </span>
              </div>
              <Input
                type="text"
                name="name"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                status={formik.errors.name ? "error" : ""}
                placeholder="الأسم"
                size="large"
                className={
                  currentLanguage === "ar"
                    ? `text-right ${styles.loginField}`
                    : "text-left"
                }
              />
              <div className={`${styles.fieldLabel} w-full flex justify-end`}>
                <p>البريد الاكتروني</p>
              </div>
              <Input
                type="text"
                name="email"
                onChange={formik.handleChange}
                status={formik.errors.email ? "error" : ""}
                placeholder="البريد الألكتروني"
                size="large"
                className={
                  currentLanguage === "ar"
                    ? `text-right ${styles.loginField} ${styles.emailField}`
                    : "text-left"
                }
              />
            </div>
          ) : (
            <div
              dir={isArabic ? "rtl" : "ltr"}
              className={`${styles.otpSectionContainer}`}
            >
              <div
                className={`ze:w-full sm:w-3/4 mb-4 flex flex-row items-center justify-between`}
              >
                <div
                  className={`me-5 w-full flex flex-col items-center justify-center gap-y-2 text-left`}
                >
                  <Input
                    type="text"
                    name="otp"
                    onChange={formik.handleChange}
                    status={formik.errors.otp ? "error" : ""}
                    className={`text-${isArabic ? "right" : "left"} ${
                      styles.loginField
                    } w-full`}
                    placeholder={t("otp_hint")}
                    size="large"
                  />
                </div>
                <div
                  className={`${styles.resendOtpButtonContainer} flex flex-row items-center justify-center gap-x-4`}
                >
                  {loginLoading ? (
                    <Spin />
                  ) : (
                    <CustomButton
                      className={`${
                        enableResend
                          ? styles.resendOtpButtonEnabled
                          : styles.resendOtpButtonDisabled
                      } ${
                        styles.resendOtpButton
                      } rounded-md flex justify-center items-center px-3`}
                      textStyling={`font-bold whitespace-nowrap`}
                      onClick={handleResendOtpClick}
                    >
                      {!enableResend ? (
                        <span className={`${styles.secondsLeftText}`}>
                          {seconds}
                        </span>
                      ) : (
                        t("resend")
                      )}
                    </CustomButton>
                  )}
                  {/* {userData && <CheckOutlined className="text-xl tex-[green]" />} */}
                </div>
              </div>
              {formik.errors.otp && (
                <p className="text-red-400 text-center">{formik.errors.otp}</p>
              )}
            </div>
          )}
          <div
            className="w-full flex flex-col items-center justify-center gap-y-4"
            style={{ marginTop: "3rem" }}
          >
            {showName === false && showOtp === false ? (
              loginLoading ? (
                <Spin />
              ) : (
                <CustomButton
                  className={`ze:w-[50%] lg:w-1/2 p-3 text-white mb-3`}
                  color={`blue`}
                  onClick={handleLoginClick}
                  buttonStyle={{ borderRadius: ".7rem" }}
                  addShadow={true}
                >
                  {t("next")}
                </CustomButton>
              )
            ) : showName === true && showOtp === false ? (
              <CustomButton
                className={`ze:w-full lg:w-3/4 rounded-xl p-3 text-white mb-3`}
                color={`blue`}
                onClick={handleNameEmailInput}
                buttonStyle={{ borderRadius: ".5rem" }}
                addShadow={true}
              >
                {t("next")}
              </CustomButton>
            ) : verifyOtpLoading ? (
              <Spin />
            ) : (
              <CustomButton
                className={`ze:w-1/2 lg:w-1/2 p-3 text-white mb-3`}
                color={`blue`}
                onClick={handleVerifyOtpClick}
                buttonStyle={{ borderRadius: ".5rem" }}
                addShadow={true}
              >
                {t("login")}
              </CustomButton>
            )}
          </div>
        </form>
      </Modal>
    );
  };

  const renderModalOld = () => {
    return (
      <Modal
        open={showLoginModal}
        closable={true}
        footer={null}
        onCancel={() => setShowLoginModal(false)}
        maskClosable={false}
      >
        <div className="w-full flex items-center justify-center mb-8 mt-10">
          <p className="text-[var(--souq-blue)] text-[1.25rem]">
            {t("not_logged_in")}
          </p>
        </div>
        {showName === false && showOtp === false ? (
          <div className="w-full flex flex-col items-center">
            <p className="mb-4 text-md">{t("fast_login")}</p>
            <div
              dir={textDirectionStyle}
              className="w-full flex flex-col items-center gap-y-2"
            >
              <p className="w-full">{t("with_phone_number")}</p>
              <div className="w-full flex gap-x-4">
                <Input
                  type="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  status={formik.errors.phone ? "error" : ""}
                  placeholder={t("with_phone_number")}
                  size="large"
                  className={`${styles.phoneField} ${
                    currentLanguage === "ar"
                      ? `text-right ${styles.loginField}`
                      : "text-left"
                  }`}
                />
                <CustomButton
                  className={`flex-grow border-round-xs text-white px-5 ${
                    enableResend && styles.resendOtpButtonDisabled
                  }`}
                  color="var(--new-blue)"
                  onClick={handleLoginClick}
                >
                  {loginLoading ? (
                    <l-dot-pulse
                      size="25"
                      speed="1.3"
                      color="white"
                    ></l-dot-pulse>
                  ) : !enableResend ? (
                    t("confirm")
                  ) : (
                    `${seconds}`
                  )}
                </CustomButton>
              </div>
              {formik.errors.phone && (
                <p
                  className="text-red-400 pt-4 text-xs"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("phonelength_errormessage")}
                </p>
              )}
            </div>
            <div
              dir={textDirectionStyle}
              className="w-full flex flex-col items-center gap-y-2 mt-4"
              style={{
                opacity: disableOTPField ? "50%" : "100%",
                cursor: disableOTPField ? "not-allowed" : "auto",
              }}
            >
              <p className="w-full">{t("verification_header")}</p>
              <Input
                type="otp"
                name="otp"
                onChange={(event) => {
                  setEnableNextButton(!(event.target.value.length < 6));
                  formik.handleChange(event);
                }}
                status={formik.errors.otp ? "error" : ""}
                placeholder={t("otp_hint")}
                size="large"
                className={`${styles.phoneField} ${
                  currentLanguage === "ar"
                    ? `text-right ${styles.loginField}`
                    : "text-left"
                }`}
                disabled={disableOTPField}
                // style={{
                //   cursor: "not-allowed",
                // }}
              />
              {formik.errors.otp && (
                <p
                  className="text-red-400 pt-4 text-xs"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("otp_errormessage")}
                </p>
              )}
            </div>
          </div>
        ) : showName === true && showOtp === false ? (
          <div className="w-full flex flex-col items-center gap-y-3">
            <div className={`${styles.fieldLabel} w-full flex justify-end`}>
              <span>
                <p>
                  <span
                    className={`${styles.fieldLabel}`}
                    style={{ color: "red" }}
                  >
                    *
                  </span>
                  الاسم
                </p>
              </span>
            </div>
            <Input
              type="text"
              name="name"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              status={formik.errors.name ? "error" : ""}
              placeholder="الأسم"
              size="large"
              className={
                currentLanguage === "ar"
                  ? `text-right ${styles.loginField}`
                  : "text-left"
              }
            />
            <div className={`${styles.fieldLabel} w-full flex justify-end`}>
              <p>البريد الاكتروني</p>
            </div>
            <Input
              type="text"
              name="email"
              onChange={formik.handleChange}
              status={formik.errors.email ? "error" : ""}
              placeholder="البريد الألكتروني"
              size="large"
              className={
                currentLanguage === "ar"
                  ? `text-right ${styles.loginField} ${styles.emailField}`
                  : "text-left"
              }
            />
          </div>
        ) : (
          <div
            dir={isArabic ? "rtl" : "ltr"}
            className={`${styles.otpSectionContainer}`}
          >
            <div
              className={`ze:w-full sm:w-3/4 mb-4 flex flex-row items-center justify-between`}
            >
              <div
                className={`me-5 w-full flex flex-col items-center justify-center gap-y-2 text-left`}
              >
                <Input
                  type="text"
                  name="otp"
                  onChange={formik.handleChange}
                  status={formik.errors.otp ? "error" : ""}
                  className={`text-${isArabic ? "right" : "left"} ${
                    styles.loginField
                  } w-full`}
                  placeholder={t("otp_hint")}
                  size="large"
                />
              </div>
              <div
                className={`${styles.resendOtpButtonContainer} flex flex-row items-center justify-center gap-x-4`}
              >
                {loginLoading ? (
                  <Spin />
                ) : (
                  <CustomButton
                    className={`${
                      enableResend
                        ? styles.resendOtpButtonEnabled
                        : styles.resendOtpButtonDisabled
                    } ${
                      styles.resendOtpButton
                    } rounded-md flex justify-center items-center px-3`}
                    textStyling={`font-bold whitespace-nowrap`}
                    onClick={handleResendOtpClick}
                    color={"red"}
                    buttonStyle={{
                      backgroundColor: "red",
                    }}
                  >
                    {secondsLeft > 0 ? (
                      <span className={`${styles.secondsLeftText}`}>
                        {secondsLeft}
                      </span>
                    ) : (
                      <></>
                    )}{" "}
                    {t("resend")}
                  </CustomButton>
                )}
                {/* {userData && <CheckOutlined className="text-xl tex-[green]" />} */}
              </div>
            </div>
            {formik.errors.otp && (
              <p className="text-red-400 text-center">{formik.errors.otp}</p>
            )}
          </div>
        )}
        <div
          className="w-full flex flex-col items-center justify-center gap-y-4"
          style={{ marginTop: "3rem" }}
        >
          {showName === false && showOtp === false ? (
            <CustomButton
              className={`ze:w-[50%] lg:w-1/2 p-3 mb-3 text-white`}
              color={`var(--new-blue)`}
              onClick={handleVerifyOtpClick}
              disabled={!enableNextButton}
              buttonStyle={{
                borderRadius: ".7rem",
              }}
              addShadow={true}
            >
              {loginLoading ? (
                <l-dot-pulse size="25" speed="1.3" color="white"></l-dot-pulse>
              ) : (
                t("next")
              )}
            </CustomButton>
          ) : showName === true && showOtp === false ? (
            <CustomButton
              className={`ze:w-full lg:w-3/4 rounded-xl p-3 text-white mb-3`}
              color={`blue`}
              onClick={handleNameEmailInput}
              buttonStyle={{ borderRadius: ".5rem" }}
              addShadow={true}
            >
              {t("next")}
            </CustomButton>
          ) : verifyOtpLoading ? (
            <Spin />
          ) : (
            <CustomButton
              className={`ze:w-1/2 lg:w-1/2 p-3 text-white mb-3`}
              color={`blue`}
              onClick={handleVerifyOtpClick}
              buttonStyle={{ borderRadius: ".5rem" }}
              addShadow={true}
            >
              {t("login")}
            </CustomButton>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <div className={`${styles.containerRow} flex items-center justify-center`}>
      <div
        className={`w-${
          isMobile ? "[100%]" : "[80%]"
        } mt-10 flex flex-row items-center justify-center gap-x-2`}
      >
        <button
          className={`${styles.nextButton} p-2 flex justify-center flex-grow`}
          type={"button"}
          onClick={async () => {
            if (!willSubmit) {
              const checkOutput = checkErrors(formik, includedFields);
              const errorExists = checkOutput.errorExists;
              const errorFields = checkOutput.errorFields;
              if (!errorExists) {
                scrollToTop();
                setShowErr(false);
                updateProgressState(progressState + 1);
              } else {
                setShowErr(true);
                console.log("\nERROR FIELDS: ", errorFields);
              }
            } else {
              const checkOutput = checkErrors(formik, includedFields);
              const errorExists = checkOutput.errorExists;
              const errorFields = checkOutput.errorFields;
              if (!errorExists) {
                setShowErr(false);
                // await getUserData();
                if (!userData?.token) {
                  setShowLoginModal(true);
                  return;
                }

                await formik.handleSubmit();
              } else {
                setShowErr(true);
                if (errorFields.includes("postMedias")) {
                  setErrorMsg(t("image_error_message"));
                } else if (errorFields.includes("main_image")) {
                  setErrorMsg(t("main_image_error_message"));
                }
                console.log("\nERROR FIELDS: ", errorFields);
              }
            }
          }}
        >
          {!isLoading ? (
            <p className="souq-large">
              {!willSubmit ? t("next") : t("publish_post")}
            </p>
          ) : (
            <l-dot-pulse size="25" speed="1.3" color="white"></l-dot-pulse>
          )}
        </button>
        <button
          className={`${styles.nextButton} p-2 w-[3.79456rem] flex justify-center`}
          type="button"
          onClick={() => {
            scrollToTop();
            if (progressState === 0) {
              dispatch(postCategoryStateAction(-1));
            }
            updateProgressState(progressState - 1);
          }}
        >
          {/* <p>{t("cancel")}</p> */}
          <ArrowRightOutlined className="souq-large" />
        </button>
      </div>
      {showErrorMessage &&
        (!willSubmit ? (
          <p className="w-full mt-6 text-center text-[#FF0000] font-bold">
            {t("main_error_message")}
          </p>
        ) : (
          <p className="w-full mt-6 text-center text-[#FF0000] font-bold">
            {errorMsg}
          </p>
        ))}
      {renderModal()}
    </div>
  );
};

export default FormButtons;
