import {
  VENDOR_DATA_LOADING,
  VENDOR_DATA_SUCCESS,
  VENDOR_DATA_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import getVendorDataService from "services/getVendorDataService";

export const getVendorDataAction = (id:any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: VENDOR_DATA_LOADING, payload: true });
    const response = await getVendorDataService(id);
    dispatch({ type: VENDOR_DATA_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: VENDOR_DATA_FAILURE, payload: err });
  } finally {
    dispatch({ type: VENDOR_DATA_LOADING, payload: false });
  }
};
