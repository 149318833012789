import * as React from "react";
import type { SVGProps } from "react";
const SvgWhatsAppIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21.446 21.446"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.234 3.116A10.63 10.63 0 0 0 1.508 15.941L0 21.446l5.634-1.479a10.6 10.6 0 0 0 5.079 1.292 10.56 10.56 0 0 0 7.516-18.143Zm-7.516 16.353a8.817 8.817 0 0 1-4.5-1.23l-.318-.192-3.341.876.89-3.26-.211-.335a8.85 8.85 0 1 1 16.414-4.7 8.932 8.932 0 0 1-8.934 8.841Zm4.844-6.616c-.263-.134-1.57-.775-1.814-.862s-.421-.134-.6.134-.685.862-.843 1.044-.311.2-.574.067a7.228 7.228 0 0 1-3.614-3.159c-.273-.469.273-.436.78-1.45a.492.492 0 0 0-.024-.464c-.067-.134-.6-1.441-.819-1.972s-.436-.445-.6-.455-.33-.01-.507-.01a.984.984 0 0 0-.708.33 2.984 2.984 0 0 0-.929 2.216A5.2 5.2 0 0 0 6.4 11.02a11.866 11.866 0 0 0 4.538 4.011 5.2 5.2 0 0 0 3.188.665 2.72 2.72 0 0 0 1.79-1.264 2.221 2.221 0 0 0 .153-1.264c-.069-.119-.243-.186-.507-.315Z"
    />
  </svg>
);
export default SvgWhatsAppIcon;
