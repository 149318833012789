import api from "api";
import { deleteCoverPhotoEndPoint } from "api/apiUrls";
import store from "redux/store";

export const deleteCoverPhotoService = async () => {
    const response = api.post(
        deleteCoverPhotoEndPoint(),
        {},
        {
            headers: {
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}