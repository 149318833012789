import
{
    CREATE_STORY_LOADING,
    CREATE_STORY_SUCCESS,
    CREATE_STORY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createStoryService } from "services/createStoryService";

export const createStoryAction = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_STORY_LOADING, payload: true});
        const response = await createStoryService(data);
        dispatch({type: CREATE_STORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: CREATE_STORY_FAILURE, payload: err});
    } finally {
        dispatch({type: CREATE_STORY_LOADING, payload: false});
    }
}