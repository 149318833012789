import { Footer } from "components";
import styles from "screens/V3/StoriesScreen/StoriesScreen.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Empty, Spin } from "antd";
import TallStoryCard from "components/V3/TallStoryCard/TallStoryCard";
import { useEffect } from "react";
import { listStoriesAction } from "redux/actions/StoriesActions/ListStoriesAction/listStoriesAction";
import { useSearch } from "hooks/useSearch";
import NewPaginator from "components/V3/NewPaginator/NewPaginator";
import { useSearchParams } from "react-router-dom";
import { changeCurrentPageAction } from "redux/actions";

const StoriesScreen = () => {
  const { t } = useTranslation();

  const dispatch: any = useDispatch();

  const { getSearchParam } = useSearch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { storiesLoading, stories, currentPage, lastPage } = useSelector(
    (state: RootState) => state.listStoriesReducer
  );

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  useEffect(() => {
    dispatch(listStoriesAction(getSearchParam("page") ?? 1));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch(changeCurrentPageAction(1));
  }, [searchParams]);

  return (
    <div className="mt-[2.94rem] w-full h-full flex flex-col items-center">
      <p className={`text-[var(--new-blue)] text-[1.25rem] font-[700]`}>
        {t("story")}
      </p>
      <div className="all-stories-spin-container mt-[2rem] w-full flex flex-row items-center justify-center">
        <Spin className="flex justify-center" spinning={storiesLoading}>
          <div className="w-[75%] flex flex-row items-center justify-center gap-x-10 mb-10">
            {stories?.length === 0 ? (
              <Empty description={t("no_stories")} className="h-[10rem]" />
            ) : (
              stories?.map((item: any) => {
                return (
                  <TallStoryCard
                    customerUUID={item.uuid}
                    image={
                      "https://souqcarlive.s3.amazonaws.com/stories/" +
                      item?.stories?.image
                    }
                    customerName={item.name}
                    customerImage={item.personal_image}
                    description={item.stories?.description}
                  />
                );
              })
            )}
          </div>
        </Spin>
      </div>
      <div className="mb-4 w-full flex flex-row items-center justify-center">
        <NewPaginator
          currentPage={parseInt(getSearchParam("page")) || 1}
          lastPage={lastPage}
          url={`/${currentLanguage}/stories`}
        />
      </div>
      <Footer />
    </div>
  );
};

export default StoriesScreen;
