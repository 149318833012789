import styles from "screens/V3/ViewPostScreen/ViewPostScreen.module.css";
import { Input, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useParams } from "react-router";
import {
  googleMaps,
  googleDirections,
  NewBackIcon,
  FreeInspectionIcon,
} from "assets/icons/index";
import { increasePostCallsCountService } from "services/increasePostCallsCountService";
import CoolLightbox from "components/CoolLightbox/CoolLightbox";

import {
  listSinglePostAction,
  sendChatMessageAction,
  viewPostMainImageAction,
  setMapCoordinatesAction,
  changeCurrentPageAction,
} from "redux/actions/index";

import {
  calender,
  carBorder,
  tachometer,
  carInsurance,
  transmission,
  gas,
  carEngine,
  sedan,
  payment,
  color,
  rentalDriver,
  rentalDuration,
  spareParts,
  sparePartsCondition,
} from "assets/icons";

import {
  CustomButton,
  CustomMap,
  Footer,
  Seo,
  CarImagesCarousel,
  CustomCarDetailsCardRent,
  CustomCarDetailsCardSpare,
  CustomCarDetailsCardMaintenance,
  ReportModalComponent,
  PostCardCarousel,
} from "components/index";
import { useNavigate } from "react-router";
import { CustomUserInfoCard as UserInfoCard } from "components/V3/CustomUserInfoCard/UserInfoCard";
import {
  ArrowLeftIcon,
  BlueArrowLeftIcon,
  FavouriteButtonIcon,
  ShareButtonIcon,
  StarIcon,
} from "assets/icons/V3";
import CustomCarInfoCard from "components/V3/CustomCarInfoCard/CustomCarInfoCard";
import CustomCarDetailCard from "components/V3/CustomCarDetailCard/CustomCarDetailCard";
import FeaturesCard from "components/V3/FeaturesCard/FeaturesCard";
import MapImage from "assets/images/MapImage.png";
import {
  PhoneFilled,
  MessageOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";

import { WhatsAppIcon } from "assets/icons/svg-components";
import { useUtilities } from "hooks/useUtilities";
import { favouritePostAction } from "redux/actions/FavouritePostActions/favouritePostAction";
import ShareButton from "components/V3/ShareButton/ShareButton";

const ViewPostScreen = () => {
  const { TextArea } = Input;

  const { t } = useTranslation();

  const dispatch: any = useDispatch();

  const { getPreFilledMessage } = useUtilities();

  const navigate = useNavigate();
  // const [selected, setSelected] = useState({ lat: 43.45, lng: -80.49 });

  const [isMobile, setIsMobile] = useState(false);

  const [fadeState, setFadeState] = useState(false);

  const [zoomedIn, setZoomedIn] = useState(true);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [autoplay, setAutoplay] = useState(true);

  const handleZoomClick = () => {
    setZoomedIn(!zoomedIn);
  };

  // https://www.google.com/maps?q=latitude,longitude

  const { postUUID } = useParams();

  const { loading, post, similar_posts } = useSelector(
    (state: RootState) => state.listSinglePostReducer
  );

  const [favoriteState, setFavoriteState] = useState(post?.favourited);

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";

  const { chatMessageLoading, chatMessageSuccess } = useSelector(
    (state: RootState) => state.sendChatMessageReducer
  );

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const { mainImage } = useSelector(
    (state: RootState) => state.viewPostMainImageReducer
  );
  const { mapState } = useSelector(
    (state: RootState) => state.setMapCoordinatesReducer
  );

  // const [selectedImage, setSelectedImage] = useState("");

  let timeoutId: any;
  const handleFade = async (newState: boolean) => {
    setFadeState(newState);
  };

  // const handleSendMessage = async () => {
  //   if (message.trim() === "") {
  //     setMessageError(true);
  //   } else {
  //     setMessageError(false);
  //     setMessage("");
  //     await dispatch(sendChatMessageAction(post?.customer_id, message));
  //     if (chatMessageSuccess) {
  //       notification.success({
  //         message: "Message Sent Successfully",
  //         description: "You can view all your messsage in the messages section",
  //       });
  //     }
  //   }
  // };

  const handleSendMessage = async () => {
    await dispatch(
      sendChatMessageAction(post?.customer_id, "هل السيارة متاحة؟")
    );
    navigate(`/${currentLanguage}/messages`);
  };

  const removeHTMLtags = (input: string) => {
    // Use a regular expression to match and remove HTML tags
    return input.replace(/<[^>]*>/g, "");
  };

  const handleShowPhoneClick = () => {
    increasePostCallsCountService(postUUID as string);
  };

  const { verifyOtpLoading, userData, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const scrollToTop = () => {
    const component = document.getElementById("view-post-top-section");
    component?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(listSinglePostAction(postUUID as string));
  }, []);

  useEffect(() => {
    dispatch(viewPostMainImageAction(post?.main_image));
    dispatch(setMapCoordinatesAction({ lat: post?.lat, lng: post?.long }));
  }, [post]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const favoriteIcons = [
    "M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z",
    "M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z",
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    dispatch(changeCurrentPageAction(-1));
  }, []);

  const handleFavoriteClick = (event: any) => {
    if (userData === null) {
      console.log("a7a");
      return;
    }
    setFavoriteState((prev: boolean) => !prev);
    dispatch(favouritePostAction(postUUID as string, !favoriteState));
    // navigate(`/${currentLanguage}/ads/show/${id}`);
  };

  useEffect(() => {
    setFavoriteState(post?.favourited);
  }, [post?.favourited]);

  return (
    <>
      <Seo
        title={`${post?.title}`}
        description={post?.title}
        keywords="سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار"
        thumbnailImage={encodeURI(post?.main_image)}
        thumbnailDescription={post?.title}
      />
      {isMobile && (
        <div
          dir="rtl"
          className="fixed z-40 bottom-0 w-full h-[4.625rem] bg-white flex items-center justify-center gap-x-3"
        >
          <CustomButton
            className={`w-[6.35181rem] h-[2.875rem] rounded-[0.3125rem] border-[0.5px] border-[var(--new-blue)] flex items-center justify-center gap-x-2`}
            onClick={() => {}}
          >
            <PhoneFilled className="text-[var(--new-blue)] rotate-[100deg]" />
            <p>{t("call")}</p>
          </CustomButton>
          <CustomButton
            className={`w-[6.35181rem] h-[2.875rem] rounded-[0.3125rem] border-[0.5px] border-[var(--new-blue)] flex items-center justify-center gap-x-2`}
            onClick={() => {}}
          >
            <MessageOutlined className="text-[var(--new-blue)]" />
            <p>{t("send_message")}</p>
          </CustomButton>
          <a
            href={`https://wa.me/${
              post?.customer_whats_app_no
                ? post?.customer_whats_app_no
                : post?.customer_phone
            }?text=${getPreFilledMessage(
              post?.brand,
              post?.car_model,
              post?.production_date,
              post?.city,
              encodeURIComponent(window.location.href)
            )}`}
          >
            <CustomButton
              className={`w-[6.35181rem] h-[2.875rem] rounded-[0.3125rem] border-[0.5px] border-[#01DB31] flex items-center justify-center gap-x-2`}
              onClick={() => {}}
              color={"rgba(1, 219, 49, 0.03)"}
            >
              <WhatsAppIcon className="text-[#01DB31]" />
              <p>{t("whatsapp")}</p>
            </CustomButton>
          </a>
        </div>
      )}
      <div className="w-full flex flex-col items-center mt-3">
        <div
          id="view-post-top-section"
          className={`${
            !isMobile ? "w-[74rem]" : "w-[95%]"
          } flex flex-col items-center ${styles.postSectionContainer}`}
          style={{ backgroundColor: "var(--new-souq-background)" }}
        >
          {loading ? (
            <Spin />
          ) : (
            <div
              className={`w-full flex flex-col items-start justify-between ${
                !isMobile ? styles.postSection : ""
              } ${isArabic ? "md:flex-row" : "md:flex-row-reverse"} `}
            >
              {!isMobile ? (
                <div
                  className="flex flex-col items-center gap-y-6"
                  style={{ width: "32%" }}
                >
                  <UserInfoCard
                    id={post?.uuid}
                    post={post}
                    viewsCount={post?.views_count}
                    callsCount={post?.contact_count}
                    whatsappCount={post?.whatsapp_contact_count}
                    customer={post?.customer}
                    phoneNumber={post?.customer_phone}
                    customerId={post?.customer_id}
                    whatsappNumber={
                      post?.customer_whats_app_no
                        ? post?.customer_whats_app_no
                        : post?.customer_phone
                    }
                    handleShowPhoneClick={handleShowPhoneClick}
                    totalPosts={post?.total_posts}
                    isMobile={isMobile}
                    isFavorited={post?.favourited}
                    sendMessageOnClick={handleSendMessage}
                    customerImage={post?.customer_image}
                  />
                  <CustomButton
                    className={`${styles.viewInsurancePageButton} ${styles.shadow} w-[26.25rem] flex flex-row items-center justify-between px-5`}
                    color={"white"}
                    onClick={() =>
                      navigate(`/${currentLanguage}/car-insurance`)
                    }
                  >
                    <div
                      dir={isArabic ? "ltr" : "rtl"}
                      className="w-full flex flex-row items-center justify-between px-5"
                    >
                      <img
                        src={BlueArrowLeftIcon}
                        style={isArabic ? {} : { transform: "rotate(180deg)" }}
                      />
                      <div
                        dir={isArabic ? "rtl" : "ltr"}
                        className="flex flex-row items-center gap-x-5 justify-center"
                      >
                        <img
                          src={FreeInspectionIcon}
                          className="w-[40px] h-[40px]"
                        />
                        <div className="flex flex-col items-start gap-y-2">
                          <p className="font-bold text-[15px]">
                            {t("viewpost_insure_car_title")}
                          </p>
                          <p className="text-[10px] text-[12px] opacity-[0.5]">
                            {t("viewpost_insure_car_body")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CustomButton>
                  <div
                    className={`w-[26.25rem] flex flex-col bg-white rounded-xl ${styles.securitySection} ${styles.shadow}`}
                    dir={dir}
                  >
                    <div
                      className={`w-full flex flex-row justify-start items-center`}
                    >
                      <p
                        style={{
                          fontSize: "1.125rem",
                          color: "var(--new-blue)",
                        }}
                      >
                        {t("viewpost_safety_percautions")}
                      </p>
                    </div>
                    <div
                      className={`w-full flex justify-start items-center gap-x-2`}
                    >
                      <div
                        className={`${styles.blueBg} w-4 h-4 rounded-full`}
                      ></div>
                      <p className="text-xs">{t("percaution_one")}</p>
                    </div>
                    <div
                      className={`w-full flex justify-start items-center gap-x-2`}
                    >
                      <div
                        className={`${styles.blueBg} w-4 h-4 rounded-full`}
                      ></div>
                      <p className="text-xs">{t("percaution_two")}</p>
                    </div>
                    <div
                      className={`w-full flex justify-start items-center gap-x-2`}
                    >
                      <div
                        className={`${styles.blueBg} w-4 h-4 rounded-full`}
                      ></div>
                      <p className="text-xs">{t("percaution_three")}</p>
                    </div>
                    <div
                      className={`w-full flex justify-start items-center gap-x-2`}
                    >
                      <div
                        className={`${styles.blueBg} w-4 h-4 rounded-full`}
                      ></div>
                      <p className="text-xs">{t("percaution_four")}</p>
                    </div>
                  </div>
                  <div
                    dir="rtl"
                    className="w-full flex flex-row items-center justify-between"
                  >
                    <p
                      style={{ color: "rgba(19, 19, 19, 0.50)" }}
                      className={`text-[0.8125rem]`}
                    >
                      {t("post_number")} {post?.id}
                    </p>
                    <ReportModalComponent dir={dir} isArabic={isArabic} />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                className={`${styles.mainBannerContainer}  flex flex-col gap-y-4 items-start justify-center`}
                style={{
                  width: isMobile ? "100%" : "47.375rem",
                  transition: "all 0.4s ease-in-out",
                }}
              >
                {/* <PostImagesCarousel image={post?.main_image} /> */}
                <div
                  onClick={(event: any) => {
                    const clickedFavorite =
                      event.target.id === "favoriteClick" ||
                      event.target.viewBox != null ||
                      (event.target.getAttribute("d") != null &&
                        favoriteIcons.includes(event.target.getAttribute("d")));

                    const clickedShare =
                      event.target.getAttribute("alt") === "share";

                    console.log("CLICKED FAVORITE?", clickedFavorite);

                    if (!clickedFavorite && !clickedShare) {
                      console.log(event.target);
                      console.log(event.target.getAttribute("d"));
                      setOpenLightbox(true);
                      setAutoplay(false);
                    }
                  }}
                  className="w-full rounded-xl"
                  style={{
                    height: isMobile ? "12.5rem" : "25.5rem",
                    position: "relative",
                    overflow: "hidden",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => {
                    // gsapAnimation();
                    setAutoplay(false);
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      // transition: 'all 0.4s ease-in-out',
                      ...(!zoomedIn
                        ? {
                            backgroundImage: `url('${encodeURI(mainImage)}')`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            filter: "blur(8px)",
                          }
                        : {}),
                    }}
                    className="flex flex-col justify-between px-3 py-3"
                  >
                    <div
                      style={{ zIndex: 9 }}
                      className="w-full flex flex-row items-start justify-between"
                      dir="rtl"
                    >
                      <div
                        className={`flex flex-row items-center justify-between rounded-[1rem] bg-[var(--souq-orange)] pl-2`}
                      >
                        {post?.isSpecial && (
                          <>
                            <img
                              className="w-[1.34631rem] h-[0.99425rem]"
                              src={StarIcon}
                              alt="star-icon"
                            />
                            <p className="text-white pb-1">{t("special_ad")}</p>
                          </>
                        )}
                      </div>
                      <div className="flex flex-row items-start justify-center gap-x-2">
                        <div
                          id="favoriteClick"
                          className={`bg-white pt-1 rounded-full flex items-center justify-center ${
                            isMobile ? "w-[1.625rem]" : "w-[2.375rem]"
                          } ${isMobile ? "h-[1.62rem]" : "h-[2.375rem]"}`}
                          onClick={handleFavoriteClick}
                        >
                          {!favoriteState ? (
                            <HeartOutlined
                              style={{
                                fontSize: isMobile ? "1rem" : "1.25rem",
                              }}
                            />
                          ) : (
                            <HeartFilled
                              style={{
                                fontSize: isMobile ? "1rem" : "1.25rem",
                                color: "red",
                              }}
                            />
                          )}
                        </div>
                        <ShareButton url={window.location.href}>
                          <img
                            alt="share"
                            src={ShareButtonIcon}
                            className={`${
                              isMobile
                                ? "w-[1.65rem] h-[1.65rem]"
                                : "w-[2.35rem] h-[2.35rem]"
                            }`}
                          />
                        </ShareButton>
                      </div>
                    </div>
                  </div>
                  <img
                    id="mainViewPostImage"
                    alt="img"
                    className={`mainPostImage rounded-xl w-full ${
                      fadeState ? styles.fadeOutBanner : styles.fadeInBanner
                    } mainImg`}
                    // onClick={minimizeImage}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "100%",
                      height: "100%",
                      // height: isMobile ? "18rem" : "30rem",
                      // height: zoomedIn ? "100%" : "",
                      // width: zoomedIn ? "100%" : "",
                      objectFit: "cover",
                      backgroundColor: "#7A7A7D",
                      transition: "all 0.4s ease-in-out",
                    }}
                    src={mainImage}
                  />
                </div>
                <CoolLightbox
                  images={post?.post_medias}
                  open={openLightbox}
                  currentIndex={
                    post?.post_medias?.findIndex(
                      (image: any) => image?.media_url === mainImage
                    ) as number
                  }
                  closeLightbox={() => {
                    setOpenLightbox(false);
                    setAutoplay(true);
                  }}
                />

                {/* Black Overlay over whole screen */}
                <div
                  className={`${openLightbox ? styles.open : styles.close} ${
                    styles.overlay
                  }`}
                ></div>
                {post && post?.post_medias?.length > 1 && (
                  <>
                    <CarImagesCarousel
                      data={post?.post_medias}
                      // onClick={setSelectedImage}
                      setFade={handleFade}
                      autoplay={autoplay}
                      setAutoplay={setAutoplay}
                      // isLightboxOpen={openLightbox}
                      // selectedImageIndex={selectedImageIndex}
                      // setSelectedImageIndex={setSelectedImageIndex}
                    />
                  </>
                )}

                <CustomCarInfoCard
                  isMobile={isMobile}
                  title={post?.title}
                  cash={post?.price}
                  installmentPrice={post?.price}
                  created_at={post?.created_at}
                  downPayment={post?.down_payment}
                  postId={post?.id}
                  condition={post?.car_condition}
                  paymentType={post?.payment_type}
                  brandName={post?.brand}
                  installments={`24/130,000`}
                />

                <div
                  className={`w-full bg-white rounded-xl mb-6 flex flex-col ${
                    currentLanguage === "ar" ? "items-end" : "items-start"
                  } flex-wrap gap-y-4 ${styles.shadow} ${
                    styles.descriptionSection
                  }`}
                >
                  <p
                    className={`font-bold ${
                      isMobile ? "text-[0.875rem]" : "text-lg"
                    }`}
                  >
                    {t("viewpost_description")}
                  </p>
                  <p
                    className={`${
                      currentLanguage === "ar" ? "text-right" : "text-left"
                    } ${isMobile ? "text-[0.75rem]" : "text-sm"}`}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: post?.description }}
                    ></div>
                  </p>
                </div>

                {/* CAR DETAILS */}
                {post?.category_id === 1 ? (
                  <CustomCarDetailCard
                    data={[
                      {
                        text: post?.production_date,
                        icon: calender,
                      },
                      {
                        text: post?.car_model,
                        icon: carBorder,
                      },
                      {
                        text: post?.brand,
                        icon: carBorder,
                      },
                      {
                        text: post?.kilo_meterage,
                        icon: tachometer,
                      },
                      {
                        text: post?.car_condition,
                        icon: carInsurance,
                      },
                      {
                        text: post?.transmission_type,
                        icon: transmission,
                      },
                      {
                        text: post?.fuel_type,
                        icon: gas,
                      },
                      {
                        text: post?.engine_capacity,
                        icon: carEngine,
                      },
                      {
                        text: post?.car_type,
                        icon: sedan,
                      },
                      {
                        text: post?.payment_type,
                        icon: payment,
                      },
                      {
                        text: post?.color,
                        icon: color,
                      },
                    ]}
                    isMobile={isMobile}
                  />
                ) : post?.category_id === 2 ? (
                  <CustomCarDetailsCardRent
                    data={[
                      {
                        text: post?.production_date,
                        icon: calender,
                      },
                      {
                        text: post?.car_model,
                        icon: carBorder,
                      },
                      {
                        text: post?.brand,
                        icon: carBorder,
                      },
                      {
                        text: post?.kilo_meterage,
                        icon: tachometer,
                      },
                      {
                        text: post?.car_condition,
                        icon: carInsurance,
                      },
                      {
                        text: post?.transmission_type,
                        icon: transmission,
                      },
                      {
                        text: post?.fuel_type,
                        icon: gas,
                      },
                      {
                        text: post?.engine_capacity,
                        icon: carEngine,
                      },
                      {
                        text: post?.car_type,
                        icon: sedan,
                      },
                      {
                        text: post?.payment_type,
                        icon: payment,
                      },
                      {
                        text: post?.color,
                        icon: color,
                      },
                      {
                        text: post?.car_rental_type,
                        icon: rentalDuration,
                      },
                      {
                        text: post?.driver_status,
                        icon: rentalDriver,
                      },
                    ]}
                    isMobile={isMobile}
                  />
                ) : post?.category_id === 3 ? (
                  <CustomCarDetailsCardMaintenance
                    data={[
                      {
                        text: post?.service_name,
                        icon: spareParts,
                      },
                      {
                        text: post?.brands,
                        icon: carBorder,
                      },
                    ]}
                    isMobile={isMobile}
                  />
                ) : post?.category_id === 4 ? (
                  <CustomCarDetailsCardSpare
                    data={[
                      {
                        text: post?.brand,
                        icon: carBorder,
                      },
                      {
                        text: post?.spare_part,
                        icon: spareParts,
                      },
                      {
                        text: post?.condition,
                        icon: sparePartsCondition,
                      },
                    ]}
                    isMobile={isMobile}
                  />
                ) : (
                  <></>
                )}

                {post?.features?.length > 0 ? (
                  <FeaturesCard
                    isMobile={isMobile}
                    featuresData={post?.features}
                  />
                ) : (
                  <></>
                )}

                <div
                  className={`w-full bg-white rounded-xl mb-6 flex flex-col ${
                    currentLanguage === "ar" ? "items-end" : "items-start"
                  } gap-y-3 ${styles.mapSection} ${styles.shadow}`}
                >
                  <p
                    className={`font-bold ${
                      isMobile ? "text-[0.9375rem]" : "text-lg"
                    }`}
                  >
                    {t("viewpost_location")}
                  </p>
                  <p
                    className={`${
                      currentLanguage === "ar" ? "text-right" : "text-left"
                    } mb-3 ${isMobile ? "text-[0.625rem]" : ""}`}
                  >
                    {post?.address}
                  </p>

                  {post?.lat !== null && post?.long !== null && (
                    <>
                      <div
                        style={{
                          backgroundImage: `url(${MapImage})`,
                        }}
                        className={`w-full rounded-[0.625rem] h-[15.36094rem] flex items-center justify-center`}
                      >
                        <a
                          href={`https://www.google.com/maps?q=${mapState?.lat},${mapState?.lng}`}
                        >
                          <CustomButton
                            className={`${
                              isMobile ? "w-[7.5rem]" : "w-[13rem]"
                            } ${isMobile ? "h-[1.9375rem]" : "h-[3.1875rem]"} ${
                              isMobile
                                ? "rounded-[0.3125rem]"
                                : "rounded-[0.625rem]"
                            }`}
                            color={`var(--new-blue)`}
                          >
                            <p
                              className={`text-white ${
                                isMobile
                                  ? "text-[0.625rem]"
                                  : "text-[0.9375rem]"
                              }`}
                            >
                              {t("view_full_map")}
                            </p>
                          </CustomButton>
                        </a>
                      </div>
                    </>
                  )}
                </div>

                {!isMobile && (
                  <div dir="rtl" className="w-full flex flex-col items-start">
                    <p>{t("similar_posts")}</p>
                    <PostCardCarousel data={similar_posts} />
                  </div>
                )}

                {isMobile && (
                  <div className="w-full flex flex-col items-center gap-y-6">
                    <UserInfoCard
                      id={post?.uuid}
                      post={post}
                      viewsCount={post?.views_count}
                      callsCount={post?.contact_count}
                      whatsappCount={post?.whatsapp_contact_count}
                      customer={post?.customer}
                      phoneNumber={post?.customer_phone}
                      customerId={post?.customer_id}
                      whatsappNumber={
                        post?.customer_whats_app_no
                          ? post?.customer_whats_app_no
                          : post?.customer_phone
                      }
                      handleShowPhoneClick={handleShowPhoneClick}
                      sendMessageOnClick={handleSendMessage}
                      totalPosts={post?.total_posts}
                      isMobile={isMobile}
                      isFavorited={post?.favourited}
                      customerImage={post?.customer_image}
                    />
                    <CustomButton
                      className={`${styles.viewInsurancePageButton} ${styles.shadow} w-full flex flex-row items-center justify-around h-[65px]`}
                      color={"white"}
                      onClick={() =>
                        navigate(`/${currentLanguage}/car-insurance`)
                      }
                    >
                      <div
                        dir={isArabic ? "ltr" : "rtl"}
                        className="w-full flex flex-row items-center justify-around"
                      >
                        <img
                          src={BlueArrowLeftIcon}
                          style={
                            isArabic ? {} : { transform: "rotate(180deg)" }
                          }
                        />
                        <div
                          dir={isArabic ? "rtl" : "ltr"}
                          className="flex flex-row items-center gap-x-3 justify-center"
                        >
                          <img
                            src={FreeInspectionIcon}
                            className="w-[40px] h-[40px]"
                          />
                          <div className="flex flex-col items-start gap-y-2">
                            <p className="font-bold text-ellipsis text-[15px]">
                              {t("viewpost_insure_car_title")}
                            </p>
                            <p className="text-[12px] opacity-[0.5]">
                              {t("viewpost_insure_car_body")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CustomButton>

                    <div
                      dir={dir}
                      className={`w-full flex flex-col gap-y-3 bg-white px-3 py-2 rounded-xl ${styles.shadow}`}
                    >
                      <div
                        className={`w-full flex flex-row justify-start items-center`}
                      >
                        <p className="text-[var(--new-blue)]">
                          {t("viewpost_safety_percautions")}
                        </p>
                      </div>
                      <div
                        className={`w-full flex justify-start items-center gap-x-2`}
                      >
                        <div
                          className={`${styles.blueBg} w-4 h-4 rounded-full`}
                        ></div>
                        <p className="text-xs">{t("percaution_one")}</p>
                      </div>
                      <div
                        className={`w-full flex justify-start items-center gap-x-2`}
                      >
                        <div
                          className={`${styles.blueBg} w-4 h-4 rounded-full`}
                        ></div>
                        <p className="text-xs">{t("percaution_two")}</p>
                      </div>
                      <div
                        className={`w-full flex justify-start items-center gap-x-2`}
                      >
                        <div
                          className={`${styles.blueBg} w-4 h-4 rounded-full`}
                        ></div>
                        <p className="text-xs">{t("percaution_three")}</p>
                      </div>
                      <div
                        className={`w-full flex justify-start items-center gap-x-2`}
                      >
                        <div
                          className={`${styles.blueBg} w-4 h-4 rounded-full`}
                        ></div>
                        <p className="text-xs">{t("percaution_four")}</p>
                      </div>
                    </div>
                    <div className="w-full flex flex-row items-center justify-center">
                      <ReportModalComponent dir={dir} isArabic={isArabic} />
                    </div>
                    {isMobile && (
                      <div
                        dir="rtl"
                        className="w-full flex flex-col items-start"
                      >
                        <p>{t("similar_posts")}</p>
                        <PostCardCarousel data={similar_posts} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {!loading ? (
          <div className="w-[100vw]">
            <Footer />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ViewPostScreen;
