import
{
    CITIES_LOADING,
    CITIES_SUCCESS,
    CITIES_FAILURE
}
from "redux/types";

const initialState = {
    citiesLoading: false,
    cities: null,
    errors: null
}

export const listCitiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CITIES_LOADING :
            return {...state, citiesLoading: action.payload};
        case CITIES_SUCCESS :
            return {...state, cities: action.payload.records};
        case CITIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}