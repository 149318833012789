import { combineReducers } from "redux";
import { changeCurrentPageReducer } from "redux/reducers/CurrentPageReducer/changeCurrentPageReducer";
import { listCarBodiesReducer } from "redux/reducers/CarBodiesReducers/listCarBodiesReducer";
import { listEngineCapacityReducer } from "redux/reducers/EngineCapacityReducers/listEngineCapacityReducer";
import { listCarConditionReducer } from "redux/reducers/CarConditionReducers/listCarConditionReducer";
import { listPaymentTypesReducer } from "redux/reducers/PaymentTypesReducers/listPaymentTypesReducer";
import { listYearsReducer } from "redux/reducers/YearsReducers/listYearsReducer";
import { listCategoriesReducer } from "redux/reducers/CategoriesReducers/listCategoriesReducer";
import { listBranchesReducer } from "redux/reducers/BranchesReducers/listBranchesReducer";
import { listCarMakerReducer } from "redux/reducers/CarMakerReducers/listCarMakerReducer";
import { listFeaturedPostsReducer } from "redux/reducers/FeaturedPostsReducers/listFeaturedPostsReducer";
import { listSinglePostReducer } from "redux/reducers/PostReducers/listSinglePostReducer";
import { changeLanguageReducer } from "redux/reducers/LanguageReducers/changeLanguageReducer";
import { listFaqsReducer } from "redux/reducers/FAQsReducer/listFaqsReducer";
import { sendMessageReducer } from "redux/reducers/SendMessageReducer/sendMessageReducer";
import { advancedSearchFilterReducer } from "redux/reducers/SearchFiltersReducers/basicSearchFilterReducer";
import { listTransmissionTypesReducer } from "redux/reducers/TransmissionTypesReducers/listTransmissionTypesReducer";
import { listFuelTypesReducer } from "redux/reducers/FuelTypesReducers/listFuelTypesReducer";
import { listarticlesReducer } from "redux/reducers/Articles/Articles";
import { listCarModelsReducer } from "redux/reducers/CarModelsReducers/listCarModelsReducer";
import { listFeaturesReducer } from "redux/reducers/FeaturesReducers/listFeaturesReducer";
import { listColorsReducer } from "redux/reducers/ColorsReducers/listColorsReducer";
import { listCitiesReducer } from "redux/reducers/CitiesReducers/listCitiesReducer";
import { mobileLoginReducer } from "redux/reducers/LoginReducers/mobileLoginReducer";
import { verifyOtpReducer } from "redux/reducers/LoginReducers/verifyOtpReducer";
import { createPostReducer } from "redux/reducers/CreatePostReducers/createPostReducer";
import { listPostStatusReducer } from "redux/reducers/PostStatusReducers/listPostStatusReducer";
import { listProfilePostsReducer } from "redux/reducers/ProfileDataReducers/listProfilePostsReducer";
import { aboutUsReducer } from "redux/reducers/AboutUsReducers/aboutUsReducer";
import { privacyPolicyReducer } from "redux/reducers/PrivacyPolicyReducers/privacyPolicyReducer";
import { termsOfUseReducer } from "redux/reducers/TermsOfUseReducers/termsOfUseReducer";
import { displayMobileReducer } from "redux/reducers/DisplayMobileReducers/displayMobileReducer";
import { getVendorDataReducer } from "redux/reducers/VendorDataReducers/getVendorDataReducers";
import { updateCustomerDataReducer } from "redux/reducers/CustomerReducers/updateCustomerDataReducer";
import { listVendorPostsReducer } from "redux/reducers/VendorPostsReducers/listVendorPostsReducer";
import { listAllChatsReducer } from "redux/reducers/ChatReducers/listAllChatsReducer";
import { listMessagesReducer } from "redux/reducers/ChatReducers/listMessagesReducer";
import { sendChatMessageReducer } from "redux/reducers/ChatReducers/sendChatMessageReducer";
import { suspendPostReducer } from "redux/reducers/SuspendPostReducers/suspendPostReducer";
import { reactivatePostReducer } from "redux/reducers/ReactivatePostReducer/reactivatePostReducer";
import { viewArticleReducer } from "redux/reducers/ViewArticleReducer/viewArticleReducer";
import { setProgressStateReducer } from "redux/reducers/SetProgressStateReducer/setProgressStateReducer";
import { postCategoryStateReducer } from "redux/reducers/CreatePostCategoryReducer/postCategoryStateReducer";
import { listRentalTypesReducer } from "redux/reducers/RentalTypesReducer/listRentalTypesReducer";
import { listRentalDriverReducer } from "redux/reducers/CreatePostReducers/listRentalDriverReducer";
import { listServiceTypesReducer } from "redux/reducers/CreatePostReducers/listServiceTypesReducer";
import { listSparePartTypeReducer } from "redux/reducers/CreatePostReducers/listSparePartTypeReducer";
import { listSparePartConditionReducer } from "redux/reducers/CreatePostReducers/listSparePartConditionReducer";
import { viewPostMainImageReducer } from "redux/reducers/ViewPostMainImageReducer/viewPostMainImageReducer";
import { setMapCoordinatesReducer } from "redux/reducers/SetMapCoordinates/setMapCoordinatesReducer";
import { setMapCoordinatesPostReducer } from "redux/reducers/SetMapCoordinatesPost/setMapCoordinatesPostReducer";
import { postPaymentTypeReducer } from "redux/reducers/PostPaymentTypeReducer/postPaymentTypeReducer";
import { updatePostReducer } from "redux/reducers/UpdatePostReducer/updatePostReducer";
import { getUserDataReducer } from "redux/reducers/GetUserDataReducer/getUserDataReducer";
import { profilePostStatusReducer } from "redux/reducers/ProfileScreenReducers/profilePostStatusReducer";
import { profilePostCategoryReducer } from "redux/reducers/ProfileScreenReducers/profilePostCategoryReducer";
import { listAllFeaturedAdsReducer } from "redux/reducers/FeaturedPostsReducers/listAllFeaturedAdsReducer";
import { drawerStatusReducer } from "redux/reducers/DrawerStatusReducers/drawerStatusReducer";
import { listSortByFiltersReducer } from "redux/reducers/SortByFiltersReducer/listSortByFiltersReducer";
import { favouritePostReducer } from "redux/reducers/FavouritePostReducers/favouritePostReducer";
import { listFavouritesReducer } from "redux/reducers/FavouritePostReducers/listFavouritesReducer";
import { listNotificationsReducer } from "redux/reducers/NotificationsReducer/listNotificationsReducer";
import { amanleekAuthenticationReducer } from "redux/reducers/AmanleekReducers/Authentication/amanleekAuthenticationReducer";
import { amanleekListBrandsReducer } from "redux/reducers/AmanleekReducers/Brands/amanleekListBrandsReducer";
import { amanleekListCarModelsReducer } from "redux/reducers/AmanleekReducers/CarModels/amanleekListCarModelsReducer";
import { createInsuranceEntryReducer } from "redux/reducers/CreateInsuranceEntryReducer/createInsuranceEntryReducer";
import { amanleekLeadInsertReducer } from "redux/reducers/AmanleekReducers/LeadInsert/amanleekLeadInsertReducer";
import { listWeekDaysReducer } from "redux/reducers/WeekDaysReducer/listWeekDaysReducer";
import { listExhibitorServicesReducer } from "redux/reducers/ExhibitorServicesReducer/listExhibitorServicesReducer";
import { listBranchTypesReducer } from "redux/reducers/BranchTypesReducer/listBranchTypesReducer";
import { listExhibitorDataReducer } from "redux/reducers/ExhibitorDataReducers/listExhibitorDataReducer";
import { updateExhibitorDataReducer } from "redux/reducers/ExhibitorDataReducers/updateExhibitorDataReducer";
import { listHomeScreenExhibitorsReducer } from "redux/reducers/HomeScreenExhibitorsReducer/listHomeScreenExhibitorsReducer";
import { listExhibitionsReducer } from "redux/reducers/ExhibitionsReducers/listExhibitionsReducer";
import { listStoriesReducer } from "redux/reducers/StoriesReducers/ListStoriesReducer/listStoriesReducer";
import { listCustomerStoriesReducer } from "redux/reducers/StoriesReducers/CustomerStoriesReducer/listCustomerStoriesReducer";
import { createStoryReducer } from "redux/reducers/StoriesReducers/CreateStoryReducer/createStoryReducer";
import { getExhibitorStatisticsReducer } from "redux/reducers/ExhibitorsReducers/getExhibitorStatisticsReducer";
import { updateSocialLinksReducer } from "redux/reducers/ExhibitorsReducers/updateSocialLinksReducer";
import { updateGeneralDataReducer } from "redux/reducers/ExhibitorsReducers/updateGeneralDataReducer";
import { listMyStoriesReducer } from "redux/reducers/StoriesReducers/ListMyStoriesReducer/listMyStoriesReducer";
import { createBranchReducer } from "redux/reducers/ExhibitorsReducers/createBranchReducer";
import { updateBranchReducer } from "redux/reducers/ExhibitorsReducers/updateBranchReducer";
import { listFavouriteExhibitorsReducer } from "redux/reducers/ListFavouriteExhibitorsReducer/listFavouriteExhibitorsReducer";
import { makePostSpecialReducer } from "redux/reducers/MakePostSpecialReducer.ts/makePostSpecialReducer";
import { deleteStoryReducer } from "redux/reducers/StoriesReducers/DeleteStoryReducer/deleteStoryReducer";
import { repostStoryReducer } from "redux/reducers/StoriesReducers/RepostStoryReducer/repostStoryReducer";
import { storeReducer } from "redux/reducers/StoreReducer/storeReducer";
import { activateOutstandingPostReducer } from "redux/reducers/ActivateOutstandingPostReducer/activateOutstandingPostReducer";

export const appReducer = combineReducers({
  storeReducer,
  drawerStatusReducer,
  listSortByFiltersReducer,
  profilePostStatusReducer,
  profilePostCategoryReducer,
  getUserDataReducer,
  postPaymentTypeReducer,
  setMapCoordinatesPostReducer,
  setMapCoordinatesReducer,
  viewPostMainImageReducer,
  listRentalDriverReducer,
  listServiceTypesReducer,
  listSparePartTypeReducer,
  listSparePartConditionReducer,
  getVendorDataReducer,
  changeCurrentPageReducer,
  listCarBodiesReducer,
  setProgressStateReducer,
  postCategoryStateReducer,
  suspendPostReducer,
  reactivatePostReducer,
  viewArticleReducer,
  listEngineCapacityReducer,
  listCarConditionReducer,
  listPaymentTypesReducer,
  listYearsReducer,
  listCategoriesReducer,
  listBranchesReducer,
  listCarMakerReducer,
  listFeaturedPostsReducer,
  listSinglePostReducer,
  changeLanguageReducer,
  listFaqsReducer,
  sendMessageReducer,
  basicSearchFilterReducer: advancedSearchFilterReducer,
  listTransmissionTypesReducer,
  listFuelTypesReducer,
  listarticlesReducer,
  listCarModelsReducer,
  listFeaturesReducer,
  listColorsReducer,
  listCitiesReducer,
  mobileLoginReducer,
  verifyOtpReducer,
  createPostReducer,
  listPostStatusReducer,
  listProfilePostsReducer,
  aboutUsReducer,
  privacyPolicyReducer,
  termsOfUseReducer,
  updateCustomerDataReducer,
  listVendorPostsReducer,
  displayMobileReducer,
  listAllChatsReducer,
  listMessagesReducer,
  sendChatMessageReducer,
  listRentalTypesReducer,
  updatePostReducer,
  listAllFeaturedAdsReducer,
  favouritePostReducer,
  listFavouritesReducer,
  listNotificationsReducer,
  amanleekAuthenticationReducer,
  amanleekListBrandsReducer,
  amanleekListCarModelsReducer,
  createInsuranceEntryReducer,
  amanleekLeadInsertReducer,
  listWeekDaysReducer,
  listExhibitorServicesReducer,
  listBranchTypesReducer,
  listExhibitorDataReducer,
  updateExhibitorDataReducer,
  listHomeScreenExhibitorsReducer,
  listExhibitionsReducer,
  listStoriesReducer,
  listCustomerStoriesReducer,
  createStoryReducer,
  getExhibitorStatisticsReducer,
  updateSocialLinksReducer,
  updateGeneralDataReducer,
  listMyStoriesReducer,
  createBranchReducer,
  updateBranchReducer,
  listFavouriteExhibitorsReducer,
  makePostSpecialReducer,
  deleteStoryReducer,
  repostStoryReducer,
  activateOutstandingPostReducer
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
