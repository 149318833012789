import api from "api";
import { activateOutstandingPostEndPoint } from "api/apiUrls";
import store from "redux/store";

export const activateOutstandingPostService = async (postUUID: any) => {
    const response = await api.post(
        activateOutstandingPostEndPoint(postUUID),
        {},
        {
            headers: {
                'Authorization' : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}