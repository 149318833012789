import api from "api";
import { getExhibitorStatisticsEndPoint } from "api/apiUrls";
import store from "redux/store";

export const getExhibitorStatisticsService = async () => {
    const response = await api.get(getExhibitorStatisticsEndPoint(), {headers: {
        "Authorization" : `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
    }});

    return response;
}