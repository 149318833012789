import api from "api";
import { sendChatMessageEndPoint } from "api/apiUrls";
import store from "redux/store";

export const sendChatMessageService = async (userId: any, message: any) => {
    const response = await api.post(
        sendChatMessageEndPoint(userId),
        {
            message: message
        },
        {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${store.getState().verifyOtpReducer?.userData?.token}`
            }
        }
    )
    return response;
}