import CustomButton from "components/CustomButton/CustomButton";
import styles from "components/V3/SquareExhibitorCard/SquareExhibitorCard.module.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { StarIcon } from "assets/icons/V3";

interface SquareExhibitorCardProps {
  id: any;
  image: string;
  name: string;
  description: string;
  isSpecial: boolean;
  width: any;
  isMobile: any;
}

const SquareExhibitorCard: FC<SquareExhibitorCardProps> = ({
  id,
  image,
  name,
  description,
  isSpecial,
  width,
  isMobile,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  return !isMobile ? (
    <div
      className={`${width} h-[17.5rem] bg-white ${styles.shadow} rounded-[0.625rem] flex flex-col items-center justify-around px-3 py-3`}
    >
      {isSpecial && (
        <div className="w-full flex flex-row items-center justify-start">
          <div className="h-[1.3125rem] w-[4rem] border-[0.5px] border-[var(--souq-orange)] rounded-xl flex items-center justify-center">
            <p className="text-[0.625rem]">{t("special_exhibitor")}</p>
          </div>
        </div>
      )}
      <div className="w-full flex flex-col items-center gap-y-2">
        <img
          alt="exhibitor-profile"
          src={image}
          className="w-[5.4485rem] h-[5.4485rem] rounded-full object-cover"
        />
        <p className={`text-[1.125rem]`}>{name}</p>
        <p
          className={`text-[0.8125rem] opacity-[0.5] line-clamp-2 overflow-ellipsis`}
        >
          {description === "" || description === null
            ? "No Description"
            : description}
        </p>
      </div>
      <CustomButton
        className={`h-[2.92856rem] w-[95%] flex items-center justify-center rounded-[0.3125rem]`}
        color={"var(--new-blue)"}
        onClick={() => {
          navigate(`/${currentLanguage}/vendor/${id}`);
        }}
      >
        <p className={`text-white`}>{t("visit_exhibitor_page")}</p>
      </CustomButton>
    </div>
  ) : (
    <div
      dir="rtl"
      className={`w-full h-[5.75rem] flex flex-row items-center justify-between px-3 gap-x-2 bg-white ${styles.shadow} rounded-[0.625rem]`}
    >
      <div className="flex flex-row items-center gap-x-2">
        <img
          alt="profile"
          src={image}
          className={`w-[3.75rem] h-[3.75rem] rounded-full object-cover`}
        />
        <div className="flex h-full py-4 flex-col items-start gap-y-2">
          <div className="flex flex-row gap-x-2">
            <p className="text-[0.9375rem]">{name}</p>
            {isSpecial && <img src={StarIcon} />}
          </div>
          <p className="opacity-[0.5] text-[0.625rem] line-clamp-2 overflow-ellipsis">
            {description === "" || description === null
              ? "No Description"
              : description}
          </p>
        </div>
      </div>
      <CustomButton
        className={`h-[3.3125rem] w-[3.6875rem] flex items-center justify-center rounded-[0.3125rem]`}
        color={"var(--new-blue)"}
        onClick={() => {
          navigate(`/${currentLanguage}/vendor/${id}`);
        }}
      >
        <p className={`text-white text-[0.625rem]`}>
          {t("visit_exhibitor_page")}
        </p>
      </CustomButton>
    </div>
  );
};

export default SquareExhibitorCard;
