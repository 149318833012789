import 
{
    WEEK_DAYS_LOADING,
    WEEK_DAYS_SUCCESS,
    WEEK_DAYS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listWeekDaysService } from "services/listWeekDaysService";

export const listWeekDaysAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: WEEK_DAYS_LOADING, payload: true});
        const response = await listWeekDaysService();
        dispatch({type: WEEK_DAYS_SUCCESS, payload: response.data}); 
    } catch (err) {
        dispatch({type: WEEK_DAYS_FAILURE, payload: err});
    } finally {
        dispatch({type: WEEK_DAYS_LOADING, payload: false});
    }
}