import
{
    UPDATE_GENERAL_DATA_LOADING,
    UPDATE_GENERAL_DATA_SUCCESS,
    UPDATE_GENERAL_DATA_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateGeneralDataService } from "services/ExhibitorServices/updateGeneralDataService";

export const updateGeneralDataAction = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_GENERAL_DATA_LOADING, payload: true});
        const response = await updateGeneralDataService(data);
        dispatch({type: UPDATE_GENERAL_DATA_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: UPDATE_GENERAL_DATA_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_GENERAL_DATA_LOADING, payload: false});
    }
}