import React, { FC, useEffect, useState } from "react";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import styles from "components/CoolLightbox/CoolLightbox.module.css";

interface CoolLightboxProps {
  images: any;
  open: boolean;
  currentIndex: number;
  closeLightbox: any;
}

// using react-spring-lightbox
const CoolLightbox: FC<CoolLightboxProps> = ({
  images,
  open,
  currentIndex,
  closeLightbox,
}) => {
  const [currentImageIndex, setCurrentIndex] = useState(currentIndex);
  //
  //
  useEffect(() => {
    setCurrentIndex(currentIndex);
    //
  }, [currentIndex]);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images?.length &&
    setCurrentIndex(currentImageIndex + 1);

  const imageList: ImagesListType = images
    ? images?.map((image: any) => {
        return {
          src: image.media_url,
          loading: "lazy",
          alt: image.alt,
        };
      })
    : [];

  return (
    <Lightbox
      isOpen={open}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={imageList}
      currentIndex={currentImageIndex}
      style={{
        zIndex: 999,
      }}
      /* Add your own UI */
      renderHeader={() => (
        // white X Button on top right
        <i className={`${styles.close} fa-solid`} onClick={closeLightbox}>
          x
        </i>
      )}
      // renderFooter={() => (<CustomFooter />)}
      renderPrevButton={() => (
        <i
          id="left"
          className={`${styles.arrows}
          ${
            currentImageIndex === 0 &&
            styles.disabled + " desk:visible invisible "
          }
          desk:ms-4 fa-solid fa-angle-left`}
          onClick={gotoPrevious}
        ></i>
      )}
      renderNextButton={() => (
        <i
          id="right"
          className={`${styles.arrows} 
          ${
            currentImageIndex === images?.length - 1 &&
            styles.disabled + " desk:visible invisible "
          }
          desk:me-4 fa-solid fa-angle-right`}
          // font size bigger on desktop
          onClick={gotoNext}
        ></i>
      )}
      // renderImageOverlay={() => (<ImageOverlayComponent >)}

      /* Add styling */
      // className="cool-class"
      // style={{
      // background: "rgba(0,0,0,0.8)",
      // }}
      /* Handle closing */
      onClose={closeLightbox}
      /* Use single or double click to zoom */
      // singleClickToZoom

      /* react-spring config for open/close animation */
      pageTransitionConfig={{
        from: { transform: "scale(0.75)", opacity: 0 },
        enter: { transform: "scale(1)", opacity: 1 },
        leave: { transform: "scale(1)", opacity: 0 },
        config: { mass: 0.5, tension: 320, friction: 32 },
      }}
    />
  );
};

export default CoolLightbox;
