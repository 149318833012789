import api from "api";
import { getVendorDataEndPoint } from "api/apiUrls";
import store from "redux/store";

const getVendorDataService = async (id: any) => {
  const response = await api.get(getVendorDataEndPoint(id), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        store.getState()?.verifyOtpReducer?.userData?.token
      }`,
    },
  });
  return response;
};

export default getVendorDataService;
