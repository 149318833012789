import {
  VENDOR_POSTS_LOADING,
  VENDOR_POSTS_SUCCESS,
  VENDOR_POSTS_FAILURE,
} from "redux/types";

const initialState = {
  vendorPostsLoading: false,
  vendorPosts: null,
  vendorBranches: null,
  isExhibitor: null,
  currentPage: null,
  lastPage: null,
  errors: null,
};

export const listVendorPostsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case VENDOR_POSTS_LOADING:
      return { ...state, vendorPostsLoading: action.payload };
    case VENDOR_POSTS_SUCCESS:
      return {
        ...state,
        vendorPosts: action.payload.posts.data,
        vendorBranches: action.payload.branches,
        isExhibitor: action.payload.is_exhibitor,
        currentPage: action.payload.posts.current_page,
        lastPage: action.payload.posts.last_page,
      };
    case VENDOR_POSTS_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
