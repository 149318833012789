import { useSelector } from "react-redux";
import { useEffect, CSSProperties } from "react";
import { RootState } from "redux/rootReducer";
import { useUtilities } from "./useUtilities";
import { listBranchTypesAction } from "redux/actions/BranchTypesAction/listBranchTypesAction";
import { listCitiesAction } from "redux/actions/CitiesActions/listCitiesAction";
import { listExhibitorServicesAction } from "redux/actions/ExhibitorServicesAction/listExhibitorServicesAction";
import { listWeekDaysAction } from "redux/actions/WeekDaysAction/listWeekDaysAction";
import { listCarMakerAction } from "redux/actions/CarMakerActions/listCarMakerAction";

export const useDropdowns = () => {
  const { dispatch } = useUtilities();

  // REDUCER DROPWDOWNS
  const { branchTypesLoading, branchTypes } = useSelector(
    (state: RootState) => state.listBranchTypesReducer
  );
  const branchTypesDropdown = branchTypes?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const { weekDaysLoading, weekDays } = useSelector(
    (state: RootState) => state.listWeekDaysReducer
  );
  const weekDaysDropdown = weekDays?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const { exhibitorServicesLoading, exhibitorServices } = useSelector(
    (state: RootState) => state.listExhibitorServicesReducer
  );
  const exhibitorServicesDropdown = exhibitorServices?.map((item: any) => {
    return {
      value: item.key,
      label: item.name,
    };
  });

  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const citiesDropdown = cities?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const { carMakersLoading, carMakers } = useSelector(
    (state: RootState) => state.listCarMakerReducer
  );

  const { carModelsLoading, carModels } = useSelector(
    (state: RootState) => state.listCarModelsReducer
  );

  const carMakerDropdown = carMakers?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
      label_other: item.name_other,
      icon: item.image,
    };
  });

  const carModelsDropdown = carModels?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
      label_other: item.name_other,
    };
  });

  // DISPATCH ALL DROPDOWNS
  useEffect(() => {
    dispatch(listBranchTypesAction());
    dispatch(listWeekDaysAction());
    dispatch(listExhibitorServicesAction());
    dispatch(listCitiesAction());
    dispatch(listCarMakerAction());
  }, []);

  // CUSTOM DROPDOWNS
  interface TimeOption {
    value: string;
    label: string;
  }

  function generateTimesDropdown(
    timeFormat: "12-hour" | "24-hour",
    incrementInMinutes: number,
    startTime: number = 0,
    endTime: number = 24
  ): TimeOption[] {
    if (incrementInMinutes <= 0) {
      throw new Error("Increment should be greater than 0");
    }

    const options: TimeOption[] = [];
    const formatOptions: Intl.DateTimeFormatOptions = {
      hour12: timeFormat === "12-hour",
      hour: "numeric",
      minute: "numeric",
    };

    let currentHour: number = startTime;
    let currentMinute: number = 0;

    while (
      currentHour < endTime ||
      (currentHour === endTime && currentMinute === 0)
    ) {
      if (currentHour >= 24) {
        break; // Ensure the loop exits when reaching the end time
      }

      const time: Date = new Date(0, 0, 0, currentHour, currentMinute);
      const timeString: string = time.toLocaleTimeString(
        "en-US",
        formatOptions
      );

      options.push({ value: timeString, label: timeString });

      currentMinute += incrementInMinutes;

      if (currentMinute >= 60) {
        currentMinute = 0;
        currentHour += 1;
      }
    }

    return options;
  }

  return {
    generateTimesDropdown,
    branchTypesDropdown,
    weekDaysDropdown,
    carModelsDropdown,
    carMakerDropdown,
    exhibitorServicesDropdown,
    citiesDropdown,
  };
};
