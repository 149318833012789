import
{
 AMANLEEK_CAR_MODELS_LOADING,
 AMANLEEK_CAR_MODELS_SUCCESS,
 AMANLEEK_CAR_MODELS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { amanleekListCarModelsService } from "ThirdPartyServices/Amanleek/amanleekListCarModelsService";

export const amanleekListCarModelsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: AMANLEEK_CAR_MODELS_LOADING, payload: true});
        const response = await amanleekListCarModelsService();
        dispatch({type: AMANLEEK_CAR_MODELS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: AMANLEEK_CAR_MODELS_FAILURE, payload: err});
    } finally {
        dispatch({type: AMANLEEK_CAR_MODELS_LOADING, payload: false});
    }
}