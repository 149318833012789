import 
{
    LIST_FAVOURITES_LOADING,
    LIST_FAVOURITES_SUCCESS,
    LIST_FAVOURITES_FAILURE

}
from "redux/types";

const initialState = {
    favouritePostsLoading: false,
    favouritePosts: null,
    currentPage: null,
    lastPage: null,
    errors: null
}

export const listFavouritesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_FAVOURITES_LOADING :
            return {...state, favouritePostsLoading: action.payload};
        case LIST_FAVOURITES_SUCCESS :
            return {...state, favouritePosts: action.payload.data.data, currentPage: action.payload.data.current_page, lastPage: action.payload.data.last_page};
        case LIST_FAVOURITES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;

    }
}