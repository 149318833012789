import
{
    SPARE_PART_CONDITION_LOADING,
    SPARE_PART_CONDITION_SUCCESS,
    SPARE_PART_CONDITION_FAILURE
}
from "redux/types";

const initialState = {
    sparePartConditionLoading: false,
    sparePartCondition: null,
    errors: null
}

export const listSparePartConditionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SPARE_PART_CONDITION_LOADING :
            return {...state, sparePartConditionLoading: action.payload}
        case SPARE_PART_CONDITION_SUCCESS :
            return {...state, sparePartCondition: action.payload.sparePartCondition}
        case SPARE_PART_CONDITION_FAILURE :
            return {...state, errors: action.payload}
        default:
            return state;
    }
}

