import
{
    CAR_MODELS_LOADING,
    CAR_MODELS_SUCCESS,
    CAR_MODELS_FAILURE
}
from "redux/types";

import { listCarModelsService } from "services/listCarModelsService";

import { Dispatch } from "redux";

export const listCarModelsAction = (carMakerId: string) => async (dispatch: Dispatch) => {
    try {   
        dispatch({type: CAR_MODELS_LOADING, payload: true});
        const response = await listCarModelsService(carMakerId);
        dispatch({type: CAR_MODELS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: CAR_MODELS_FAILURE, payload: err});
    } finally {
        dispatch({type: CAR_MODELS_LOADING, payload: false});
    }
}