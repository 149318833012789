import styles from "screens/V3/CreatePostScreen/CreatePostScreen.module.css";
import { Checkbox, Input, Select, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import Seo from "components/Seo/Seo";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postCategoryStateAction } from "redux/actions/CreatePostCategoryAction/postCategoryStateAction";
import { CheckOutlined } from "@ant-design/icons";
import { listCategoriesAction } from "redux/actions/CategoriesActions/listCategoriesAction";
import { setProgressStateAction } from "redux/actions/SetProgressStateAction/setProgressStateAction";
import { changeCurrentPageAction } from "redux/actions";
import { useNavigate } from "react-router";
import { useResponsive } from "hooks/useResponsive";
import { motion } from "framer-motion";

import BuyCarForm from "components/V3/CreatePostForms/BuyCarForm/BuyCarForm";
import RentCarForm from "components/V3/CreatePostForms/RentCarForm/RentCarForm";
import MaintenanceForm from "components/V3/CreatePostForms/MaintenanceForm/MaintenanceForm";
import SparePartsForm from "components/V3/CreatePostForms/SparePartsForm/SparePartsForm";
import { useUtilities } from "hooks/useUtilities";

const CreatePostScreen = () => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";
  const { t, navigate, dispatch, getUserData } = useUtilities();

  const { isMobile, isMax1240, isMax1280 } = useResponsive();

  const { verifyOtpLoading, userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  useEffect(() => {
    // getUserData();
    dispatch(changeCurrentPageAction(-1));
    dispatch(postCategoryStateAction(-1));
    dispatch(setProgressStateAction(-1));
    dispatch(listCategoriesAction());
    // if (!userData || !userData.token) {
    //   navigate("/login");
    // }
  }, []);

  const isValidCategory = (id: any) => {
    return id === 1 || id === 2 || id === 3 || id === 4;
  };

  const { categoriesLoading, categories } = useSelector(
    (state: RootState) => state.listCategoriesReducer
  );

  const { progressState } = useSelector(
    (state: RootState) => state.setProgressStateReducer
  );

  const { categoryState } = useSelector(
    (state: RootState) => state.postCategoryStateReducer
  );

  useEffect(() => {
    console.log("CATEGORY STATE: ", categoryState);
  }, [categoryState]);

  const progressStates = [
    {
      title: t("pictures"),
      state: 2,
    },
    {
      title: t("post_details"),
      state: 1,
    },
    {
      title: t("car_details"),
      state: 0,
    },
    {
      title: t("category"),
      state: -1,
    },
  ];

  const excludeCategories = (category: any) => {
    return (
      category.id !== 5 &&
      category.id !== 6 &&
      category.id !== 3 &&
      category.id !== 4
    );
  };

  const renderCards = (data: { items: any; numberPerRow?: any }) => {
    console.log("DATA: ", data.items);
    const numberPerRow: number = data.numberPerRow ?? isMobile ? 2 : 4;
    const gap = isMobile ? "0.4rem" : "1rem";
    const maxWidth = `calc(100% / ${numberPerRow} - ${Number(
      gap?.split("rem")[0]
    )}rem)`;
    return (
      <div className="w-full flex items-center justify-center mb-[1.75rem]">
        {!categoriesLoading && (
          <motion.div
            className={`w-[90%] flex flex-wrap flex-row items-center justify-between`}
            style={{ rowGap: `${gap.split("rem")[0]}9rem` }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {data.items.map((category: any, index: any) => {
              return (
                <div
                  className={`cursor-pointer ${styles.categoryOption} ${
                    category.id === categoryState
                      ? styles.categoryActive
                      : styles.categoryInactive
                  }`}
                  style={{ maxWidth: maxWidth }}
                  onClick={() => {
                    dispatch(postCategoryStateAction(category.id));
                  }}
                >
                  <p>{category.name}</p>
                  <img src={category.image} alt="" />
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    );
  };

  return (
    <>
      <Seo
        title="سوق كار - أضف إعلان"
        description="أضف إعلان"
        keywords="سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار"
      />
      <div
        className={`w-full flex flex-col items-center pt-[2.25rem] ${styles.mainWrapper}`}
        style={{
          backgroundColor: "var(--new-souq-background)",
          paddingInline: isMobile
            ? "0%"
            : isMax1240
            ? "10%"
            : isMax1280
            ? "20%"
            : "28%",
        }}
      >
        <p className="souq-xl souq-bold w-full flex items-center justify-center mb-7">
          أضف اعلانك
        </p>
        {/* PROGRESS INDICATOR */}
        <div
          className={`${styles.progressSection} w-full flex items-start justify-center`}
        >
          <div
            dir={dirFlip}
            className={`w-${
              isMobile ? "[95%]" : "[60%]"
            } flex flex-row items-center justify-between gap-x-${
              isMobile ? "2" : "5"
            }`}
          >
            {progressStates.map((item, index) => {
              return (
                <div
                  className={`${styles.progressText} w-full flex flex-col items-center justify-center gap-y-2`}
                >
                  <div
                    className={`${styles.progressLine} ${
                      item.state <= progressState
                        ? styles.progressLineActive
                        : styles.progressLineInactive
                    }`}
                  ></div>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* <p className="font-bold text-lg mt-[3.63rem] mb-[1.69rem]">
          {t("create_post_title")}
        </p> */}
        <div className="w-full mt-12">
          {progressState === -1 &&
            renderCards({
              items: categories
                ?.filter(excludeCategories)
                .map((item: any) => {
                  if (item?.id && item?.id === 1) {
                    item.name = t("sell");
                  }
                  return item;
                })
                .reverse(),
              numberPerRow: 2,
            })}
        </div>

        {isValidCategory(categoryState) && (
          <div className={`w-full flex flex-col items-center`}>
            {/* MAIN FORM CONTAINER */}
            <div
              className={`${styles.mainFormContainer} ${
                !isMobile && "bg-white"
              } flex flex-col gap-y-4 items-center rounded-[var(--radius-large)] ${
                isMobile ? styles.mobileWidth : styles.firstSection
              }`}
              style={{
                paddingInline: progressState != 2 ? "7%" : "3%",
                boxShadow: `${!isMobile ? "var(--new-souq-shadow)" : "none"}`,
                paddingBlock: `${!isMobile && "2.75rem"}`,
              }}
            >
              {isValidCategory(categoryState) ? (
                categoryState === 1 ? (
                  <BuyCarForm />
                ) : categoryState === 2 ? (
                  <RentCarForm />
                ) : categoryState === 3 ? (
                  <MaintenanceForm />
                ) : (
                  categoryState === 4 && <SparePartsForm />
                )
              ) : (
                <p style={{ opacity: "50%" }}>{t("choose_category_message")}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreatePostScreen;
