import
{
    SUSPEND_POST_LOADING,
    SUSPEND_POST_SUCCESS,
    SUSPEND_POST_FAILURE
}
from "redux/types";

import { suspendPostService } from "services/suspendPostService";

import { Dispatch } from "redux";

export const suspendPostAction = (postId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: SUSPEND_POST_LOADING, payload: true});
        const response = await suspendPostService(postId);
        dispatch({type: SUSPEND_POST_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: SUSPEND_POST_FAILURE, payload: err});
    } finally {
        dispatch({type: SUSPEND_POST_LOADING, payload: false});
    }
}