import 
{
    LIST_CAR_BODIES_LOADING,
    LIST_CAR_BODIES_SUCCESS,
    LIST_CAR_BODIES_FAILURE
}
from "redux/types";

const initialState = {
    carBodiesLoading: false,
    carBodies: null,
    errors: null
};

export const listCarBodiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CAR_BODIES_LOADING :
            return {...state, carBodiesLoading: action.payload};
        case LIST_CAR_BODIES_SUCCESS :
            return {...state, carBodies: action.payload.data};
        case LIST_CAR_BODIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}