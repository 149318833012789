import * as React from "react";
import type { SVGProps } from "react";
const SvgDashedCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 109 109"
    {...props}
  >
    <circle
      cx={54.5}
      cy={54.5}
      r={53.5}
      stroke="#0050FF"
      strokeDasharray="10 10"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
export default SvgDashedCircle;
