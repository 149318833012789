import { ArrowLeftIcon, UserIcon } from "assets/icons/V3";
import CustomButton from "components/CustomButton/CustomButton";
import styles from "components/V3/Drawer/Drawer.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useNavigate } from "react-router";
import { drawerStatusAction } from "redux/actions/DrawerStatusActions/drawerStatusAction";
import { useTranslation } from "react-i18next";
import {
  BellOutlined,
  FormOutlined,
  GlobalOutlined,
  HeartFilled,
  MessageOutlined,
  NotificationOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { LOG_OUT } from "redux/types";
import {
  StoreIcon,
  BuySellIcon,
  HomeIcon,
  MaintenanceIcon,
  NewsIcon,
  RentCarsIcon,
  SparePartsIcon,
} from "assets/IconComponents";

const Drawer = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { t } = useTranslation();

  const { userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const iconSize = "1.75rem";
  const iconStyle = { fill: "var(--new-blue)" };

  const categories = [
    {
      name: t("drawer_home"),
      route: "",
      icon: <HomeIcon width={iconSize} height={iconSize} style={iconStyle} />,
    },
    {
      name: t("homepage_buy_and_sell"),
      route: "/ads/view?category_id=1",
      icon: (
        <BuySellIcon width={iconSize} height={iconSize} style={iconStyle} />
      ),
    },
    {
      name: t("homepage_rental"),
      route: "/ads/view?category_id=2",
      icon: (
        <RentCarsIcon width={iconSize} height={iconSize} style={iconStyle} />
      ),
    },
    {
      name: t("viewpost_spare_parts"),
      route: "/ads/view?category_id=4",
      icon: (
        <SparePartsIcon width={iconSize} height={iconSize} style={iconStyle} />
      ),
    },
    {
      name: t("navbar_maintenance_text"),
      route: "/ads/view?category_id=3",
      icon: (
        <MaintenanceIcon width={iconSize} height={iconSize} style={iconStyle} />
      ),
    },
    {
      name: t("navbar_news_text"),
      route: "/News/view",
      icon: <NewsIcon width={iconSize} height={iconSize} style={iconStyle} />,
    },
    {
      name: t("store"),
      route: "/store",
      icon: (
        <div
          className="rounded-full flex items-center justify-center bg-transparent"
          style={{ width: iconSize, height: iconSize, color: "white" }}
        >
          <StoreIcon
            width={"80%"}
            height={"80%"}
            color="white"
            // height={iconSize}
            style={{ color: "var(--new-orange" }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div
      className="hide-scrollbar"
      style={{
        width: "100%",
        height: "calc(100vh - 70px)",
      }}
    >
      <div className="w-full flex flex-col items-center px-[1.2rem] pt-[1.5rem] overflow-y-auto">
        {userData?.token ? (
          <div
            dir="rtl"
            className={`w-full h-[4.375rem] border-[var(--new-blue)] border-[0.5px] bg-white rounded-[1.25rem] flex flex-row items-center justify-between px-3 gap-x-3 ${styles.shadow}`}
            onClick={() => {
              navigate(`/${currentLanguage}/profile/view`);
              dispatch(drawerStatusAction(false));
            }}
          >
            <div className="w-full flex flex-row gap-x-3 items-start">
              <div
                style={{
                  backgroundImage: `url('${userData?.user?.personal_image}')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                className="w-[3rem] h-[3rem] rounded-full"
              ></div>
              <div className="h-[70%] flex flex-col items-start justify-start">
                <p className="text-[0.9375rem]">{userData?.user?.name}</p>
                <p className={`text-[0.625rem] ${styles.grayColor}`}>
                  {userData?.user?.phone}
                </p>
              </div>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
        ) : (
          <div
            dir="rtl"
            className={`w-full h-[4.375rem] border-[var(--new-blue)] border-[0.5px] bg-white rounded-[1.25rem] flex flex-row items-center justify-between px-3 gap-x-3 ${styles.shadow}`}
            onClick={() => {
              navigate(`/${currentLanguage}/login`);
              dispatch(drawerStatusAction(false));
            }}
          >
            <div className="flex flex-row items-center gap-x-4">
              <div className="w-[3rem] h-[3rem] bg-[var(--new-blue)] rounded-full flex items-center justify-center">
                <UserOutlined className="text-white text-[1.5rem]" />
              </div>
              <p className="text-[0.9375rem]">{t("login")}</p>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
        )}

        {/* Categories */}
        {categories.map((category: any) => (
          <div
            dir="rtl"
            className={`mt-[0.8rem] w-full h-[4.375rem] bg-white rounded-[1.25rem] flex flex-row items-center justify-start px-3 gap-x-3 ${styles.shadow}`}
            onClick={() => {
              navigate(`/${currentLanguage}${category.route}`);
              dispatch(drawerStatusAction(false));
            }}
            style={{
              ...(category.name === t("store") && {
                border: "1px solid var(--new-orange)",
              }),
            }}
          >
            {category.icon}
            <p className="text-[0.9375rem]">{category.name}</p>
          </div>
        ))}

        {/* End Categories */}

        {/* Logged In Categories */}
        <div
          dir="rtl"
          className={`mt-[0.8rem] w-full py-3 bg-white rounded-[1.25rem] flex flex-col items-start justify-center gap-y-6 px-3 gap-x-3 ${styles.shadow}`}
        >
          <div
            onClick={() => {
              // go to messages
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <div className="flex flex-row justify-center items-center gap-x-3">
              <MessageOutlined className="text-[var(--new-blue)] text-[1.2rem]" />
              <p className="text-[0.9375rem]">{t("all_messages")}</p>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
          <div
            onClick={() => {
              if (userData?.token)
                navigate(`/${currentLanguage}/favorite-posts`);
              else navigate(`/${currentLanguage}/login`);
              dispatch(drawerStatusAction(false));
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <div className="flex flex-row justify-center items-center gap-x-3">
              <HeartFilled
                width={iconSize}
                height={iconSize}
                className="text-[var(--new-blue)] text-[1.2rem]"
              />
              <p className="text-[0.9375rem]">{t("favorited_ads")}</p>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
          <div
            onClick={() => {
              if (userData?.token)
                navigate(`/${currentLanguage}/notifications`);
              else navigate(`/${currentLanguage}/login`);
              dispatch(drawerStatusAction(false));
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <div className="flex flex-row justify-center items-center gap-x-3">
              <BellOutlined
                width={iconSize}
                height={iconSize}
                className="text-[var(--new-blue)] text-[1.2rem]"
              />
              <p className="text-[0.9375rem]">{t("notifications")}</p>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
        </div>
        {/* End Logged In Categories */}

        {/* Data Pages */}
        <div
          dir="rtl"
          className={`mt-[0.8rem] w-full py-3 bg-white rounded-[1.25rem] flex flex-col items-start justify-center gap-y-6 px-3 gap-x-3 ${styles.shadow} mb-4`}
        >
          <div
            onClick={() => {
              // change language
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <div className="flex flex-row justify-center items-center gap-x-3">
              <GlobalOutlined className="text-[var(--new-blue)] text-[1.2rem]" />
              <p className="text-[0.9375rem]">{t("language")} :</p>
            </div>
            <div className="flex flex-row gap-x-3">
              <p className="text-[0.9375rem]">
                {currentLanguage === "ar" ? "Arabic" : "English"}
              </p>
              <CustomButton className={``}>
                <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
              </CustomButton>
            </div>
          </div>
          <div
            onClick={() => {
              navigate(`/${currentLanguage}/contact-us`);
              dispatch(drawerStatusAction(false));
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <div className="flex flex-row justify-center items-center gap-x-3">
              <FormOutlined className="text-[var(--new-blue)] text-[1.2rem]" />
              <p className="text-[0.9375rem]">{t("share_your_thoughts")}</p>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
          <div
            onClick={() => {
              // go to data pages
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <div className="flex flex-row justify-center items-center gap-x-3">
              <SafetyOutlined className="text-[var(--new-blue)] text-[1.2rem]" />
              <p className="text-[0.9375rem]">{t("help")}</p>
            </div>
            <CustomButton className={``}>
              <img src={ArrowLeftIcon} className="w-[1rem] h-[1rem]" />
            </CustomButton>
          </div>
        </div>
        {/* End Data Pages */}

        {userData?.token && (
          <CustomButton
            className={`border-[0.5px] mb-2 border-[red] rounded-full h-[2rem] px-6 flex flex-row items-center`}
            onClick={() => {
              dispatch({ type: LOG_OUT });
              dispatch(drawerStatusAction(false));
            }}
          >
            <p>{t("drawer_logout")}</p>
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default Drawer;
