import 
{
    FAVOURITE_POST_LOADING,
    FAVOURITE_POST_SUCCESS,
    FAVOURITE_POST_FAILURE

}
from "redux/types";

const initialState = {
    favouritePostLoading: false,
    favouritePostSuccess: false,
    errors: null
}

export const favouritePostReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FAVOURITE_POST_LOADING :
            return {...state, favouritePostLoading: action.payload};
        case FAVOURITE_POST_SUCCESS :
            return {...state, favouritePostSuccess: action.payload.success};
        case FAVOURITE_POST_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}