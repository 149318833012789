const priceStatusData = [
    {
        value: 'قابل للتفاوض',
        label: 'قابل للتفاوض'
    },
    {
        value: 'نهائي',
        label: 'نهائي'
    }
];

export default priceStatusData;