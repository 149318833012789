import { POST_PAYMENT_TYPE } from "redux/types";

const initialState = {
  postPaymentType: 0,
};

export const postPaymentTypeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_PAYMENT_TYPE:
      return { ...state, postPaymentType: action.payload };
    default:
      return state;
  }
};
