import api from "api";
import { createBranchEndPoint } from "api/apiUrls";
import store from "redux/store";

export const createBranchService = async (data: any) => {
    const response = await api.post(
        createBranchEndPoint(),
        data,
        {
            headers: {
                'Authorization' :  `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
            }
        }
    );

    return response;
}