import styles from "screens/UpdatePostScreen/UpdatePostScreen.module.css";
import { Checkbox, Input, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import Seo from "components/Seo/Seo";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postCategoryStateAction } from "redux/actions/CreatePostCategoryAction/postCategoryStateAction";
import { CheckOutlined } from "@ant-design/icons";
import { listCategoriesAction } from "redux/actions/CategoriesActions/listCategoriesAction";
import MaintenanceForm from "components/CreatePostForms/MaintenanceForm/MaintenanceForm";
import SparePartsForm from "components/CreatePostForms/SparePartsForm/SparePartsForm";
import { setProgressStateAction } from "redux/actions/SetProgressStateAction/setProgressStateAction";
import BuyCarUpdateForm from "components/UpdatePostForms/BuyCarUpdateForm/BuyCarUpdateForm";
import RentCarUpdateForm from "components/UpdatePostForms/RentCarUpdateForm/RentCarUpdateForm";
import SparePartsUpdateForm from "components/UpdatePostForms/SparePartsUpdateForm/SparePartsUpdateForm";
import MaintenanceUpdateForm from "components/UpdatePostForms/MaintenanceUpdateForm/MaintenanceUpdateForm";
import { useNavigate } from "react-router";

const UpdatePostScreen = () => {
  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  const isArabic = currentLanguage === "ar";
  const dir = isArabic ? "rtl" : "ltr";
  const dirFlip = !isArabic ? "rtl" : "ltr";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const { verifyOtpLoading, userData } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );
  useEffect(() => {
    if (!userData?.token) {
      navigate(`/`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 ? setIsMobile(false) : setIsMobile(true);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
  }, []);

  // useEffect(() => {

  const { post, updatePostLoading, updatedPost } = useSelector(
    (state: RootState) => state.updatePostReducer
  );

  return (
    <>
      <Seo
        title="سوق كار - أضف إعلان"
        description="أضف إعلان"
        keywords="سوق السيارات,سيارات,شراء عربيات,مدينة نصر، مصر,هيونداي,بيع سيارات مستعملة,عربيات للبيع,شراء سيارات,سيارات مستعملة للبيع,souq.car,souq car,سوق دوت كار,سوق كار"
      />
      {userData?.token ? (
        <div
          className={`w-full flex flex-col items-center pt-6 mb-10 ${styles.mainWrapper}`}
          style={{ backgroundColor: "var(--new-souq-background)" }}
        >
          <div className={`w-full flex flex-col items-center`}>
            {/* MAIN FORM CONTAINER */}
            <div
              className={`${
                styles.mainFormContainer
              } mt-6 bg-white flex flex-col gap-y-4 items-center rounded-lg ${
                isMobile ? styles.mobileWidth : styles.firstSection
              }`}
            >
              {post?.category_id === 1 ? (
                <BuyCarUpdateForm />
              ) : post?.category_id === 2 ? (
                <RentCarUpdateForm />
              ) : post?.category_id === 3 ? (
                <MaintenanceUpdateForm />
              ) : post?.category_id === 4 ? (
                <SparePartsUpdateForm />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default UpdatePostScreen;
