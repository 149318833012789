import api from "api";
import { loginWithMobileEndPoint } from "api/apiUrls";

export const loginWithMobileService = async (mobile: string) => {
    const response = await api.post(
        loginWithMobileEndPoint(),
        {
            mobile: mobile
        },
        {
            headers: {'Content-Type' : 'application/json'}
        }
    );
    return response;
}