import styles from "screens/V3/SingleArticleScreen/SingleArticleScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect } from "react";
import { changeCurrentPageAction } from "redux/actions";
import { viewArticleAction } from "redux/actions/ViewArticleAction/viewArticleAction";
import { useParams } from "react-router";
import { Spin } from "antd";
import { ShareButtonIcon } from "assets/icons/V3";
import { Footer } from "components";
import { useResponsive } from "hooks/useResponsive";
import ShareButton from "components/V3/ShareButton/ShareButton";

const SingleArticleScreen = () => {
  const dispatch: any = useDispatch();

  const { id } = useParams<any>();

  const { isMobile } = useResponsive();

  const { articleLoading, articleData } = useSelector(
    (state: RootState) => state.viewArticleReducer
  );

  useEffect(() => {
    dispatch(changeCurrentPageAction(-1));
    dispatch(viewArticleAction(id));
  }, []);

  return (
    <div
      className={`mt-[0.96rem] w-full flex flex-col items-center gap-y-[2.17rem] ${
        isMobile ? "px-3" : ""
      }`}
    >
      <Spin
        spinning={articleLoading}
        className="w-full flex flex-col items-center gap-y-[2.17rem]"
      >
        <div
          style={{
            backgroundImage: `url('${articleData?.image}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className={`${isMobile ? "w-full" : "w-[75rem]"} ${
            isMobile ? "h-[13.1875rem]" : "h-[20.5625rem]"
          } rounded-[0.9375rem] flex flex-row items-end justify-between py-3 px-3`}
        >
          <ShareButton url={window.location.href}>
            <img src={ShareButtonIcon} />
          </ShareButton>
          <div
            className={`rounded-full ${
              isMobile ? "w-[4.6875rem]" : "w-[7.375rem]"
            } ${
              isMobile ? "h-[1.625rem]" : "h-[2.375rem]"
            } bg-white flex items-center justify-center`}
          >
            <p className={`${isMobile ? "text-[0.625rem]" : ""}`}>
              {articleData?.created_at?.split("T")[0]}
            </p>
          </div>
        </div>
        <div
          dir="rtl"
          className={`mt-[2.17rem] w-full flex flex-col items-start ${isMobile ? "gap-y-[1rem]" : "gap-y-[2.56rem]"}`}
        >
          <h1
            className={`text-[var(--new-blue)] ${
              isMobile ? "text-[0.9375rem]" : "text-[1.5625rem]"
            }`}
          >
            {articleData?.title}
          </h1>
          <div
            className={`${styles.descriptionSection} ${
              isMobile ? "w-full" : "w-[75rem]"
            }`}
            dangerouslySetInnerHTML={{ __html: articleData?.description }}
          ></div>
        </div>
      </Spin>
      <Footer />
    </div>
  );
};

export default SingleArticleScreen;
