import 
{
    EXHIBITOR_SERVICES_LOADING,
    EXHIBITOR_SERVICES_SUCCESS,
    EXHIBITOR_SERVICES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listExhibitorServicesService } from "services/listExhibitorServicesService";

export const listExhibitorServicesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: EXHIBITOR_SERVICES_LOADING, payload: true});
        const response = await listExhibitorServicesService();
        dispatch({type: EXHIBITOR_SERVICES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: EXHIBITOR_SERVICES_FAILURE, payload: err});
    } finally {
        dispatch({type: EXHIBITOR_SERVICES_LOADING, payload: false});
    }
}