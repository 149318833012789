import api from "api";
import { deleteMainPhotoEndPoint } from "api/apiUrls";
import store from "redux/store";

export const deleteMainPhotoService = async () => {
  const response = api.post(
    deleteMainPhotoEndPoint(),
    {},
    {
      headers: {
        Authorization: `Bearer ${
          store.getState()?.verifyOtpReducer?.userData?.token
        }`,
      },
    }
  );

  return response;
};
