import
{
    RENTAL_DRIVER_LOADING,
    RENTAL_DRIVER_SUCCESS,
    RENTAL_DRIVER_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listRentalDriverService } from "services/listRentalDriverService";

export const listRentalDriverAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: RENTAL_DRIVER_LOADING, payload: true});
        const response = await listRentalDriverService();
        dispatch({type: RENTAL_DRIVER_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: RENTAL_DRIVER_FAILURE, payload: err});
    } finally {
        dispatch({type: RENTAL_DRIVER_LOADING, payload: false});
    }
}