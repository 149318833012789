import styles from "components/V3/TallStoryCard/TallStoryCard.module.css";
import { FC } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface TallStoryCardProps {
  image: string;
  className?: string;
  customerImage?: string;
  customerUUID?: any;
  customerName?: string;
  description?: string;
}

const TallStoryCard: FC<TallStoryCardProps> = ({
  image,
  customerUUID,
  className,
  customerName,
  customerImage,
  description,
}) => {
  const navigate = useNavigate();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  return (
    <div
      className={`${className} w-[14.26194rem] h-[22.90556rem] rounded-[0.625rem] flex flex-col justify-between px-3 py-3 ${styles.shadow} hover:cursor-pointer`}
      style={{
        backgroundImage: `url('${image}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      onClick={() => {
        if (!customerUUID) return;
        navigate(`/${currentLanguage}/stories/${customerUUID}`);
      }}
    >
      {customerImage && customerName && (
        <div
          dir="rtl"
          className="w-full flex flex-row items-center justify-between"
        >
          <div className="flex flex-row items-center gap-x-2">
            <img
              src={customerImage}
              className="w-[1.72913rem] h-[1.72913rem] rounded-full"
            />
            <p className="text-white text-[0.9375rem] overflow-ellipsis line-clamp-1">
              {customerName}
            </p>
          </div>
        </div>
      )}
      {description && (
        <p
          dir="rtl"
          className="text-[0.9375rem] line-clamp-2 overflow-ellipsis text-white"
        >
          {description}
        </p>
      )}
    </div>
  );
};

export default TallStoryCard;
