import api from "api";
import { searchFilterEndPoint } from "api/apiUrls";
import store from "redux/store";

export const searchFilterService = async (searchFilters: string) => {
    const response = await api.get(
        searchFilterEndPoint(searchFilters),
        {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState()?.verifyOtpReducer?.userData?.token}`
        }}
    );
    return response;
}