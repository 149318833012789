import {
  GET_STORE_ITEMS_LOADING,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";
import { getStoreItemsService } from "services/getStoreItemsService";

export const getStoreItemsAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_STORE_ITEMS_LOADING, payload: true });
    const response = await getStoreItemsService();
    dispatch({ type: GET_STORE_ITEMS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_STORE_ITEMS_FAILURE, payload: err });
  } finally {
    dispatch({ type: GET_STORE_ITEMS_LOADING, payload: false });
  }
};
