import
{
    LIST_BRANCHES_LOADING,
    LIST_BRANCHES_SUCCESS,
    LIST_BRANCHES_FAILURE
}
from "redux/types";

const initalState = {
    branchesLoading: false,
    branches: null,
    errors: null
};

export const listBranchesReducer = (state = initalState, action: any) => {
    switch (action.type) {
        case LIST_BRANCHES_LOADING :
            return {...state, branchesLoading: action.payload};
        case LIST_BRANCHES_SUCCESS :
            return {...state, branches: action.payload.data};
        case LIST_BRANCHES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}