import styles from "screens/V3/PrivacyPolicy/PrivacyPolicy.module.css";
import { CustomButton, Footer } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResponsive } from "hooks/useResponsive";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );

  const { isMobile } = useResponsive();

  return (
    <div className="w-full flex flex-col items-center">
      <div
        className={`mt-[2.96rem] ${
          isMobile ? "w-[95%]" : "w-[65%]"
        } flex flex-col items-center`}
      >
        <p className="text-[var(--new-blue)] text-[1.25rem] font-[700]">
          {t("terms_of_use")}
        </p>
        <div
          dir="rtl"
          className="mt-[2.94rem] w-full flex flex-col items-start gap-y-6"
        >
          <p className="text-[var(--new-blue)] text-[0.9375rem] font-[700]">
            {t("about_souq_car")}
          </p>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item: any) => {
            return <p>- {t(`privacy_policy_a${item}`)}</p>;
          })}

          <p className="mt-[3.75rem] text-[var(--new-blue)] text-[0.9375rem] font-[700]">
            {t("about_seller")}
          </p>
          {[9, 10, 11, 12].map((item: any) => {
            return <p>- {t(`privacy_policy_a${item}`)}</p>;
          })}

          <p className="mt-[3.75rem] text-[var(--new-blue)] text-[0.9375rem] font-[700]">
            {t("about_buyer")}
          </p>
          {[13, 14, 15, 16].map((item: any) => {
            return <p>- {t(`privacy_policy_a${item}`)}</p>;
          })}
        </div>
      </div>
      <div
        dir="rtl"
        className={`mt-[2.87rem] mb-10 w-[20.75rem] h-[3.875rem] p-[0.6rem] rounded-[0.625rem] flex flex-row items-center justify-between gap-x-3 ${styles.shadow}`}
      >
        <div className="w-1/2 h-full flex items-center justify-center">
          <p>{t("didnt_find_your_question")}</p>
        </div>
        <CustomButton
          className={`w-1/2 h-full rounded-[0.3125rem] flex items-center justify-center`}
          onClick={() => {
            navigate(`/${currentLanguage}/contact-us`);
          }}
          color={"var(--new-blue)"}
        >
          <p className="text-white">{t("contact_us")}</p>
        </CustomButton>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
