import styles from "screens/V3/LoginScreen/DetailsForm.module.css";
import { Button, Form, Input, Select } from "antd";
import LoginIllustration from "assets/images/LoginIllustration.svg";
import { motion } from "framer-motion";
import { FC } from "react";
import { useLanguage } from "hooks/useLanguage";
import { dotPulse } from "ldrs";
import { useUtilities } from "hooks/useUtilities";
import { boolean } from "yup";
import { useDropdowns } from "hooks/useDropdowns";

interface DetailsFormProps {
  onFinish: any;
  // values: any;
  isLoading?: boolean;
}

const DetailsForm: FC<DetailsFormProps> = ({
  onFinish,
  // values,
  isLoading = false,
}) => {
  const { textDirectionStyle } = useLanguage();
  const { citiesDropdown } = useDropdowns();
  const { t, navigate } = useUtilities();
  const { currentLanguage } = useLanguage();
  dotPulse.register();

  const renderFormField = (data: {
    label: string;
    name: string;
    placeholder?: string;
    isRequired?: { message: string } | false;
    isDropdown?: [{ value: any; label: any }] | false;
    rules?: any[];
  }) => {
    function isBoolean(value: any): value is boolean {
      return typeof value === "boolean";
    }

    // default value for isRequired
    data.isRequired = data.isRequired || false;
    data.isDropdown = data.isDropdown || false;

    return (
      <div dir={textDirectionStyle} className="flex flex-col w-full items-end">
        <div className="w-full flex">
          <p className={`${data.isRequired && "required"} souq-s`}>
            {data.label}
          </p>
          {!data.isRequired && (
            <p style={{ marginInlineStart: "0.3rem" }}>(اختياري)</p>
          )}
        </div>
        <Form.Item
          name={data.name}
          className="w-full"
          // initialValue={values[data.name]}
          style={{ direction: textDirectionStyle }}
          rules={[
            !isBoolean(data.isRequired) && {
              required: true,
              message: data.isRequired?.message,
            },
            ...(data.rules || []),
          ]}
          // style={{ direction: textDirectionStyle }}
        >
          {!isBoolean(data.isDropdown) ? (
            <Select
              className="w-full mt-2 souq-s"
              placeholder={data.placeholder}
              size="large"
              // direction={textDirectionStyle}
            >
              {data.isDropdown.map((option) => (
                <Select.Option value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              placeholder={data.placeholder}
              className="w-full mt-2 souq-s"
              size="large"
              // status={formik.errors.price ? "error" : ""}
              dir={textDirectionStyle}
            />
          )}
        </Form.Item>
      </div>
    );
  };

  return (
    <motion.div
      className="w-full h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Form
        id="ant-login-form"
        className="w-full h-full flex flex-col items-center justify-between gap-y-10"
        onFinish={onFinish}
      >
        <div
          className={`w-full flex flex-col items-center justify-start pt-12`}
        >
          <h2>
            <p className={`souq-large text-[var(--new-blue)] mb-16`}>
              كمل بياناتك
            </p>
          </h2>
          {renderFormField({
            label: "الاسم",
            name: "name",
            placeholder: "أكتب الاسم",
            isRequired: { message: "الاسم مطلوب" },
          })}
          {renderFormField({
            label: "المحافظة",
            name: "city",
            isDropdown: citiesDropdown,
            isRequired: { message: "المحافظة مطلوبة" },
            placeholder: "مثال: القاهرة",
          })}
          {renderFormField({
            label: "الاميل",
            name: "email",
            placeholder: "مثال: mail@mail.com",
          })}
        </div>
        <div className="w-full flex flex-col items-center">
          <p className="souq-xs mb-3 text-center">
            بتمابعتك فأنت موافق على{" "}
            <span
              className="cursor-pointer"
              style={{ color: "var(--new-blue)" }}
              onClick={() => navigate(`/${currentLanguage}/privacy-policy`)}
            >
              سياسة الخصوصية
            </span>{" "}
            و{" "}
            <span
              className="cursor-pointer"
              style={{ color: "var(--new-blue)" }}
              onClick={() => navigate(`/${currentLanguage}/terms-of-use`)}
            >
              شروط الاستخدام
            </span>
          </p>
          <Button
            className={`flex items-center justify-center ${styles.customButton}`}
            htmlType="submit"
          >
            {!isLoading ? (
              <p className="souq-large">{t("login")}</p>
            ) : (
              <l-dot-pulse size="25" speed="1.3" color="white"></l-dot-pulse>
            )}
          </Button>
        </div>
      </Form>
    </motion.div>
  );
};

export default DetailsForm;
