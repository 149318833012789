import styles from "components/FaqCard/FaqCard.module.css";
import { FC } from "react";
import { useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface FaqCardProps {
  question: string;
  answer: string;
}

const FaqCard: FC<FaqCardProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  const { currentLanguage } = useSelector(
    (state: RootState) => state.changeLanguageReducer
  );
  return (
    <div
      dir={currentLanguage === "ar" ? "rtl" : "ltr"}
      className={`${open ? styles.expand : ""} ${styles.faq} ${styles.shadow} rounded-lg w-[90%] lg:w-[550px]`}
    >
      <div
        // ${open ? styles.cardOpen : styles.cardClosed}
        className={` ${styles.hover} ${styles.cardClosed} ${styles.shadow}  w-full bg-white flex flex-row items-center justify-between p-5`}
        onClick={() => setOpen(!open)}
      >
        <p className="text-base">{question}</p>
        {currentLanguage === "ar" ? (
          <RightOutlined
            className={`${
              open ? styles.arrowOpen : styles.arrowClosed
            } text-lg`}
          />
        ) : (
          <LeftOutlined
            className={`${
              open ? styles.arrowOpenEn : styles.arrowClosed
            } text-lg`}
          />
        )}
      </div>
      {/* bg-[#70707047] */}
      <div className={`${open ? styles.expand : ""} ${styles.textBox} ${styles.blueBg}  w-full`}>
        <p className={`text-base p-3`}>{answer}</p>
      </div>
    </div>
  );
};

export default FaqCard;
