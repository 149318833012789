import { FC, useEffect, useState } from "react";
import styles from "components/V3/UserSettings/NewUserSettings.module.css";
import { useTranslation } from "react-i18next";
import { useLanguage } from "hooks/useLanguage";
import { useTimeFormat } from "hooks/useTimeFormat";
import { useUtilities } from "hooks/useUtilities";
import { seeNotificationAction } from "redux/actions/NotificationsAction/seeNotificationAction";
import { listNotificationsAction } from "redux/actions/NotificationsAction/listNotificationsAction";
import defaultUserImage from "assets/images/customer_avatar.jpeg";
import UpdateUserDataModel from "models/UpdateUserDataModel";
import { Button, Empty, Form, Input, Spin, Switch, notification } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "redux/rootReducer";
import {
  ProfileDataCard,
  ProfileScreenUserInfoCard,
  WidePostCard,
  CustomButton,
  PostCard,
} from "components/index";
import {
  listPostStatusAction,
  listProfilePostsAction,
  listCategoriesAction,
  displayMobileAction,
  suspendPostAction,
  updateCustomerAction,
  reactivatePostAction,
  setUpdatePostData,
  changeCurrentPageAction,
  setMapCoordinatesPostAction,
  profilePostCategoryAction,
  profilePostStatusAction,
} from "redux/actions/index";
import { useSelector } from "react-redux";
import { useResponsive } from "hooks/useResponsive";
import { DeleteOutlined } from "@ant-design/icons";
import ReplaceIcon from "assets/icons/icons/ReplaceIcon.svg";

interface NewUserSettingsProps {
  id?: string;
}

const NewUserSettings: FC<NewUserSettingsProps> = ({ id }) => {
  const { t, dispatch, navigate, print, userData } = useUtilities();
  const { isMobile } = useResponsive();
  const [displayMobileSwitch, setDisplayMobileSwitch] = useState<any>();

  const { isArabic, textDirectionStyle, textAlign, flexAlignStyle } =
    useLanguage();
  const { getTimeAgo, formatDate, getTimeFromDate } = useTimeFormat();
  const settingsSections = [t("my_info"), t("user_settings")];
  const [selectedSettingsSection, setSelectedSettingsSection] = useState(0);
  const [userProfileImage, setUserProfileImage] = useState<any>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const { updateCustomerDataLoading, updatedCustomer } = useSelector(
    (state: RootState) => state.updateCustomerDataReducer
  );

  const { profilePostStatus } = useSelector(
    (state: RootState) => state.profilePostStatusReducer
  );

  const { profilePostCategory } = useSelector(
    (state: RootState) => state.profilePostCategoryReducer
  );

  const { verifyOtpLoading, errors } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  const handleUpdateUserDataClick = async (
    name: any,
    email: any,
    personal_image: any
  ) => {
    await dispatch(updateCustomerAction(name, email, personal_image));
  };

  const handleFileUpload = (e: any, image?: any) => {
    if (!image) {
      setUserProfileImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("personal_image", e.target.files[0]);
      form.setFieldsValue({ personal_image: e.target.files[0] });
    } else {
      setUserProfileImage(null);

      formik.setFieldValue("personal_image", null);
      form.setFieldsValue({ personal_image: null });
      // clearTimeout(timeout);
    }
  };

  const formik = useFormik({
    initialValues: {
      //   phone: "",
      name: userData?.user?.name,
      email: userData?.user?.email,
      personal_image: userData?.user?.personal_image,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
    }),
    onSubmit: async (values: UpdateUserDataModel) => {
      // Check that name is not empty
      if (!values.name || values.name === "") {
        if (isArabic)
          notification.error({
            message: "الرجاء إدخال الاسم",
            description: "",
          });
        else
          notification.error({
            message: "Please enter your name",
            description: "",
          });
        return;
      }

      await handleUpdateUserDataClick(
        values.name,
        values.email,
        values.personal_image
      );
      if (updatedCustomer) {
        if (isArabic)
          notification.success({
            message: "تم تحديث البيانات بنجاح",
            description: "",
          });
        else
          notification.success({
            message: "Data updated successfully",
            description: "",
          });
      }
    },
  });

  const onNameChange = (value: string) => {
    form.setFieldsValue({ name: value });
  };

  const onFormFinish = async (values: any) => {
    console.log("values", values);
    await handleUpdateUserDataClick(
      values.name,
      values.email,
      values.personal_image
    );
    if (updatedCustomer) {
      if (isArabic)
        notification.success({
          message: "تم تحديث البيانات بنجاح",
          description: "",
        });
      else
        notification.success({
          message: "Data updated successfully",
          description: "",
        });
    }
  };

  useEffect(() => {
    if (userData === null || errors) {
      navigate("/");
    }
  }, [userData, errors]);

  useEffect(() => {
    dispatch(listProfilePostsAction(profilePostStatus, profilePostCategory));
  }, [profilePostStatus, profilePostCategory]);

  useEffect(() => {
    setDisplayMobileSwitch(
      userData?.user?.show_phone === 1 || userData?.user?.show_phone
    );
  }, [userData]);

  useEffect(() => {
    setUserProfileImage(userData?.user?.personal_image);
    dispatch(listPostStatusAction());
    setDisplayMobileSwitch(
      userData?.user?.show_phone === 1 || userData?.user?.show_phone
    );
  }, []);

  // DESKTOP VIEW
  // if (!isMobile)
  return (
    <>
      {selectedSettingsSection === 0 ? (
        // MY INFO SECTION
        <div className={`w-full flex flex-col items-center gap-y-4 p-4`}>
          <div>
            <p
              className={`w-full text-right font-bold text-base`}
              style={{
                color: "var(--souq-blue)",
              }}
            >
              {t("user_settings")}
            </p>
          </div>
          {/* <div>
              <p className={`w-full text-right text-md`}>
                {t("basic_info_msg")}
              </p>
            </div> */}
          <div
            className={`${styles.imageCard} w-24 h-24 rounded-full ${styles.blueBg} flex items-center justify-center`}
            style={{
              backgroundImage: `url('${encodeURI(
                userProfileImage !== null ? userProfileImage : defaultUserImage
              )}')`,
              backgroundColor:
                userProfileImage == null ? "transparent" : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <label htmlFor="profile_file">
              <div
                className={`${styles.iconButton} ${
                  isArabic ? styles.removeImage : styles.removeImageAr
                }`}
              >
                <img
                  src={ReplaceIcon}
                  alt=""
                  // className={`${styles.editIcon}`}
                  style={{
                    scale: "1.5",
                  }}
                />
                <input
                  id="profile_file"
                  type="file"
                  onChange={(event: any) => {
                    handleFileUpload(event);
                  }}
                  accept="image/*"
                />
              </div>
            </label>

            <button
              className={`${styles.iconButton} ${
                isArabic ? styles.removeImageAr : styles.removeImage
              }`}
              onClick={(e: any) => {
                handleFileUpload(e, defaultUserImage);
              }}
            >
              <DeleteOutlined className="text-xs text-red-400" />
            </button>
          </div>

          <Form
            layout="vertical"
            form={form}
            initialValues={
              userData?.user
                ? {
                    name: userData?.user?.name,
                    email: userData?.user?.email,
                    personal_image: userData?.user?.personal_image,
                  }
                : {}
            }
            name="control-hooks"
            onFinish={onFormFinish}
            style={{ width: !isMobile ? "75%" : "100%" }}
            requiredMark={false}
          >
            <Form.Item
              name="name"
              label={
                <p>
                  {t("name")} <span className="text-red-500">*</span>
                </p>
              }
              rules={[
                { required: true, message: t("please_provide_this_field") },
              ]}
              style={{
                direction: textDirectionStyle,
              }}
            >
              <Input
                type="text"
                name="name"
                placeholder={t("name")}
                size="large"
                className={`w-full text-${textAlign}`}
              />
            </Form.Item>
            <Form.Item name="email" label={<p>{t("email")}</p>}>
              <Input
                type="text"
                name="email"
                placeholder={t("email")}
                size="large"
                className={`w-full text-${textAlign}`}
              />
            </Form.Item>

            <Form.Item
              name="personal_image"
              label={t("personal_image")}
              style={{ display: "none" }}
            />

            <Form.Item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 0",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="w-full"
                loading={updateCustomerDataLoading}
                style={{
                  width: !isMobile ? "18rem" : undefined,
                  paddingInline: isMobile ? "3rem" : undefined,
                  height: "3rem",
                  borderRadius: "0.75rem",
                  direction: textDirectionStyle,
                }}
              >
                {t("confirm")}
              </Button>
            </Form.Item>
          </Form>
          <a className="text-red-500 text-md">{t("disable_account")}</a>

          {/* DISABLE ACCOUNT */}

          {/* <form
              className={`w-full flex flex-col items-center gap-y-4`}
              onSubmit={formik.handleSubmit}
            >
              <div className={`w-3/4 flex flex-col gap-y-2`}>
                <p>
                  {t("name")} <span className="text-red-500">*</span>
                </p>
                <Input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  status={formik.errors.name ? "error" : ""}
                  placeholder={t("name")}
                  size="large"
                  className={`w-full text-${textAlign}`}
                />
              </div>

              <div className={`w-3/4 flex flex-col gap-y-2`}>
                <p>{t("user_email")}</p>
                <Input
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  status={formik.errors.email ? "error" : ""}
                  placeholder="البريد الألكتروني"
                  size="large"
                  className={`w-full text-${textAlign}`}
                />
              </div>
              {updateCustomerDataLoading ? (
                <Spin />
              ) : (
                <CustomButton
                  type="submit"
                  className={`ze:w-full lg:w-1/2 rounded-lg p-3 text-white mt-6 ${styles.buttonHover}`}
                  color={`#3127ff`}
                >
                  {t("confirm")}
                </CustomButton>
              )}

            </form> */}
        </div>
      ) : (
        // SETTINGS SECTION
        <div
          dir={textDirectionStyle}
          className={`${styles.shadow} ${styles.settingsContainer} w-full flex flex-col items-start gap-y-4 ${styles.whiteBackground} rounded-xl p-12`}
        >
          <div>
            <p className={`w-full  font-bold text-md mb-8`}>
              {t("personal_profile")} :
            </p>
          </div>
          <div className="flex flex-row gap-x-8">
            <div className="flex flex-col gap-y-6">
              <div className={`w-full flex flex-row items-center gap-x-3`}>
                <p className={` text-md`}>{t("show_phone_number")} :</p>
              </div>
              <div className={`w-full flex flex-row items-center gap-x-3`}>
                <p className={` text-md`}>{t("show_email")} :</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-6">
              <div className={`w-full flex flex-row items-center gap-x-3`}>
                <Switch
                  defaultChecked={displayMobileSwitch}
                  onChange={(checked: boolean) => {
                    dispatch(displayMobileAction(checked));
                  }}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  style={{ boxShadow: "0 0 0 1px #EFEFFF" }}
                />
              </div>
              <div className={`w-full flex flex-row items-center gap-x-3`}>
                <Switch
                  defaultChecked
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  style={{ boxShadow: "0 0 0 1px #EFEFFF" }}
                />
              </div>
            </div>
          </div>
          <div>
            <p className={`w-full  font-bold text-md mt-10`}>
              {t("drawer_account")} :
            </p>
          </div>
          <div className="flex flex-row gap-x-8">
            <div className={`w-full flex flex-row items-center gap-x-3`}>
              <p className={`text-md`}>{t("disable_account")} :</p>
              <a className="text-red-500 text-md">{t("disable")}</a>
            </div>
          </div>
        </div>
      )}
    </>
  );

  // MOBILE VIEW
  return (
    <>
      <div
        dir={textDirectionStyle}
        className={`${styles.shadow} w-full flex flex-row items-center gap-x-3 ${styles.whiteBackground} rounded-xl p-2`}
      >
        {Array.isArray(settingsSections) &&
          settingsSections?.map((item: any, index: number) => {
            return (
              <p
                className={`${
                  index === selectedSettingsSection
                    ? `${styles.selectedPostStatus} p-2 px-10 py-4 rounded-xl`
                    : "p-2 px-10 py-4"
                } text-black ${styles.hover}`}
                onClick={() => {
                  setSelectedSettingsSection(index);
                }}
              >
                {item}
              </p>
            );
          })}
      </div>

      {selectedSettingsSection === 0 ? (
        // MY INFO SECTION
        <div
          className={`${styles.shadow} ${styles.settingsContainer} w-full flex flex-col items-center gap-y-4 ${styles.whiteBackground} rounded-xl p-8`}
        >
          <div>
            <p className={`w-full text-center font-bold text-lg`}>
              {t("basic_info")}
            </p>
          </div>
          <div>
            <p className={`w-full text-center text-md`}>
              {t("basic_info_msg")}
            </p>
          </div>

          <div
            className={`${styles.imageCard} w-full flex flex-col gap-y-4 rounded-lg p-6`}
          >
            {userProfileImage !== null ? (
              <div
                className={`w-20 h-20 rounded-full ${styles.blueBg} flex items-center justify-center`}
                style={{
                  backgroundImage: `url('${encodeURI(userProfileImage)}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* <img
                            src={userIcon}
                            className={`${styles.loginIconSettings}`}
                            alt="Login Icon"
                          /> */}
              </div>
            ) : (
              <div
                className={`w-20 h-20 rounded-full ${styles.blueBg} flex items-center justify-center`}
                style={{
                  backgroundImage: `url('${encodeURI(defaultUserImage)}')`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* <img
                          src={userIcon}
                          className={`${styles.loginIconSettings}`}
                          alt="Login Icon"
                        /> */}
              </div>
            )}

            <input
              id="profile_file"
              type="file"
              onChange={(event: any) => {
                handleFileUpload(event);
              }}
              accept="image/*"
            />
            <label htmlFor="profile_file">
              <a className={`text-sm`}>{t("add_personal_photo")}</a>
            </label>

            <div
              style={isArabic ? { left: 0 } : { right: 0 }}
              className={`${styles.removeImage}`}
            >
              <a
                className={`text-xs`}
                onClick={(e: any) => {
                  handleFileUpload(e, defaultUserImage);
                }}
              >
                {t("remove_photo")}
              </a>
            </div>
          </div>

          <form
            className={`w-full flex flex-col items-center gap-y-4`}
            onSubmit={formik.handleSubmit}
          >
            <Input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              status={formik.errors.name ? "error" : ""}
              placeholder={t("name")}
              size="large"
              className={`w-full text-${textAlign}`}
            />
            <Input
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              status={formik.errors.email ? "error" : ""}
              placeholder={t("email")}
              size="large"
              className={`w-full text-${textAlign}`}
            />
            {updateCustomerDataLoading ? (
              <Spin />
            ) : (
              <CustomButton
                type="submit"
                className={`ze:w-2/3 lg:w-1/4 rounded-lg p-3 text-white mt-6 ${styles.buttonHover}`}
                color={`#3127ff`}
              >
                {t("confirm")}
              </CustomButton>
            )}
          </form>
        </div>
      ) : (
        // SETTINGS SECTION
        <div
          dir={textDirectionStyle}
          className={`${styles.shadow} ${styles.settingsContainer} w-full flex flex-col items-start gap-y-4 ${styles.whiteBackground} rounded-xl p-12`}
        >
          <div>
            <p className={`w-full font-bold text-md mb-8`}>
              {t("personal_profile")} :
            </p>
          </div>
          <div className="w-full flex flex-col gap-y-6">
            <div
              className={`w-full flex flex-row justify-between items-center gap-x-6`}
            >
              <p className={`text-md`}>{t("show_phone_number")} :</p>
              <Switch
                defaultChecked={displayMobileSwitch}
                onChange={(checked: boolean) => {
                  dispatch(displayMobileAction(checked));
                }}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                style={{ boxShadow: "0 0 0 1px #EFEFFF" }}
              />
            </div>
            <div
              className={`w-full flex flex-row justify-between items-center gap-x-6`}
            >
              <p className={`text-md`}>{t("show_email")} :</p>
              <Switch
                defaultChecked
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                style={{ boxShadow: "0 0 0 1px #EFEFFF" }}
              />
            </div>
          </div>
          <div>
            <p className={`w-full text font-bold text-md mt-10`}>
              {t("drawer_account")} :
            </p>
          </div>
          <div className="flex flex-row gap-x-8">
            <div className={`w-full flex flex-row items-center gap-x-3`}>
              <p className={`text text-md`}>{t("disable_account")} :</p>
              <a className="text-red-500 text-md">{t("disable")}</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewUserSettings;
