import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILURE } from "redux/types";

import { loginWithMobileService } from "services/loginWithMobileService";

import { Dispatch } from "redux";

export const mobileLoginAction =
  (mobile: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: LOGIN_LOADING, payload: true });
      const response = await loginWithMobileService(mobile);
      dispatch({ type: LOGIN_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: LOGIN_FAILURE, payload: err });
    } finally {
      dispatch({ type: LOGIN_LOADING, payload: false });
    }
  };
