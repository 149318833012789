import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './lang/en';
import arabicTranslations from './lang/ar';
import getPreferredLanguage from './lang/languageUtils';


const preferredLanguage = getPreferredLanguage();

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Specify the supported languages
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'ar', // Default language in case the user's preferred language is not available
    detection: {
      order: ['localStorage', 'navigator'], // Detect language from localStorage or the browser's navigator language
    },
    resources: {
      en: {
        translation: englishTranslations,
      },
      ar: {
        translation: arabicTranslations,
      },
    },
    lng: preferredLanguage, // Set the initial language based on the user's preference from localStorage
});