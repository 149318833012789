import
{
    Articles_TYPES_LOADING,
    Articles_TYPES_SUCCESS,
    Articles_TYPES_FAILURE
}
from "redux/types";

const initalState = {
    articlesLoading: false,
    articles: null,
    errors: null
};

export const listarticlesReducer = (state = initalState, action: any) => {
    switch (action.type) {
        case Articles_TYPES_LOADING :
            return {...state, articlesLoading: action.payload};
        case Articles_TYPES_SUCCESS :
            return {...state, articles: action.payload.data.data};
        case Articles_TYPES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}