import
{
    ACTIVATE_OUTSTANDING_POST_LOADING,
    ACTIVATE_OUTSTANDING_POST_SUCCESS,
    ACTIVATE_OUTSTANDING_POST_FAILURE
}
from "redux/types";

const initialState = {
    activatePostLoading: false,
    activatePostSuccess: null,
    errors: null
}

export const activateOutstandingPostReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIVATE_OUTSTANDING_POST_LOADING :
            return {...state, activatePostLoading: action.payload};
        case ACTIVATE_OUTSTANDING_POST_SUCCESS :
            return {...state, activatePostSuccess: action.payload.success};
        case ACTIVATE_OUTSTANDING_POST_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}