import
{
    LIST_YEARS_LOADING,
    LIST_YEARS_SUCCESS,
    LIST_YEARS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listTableDataService } from "services/listTableDataService";

export const listYearsAction =
    () => async (dispatch: Dispatch) => {
        try {
            dispatch({type: LIST_YEARS_LOADING, payload: true});
            const response = await listTableDataService('productionYearsList');
            dispatch({type: LIST_YEARS_SUCCESS, payload: response.data});
        } catch (err) {
            dispatch({type: LIST_YEARS_FAILURE, payload: err});
        } finally {
            dispatch({type: LIST_YEARS_LOADING, payload: false});
        }
    }