import styles from "screens/V3/StoreScreen/MobileStoreScreen/MobileStoreScreen.module.css";
import { Spin, Tooltip } from "antd";
import { useLanguage } from "hooks/useLanguage";
import StoreCard from "components/V3/StoreCard/StoreCard";
import CartItem from "components/V3/CartItem/CartItem";
import { useUtilities } from "hooks/useUtilities";
import { AnimatePresence, motion } from "framer-motion";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";
import StickyPackageDetails from "./StickyPackageDetails";

interface StoreScreenProps {
  useStore: any;
}

const StoreScreen: FC<StoreScreenProps> = ({ useStore }) => {
  const { t } = useUtilities();
  const { textDirectionStyle } = useLanguage();
  const {
    // CONSTANTS
    cartContainerRef,
    allProducts,
    infoText,
    // STATES
    cartTotal,
    storeCart,

    currentProductID, // when selecting a product (باقة) to show its details
    setCurrentProductID,

    details, // the details of the selected product (باقة)
    setDetails,
    // FUNCTIONS
    deselectProduct,
    updateInCart,
    getActiveState,
    handleCheckout,
  } = useStore;

  return (
    <div
      className="w-full flex justify-center"
      style={
        {
          // minHeight: "calc(100vh - var(--nav-bar-height))",
          // paddingInline: "20rem",
          // paddingBlock: "1.25rem",
        }
      }
      onClick={(e: any) => {
        if (
          e.target.getAttribute("data-store-selected-product") !==
          "dontDeselectProduct"
        ) {
          deselectProduct();
        }
      }}
    >
      <div className="w-full flex flex-col justify-center gap-3">
        <div className="flex-grow h-full">
          <p className={`${styles.infoHeading} flex items-center shadow-focus`}>
            {/* INFO HEADING */}
            {infoText}
          </p>
          <StickyPackageDetails details={details} />
          {/* LEFT CONTAINER */}
          <div className="border-round-s p-3 pb-0">
            {allProducts.map((section: any) => (
              <div className="w-full">
                {/* HEADING */}
                <p
                  className={`${styles.containerHeading} flex items-center gap-4`}
                >
                  {section?.info && (
                    <Tooltip className="cursor-pointer" title={section?.info}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
                  <span>{section?.productType}</span>
                </p>

                {/* PRODUCT CARDS */}
                <div
                  dir={textDirectionStyle}
                  className="w-full flex flex-wrap gap-4 mb-3"
                >
                  {section.products.map((product: any, idx: any) => (
                    <StoreCard
                      key={idx}
                      product={product}
                      cartItem={
                        storeCart.find(
                          (cartItem: any) =>
                            cartItem.product.data_id === product.data_id
                        ) || null
                      }
                      updateInCart={(quantity: number) => {
                        updateInCart(product, quantity);
                      }}
                      activeState={getActiveState(product)}
                      detailsState={[currentProductID, setDetails]}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* RIGHT CONTAINER */}
        <div
          className="w-full flex flex-col gap-3 p-3 "
          // style={{ minWidth: "33.333333333%" }}
        >
          <div
            dir={textDirectionStyle}
            className="shadow-focus border-round-s bg-white"
            style={{
              border: "1px solid var(--new-blue)",
            }}
          >
            {/* BOTTOM - CART ITEMS */}
            <div className="p-[0.9rem] shadow-focus border-round-s flex flex-col justify-between">
              <div className="w-full flex flex-col gap-y-2">
                {/* <p className={`${styles.containerHeading}`}>ملخص الطلب</p> */}
                <div
                  className={`${styles.cartHeading} w-full flex justify-between`}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "5fr 3fr 3fr",
                  }}
                >
                  <p>المنتج</p>
                  <p>السعر</p>
                  <p className="text-end">الكمية</p>
                </div>
                <div
                  dir={textDirectionStyle}
                  ref={cartContainerRef}
                  className="flex flex-col gap-[0.62rem] max-h-[20rem] pb-3 overflow-auto"
                >
                  {storeCart.map((item: any, idx: any) => (
                    // <AnimatePresence>
                    <motion.div
                      className="storeDontDeselect cursor-pointer"
                      key={item.product.data_id} // Assuming 'item' has a unique property 'id'
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2, delay: idx * 0.1 }}
                      onClick={() => {
                        if (currentProductID !== item.product.data_id) {
                          setCurrentProductID(item.product.data_id);
                          setDetails(item.product.details);
                        } else {
                          deselectProduct();
                        }
                      }}
                      onMouseOver={() => {
                        if (!currentProductID) {
                          setDetails(item.product.details);
                        }
                      }}
                      onMouseLeave={() => {
                        if (!currentProductID) {
                          setDetails([]);
                        }
                      }}
                    >
                      <CartItem item={item} />
                    </motion.div>
                    // </AnimatePresence>
                  ))}
                </div>
              </div>
              <div className={`divider`} />
              <div className="w-full flex item-center justify-between">
                <p>الاجمالي</p>
                <p dir="rtl" className="flex justify-end">
                  {cartTotal}ج
                </p>
              </div>
            </div>
          </div>
          <button
            className={`${styles.checkoutContainer} shadow-focus`}
            onClick={() => {
              handleCheckout(storeCart);
            }}
          >
            أتمام الشراء
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreScreen;
