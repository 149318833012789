import
{
    SPARE_PART_TYPE_LOADING,
    SPARE_PART_TYPE_SUCCESS,
    SPARE_PART_TYPE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSparePartTypeService } from "services/listSparePartTypeService";

export const listSparePartTypeAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: SPARE_PART_TYPE_LOADING, payload: true});
        const response = await listSparePartTypeService();
        dispatch({type: SPARE_PART_TYPE_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: SPARE_PART_TYPE_FAILURE, payload: err});
    } finally {
        dispatch({type: SPARE_PART_TYPE_LOADING, payload: false});
    }
}