import { t } from "i18next";
import { useLanguage } from "./useLanguage";
import { format } from "date-fns";

export const useTimeFormat = () => {
  const { currentLanguage, isArabic } = useLanguage();
  const defaultLanguage = isArabic ? "ar-EG" : "en-US";
  
  function formatDate(
    dateString: any,
    lang?: "ar" | "en",
    dateFormat?: string
    ) {
    const inputDate = new Date(dateString);
    
    if (dateFormat) {
      return format(inputDate, dateFormat);
    }

    const language = lang
      ? lang === "ar"
        ? "ar-EG"
        : "en-US"
      : defaultLanguage;
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formatOptions: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    if (inputDate.toDateString() === today.toDateString()) {
      return t("today");
    } else if (inputDate.toDateString() === yesterday.toDateString()) {
      return t("yesterday");
    } else {
      return inputDate.toLocaleDateString(language, formatOptions);
    }
  }

  const getTimeFromDate = (dateStr: string) => {
    var date = new Date(dateStr);
    var datetime = date.toLocaleString(defaultLanguage, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return datetime;
  };

  const getTimeAgo = (dateStr: string) => {
    const timeDifference = new Date(dateStr).getTime() - new Date().getTime();

    const relativeFormat = (
      lang: string,
      value: number,
      duration: Intl.RelativeTimeFormatUnit
    ) => {
      return new Intl.RelativeTimeFormat(lang, {
        numeric: "auto",
      }).format(value, duration);
    };

    const replaceArabic = (str: string) => {
      if (isArabic) return str.replace("قبل", "منذ");
      else return str;
    };

    const timeInYears = Math.ceil(timeDifference / 1000 / 60 / 60 / 24 / 365);
    const timeInMonths = Math.ceil(timeDifference / 1000 / 60 / 60 / 24 / 30);
    const timeInWeeks = Math.ceil(timeDifference / 1000 / 60 / 60 / 24 / 7);
    const timeInDays = Math.ceil(timeDifference / 1000 / 60 / 60 / 24);
    const timeInHours = Math.ceil(timeDifference / 1000 / 60 / 60);
    const timeInMinutes = Math.ceil(timeDifference / 1000 / 60);
    const timeInSeconds = Math.ceil(timeDifference / 1000);

    if (Math.abs(timeInYears) >= 1)
      return replaceArabic(
        relativeFormat(currentLanguage, timeInYears, "years")
      );
    else if (Math.abs(timeInMonths) >= 1)
      return replaceArabic(
        relativeFormat(currentLanguage, timeInMonths, "months")
      );
    else if (Math.abs(timeInWeeks) >= 1)
      return replaceArabic(
        relativeFormat(currentLanguage, timeInWeeks, "weeks")
      );
    else if (Math.abs(timeInDays) >= 1)
      return replaceArabic(relativeFormat(currentLanguage, timeInDays, "days"));
    else if (Math.abs(timeInHours) >= 1)
      return replaceArabic(
        relativeFormat(currentLanguage, timeInHours, "hours")
      );
    else if (Math.abs(timeInMinutes) >= 2)
      return replaceArabic(
        relativeFormat(currentLanguage, timeInMinutes, "minutes")
      );
    else if (Math.abs(timeInSeconds) >= 30)
      return replaceArabic(
        relativeFormat(currentLanguage, timeInSeconds, "seconds")
      );
    else return t("just_now");
  };

  return { getTimeFromDate, getTimeAgo, formatDate };
};
