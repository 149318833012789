import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { RootState } from "redux/rootReducer";
import { getUserDataAction } from "redux/actions/GetUserDataAction/getUserDataAction";
import { RESET_USER_DATA, UPDATE_CUSTOMER_DATA } from "redux/types";

export const useUtilities = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { userData, isExhibitor } = useSelector(
    (state: RootState) => state.verifyOtpReducer
  );

  function isDefined(value: any) {
    return value !== undefined && value !== null;
  }

  async function getUserData() {
    await dispatch(getUserDataAction());
  }

  function resetUserData() {
    dispatch({ type: RESET_USER_DATA });
    dispatch({ type: UPDATE_CUSTOMER_DATA });
  }

  function isValidLink(input: string): boolean {
    // Regular expression pattern to match URLs with either "http://" or "https://"
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;

    // Test if the input string matches the updated URL pattern
    return urlPattern.test(input);
  }

  const print = (value: any) => {};

  function getPreFilledMessage(
    maker: string,
    model: string,
    year: string,
    location: string,
    link: any
  ) {
    const br = "%0a";
    const title = `${t("prefilled_greeting")},${br}${t(
      "prefilled_title"
    )} souq.car${br}${br}`;

    const carManufacturer =
      maker !== undefined ? `${t("prefilled_maker")}: ${maker}` + `${br}` : ``;
    const carModel =
      model !== undefined ? `${t("prefilled_model")}: ${model}` + `${br}` : ``;
    const carYear =
      year !== undefined ? `${t("prefilled_year")}: ${year}` + `${br}` : ``;
    // const carLocation = `${t("prefilled_location")}: ${location}` + `${br}`;
    const postLink = `${br}${t("prefilled_link")}:${br}${link}` + `${br}`;

    const footer = `${br}*${t("prefilled_footer")}*.`;

    return title + carManufacturer + carModel + carYear + postLink + footer;
  }

  async function blobToFile(url: string, mimeType?: string) {
    // Convert url to blob
    const blob = await urlToBlob(url);

    if (blob) {
      // Get URL
      const previewURL = URL.createObjectURL(blob);

      const fileMimeType = mimeType || "image/*";
      const arr = url.split("/");

      // Create a new File object
      const file = new File([blob], arr[arr.length - 1], {
        type: fileMimeType,
        lastModified: Date.now(),
      });

      return { file: file, url: previewURL };
    } else {
      // Handle the case where the conversion failed.
      console.error("Failed to convert URL to Blob.");
      return {
        file: null,
        url: null,
      };
    }
  }

  async function urlToBlob(url: string): Promise<Blob | null> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch the blob data: ${response.status} ${response.statusText}`
        );
      }
      const blobData = await response.blob();
      return blobData;
    } catch (error) {
      console.error("Error converting URL to Blob:", error);
      return null;
    }
  }

  return {
    t,
    navigate,
    dispatch,
    userData,
    print,
    blobToFile,
    isValidLink,
    getPreFilledMessage,
    isExhibitor,
    isDefined,
    getUserData,
    resetUserData,
  };
};
