import styles from "screens/V3/LoginScreen/OTPForm.module.css";
import { Button, Form, Input } from "antd";
import LoginIllustration from "assets/images/LoginIllustration.svg";
import { motion } from "framer-motion";
import { FC } from "react";
import { useLanguage } from "hooks/useLanguage";
import { dotPulse } from "ldrs";
import { InputOTP } from "antd-input-otp";
import { useTimer } from "react-timer-hook";
import { useUtilities } from "hooks/useUtilities";
import FormEditIcon from "assets/icons/icons/FormEditIcon.svg";

interface OTPFormProps {
  onFinish: any;
  mobileNumber: string;
  resendOTP: any;
  goBack: any;
  isLoading?: boolean;
  className?: string;
}

const OTPForm: FC<OTPFormProps> = ({
  onFinish,
  mobileNumber,
  resendOTP,
  goBack,
  className,
  isLoading = false,
}) => {
  const egyptianPhoneNumberPattern = /^(010|011|012|015)\d{8}$/;
  dotPulse.register();

  const { t, navigate } = useUtilities();
  const { currentLanguage } = useLanguage();

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    // autoStart: true,
    expiryTimestamp: new Date(new Date().getTime() + 60000),
    onExpire: () => console.warn("onExpire called"),
  });

  function formatNumber(number: number) {
    return number.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  const resetTimer = () => {
    // Restarts to 5 minutes timer
    const valueInMinutes = 1;
    const time = new Date();
    time.setSeconds(time.getSeconds() + valueInMinutes * 60);
    restart(time);
  };

  return (
    <motion.div
      className={`w-full h-full ${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, delay: 0.3 }}
    >
      <Form
        id="ant-login-form"
        className="w-full h-full flex flex-col items-center justify-between pt-20"
        onFinish={onFinish}
      >
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center justify-between">
            <p
              className="text-[var(--new-blue)]"
              style={{
                fontSize: " 3.4rem",
              }}
            >
              {formatNumber(minutes)}:{formatNumber(seconds)}
            </p>
            <p className="mt-2 mb-8">{t("otp_sent_code")}</p>
          </div>

          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                message: "Please input the 6-digit OTP!",
              },
            ]}
          >
            <InputOTP
              inputType="numeric"
              onKeyDown={(e) => {
                console.log("PRESSSED KEY");
                console.log(e.key);

                // if (e.key === "Enter") {
                //   console.log("Enter");
                // }
              }}
            ></InputOTP>
          </Form.Item>
          <div className="flex gap-x-2">
            <p>{mobileNumber}</p>
            <img
              className="cursor-pointer"
              src={FormEditIcon}
              alt="edit-phone"
              onClick={goBack}
            />
          </div>
          <div>
            <p
              onClick={() => {
                if (seconds == 0 && minutes == 0) {
                  resendOTP();
                  resetTimer();
                }
              }}
              className={`mt-10 ${
                seconds == 0 && minutes == 0
                  ? "cursor-pointer text-[var(--new-blue)]"
                  : "cursor-not-allowed text-[rgba(0,0,0,0.2)]"
              }`}
            >
              أعادة ارسال
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col items-center">
          <p className="souq-xs mb-3">
            بتمابعتك فأنت موافق على{" "}
            <span
              className="cursor-pointer"
              style={{ color: "var(--new-blue)" }}
              onClick={() => navigate(`/${currentLanguage}/privacy-policy`)}
            >
              سياسة الخصوصية
            </span>{" "}
            و{" "}
            <span
              className="cursor-pointer"
              style={{ color: "var(--new-blue)" }}
              onClick={() => navigate(`/${currentLanguage}/terms-of-use`)}
            >
              شروط الاستخدام
            </span>
          </p>
          <Button
            className={`flex items-center justify-center ${styles.customButton}`}
            htmlType="submit"
          >
            {!isLoading ? (
              <p className="souq-large">{t("login")}</p>
            ) : (
              <l-dot-pulse size="25" speed="1.3" color="white"></l-dot-pulse>
            )}
          </Button>
        </div>
      </Form>
    </motion.div>
  );
};

export default OTPForm;
