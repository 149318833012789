import 
{
    EXHIBITOR_SERVICES_LOADING,
    EXHIBITOR_SERVICES_SUCCESS,
    EXHIBITOR_SERVICES_FAILURE
}
from "redux/types";

const initialState = {
    exhibitorServicesLoading: false,
    exhibitorServices: null,
    errors: null
};

export const listExhibitorServicesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case EXHIBITOR_SERVICES_LOADING :
            return {...state, exhibitorServicesLoading: action.payload};
        case EXHIBITOR_SERVICES_SUCCESS :
            return {...state, exhibitorServices: action.payload.data};
        case EXHIBITOR_SERVICES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}